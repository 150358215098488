import { Input, Radio, Form, Collapse } from 'antd'
import { DeleteFilled } from '@ant-design/icons'
import React from 'react'
import styled from 'styled-components'

import Button from 'src/components/Button'
import { RADIO_DATA_TYPE_OPTIONS } from 'src/constants'

const AddParamBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1em;
`

const Panel = styled(Collapse.Panel)`
  box-shadow: 3px 3px 10px 0 rgb(0 0 0 / 10%);
  margin-bottom: 1em;

  &.ant-collapse-item,
  &.ant-collapse-item:last-child {
    border-radius: 0.5rem;
  }
`

/**
 * @typedef PresetSchemaProps
 * @type {object}
 *
 * @param {PresetSchemaProps} props
 * @returns {React.ReactNode}
 */

const PresetSchema = ({ form, edit }) => {
  const renderDeleteBtn = clickHandler => (
    <DeleteFilled
      style={{
        color: edit ? 'grey' : '#d9363e'
      }}
      onClick={e => {
        if (edit) return

        e.stopPropagation()

        clickHandler()
      }}
    />
  )

  return (
    <Form.List name='presetSchema'>
      {(fields, { add, remove }) => (
        <>
          <Collapse defaultActiveKey={[fields?.[fields.length - 1]?.key]} key={fields.length} ghost>
            {fields.map((field, i) => {
              const schemaKey = form.getFieldValue(['presetSchema', field.name, 'key'])

              return (
                <Panel
                  header={`Key ${schemaKey ?? ''}`}
                  key={field.key}
                  extra={renderDeleteBtn(() => {
                    remove(i)
                  })}
                  forceRender
                >
                  <Form.Item
                    label='Key name'
                    name={[field.name, 'key']}
                    required
                    rules={[
                      {
                        required: true
                      }
                    ]}
                    hasFeedback
                  >
                    <Input disabled={edit} placeholder='Key name' />
                  </Form.Item>
                  <Form.Item
                    label='Type'
                    name={[field.name, 'dataType']}
                    required
                    rules={[
                      {
                        required: true
                      }
                    ]}
                    hasFeedback
                  >
                    <Radio.Group
                      options={RADIO_DATA_TYPE_OPTIONS}
                      buttonStyle='solid'
                      optionType='button'
                      disabled={edit}
                    />
                  </Form.Item>
                </Panel>
              )
            })}
          </Collapse>

          <AddParamBtnContainer>
            <Button
              onClick={e => {
                e.preventDefault()

                add()
              }}
            >
              Add preset key
            </Button>
          </AddParamBtnContainer>
        </>
      )}
    </Form.List>
  )
}

export default PresetSchema
