import { Drawer, Tabs } from 'antd'
import styled from 'styled-components'

import { useAuth, useTenant } from 'src/hooks'
import * as c from 'src/constants'
import NodeSettingsTab from 'src/components/FlowEditor/SettingsDrawer/NodeSettingsTab'
import FlowSettingsTab from 'src/components/FlowEditor/SettingsDrawer/FlowSettingsTab'
import LogTab from 'src/components/FlowEditor/SettingsDrawer/LogTab'

const SettingsDrawer = styled(Drawer)`
  .ant-drawer-body{
    display: flex;
    flex-direction: column;
    padding: 24px 0;
  }

  & > .ant-drawer-content-wrapper > .ant-drawer-content > .ant-drawer-wrapper-body > .ant-drawer-body > .ant-tabs > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane > .ant-timeline > .ant-timeline-item > .ant-timeline-item-content > .ant-collapse {
    margin-right: 0;
  }
`

const StyledTabs = styled(Tabs)`
  & {
    flex: 1;
  }

  .ant-tabs-content-holder {
    overflow-y: auto;
  }

  & > .ant-tabs-nav {
    margin: 0 24px;
  }

  & > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
    padding: 24px;
  }

  .ant-tabs-tabpane,
  .ant-tabs-content-holder {
    display: flex;
  }

  .ant-form {
    max-width: 100%;
    width: 100%;
  }
`

const NodeSettingsDrawer = props => {
  const { drawerProps, visibleNodeId, nodes = [], activeKey, handleTabClick } = props
  const currentNode = nodes.find(node => node.id === visibleNodeId) ?? nodes?.[0]

  return (
    <SettingsDrawer
      {...drawerProps}
      title={`Settings ${currentNode?.AlgorithmNode?.name ?? 'Node'}`}
      forceRender
      width={400}
    >
      <StyledTabs activeKey={activeKey} onTabClick={handleTabClick}>
        <Tabs.TabPane
          forceRender
          tab='Flow'
          key='flow'
        >
          <FlowSettingsTab {...props} />
        </Tabs.TabPane>
        <Tabs.TabPane
          forceRender
          tab={currentNode?.AlgorithmNode?.name ?? 'Node'}
          key='node'
          disabled={currentNode == null}
        >
          <NodeSettingsTab {...props} currentNode={currentNode} visibleNodeId={visibleNodeId ?? currentNode?.id} />
        </Tabs.TabPane>
        {/**
         * @todo Acitvate App logs
         */}
        {/* <Tabs.TabPane
          tab='Logs'
          key='logs'
        >
          <LogTab {...props} />
        </Tabs.TabPane> */}
      </StyledTabs>
    </SettingsDrawer>
  )
}

export default NodeSettingsDrawer
