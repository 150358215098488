import { Form, Input, Select, Switch } from 'antd'
import { REACT_FLOW_HANDLE_POSITION_OPTIONS } from 'src/constants'

const { Item } = Form

const REACT_FLOW_HANDLE_TYPE_OPTIONS = [
  { label: 'Target', value: 'target' },
  { label: 'Source', value: 'source' }
]

const HandleFields = ({ field }) => {
  return (
    <>
      <Item
        {...field}
        name={[field.name, 'id']}
        fieldKey={[field.fieldKey, 'id']}
        hidden
      >
        <Input />
      </Item>
      <Item
        {...field}
        label='Tag'
        name={[field.name, 'tag']}
        fieldKey={[field.fieldKey, 'tag']}
      >
        <Input />
      </Item>
      <Item
        {...field}
        label='Type'
        name={[field.name, 'type']}
        fieldKey={[field.fieldKey, 'type']}
      >
        <Select
          options={REACT_FLOW_HANDLE_TYPE_OPTIONS}
        />
      </Item>
      <Item
        {...field}
        label='Position'
        name={[field.name, 'position']}
        fieldKey={[field.fieldKey, 'position']}
      >
        <Select
          options={REACT_FLOW_HANDLE_POSITION_OPTIONS}
        />
      </Item>
      <Item
        {...field}
        label='Is connectable'
        name={[field.name, 'isConnectable']}
        fieldKey={[field.fieldKey, 'isConnectable']}
      >
        <Switch />
      </Item>
      <Item
        {...field}
        label='Additional class names'
        name={[field.name, 'className']}
        fieldKey={[field.fieldKey, 'className']}
      >
        <Input />
      </Item>
    </>
  )
}

export default HandleFields
