import AdminList from 'src/components/AdminList'
import { navigate } from '@redwoodjs/router'

import { useTenant } from 'src/hooks'
import { useAlgorithmNodes } from 'src/hooks/algorithmNodes'
import { Dropdown, Menu, Space } from 'antd'
import DeleteCheckModal from 'src/components/DeleteCheckModal'
import styled from 'styled-components'

const DropdownLink = styled.a`
  font-size: 1.5rem;
  color: ${props => props?.colors?.['card.menu.color'] || '#C4C4C4'};
`

const MenuItem = styled(Menu.Item)`
  text-align: center;
`

const NodesTab = (props) => {
  const { getRoute } = useTenant()
  const { algorithmNodes, deleteAlgorithmNode, loading } = useAlgorithmNodes()

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Description',
      dataIndex: 'description'
    },
    {
      title: 'Global',
      dataIndex: 'global',
      render: global => global ? <i className='fas fa-check' /> : null
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        const menu = (
          <Menu>
            <MenuItem>
              <DeleteCheckModal
                titleText={`Are you sure you want to delete this node ${record?.name ? `(name: ${record?.name})` : ''}?`}
                deleteFn={() => deleteAlgorithmNode(record.id)}
                deleteBtnText='Yes, delete'
                cancelBtnText='No, cancel'
              />
            </MenuItem>
          </Menu>
        )

        return (
          <Space size='middle'>
            <div onClick={(e) => e.stopPropagation()}>
              <Dropdown
                overlay={menu}
                placement='bottomRight'
                trigger={['click']}
              >
                <DropdownLink className='ant-dropdown-link' onClick={e => e.stopPropagation()}>
                  <i className='fas fa-ellipsis-v' />
                </DropdownLink>
              </Dropdown>
            </div>
          </Space>
        )
      }
    }
  ]

  const handleAdd = () =>
    navigate(getRoute('flowNodePage', { id: 'add' }))


  const handleClick = (record) => {
    const { id } = record

    navigate(getRoute('flowNodePage', { id }))
  }

  return (
    <AdminList
      {...props}
      onAddClick={handleAdd}
      handleClick={handleClick}
      data={algorithmNodes}
      columns={columns}
      loading={loading}
    />
  )
}

export default NodesTab
