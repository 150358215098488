import { Modal, Button as AntButton } from 'antd'
import { useSpaces } from 'src/hooks'
import { WarningOutlined, DeleteFilled } from '@ant-design/icons'

import styled from 'styled-components'

const { confirm } = Modal

const DeleteFont = styled.span`
  text-decoration: underline;
`

const DeleteSensor = ({
  space,
  refresh,
  handleRemove,
  setVisible,
  icon
}) => {
  const { deleteSpace } = useSpaces({ fetch: false })

  const handleClick = space => {
    const { id, name } = space
    confirm({
      title: 'Danger zone',
      icon: <WarningOutlined />,
      content: [
        <div key='info'>
          <p>{`Are you sure you want to delete ${name}`}</p>
          <ul>
            <li>This action is irreversible.</li>
            <li>Deleting a space will remove all associated information from our servers.</li>

          </ul>
        </div>
      ],
      okButtonProps: { size: 'large', type: 'primary' },
      cancelButtonProps: { size: 'large' },
      okText: `Yes, delete ${name}`,
      okType: 'danger',
      cancelText: 'Cancel',
      onOk () {
        deleteSpace(id)
          .then((res) => {
            refresh?.()
            handleRemove?.(res)
            setVisible(false)
          })
          .catch(() => {})
      }
    })
  }
  return (

    <AntButton
      type='link'
      danger
      size='large'
      key='cancel'
      onClick={e => {
        e.stopPropagation()
        handleClick(space)
      }}
    >
      <DeleteFont>{icon ? <DeleteFilled /> : 'Delete'}</DeleteFont>
    </AntButton>

  )
}

export default DeleteSensor
