import { message } from 'antd'
import { useCallback, useEffect, useState } from 'react'

import { apiInstance as api } from 'src/api'
import { useIsMounted } from 'src/hooks/mounted'
import { useTenant } from 'src/hooks/tenant'

export const useSensorTypes = ({ fetch = true } = {}) => {
  const mounted = useIsMounted()
  const { tenantId, isValidScopeFetch } = useTenant()
  const [sensorTypes, setSensorTypes] = useState([])
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())

  const refresh = () => setLastUpdate(new Date())

  const adminAddSensorType = useCallback((SensorType) => {
    return new Promise((resolve, reject) => {
      return api.adminAddSensorType(SensorType)
        .then((res) => {
          message.success(res.message || 'Added SensorType')
          resolve(res.data)
        })
        .catch((err) => {
          message.error(err.response.data.error || 'Unable to add SensorType')
          reject(err)
        })
    })
  }, [])

  const adminUpdateSensorType = useCallback((SensorType) => {
    return new Promise((resolve, reject) => {
      console.log('SensorType hooks', SensorType)
      return api.adminUpdateSensorType(SensorType)
        .then((res) => {
          message.success(res.message || 'Updated SensorType')
          resolve(res.data)
        })
        .catch((err) => {
          message.error(err.response.data.error || 'Unable to update SensorType')
          reject(err)
        })
    })
  }, [])

  const adminDeleteSensorType = useCallback((SensorType) => {
    return new Promise((resolve, reject) => {
      const { id } = SensorType
      return api.adminDeleteSensorType(id)
        .then((res) => {
          message.success(res.message || 'Deleted SensorType')
          resolve(res.data)
        })
        .catch((err) => {
          message.error(err.response.data.error || 'Unable to delete SensorType')
          reject(err)
        })
    })
  }, [])

  useEffect(() => {
    if (!fetch) return
    setLoading(true)
    api.getSensorTypes()
      .then(({ data, config }) => {
        if (mounted() && isValidScopeFetch(config)) {
          setSensorTypes(data)
        }
      })
      .catch(e => {
        if (mounted()) {
          setSensorTypes([])
          setError(e)
        }
      })
      .finally(() => {
        if (mounted()) setLoading(false)
      })
  }, [lastUpdate, tenantId])

  return {
    sensorTypes,
    loading,
    error,
    adminDeleteSensorType,
    adminUpdateSensorType,
    adminAddSensorType,
    refresh
  }
}
