export class CachedToken {
  #token

  constructor() {
    this.getCachedToken()
  }

  set = (token) => {
    const cachedToken = this.cleanToken(token)
    localStorage.setItem('token', JSON.stringify(cachedToken))
    this.#token = cachedToken

    return this.#token
  }

  getCachedToken = () => {
    this.#token = JSON.parse(localStorage.getItem('token') ?? '{}')
  }

  get = () => this.#token

  cleanToken = (token) => {
    return token.split(' ')?.[1] ?? ''
  }

  remove = () => {
    localStorage.removeItem('token')
    this.#token = null

    return this.#token
  }
}

export default new CachedToken()
