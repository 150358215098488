import React from 'react'
import { Popconfirm, Space, Table } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

import Button from 'src/components/Button'
import SensorTenantAccessModal from 'src/components/SensorTenantAccessModal'
import { useAuth, useTenant, useTenants } from 'src/hooks'

/**
 * @typedef SensorTenantAccessProps
 * @type {object}
 *
 * @param {SensorTenantAccessProps} props
 * @returns {React.ReactNode}
 */

const AdminSensorAccess = ({ sensor = {}, refresh }) => {
  const { id, tenants } = sensor
  const { isTenantScope } = useTenant()
  const { hasRole } = useAuth()
  const { unbindSensorTenantRel } = useTenants({ fetch: false })

  const handleDeleteTenant = (record) => {
    unbindSensorTenantRel(record.id, id)
      .then((res) => {
        refresh()
      })
      .catch(() => {})
  }

  const tenantColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: name => <a>{name}</a>
    },
    {
      title: 'Active',
      dataIndex: 'TenantSensor',
      key: 'active',
      responsive: ['md'],
      render: ({ active }) => active ? <i className='fas fa-check' /> : ''
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size='middle'>
          <div onClick={(e) => e.stopPropagation()}>
            <Popconfirm
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title='Are you sure delete this sensor?'
              onConfirm={() => handleDeleteTenant(record)}
              okText='Yes, delete'
              cancelText='No'
            >
              <a href='#'>Delete</a>
            </Popconfirm>
          </div>
        </Space>
      )
    }
  ]

  return (
    <>
      {!isTenantScope() && hasRole('superAdmin') && (
        <SensorTenantAccessModal
          refresh={refresh}
          sensorTenants={tenants}
          sensorId={id}
        >
          <Button
            style={{ margin: '1rem 0 1rem 1rem' }}
          >
            Add client
          </Button>
        </SensorTenantAccessModal>
      )}
      {!isTenantScope() && hasRole('superAdmin') && tenants && (
        <Table
          dataSource={tenants}
          columns={tenantColumns}
        />
      )}
    </>
  )
}

export default AdminSensorAccess
