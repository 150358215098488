import { useEffect, useState } from 'react'
import QrReader from 'react-qr-reader'
import { message, Alert } from 'antd'
import styled from 'styled-components'

const QrWrap = styled.div`
  margin: 1em;
  & .qr-image-container section {
    border-radius: 1em;
  }

  & .qr-image-container section div {
    box-shadow: unset !important;
    border: 50px solid rgba(0114, 151, 255, 0.3) !important;
    border-radius: 1em;
  }
`

const AlertContainer = styled.div`
  margin: 1em 0;
`

const QrScanner = (props) => {
  const { setData, messageKey, setCameraError } = props
  const [secure, setSecure] = useState()
  const handleScan = data => {
    if (data) {
      message.success({
        content: 'Successfully scanned QR',
        duration: 2,
        key: messageKey
      })
      setData(data)
    }
  }

  const handleError = err => {
    setCameraError(err)
  }

  const handleInit = e => {
    message.loading({
      content: 'Scanning for QR-code',
      duration: 0,
      key: messageKey
    })
  }

  useEffect(() => {
    if (window.location.protocol === 'https:' || window.location.hostname === 'localhost') {
      setSecure(true)
    } else {
      setSecure(false)
    }
  }, [])

  return (
    <QrWrap>
      {secure
        ? (
          <QrReader
            className='qr-image-container'
            delay={300}
            onError={handleError}
            onScan={handleScan}
            onLoad={handleInit}
            facingMode='environment'
            style={{
              width: '100%'
            }}
          />)
        : (
          <AlertContainer>
            <Alert message='Camera error' description='QR scanner is only allowed over HTTPS' type='error' showIcon />
          </AlertContainer>
        )}
    </QrWrap>
  )
}

export default QrScanner
