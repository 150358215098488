import React, { Fragment, useEffect } from 'react'
import { Select, Form, Input } from 'antd'

import { useAdminCommands } from 'src/hooks'
import { COMMAND_PARAM_USER_UI_OPTIONS } from 'src/constants'

const CommandConfig = {
  render: ({ optionValueKey, optionLabelKey, ...props }) => {
    const { commands, loading } = useAdminCommands()

    const commandOptions = commands.map(command => ({
      label: command.command,
      value: command.command
    }))
    const { CommandParamUis = []} = commands.find(({ command }) => command === props?.formInstance?.getFieldValue([...props.fieldName, 'command'])) ?? {}
    const commandParamOptions = CommandParamUis.map(paramUi => ({
      label: paramUi?.title ?? paramUi?.buttonName,
      value: paramUi?.id
    }))
    const { params = [] } = CommandParamUis.find(paramUi => paramUi?.id === props?.formInstance?.getFieldValue([...props.fieldName, 'paramUiId'])) ?? {}

    return (
      <>
        <Select
          {...props}
          label="Command"
          placeholder='Select command'
          name='command'
          showSearch
          showArrow
          loading={loading}
          options={commandOptions}
        />
        {Array.isArray(commandParamOptions) && commandParamOptions?.length && (
          <Select
            {...props}
            label="Action"
            placeholder='Select command'
            name='paramUiId'
            showSearch
            showArrow
            loading={loading}
            options={commandParamOptions}
          />
        )}
        {params?.map((param, i) => {
          if (param?.value) return <Input initialValue={param.value} name={['params', i]} hidden />
          const { comp: InputComponent = Input } = COMMAND_PARAM_USER_UI_OPTIONS
            .find(({ value }) => param.uiComponent === value) ?? {}

          return <InputComponent {...props} label={param.label} name={['params', i]} />
        })}
      </>
    )
  }
}

export default {
  CommandConfig
}
