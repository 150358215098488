import { message } from 'antd'
import { useEffect, useState } from 'react'
import Bugsnag from '@bugsnag/js'

import { apiInstance as api } from 'src/api'
import { useTenant } from 'src/hooks'
import { useIsMounted } from 'src/hooks/mounted'

export const useTemperatureControl = (spaceId) => {
  const ALGO_KEY = 'temperatureControlA'
  const mounted = useIsMounted()
  const { tenantId, isValidScopeFetch } = useTenant()
  const [intervals, setIntervals] = useState([])
  const [days, setDays] = useState([])
  const [configurableSettings, setConfigurableSettings] = useState()
  const [intervalKeys, setIntervalKeys] = useState([])
  const [restKeys, setRestKeys] = useState([])
  const [response, setResponse] = useState()
  const [temperatures, setTemperatures] = useState() // structured for component

  // format body according to api
  const formatToApi = (componentPayload) => {
    const flatArr = componentPayload.reduce((acc, cur) => [...acc, ...cur], [])
    if (flatArr.length !== intervalKeys.length) {
      message.warn('Different lengths input / output in Temperature Control', 2)
    }
    const structured = intervalKeys.reduce(
      (acc, key, i) => ({
        ...acc,
        [key]: {
          ...configurableSettings[key],
          value: flatArr[i]
        }
      }), {})
    return structured
  }

  // format to temperature controls component prefered format
  const getPayload = () => {

  }

  const save = (componentPayload) =>
    new Promise((resolve, reject) => {
      const apiPayload = formatToApi(componentPayload)
      const nonIntervalSettings = restKeys.reduce((acc, key) => ({
        ...acc,
        [key]: {
          ...configurableSettings[key],
          value: configurableSettings[key].default
        }
      }), {})
      const userSettings = {
        user: {
          ...apiPayload,
          ...nonIntervalSettings
        }
      }
      const jsonStr = JSON.stringify(userSettings)
      const payload = {
        userSettings: jsonStr
      }
      api.updateOrAddAlgoByKeyForSpace(ALGO_KEY, spaceId, payload)
        .then((res) => {
          message.success('Successfully set temperature control! 🚀')
          resolve(res.data)
        })
        .catch((err) => {
          message.error(err.message)
          console.log('hooks.useTempControl.save', err)
          reject(err)
        })
    })

  const parseJSONSettings = (jsonStr) => {
    const configurableSettings = JSON.parse(jsonStr)
    const { user = {} } = configurableSettings
    setConfigurableSettings(user)
    const objKeys = Object.keys(user)
    const sorted = objKeys.reduce((acc, key) => {
      const isInterval = key.indexOf('-') > -1
      return {
        intervalKeys: isInterval
          ? [...acc.intervalKeys, key]
          : acc.intervalKeys,
        rest: !isInterval
          ? [...acc.rest, key]
          : acc.rest
      }
    }, { intervalKeys: [], rest: [] })
    const intervalKeys = sorted.intervalKeys
    console.log(sorted.rest, intervalKeys)
    setRestKeys(sorted.rest)
    setIntervalKeys(intervalKeys)
    const structured = intervalKeys.reduce((acc, interval) => {
      const [dayStr, rangeStart, rangeEnd] = interval.split('-')
      const range = [rangeStart, rangeEnd].join('-')
      const day = parseInt(dayStr, 10)
      const defaultTemp = 23
      const tempValue = user[interval].value || defaultTemp
      return {
        days: acc.days
          ? [...acc.days, day]
          : [day],
        intervals: acc.intervals
          ? [...acc.intervals, range]
          : [range],
        temperatures: acc.temperatures
          ? [...acc.temperatures, tempValue]
          : [tempValue]
      }
    }, {})
    // remove duplicates
    const days = [...new Set(structured.days)]
    const intervals = [...new Set(structured.intervals)]
    const temperatures = days.reduce((acc, _, i) => {
      const startIndex = i * intervals.length
      const endIndex = (i + 1) * intervals.length
      const part = structured.temperatures.slice(startIndex, endIndex)
      return [...acc, part]
    }, [])
    return {
      days,
      intervals,
      temperatures
    }
  }

  const setSettings = (settingsObj) => {
    const { days, intervals, temperatures } = settingsObj
    setDays(days)
    setIntervals(intervals)
    setTemperatures(temperatures)
    console.log(temperatures)
  }

  useEffect(() => { // get info from backend
    api.getAlgoByKeyForSpace(ALGO_KEY, spaceId)
      .then(({ data, config, status }) => {
        if (status === 200 && mounted() && isValidScopeFetch(config)) {
          setResponse(data)
          const settings = parseJSONSettings(data.userSettings)
          setSettings(settings)
        }
      })
      .catch((err) => {
        Bugsnag.notify(err)
        const res = err.response
        if (res.status === 404) {
          api.getAlgoByKey(ALGO_KEY)
            .then(({ data, config }) => {
              if (mounted() && isValidScopeFetch(config)) {
                setResponse(data)
                const settings = parseJSONSettings(data.configurableSettings)
                setSettings(settings)
              }
            })
            .catch((err) => message.error(err.message))
        } else {
          message.error(err.message)
        }
      })
  }, [spaceId, tenantId])

  return {
    days,
    configurableSettings,
    intervals,
    save,
    temperatures
  }
}
