import styled from 'styled-components'
import { Spin as AntSpin } from 'antd'

const SpinContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const Spin = (props) => {
  const { size = 'large' } = props
  return (
    <SpinContainer>
      <AntSpin size={size} />
    </SpinContainer>
  )
}

export default Spin
