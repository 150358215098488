import { useCallback, useEffect, useRef } from 'react'

/**
 * @returns {() => boolean}
 */
export const useIsMounted = () => {
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  return useCallback(() => mounted.current, [mounted])
}