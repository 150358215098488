import { useEffect, useState } from 'react'
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  Radar,
  Tooltip,
  Label
} from 'recharts'
import styled from 'styled-components'
import ResponsiveContainer from 'src/components/ResponsiveContainer/ResponsiveContainer'
import { Checkbox } from 'antd'
import {
  useTheme,
  useCurrentState
} from 'src/hooks'
import { config, restructure } from 'src/libraries/measurements'

const StyledRadarChart = styled(RadarChart)`
  .recharts-polar-grid-angle > line {
    stroke-width: 2;
    stroke: #d6d6d6;
  }
  .recharts-polar-grid-concentric > path {
    &:last-child {
      stroke: #d6d6d6;
      opacity: 0.5;
    }
    stroke: transparent;
  }
`

const RadarChartTicks = (props) => {
  const {
    onCheckClick,
    checkedKeys,
    data: _data,
    latestMeasurements,
    outerRadius = '90%',
    aspectRatio = 4 / 3,
    ...rest
  } = props
  const [data, setData] = useState()
  const { units } = config

  useEffect(() => {
    const { radar } = restructure(_data)
    setData(radar)
  }, [_data])

  const labelFormatter = (label) => units[label].displayName
  const valueFormatter = (_, key, config = {}) => {
    const { payload, dataKey } = config
    const unit = units[payload.unit]
    let value
    const {
      acceptableValues: av,
      comfortableValues: cv
    } = unit
    if (dataKey === 'comfortable') {
      value = `${cv.min}-${cv.max}`
    } else if (dataKey === 'acceptable') {
      value = `${av.min}-${av.max}`
    } else if (dataKey === 'current') {
      const latest = latestMeasurements[payload.unit]
      value = latest && parseInt(latest)
    }
    const displayTip = value
      ? `${value} ${unit.unit}`
      : '-'
    return displayTip
  }

  return (
    <ResponsiveContainer aspect={aspectRatio} {...rest}>
      <StyledRadarChart
        outerRadius={outerRadius}
        data={data}
      >
        <PolarGrid />
        <PolarAngleAxis
          dataKey='unit'
          tickFormatter={(key) => config.units[key].displayName}
        />
        <Radar
          name='Current'
          dataKey='current'
          fill='rgba(64, 108, 240, 0.5)'
          stroke='transparent'
          fillOpacity={0.5}
        />
        <Radar
          name='Acceptable'
          dataKey='acceptable'
          fill='rgba(52, 65, 103, 0.2)'
          stroke='transparent'
          fillOpacity={0.5}
        />
        <Radar
          name='Comfortable'
          dataKey='comfortable'
          fill='rgba(52, 65, 103, 0.2)'
          stroke='transparent'
          fillOpacity={0.25}
        />
        <Tooltip
          formatter={valueFormatter}
          labelStyle={{ color: 'black' }}
          itemStyle={{ color: 'black' }}
          labelFormatter={labelFormatter}
        />
      </StyledRadarChart>
    </ResponsiveContainer>
  )
}

export default RadarChartTicks
