import styled, { css } from 'styled-components'
import { Slider } from 'antd'
import { useState, useEffect } from 'react'

import Button from 'src/components/Button'

import { useTemperatureControl } from 'src/hooks'

const WEEKDAYS = [
  {
    short: 'Mo',
    displayName: 'Monday'
  },
  {
    short: 'Tu',
    displayName: 'Tuesday'
  },
  {
    short: 'We',
    displayName: 'Wednesday'
  },
  {
    short: 'Th',
    displayName: 'Thursday'
  },
  {
    short: 'Fr',
    displayName: 'Friday'
  },
  {
    short: 'Sa',
    displayName: 'Saturday'
  },
  {
    short: 'Su',
    displayName: 'Sunday'
  }
]

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
`

const Main = styled(Column)`
  min-width: 1024px;
`

const TopRight = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Cross = ({ className = '', ...props }) => <i className={`fas fa-times ${className}`} {...props} />

const CloseButton = styled(Cross)`
  color: red;
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`

const Title = styled.p`
  font-size: 2.5rem;
  font-weight: lighter;
  margin-bottom: 0.25rem;
  color: black;
`

const YInfoWrapper = styled.div`
  display: flex;
  padding-right: 1rem;
  flex-direction: column;
  justify-content: space-between;
  font-style: italic;
  font-weight: lighter;
  font-size: 0.75rem;
  padding-bottom: 4rem; // for the labels
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
`

const Subtitle = styled.p`
  font-size: 1.5rem;
  color: ${props => props.theme.primaryColor || '#7297ff'};
  margin-bottom: 0;
`

const SliderLabel = styled.p`
  transform: rotate(45deg);
  transform-origin: center center;
  color: black;
  font-size: 0.75rem;
  position: absolute;
  bottom: -4rem;
  justify-self: center;
`

const DayWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`

const Day = styled.div`
  background-color: white;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  color: ${props => props.theme.primaryColor || '#7297ff'};
  display: flex;
  margin-right: 0.25rem;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  border-radius: 10px;
  cursor: pointer;
  ${props => props.selected && css`
    background-color: ${props => props.theme.primaryColor || '#7297ff'};
    color: ${props => props.theme.primaryTextColor || 'white'};
  `}
`

const TempGaugeWrapper = styled.div`

  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const ComfortLabel = styled.p`
  color: #a1d9ae;
`

const ContentBox = styled.div`
  border-left: 2px solid #cbcbcb;
  border-bottom: 2px solid #cbcbcb;
  min-height: 512px;
  display: flex;
  flex: 1;
  margin-bottom: 4rem; // for the labels
`

const SliderWrapper = styled(Column)`
  align-items: center;
`

const TitleRow = styled(Row)`
  align-items: center;
`

const comfortMark = (value) => ({
  style: { color: '#a1d9ae' },
  label: <span>{value}</span>
})

const marks = {
  22: comfortMark('22C'),
  25: comfortMark('25C')
}

const _Slider = (props) =>
  <Slider
    vertical
    max={32}
    min={16}
    marks={marks}
    {...props}
  />

const CustomSlider = styled(_Slider)`
  & .ant-slider-handle {
    width: 32px;
    height: 32px;
    border: 0;
    box-shadow: 0 3px 8px rgba(0,0,0,0.2);
    margin-left: -14px;
    ${props => props.readOnly && css`
      width: 0;
      height: 0;
    `}
  }
  & .ant-slider-track {
    background-color: ${props => props.theme.primaryColor || '#7297ff'};
  }
  & .ant-slider-mark-text {
    left: 12px;
  }
  & .ant-slider-dot {
    border-color: #a1d9ae;
    background-color: #a1d9ae;
  }
`

const Footer = styled(Row)`
  padding-top:2rem;
  & > * {
    margin-right: 0.5rem;
  }
`

const TemperatureControl = (props) => {
  const {
    onClose: handleClose,
    readOnly = false,
    space
  } = props
  const [selectedDay, setSelectedDay] = useState(0)
  const [userTemperatures, setUserTemperatures] = useState(props.temperatures || [])
  const {
    intervals,
    days,
    getPayload,
    temperatures,
    save
  } = useTemperatureControl(space.id)
  const weekdays = days
  const currentTemperatures = userTemperatures[selectedDay]
  useEffect(() => {
    if (!temperatures) return
    setUserTemperatures(temperatures)
  }, [temperatures])
  useEffect(() => {
    if (userTemperatures.length === 0) {
      if (weekdays.length === 0 || intervals.length === 0) return
      console.log('populating')
      const defaults = weekdays.map((_, wi) => intervals.map((_, i) => 18 + i + wi))
      setUserTemperatures(defaults)
    }
  }, [intervals, weekdays])

  const { displayName: dayName } = WEEKDAYS[selectedDay] || {}
  const handleSave = () => {
    // close modal after done
    save(userTemperatures)
      .then(() => handleClose())
  }
  const handleDayClick = (index) =>
    () => {
      setSelectedDay(index)
    }

  const handleSliderChange = (index) =>
    (value) => {
      if (readOnly) return
      setUserTemperatures(temps => {
        return temps.map((data, wi) => wi === selectedDay // find right row
          ? data.map((interval, ii) => ii === index // find right index
            ? value // set new slider value
            : interval)
          : data)
      })
    }

  const displayTitle = space
    ? space.name
    : 'Temperature'
  return (
    <Main>
      <TopRight>
        {/* <CloseButton onClick={handleClose} /> */}
      </TopRight>
      <TitleRow>
        <TitleWrapper>
          <Title>{displayTitle}</Title>
          <Subtitle>{dayName}s</Subtitle>
        </TitleWrapper>
        <DayWrapper>
          {weekdays.map(
            (dayIndex, i) =>
              <Day
                key={i}
                onClick={handleDayClick(i)}
                selected={i === selectedDay}
              >{WEEKDAYS[dayIndex].short}
              </Day>
          )}
        </DayWrapper>
        <TempGaugeWrapper />
      </TitleRow>
      <Row>
        <YInfoWrapper>
          <div>Warmer</div>
          <ComfortLabel>Comfort</ComfortLabel>
          <div>Colder</div>
        </YInfoWrapper>
        <ContentBox>
          {intervals.map((_interval, i) => {
            const display = _interval.split('-')
              .map((hour) => hour + ':00')
              .join('-')
            const temp = currentTemperatures && currentTemperatures[i]
            return (
              <SliderWrapper key={_interval}>
                <CustomSlider readOnly={readOnly} onChange={handleSliderChange(i)} value={temp} />
                <SliderLabel>{display}</SliderLabel>
              </SliderWrapper>
            )
          })}
        </ContentBox>
      </Row>
      <Footer>
        <Button key='submit' onClick={handleSave}>Save</Button>
        <Button key='back' onClick={handleClose} outline>Cancel</Button>
      </Footer>
    </Main>
  )
}

export default TemperatureControl
