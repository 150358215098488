import React from 'react'
import ReactDOM from 'react-dom'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { RedwoodProvider, FatalErrorBoundary } from '@redwoodjs/web'
import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'
import { customAuth } from 'src/libraries/auth'
import 'src/index.css'
import 'antd/dist/antd.less'
import 'antd-mobile/dist/antd-mobile.less'
import '@fortawesome/fontawesome-free/js/all.js'

import { AuthProvider } from 'src/contexts/auth'
import { Provider } from 'src/context'
import { Provider as TenantProvider } from 'src/contexts/tenant'

const Passthrough = (props) => props.children

const bugsnagKey = process.env.BUGSNAG_API_KEY
if (bugsnagKey) {
  Bugsnag.start({
    apiKey: bugsnagKey,
    plugins: [new BugsnagPluginReact()]
  })
} else {
  console.log('⚠️', 'No bugsnag API key set, ignoring.')
}
const ErrorBoundary = Bugsnag.getPlugin('react')
  ?.createErrorBoundary(React) || Passthrough

const externalStylesheets = [
  { href: 'https://unpkg.com/react-gif-player@0.4.2/dist/gifplayer.css' }
]

const createLink = (stylesheet) => {
  const {
    rel = 'stylesheet',
    href,
    type = 'text/css'
  } = stylesheet
  var resource = document.createElement('link')
  resource.setAttribute('rel', rel)
  resource.setAttribute('href', href)
  resource.setAttribute('type', type)
  return resource
}

const loadExternalStylesheetsAsync = () => {
  const links = externalStylesheets.map(createLink)
  const head = document.getElementsByTagName('head')[0]
  for (const link of links) {
    head.appendChild(link)
  }
}

loadExternalStylesheetsAsync()

ReactDOM.render(
  <FatalErrorBoundary page={FatalErrorPage}>
    <ErrorBoundary>
      <AuthProvider client={customAuth()} type='custom'>
        <Provider>
          <RedwoodProvider>
            <TenantProvider>
              <Routes />
            </TenantProvider>
          </RedwoodProvider>
        </Provider>
      </AuthProvider>
    </ErrorBoundary>
  </FatalErrorBoundary>,
  document.getElementById('redwood-app')
)
