import AdminList from 'src/components/AdminList'
import { useIssues } from 'src/hooks'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: text => <a>{text}</a>
  },
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name'
  },
  {
    title: 'Co2',
    dataIndex: 'co2',
    key: 'co2'
  },
  {
    title: 'Temp',
    dataIndex: 'temp',
    key: 'temp'
  },
  {
    title: 'VOC',
    dataIndex: 'voc',
    key: 'voc'
  }
]

const data = [
  {
    key: '1',
    id: '3c:71:bf:2e:55:e5',
    name: 'Sensor 429: Cloud Garden - Testbord',
    co2: 4,
    temp: 10,
    voc: 21
  },
  {
    key: '2',
    id: '3c:71:bf:2e:55:e5',
    name: 'Sensor 429: Cloud Garden - Testbord',
    co2: 4,
    temp: 10,
    voc: 21
  }
]

const AdminMissingData = (props) => {
  const { typeName } = props
  const { issues } = useIssues({ code: 9 }) // get issues with code 9
  return (
    <AdminList
      addAction={false}
      search={false}
      typeName={typeName}
      data={issues}
      columns={[
        {
          title: 'Timestamp',
          dataIndex: 'time'
        },
        {
          title: 'Sensor',
          dataIndex: 'deviceId'
        },
        {
          title: 'Message',
          dataIndex: 'message'
        },
        {
          title: 'Value',
          dataIndex: 'value'
        }
      ]}
    />
  )
}

export default AdminMissingData
