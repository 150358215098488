import { Form, Input, Select, Switch } from 'antd'
import ButtonComponent from 'src/components/Button/Button'

export const SelectWithProps = {
  renderPropFields: ({ fieldName: name, fieldKey, ...field }) => {
    return (
      <>
        <Form.Item
          {...field}
          name={[name, 'props', 'mode']}
          fieldKey={[fieldKey, 'mmode']}
          label='Multiple'
        >
          <Select
            options={[
              { value: 'muliple' },
              { value: 'tags' }
            ]}
            allowClear
          />
        </Form.Item>
        <Form.List name={[name, 'props', 'options']}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <div key={key}>
                  <Form.Item
                    {...restField}
                    name={[name, 'label']}
                    fieldKey={[fieldKey, 'label']}
                    label='Label'
                  >
                    <Input placeholder='Label' />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'value']}
                    fieldKey={[fieldKey, 'value']}
                    label='Value'
                  >
                    <Input placeholder='Value' />
                  </Form.Item>
                </div>
              ))}
              <ButtonComponent
                onClick={e => {
                  e.preventDefault()
                  add()
                }}
              >
                Add field
              </ButtonComponent>
            </>
          )}
        </Form.List>
      </>
    )
  },
  render: props => (
    <Select
      {...props}
    />
  )
}

export const SwitchWithProps = {
  defaultProps: {
    valuePropName: 'checked'
  },
  render: props => (
    <Switch
      {...props}
    />
  )
}

export default {
  SelectWithProps,
  SwitchWithProps
}
