/**
 * @typedef FormattedSensorOptions
 * @type {{
 *   showName: ?boolean
 *   showDName: ?boolean
 *   showLocation: ?boolean
 * }}
 *
 * @param {object} param0
 * @param {FormattedSensorOptions} param1
 * @returns {string}
 */
export const getFormattedSensorName = (
  { name, displayName, location } = {},
  { showName = true, showDName = true, showLocation = true } = {}
) => {
  let formatted = ''

  if (name && showName) formatted += name
  if (formatted && displayName && showDName) formatted += ' - '
  if (displayName && showDName) formatted += displayName
  if (formatted && location && showLocation) formatted += ' ('
  if (location && showLocation) formatted += `${formatted ? `${location})` : `${location}`}`

  return formatted
}
