import { routes } from '@redwoodjs/router'
import { parseSearch } from '@redwoodjs/router/dist/internal'

import { apiInstance as api } from 'src/api'

/**
  * @param {?boolean} login
  * @param {?boolean} logout
  */
export const getRedirectRoute = async ({ login, logout, isLoggedIn, tenantId, isTenantAdmin, allowSubTenants }) => {
  const { pathname = '', search = '' } = window?.location || {}

  if (routes.login() !== pathname && (logout || !isLoggedIn)) {
    return routes.login()
  }

  if ((isLoggedIn && (pathname === '/' || pathname === routes.login())) || login) {
    try {
      const { redirectTo = '' } = parseSearch(search)

      if (redirectTo) return redirectTo
    } catch {}

    try {
      const { data: { meta: { totalCount = 0 }, data = [] } } = await api.getTenants()

      if (totalCount === 0) {
        return routes.app()
      } else if (
        (totalCount === 1 || typeof tenantId === 'number') &&
        (!isTenantAdmin || !allowSubTenants)
      ) {
        return routes.appTenant({ tenantId: tenantId ?? data[0].id })
      }
    } catch {}

    if (typeof tenantId === 'number') return routes.v4DashboardTenant({ tenantId })

    return routes.v4Dashboard()
  }
}
