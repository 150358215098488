import { Tabs } from 'antd'
import AdminSensorTypeTab from 'src/components/AdminSensorTypes/AdminSensorTypesTab'
import ConfigSettingsTab from 'src/components/AdminSensorTypes/ConfigSettingsTab'
import styled from 'styled-components'

const StyledTabs = styled(Tabs)`
  padding: 1rem;
  width: 100%;
  max-width: 1200px;
`

const AdminSensorType = (props) => {
  const { typeName } = props

  return (
    <StyledTabs>
      <Tabs.TabPane tab='Types' key='types'>
        <AdminSensorTypeTab {...props} />
      </Tabs.TabPane>
      <Tabs.TabPane tab='Configurable settings' key='settings'>
        <ConfigSettingsTab {...props} />
      </Tabs.TabPane>
    </StyledTabs>
  )
}

export default AdminSensorType
