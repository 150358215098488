import styled from 'styled-components'
const MaxWidth = styled.div`
  max-width: ${props => props.max || '1800px'};
  margin: 0 auto;
  width: 100%;
  flex:1;
  display: flex;
`

export default MaxWidth
