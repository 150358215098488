import { useCallback, useEffect, useMemo, useState } from 'react'
import { navigate } from '@redwoodjs/router'
import { Spin } from 'antd'
import Button, { BackButton } from 'src/components/Button'
import InfoCard from 'src/components/InfoCard'
import IssueCard from 'src/components/IssueCard'
import * as c from 'src/constants'
import { getSpaceRelations } from 'src/libraries/spaces'
import ColorSpinner from 'src/components/ColorSpinner'
import { useTenant } from 'src/hooks'
import DeleteSpace from 'src/components/DeleteSpace/DeleteSpace'
import { getWeatherString } from 'src/libraries/weather'
import AddSpace from 'src/components/AddSpace/AddSpace'

const header = {
  name: 'Name',
  userIssues: 'User issues',
  machineIssues: 'Machine issues',
  comfort: 'Comfort',
  weather: 'Weather'
}

const ListCard = ({ spaces = [], refresh, loading }) => {
  const { getRoute } = useTenant()
  const [currentSpaceId, setCurrentSpaceId] = useState()
  const [currentBuilding, setCurrentBuilding] = useState()
  const [currentType, setCurrentType] = useState() // building, room, floor as string
  const [viewSpaces, setViewSpaces] = useState([])
  const [title, setTitle] = useState('Issue overview')
  const [subTitle, setSubTitle] = useState('Buildings')
  const inSubMenu = currentSpaceId != null
  const showWeather = currentType == null
  const spaceType = currentType == null
    ? c.TYPE_BUILDING
    : currentType + 1

  const buildingFloors = useCallback(
    (spaceId = currentBuilding) => spaceId >= 0
      ? spaces.filter((space) =>
        space.parentSpaceId === spaceId && space.type === c.TYPE_FLOOR
      ).length
      : null,
    [spaces, currentBuilding]
  )

  const buildingRooms = useCallback(
    (spaceId = currentBuilding) => spaceId >= 0
      ? spaces.filter((space) =>
        space.parentSpaceId === spaceId && space.type === c.TYPE_ROOM
      ).length
      : null,
    [spaces, currentBuilding]
  )

  const currSpaces = useMemo(
    () => spaces.filter((space) => currentSpaceId != null
      ? space.parentSpaceId === currentSpaceId && (currentType == null || space.type === currentType + 1)
      : space.parentSpaceId == null
    ),
    [spaces, currentSpaceId, currentType]
  )
  const roomsWithNoFloor = useMemo(
    () => spaces
      .filter((space) => space.parentSpaceId === currentBuilding && space.type === c.TYPE_ROOM)
      .map(space => ({ ...space, parentSpaceId: 'roomsWithoutFloor' })),
    [spaces, currentBuilding]
  )

  useEffect(() => {
    if (currentSpaceId === 'roomsWithoutFloor') {
      setCurrentType(c.TYPE_FLOOR)
      setViewSpaces(roomsWithNoFloor)
    } else if (currentBuilding >= 0 && currentType === c.TYPE_BUILDING && roomsWithNoFloor.length > 0) {
      setViewSpaces([
        ...currSpaces,
        ...currentType + 1 === c.TYPE_FLOOR
          ? [
            {
              id: 'roomsWithoutFloor',
              name: 'Rooms without floor',
              parentSpaceId: currentBuilding,
              type: c.TYPE_FLOOR
            }
          ]
          : []
      ])
    } else {
      setViewSpaces(currSpaces)
    }
  }, [currentType, currentSpaceId, currentBuilding, currSpaces, roomsWithNoFloor])

  const mapType = (type, plural) => {
    let name

    switch (type) {
      case c.TYPE_BUILDING:
        name = 'Building'
        break
      case c.TYPE_FLOOR:
        name = 'Floor'
        break
      case c.TYPE_ROOM:
        name = 'Room'
        break
      default:
        name = 'Building'
    }

    return `${name}${plural ? 's' : ''}`
  }

  useEffect(() => {
    const currType = currentType == null
      ? null
      : currentType + 1
    const building = spaces.find(({ id }) => currentBuilding != null && currentBuilding === id)
    const weather = getWeatherString(building?.weather, null)

    setSubTitle(`${mapType(currType, true)}${weather ? ` ${weather}` : ''}`)
  }, [currentType, currentBuilding])

  const handleClick = (item) => (e) => {
    const { type, id } = item
    if (type === c.TYPE_ROOM) { // room is last step
      navigate(getRoute('space', { id }))
    } else {
      if (type === c.TYPE_BUILDING) {
        setCurrentBuilding(id)
      }

      setCurrentSpaceId(
        type === c.TYPE_BUILDING && !buildingFloors(id) && buildingRooms(id)
          ? 'roomsWithoutFloor'
          : id
      )
      setCurrentType(type)
    }
  }

  const handleBack = () => {
    const backType = !buildingFloors()
      ? null
      : currentType - 1
    const currentSpace = currentSpaceId === 'roomsWithoutFloor'
      ? { parentSpaceId: currentBuilding }
      : spaces.find(({ id }) => id === currentSpaceId)

    setCurrentSpaceId(
      !buildingFloors()
        ? null
        : currentSpace?.parentSpaceId
    )
    setCurrentType(
      backType > 0
        ? backType
        : null
    )
    if (!backType) {
      setCurrentBuilding(null)
    }
  }

  const handleSpaceClick = () => {
    navigate(getRoute('space', { id: currentSpaceId }))
  }

  return (
    <InfoCard
      title={title}
      subTitle={subTitle}
      style={{ flex: '1', widht: '100%' }}
      titleAction={inSubMenu && <BackButton onClick={handleBack} />}
      extra={
        currentSpaceId != null &&
        currentSpaceId !== 'roomsWithoutFloor' &&
        currentType >= c.TYPE_BUILDING &&
        currentType < c.TYPE_ROOM && (
          <Button
            onClick={handleSpaceClick}
            backgroundColor='#ac47c2'
            size='large'
          >
            {`View ${mapType(currentType)}`}
          </Button>
      )
      }
      nomargin
    >
      <IssueCard header showWeather={showWeather} item={header} />
      {viewSpaces.length > 0
        ? viewSpaces.map((item, i) => {
          const { scores, issues } = getSpaceRelations(spaces, item.id)
          return (
            <IssueCard
              color='#7edcb4'
              scores={scores}
              issues={issues}
              showWeather={showWeather}
              key={i}
              item={{
                ...item,
                coverImage: item.coverImage || '/images/placeholder-building.png'
              }}
              deleteHandler={
                <DeleteSpace
                  refresh={refresh}
                  space={item}
                  icon
                />
              }
              onClick={handleClick(item)}
            />
          )
        })
        : loading
          ? <ColorSpinner color='darkgray' />
          : <IssueCard noData />}
      {(currentSpaceId !== 'roomsWithoutFloor' || !buildingFloors()) && (
        <AddSpace
          toggleBtn={false}
          spaceType={currentSpaceId !== 'roomsWithoutFloor'
            ? spaceType
            : c.TYPE_FLOOR}
          parentSpace={currentSpaceId !== 'roomsWithoutFloor'
            ? currentSpaceId
            : currentBuilding}
          onDone={refresh}
          onUpdate={refresh}
        />
      )}
    </InfoCard>
  )
}

export default ListCard
