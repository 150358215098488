import { message } from 'antd'
import { useCallback, useEffect } from 'react'
import { navigate, routes } from '@redwoodjs/router'

import { useTenant, useAuth } from 'src/hooks'
import { apiInstance as api } from 'src/api'
import { ACCESS_READ, ACCESS_WRITE } from 'src/constants'
import { getRedirectRoute } from 'src/libraries/redirect'

export const useUsers = (props = {}) => {
  useTenant()
  const { fetch = true } = props

  const adminAddUser = useCallback((user) => {
    return new Promise((resolve, reject) => {
      return api.adminAddUser(user)
        .then((res) => {
          message.success(res.message || 'Added user')
          resolve(res.data)
        })
        .catch((err) => {
          message.error(err.response.data.error || 'Unable to add user')
          reject(err)
        })
    })
  }, [])

  const adminUpdateUser = useCallback((user) => {
    return new Promise((resolve, reject) => {
      return api.adminUpdateUser(user)
        .then((res) => {
          message.success(res.message || 'Updated user')
          resolve(res.data)
        })
        .catch((err) => {
          message.error(err.response.data.error || 'Unable to update user')
          reject(err)
        })
    })
  }, [])

  const adminDeleteUser = useCallback((user) => {
    return new Promise((resolve, reject) => {
      const { id } = user
      return api.adminDeleteUser(id)
        .then((res) => {
          message.success(res.message || 'Deleted user')
          resolve(res.data)
        })
        .catch((err) => {
          message.error(err.response.data.error || 'Unable to delete user')
          reject(err)
        })
    })
  }, [])
  return {
    adminAddUser,
    adminUpdateUser,
    adminDeleteUser
  }
}

/**
 * @returns {{
 *  user: object,
 *  hasPermission: (tenantId: number, minPermission: ?number) => boolean,
 *  isLoggedIn: () => boolean,
 *  login: (username: string, password: string) => void,
 *  logout: () => void,
 *  redirect: (login: ?boolean) => void
 * }}
 */
export const useUser = () => {
  const {
    hasRole,
    userMetadata: user,
    isAuthenticated,
    logIn: login,
    logOut: logout
  } = useAuth()
  const { fetchTenant } = useTenant()

  if (user === undefined) {
    throw new Error('useUser must be used within the Context Provider')
  }

  const hasPermission = useCallback((tenantId, minPermission = ACCESS_READ) => !!(
    user.admin ||
    user?.tenants.find(({ id, TenantUser }) => id === tenantId && TenantUser?.permission >= minPermission)
  ), [user])

  const isLoggedIn = useCallback(() => isAuthenticated, [isAuthenticated])

  const redirect = async ({ login, logout, isLoggedIn = isAuthenticated } = {}) => {
    const route = await getRedirectRoute({ login, logout, isLoggedIn })
    if (route) navigate(route)
  }

  return {
    user: user ?? {},
    hasPermission,
    isLoggedIn,
    login,
    logout,
    getRedirectRoute: async ({ login, logout, isLoggedIn = isAuthenticated, tenantId, allowSubTenants } = {}) =>
      getRedirectRoute({
        login,
        logout,
        isLoggedIn,
        tenantId,
        isTenantAdmin: (tenantId && hasRole(`tenant.${tenantId}.${ACCESS_WRITE}`)) || hasRole('superAdmin'),
        allowSubTenants
      }),
    redirect
  }
}

export const useRedirect = () => {
  const { redirect } = useUser()
  const { loading, isAuthenticated } = useAuth()

  useEffect(() => {
    if (!loading) redirect({ isLoggedIn: isAuthenticated })
  }, [loading])
}

// ui related functions, mini hooks
export const login = (username, password) => {
  api.login(username, password)
    .then(({ user }) => {
      localStorage.setItem('user', JSON.stringify(user))
      navigate(routes.app())
      message.success('Sign in successfully')
    })
    .catch((err) => {
      console.log(err.response.data.error)
      message.error(err.response.data.error || 'Wrong username / password')
    })
}

export const updatePasswordWithKey = (payload) =>
  new Promise((resolve, reject) => {
    api.updatePasswordWithKey(payload)
      .then(res => {
        message.success('Successfully update password')
        resolve(res)
      })
      .catch(err => {
        console.log(err.response.data.error)
        message.error(err.response.data.error || 'Something went wrong updating your password')
        reject(err)
      })
  })

export const logout = () => {
  api.logout()
    .then(() => {
      localStorage.removeItem('user')
      navigate(routes.login())
    })
    .catch((err) => message.error(err.message || 'Unable to logout?!'))
}

export const forgotPwd = (email) => {
  return api.forgotPwd(email)
}
