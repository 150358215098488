import { Tabs } from 'antd'
import React from 'react'
import CodeEditor from 'src/components/CodeEditor/CodeEditor'
import styled from 'styled-components'

const StyledEditor = styled(CodeEditor)`
  height: 250px;
`

const MetaTab = ({ value }) => {
  return (
      <StyledEditor
        defaultLanguage='json'
        options={{
          readOnly: true,
          folding: false,
          minimap: {
            enabled: false
          },
          lineNumbers: 'off'
        }}
        value={JSON.stringify(value ?? {}, null, 2)}
      />
  )
}

export default MetaTab
