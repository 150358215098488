import React from 'react'
import { Form, Input } from 'antd'
import { isLatitude, isLongitude } from 'src/libraries/location'

/**
 * @param {import('antd/lib/form').FormListProps} props
 * @returns {React.ReactNode}
 */

const CoordinatesField = (props) => {
  return (
    <Form.List {...props} name={props?.name ?? ['coordinates', 'coordinates']}>
      {(fields, _, { errors }) => (
        <>
          {fields.map((field, i) => (
            <Form.Item
              {...field}
              key={field.key}
              rules={[
                () => ({
                  validator (rule, value) {
                    if ((i === 0 && isLatitude(value)) || (i === 1 && isLongitude(value))) {
                      return Promise.resolve()
                    }

                    return Promise.reject(new Error(`The value is not a valid ${i === 0 ? 'Latitude' : 'Longitude'}`))
                  }
                })
              ]}
            >
              <Input placeholder={i === 0 ? 'Latitude' : 'Longitude'} />
            </Form.Item>
          ))}
        </>
      )}
    </Form.List>
  )
}

export default CoordinatesField
