import { Tooltip } from 'antd'
import Comfort from 'src/components/ComfortChartStatic/Comfort'
import styled from 'styled-components'

const ChartWrapper = styled.figure`
  margin: 0;
  padding: 0;
  position: relative;
  padding-bottom: 70%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`

const ChartContent = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

const Chart = styled(Comfort)`
  height: 100%;
  width: 100%;
  object-fit: contain;
`

const Ellipse = styled.figure`
  cursor: pointer;
  position: absolute;
  margin: 0;
  color: white;
  padding: 1rem;
  text-align: center;
  border-radius: 50%;
  width: ${props => props.width};
  height: ${props => props.height};
  background-color: ${props => props.bg ?? 'rgba(54, 81, 182, 0.58)'};
  opacity: ${props => props.bg && props.opacity ? props.opacity : 1};
  transform: translate(-50%, -50%);
  top: ${props => props.top}%;
  left: ${props => props.left}%;
`

const normalize = (val, min, max) => (val - min) / (max - min)

const ComfortChartStatic = (props) => {
  const {
    temp,
    hum,
    ellipseStyle,
    comfortStyle,
    tooltip = 'Actual state'
  } = props

  // offset for where the chart starts in percentage
  const OFFSET_X = [5, 5]
  const OFFSET_Y = [0, 10]

  // max min values for the domain
  const xDomain = [11, 29]
  const yDomain = [100, 0]

  const nx = normalize(temp, ...xDomain) * 100
  const ny = normalize(hum, ...yDomain) * 100
  const rx = OFFSET_X[0] + nx - OFFSET_X[1] // apply offsets
  const ry = OFFSET_Y[0] + ny - OFFSET_Y[1]
  const x = Math.max(Math.min(rx, 100), 0) // cap values 0 - 100%
  const y = Math.max(Math.min(ry, 100), 0)

  const tooltipTitle = `${temp}C, ${hum}%`
  // console.log('n', nx, ny, 'r', rx, ry, 'vals', x, y)
  return (
    <ChartWrapper>
      <ChartContent>
        <Ellipse top={y} left={x} {...ellipseStyle} >
          <Tooltip title={tooltipTitle}>{tooltip}</Tooltip>
        </Ellipse>
        <Chart {...comfortStyle} />
      </ChartContent>
    </ChartWrapper>
  )
}

export default ComfortChartStatic
