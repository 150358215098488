import { message } from 'antd'
import { useCallback, useEffect, useRef, useState } from 'react'

import { apiInstance as api } from 'src/api'
import { useIsMounted } from 'src/hooks/mounted'
import { useTenant } from 'src/hooks/tenant'

export const useSensorPlugins = ({ fetch = true } = {}) => {
  const mounted = useIsMounted()
  const { tenantId, isValidScopeFetch } = useTenant()
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [sensorPlugins, setSensorPlugins] = useState()

  const addPluginToSensor = useCallback((sensorId, plugin) => {
    return new Promise((resolve, reject) => {
      return api.addPluginToSensor(sensorId, plugin)
        .then(res => {
          message.success(res.message || 'Plugin added to sensor')
          resolve(res.data)
        }).catch(err => {
          message.error(err.response.data.message || 'Unable to add plugin to sensor')
          reject(err)
        })
    })
  }, [])

  const updateSensorPlugin = useCallback((sensorPluginID, plugin) => {
    return new Promise((resolve, reject) => {
      return api.updateSensorPlugin(sensorPluginID, plugin)
        .then(res => {
          message.success(res.message || 'Plugin updated')
          resolve(res.data)
        }).catch(err => {
          message.error(err.response.data.message || 'Unable to update plugin')
          reject(err)
        })
    })
  }, [])

  useEffect(() => {
    if (!fetch) return
    setLoading(true)
    api.getSensorPlugins()
      .then(({ data, config }) => {
        if (mounted() && isValidScopeFetch(config)) {
          setSensorPlugins(data)
        }
      })
      .catch(e => {
        if (mounted()) {
          setError(e)
          setSensorPlugins([])
        }
      })
      .finally(() => {
        if (mounted()) setLoading(false)
      })
  }, [tenantId])

  return {
    loading,
    error,
    updateSensorPlugin,
    addPluginToSensor,
    sensorPlugins
  }
}
