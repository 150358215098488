import styled, { css } from 'styled-components'

import MaxWidth from 'src/components/MaxWidth'

const Container = styled.div`
  ${props => !props.noFlex && css`
  display: flex;
  `}
  width: 100%;
  padding: ${props => props.noPadding ? '0' : '1rem'};
  box-sizing: border-box;
  @media (max-width: 768px) {
    & {
      flex-direction: column;
      align-items: center;
    }
  }
`

const AppContent = ({ children, ...props }) =>
  <MaxWidth>
    <Container {...props}>
      {children}
    </Container>
  </MaxWidth>

export default AppContent
