import { navigate } from '@redwoodjs/router'
import { useEffect, useState } from 'react'
import { useKey } from 'react-use'
import {
  Collapse,
  Button as AntButton,
  Modal
} from 'antd'
import { CaretLeftOutlined } from '@ant-design/icons'
import AppHeader from 'src/components/AppHeader'
import AppContent from 'src/components/AppContent'
import AppLayout from 'src/layouts/AppLayout'
import { useAuth, useSpace, useTenant, useUser } from 'src/hooks'
import Button, { BackButton } from 'src/components/Button'
import styled, { ThemeProvider } from 'styled-components'
import { InfoCardDynamic } from 'src/components/InfoCard'
import * as c from 'src/constants'
import AddSpaceModal from 'src/components/AddSpace'
import { groupSpacesByParent, getMeasurements, getRoomStyle } from 'src/libraries/spaces'
import CardWrapper from 'src/components/CardWrapper'
import UserDataView from 'src/components/UserDataView'
import MachineDataView from 'src/components/MachineDataView'
import AddSensorModal from 'src/components/AddSensorModal'
import AppTitle from 'src/components/AppTitle'
import ColorSpinner from 'src/components/ColorSpinner'
import TemperatureControl from 'src/components/TemperatureControl'

const { Panel } = Collapse

const SideBar = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ececec;
  box-shadow: 5px 0px 10px rgba(0,0,0,0.15);
  padding: 1rem;
  @media (max-width: ${c.SMALL_SCREEN}){
    width: 100%
  }
`

const StyledCollapse = styled(Collapse)`
background-color: transparent;
border-width: 0;
.ant-collapse-content {
  border-top: 0;
  border-bottom: 0;
}
.ant-collapse-header {
  border: none;
  border-radius: 0.5rem !important;
  box-shadow: 0 5px 10px rgba(0,0,0,0.1);
  background-color: white;
  & > p {
    color: black;
    font-weight: bold;
  }
}
.ant-collapse-content.ant-collapse-content-active {
  background-color: transparent;
}
`

const StyledPanel = styled(Panel)`
  border-bottom: 0 !important;
  padding-bottom: 1rem;
`

const Content = styled.div`
  flex: 2;
  padding: 1rem;
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
`

const FillWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0.25rem 1rem;
`

const TabBar = styled.nav`
  flex: 1;
  display: flex;
  flex-direction: row;
  max-height: 75px;
`

const DataView = styled.main`
  flex: 1;
  display: flex;
`

const RoomTitle = styled.p`
  font-weight: bold;
  font-size: 2.5rem;
  margin: 0;
  padding: 0;
`

const StyledModal = styled(Modal)`
  min-width: 1064px;
  width: 70%;
  & .ant-modal-content {
    border-radius: 1rem;
  }
`

const SpacePage = ({ id }) => {
  const { tenantId, getRoute } = useTenant()
  const { hasRole } = useAuth()
  const { refresh, space, loading, spaces } = useSpace(id)
  const {
    children: spaceChildren = []
  } = space
  const [addSpaceVisible, setAddSpaceVisible] = useState(false)
  const [spaceType, setSpaceType] = useState()
  const [currentParentSpaceId, setParentSpaceId] = useState(id)
  const [selectedSpaceId, setSelectedSpaceId] = useState()
  const [activeTab, setActiveTab] = useState(c.TAB_USER_DATA)
  const spaceTree = groupSpacesByParent(spaceChildren)
  const [showTempModal, setShowTempModal] = useState(false)
  const [spaceForTempModal, setSpaceForTempModal] = useState()
  const showTempControlFor = (space) => {
    setSelectedSpaceId(space.id)
    setSpaceForTempModal(space)
    setShowTempModal(true)
  }
  const closeTempModal = () => {
    setShowTempModal(false)
  }
  const floors = spaceChildren
    ? spaceChildren.filter((space) => space?.type === c.TYPE_FLOOR)
    : []
  const allRooms = spaceChildren
    ? spaceChildren.filter((space) => space?.type === c.TYPE_ROOM)
    : []
  const currentSpace = spaceChildren.find((space) => space?.id === selectedSpaceId) || space
  const readOnly =
    spaceForTempModal &&
    !hasRole('superAdmin') &&
    spaceForTempModal.accessLevel < c.ACCESS_WRITE &&
    !hasRole(`tenant.${tenantId}.${c.ACCESS_WRITE}`) &&
    spaceForTempModal.tenantAccessLevel < c.ACCESS_WRITE

  const isManager = currentSpace && (
    hasRole('superAdmin') ||
    currentSpace.accessLevel >= c.ACCESS_WRITE ||
    (
      typeof tenantId === 'number' &&
      hasRole(`tenant.${tenantId}.${c.ACCESS_WRITE}`) &&
      currentSpace.tenantAccessLevel >= c.ACCESS_WRITE
    )
  )
  useKey('Escape', () => setSelectedSpaceId(null))

  useEffect(() => {
    if (space && space.children) {
      const spaceWithSensors = space.type === c.TYPE_ROOM && space?.sensors?.length > 0 &&
      (
        hasRole('superAdmin') ||
        typeof space.accessLevel === 'number' ||
        (typeof tenantId === 'number' && typeof space.tenantAccessLevel === 'number')
      )
        ? space
        : space.children.find(
          (space) =>
            space?.sensors?.length > 0 &&
            (
              hasRole('superAdmin') ||
              typeof space.accessLevel === 'number' ||
              (typeof tenantId === 'number' && typeof space.tenantAccessLevel === 'number')
            )
        )
      const spaceWithAccess =
        hasRole('superAdmin') ||
        typeof space.accessLevel === 'number' ||
        (typeof tenantId === 'number' && typeof space.tenantAccessLevel === 'number')
          ? space
          : space.children.find(
            (space) => (
              hasRole('superAdmin') ||
              typeof space.accessLevel === 'number' ||
              (typeof tenantId === 'number' && typeof space.tenantAccessLevel === 'number')
            )
          )
      const selectedSpace = spaceWithSensors || spaceWithAccess || space
      setSelectedSpaceId(selectedSpace.id)
    }
  }, [space])
  useEffect(() => {
    setActiveTab(isManager
      ? activeTab
      : c.TAB_USER_DATA)
  }, [currentSpace])
  const handleBack = () => {
    navigate(getRoute('buildings'))
  }

  const handleAddSpace = (type, parentSpaceId) => () => {
    setSpaceType(type)
    setParentSpaceId(parentSpaceId)
    setAddSpaceVisible(true)
  }

  const handleDone = (space) => {
    console.log('added new building', space)
    refresh()
  }

  const shouldUpdate = () => {
    refresh()
  }
  const handleModalClose = () => {
    setShowTempModal(false)
  }
  const handleModalOk = () => {
    console.log('saving')
  }

  const handleSelectSpaceId = (spaceId) => () => {
    setSelectedSpaceId(selectedSpaceId =>
      selectedSpaceId === spaceId
        ? space.id // set parent again
        : spaceId
    )
  }

  const renderAddButton = (spaceType, curSpace) => {
    if (!curSpace) return
    const title = spaceType === c.TYPE_ROOM
      ? 'Add room'
      : 'Add floor'
    const showForParent = spaceType === c.TYPE_FLOOR
      ? space.type === c.TYPE_BUILDING
      : (space.type === c.TYPE_BUILDING || space.type === c.TYPE_FLOOR)
    const hasWriteAccess =
      hasRole('superAdmin') ||
      curSpace.accessLevel >= c.ACCESS_WRITE ||
      (
        typeof tenantId === 'number' &&
        hasRole(`tenant.${tenantId}.${c.ACCESS_WRITE}`) &&
        curSpace.tenantAccessLevel >= c.ACCESS_WRITE
      )
    return hasWriteAccess && showForParent &&
      <FillWrapper>
        <Button
          color='#7297ff'
          fill
          outline
          onClick={handleAddSpace(spaceType, curSpace.id)}
        >{title}
        </Button>
      </FillWrapper>
  }

  const renderRoom = (room = {}) => {
    const { sensors = [] } = room
    const measurements = getMeasurements(sensors)
    const {
      temp: _avgTemp,
      hum: _avgHum
    } = measurements
    const avgTemp = !isNaN(_avgTemp) && _avgTemp.toFixed(1)
    const avgHum = !isNaN(_avgHum) && _avgHum.toFixed(1)
    const tempDisplay = avgTemp
      ? `${avgTemp}°C`
      : '-'
    const humDisplay = avgHum
      ? `${avgHum}%`
      : '-'

    const data = sensors.length > 0
      ? [
        { name: 'Temperature', value: tempDisplay },
        { name: 'Humidity', value: humDisplay }
      ]
      : null
    const controlAvail =
      (
        hasRole('superAdmin') ||
        room.accessLevel >= c.ACCESS_READ ||
        (
          typeof tenantId === 'number' &&
          hasRole(`tenant.${tenantId}.${c.ACCESS_WRITE}`) &&
          room.tenantAccessLevel >= c.ACCESS_READ
        )
      ) && data
    const roomManger =
      hasRole('superAdmin') ||
      room.accessLevel >= c.ACCESS_WRITE ||
      (
        typeof tenantId === 'number' &&
        hasRole(`tenant.${tenantId}.${c.ACCESS_WRITE}`) &&
        room.tenantAccessLevel >= c.ACCESS_WRITE
      )

    const Bottom = controlAvail && (
      <Button
        backgroundColor='white'
        color='darkgray'
        fontSize='18px'
      >
        Control temperature
      </Button>
    )
    const onBottomClick = () => controlAvail && showTempControlFor(room)
    const style = getRoomStyle(room.category)

    const shouldUpdate = () => {
      refresh()
    }

    return (
      <InfoCardDynamic
        onClick={handleSelectSpaceId(room.id)}
        selected={selectedSpaceId === room.id}
        key={room.id}
        color={style.primaryColor}
        icon={style.iconClassName}
        darkColor='black'
        title={room.name}
        room={room}
        data={data}
        Bottom={Bottom}
        onBottomClick={onBottomClick}
        editIcon='ellipsis-v'
        editCap={roomManger}
        shouldUpdate={shouldUpdate}
      />

    )
  }
  const theme = getRoomStyle(currentSpace.category)

  return (
    <ThemeProvider theme={theme}>
      <AppLayout>
        <StyledModal
          visible={showTempModal}
          width='auto'
          onOk={handleModalOk}
          footer={null}
          maskStyle={{ opacity: 0 }}
          okText='Save'
          closeIcon={<i className='fas fa-times' style={{ color: '#f77a8f' }} />}
          onCancel={handleModalClose}
        >
          <TemperatureControl
            onClose={handleModalClose}
            readOnly={readOnly}
            space={spaceForTempModal}
          />
        </StyledModal>
        <AddSpaceModal
          visible={addSpaceVisible}
          onDone={handleDone}
          spaceType={spaceType}
          parentSpace={currentParentSpaceId}
          setVisible={setAddSpaceVisible}
        />

        <AppHeader
          noMargin
          extra={
            <AddSensorModal
              spaces={spaces}
              parentSpaceId={id}
              spaceId={selectedSpaceId}
              shouldUpdate={shouldUpdate}
            />
          }
        >
          <BackButton onClick={() => handleBack()} />
          <AppTitle noMargin>{space.name}</AppTitle>
        </AppHeader>
        <AppContent noPadding>
          <SideBar>
            {loading && <ColorSpinner center color='darkgray' />}
            {floors.length > 0
              ? (
                <StyledCollapse
                  // ghost
                  defaultActiveKey={floors.map((_, i) => i)}
                  expandIconPosition='right'
                  expandIcon={({ isActive }) => <CaretLeftOutlined rotate={isActive ? -90 : 0} />}
                >
                  {floors.map((floor, i) => {
                    const rooms = spaceTree[floor.id] || []
                    return (
                      <StyledPanel header={<p key={floor.id}>{floor.name} ({rooms.length} Rooms)</p>} key={i}>
                        {rooms.map(renderRoom)}
                        {renderAddButton(c.TYPE_ROOM, floor)}
                      </StyledPanel>
                    )
                  })}
                  {renderAddButton(c.TYPE_FLOOR, space)}
                </StyledCollapse>
              )
              : [
                ...allRooms.map(renderRoom),
                renderAddButton(c.TYPE_FLOOR, space)
              ]}
          </SideBar>
          <Content>
            <RoomTitle>{currentSpace.name}</RoomTitle>
            {isManager && (
              <TabBar>
                <CardWrapper active={activeTab === c.TAB_USER_DATA} hover onClick={() => setActiveTab(c.TAB_USER_DATA)}>User data</CardWrapper>
                <CardWrapper active={activeTab === c.TAB_MACHINE_DATA} hover onClick={() => setActiveTab(c.TAB_MACHINE_DATA)}>Machine data</CardWrapper>
              </TabBar>
            )}
            <DataView>
              {activeTab === c.TAB_USER_DATA
                ? (
                  <UserDataView
                    showTempControl={() => showTempControlFor(currentSpace)}
                    closeTempControl={closeTempModal}
                    loading={loading}
                    space={currentSpace}
                  />
                )
                : <MachineDataView shouldUpdate={shouldUpdate} loading={loading} space={currentSpace} />}
            </DataView>
          </Content>
        </AppContent>
      </AppLayout>
    </ThemeProvider>
  )
}

export default SpacePage
