import {
  Modal,
  Button as AntButton
} from 'antd'
import React from 'react'
import { WarningOutlined } from '@ant-design/icons'
import Bugsnag from '@bugsnag/js'

const { confirm } = Modal

/**
 * @typedef DeleteCheckModalProps
 * @type {object}
 * @property {string} type
 * @property {?React.ReactNode} children
 * @property {?string} titleText
 * @property {?string} titleKey
 * @property {?string} modalOpenBtnText
 * @property {?string} deleteBtnText
 * @property {?string} okBtnKey
 * @property {?string} cancelBtnText
 * @property {(deleteObj) => void} deleteFn
 * @property {() => void} setVisible
 * @property {() => void} shouldUpdate
 * @property {object} deleteObj
 *
 * @param {DeleteCheckModalProps} props
 * @returns {React.ReactNode}
 */

const DeleteCheckModal = ({
  type,
  children,
  deleteObj,
  titleText = 'Are you sure you want to delete',
  titleKey,
  modalOpenBtnText = 'Delete',
  deleteBtnText = 'Yes, delete',
  okBtnKey,
  cancelBtnText = 'Cancel',
  shouldUpdate,
  deleteFn
}) => {
  const handleClick = obj => {
    confirm({
      title: 'Danger zone',
      icon: <WarningOutlined />,
      content: [
        <div key='info'>
          <p>{`${titleText}${obj?.[titleKey] ? ` ${obj[titleKey]}` : ''}`}</p>
          {children || (
            <ul>
              <li>This action is irreversible.</li>
              <li>{`Deleting a ${type} will remove all associated information from our servers.`}</li>
            </ul>
          )}
        </div>
      ],
      okButtonProps: { size: 'large', type: 'primary' },
      cancelButtonProps: { size: 'large' },
      okText: `${deleteBtnText}${obj?.[okBtnKey] ? ` ${obj[okBtnKey]}` : ''}`,
      okType: 'danger',
      cancelText: cancelBtnText,
      onOk () {
        Promise
          .resolve(deleteFn(deleteObj))
          .then(() => {
            if (typeof shouldUpdate === 'function') shouldUpdate()
          })
          .catch(Bugsnag.notify)
      }
    })
  }

  return (
    <AntButton type='link' danger size='large' key='cancel' onClick={() => handleClick(deleteObj)}>
      {modalOpenBtnText}
    </AntButton>
  )
}

export default DeleteCheckModal
