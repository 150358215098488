import { useEffect, useState } from 'react'
import AdminList from 'src/components/AdminList'
import { Tag, Space, Popconfirm } from 'antd'
import { useAdminAllUsers, useAuth, useTenant, useUsers } from 'src/hooks'
import { navigate } from '@redwoodjs/router'
import { QuestionCircleOutlined } from '@ant-design/icons'
import AdminAddUserModal from 'src/components/AdminAddUserModal'
import * as c from '../../constants'

const AdminUsers = ({ typeName }) => {
  const { getRoute } = useTenant()
  const { hasRole } = useAuth()
  const { refresh, users, loading } = useAdminAllUsers()
  const { adminDeleteUser } = useUsers()

  const handleClick = (record) => {
    const { id } = record
    navigate(getRoute('adminUser', { id }))
  }

  const shouldUpdate = () => {
    refresh()
  }

  const handleDelete = (record) => {
    adminDeleteUser(record)
      .then(res =>
        refresh()
      )
  }

  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      render: text => <a>{text}</a>
    },
    ...hasRole('superAdmin')
      ? [
          {
            title: 'Admin',
            dataIndex: 'admin',
            key: 'admin',
            responsive: ['md'],
            render: notifications => {
              return notifications ? <i className='fas fa-check' /> : ''
            },
            filters: [
              {
                text: 'Admin',
                value: 1
              },
              {
                text: 'Not Admin',
                value: 0
              }
            ],
            onFilter: (value, record) => record.admin === value
          }
        ]
      : [],
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      responsive: ['md'],
      render: active => {
        return active === 1 ? <i className='fas fa-check' /> : ''
      },
      filters: [
        {
          text: 'Active',
          value: 1
        },
        {
          text: 'Not active',
          value: 0
        }
      ],
      onFilter: (value, record) => record.active === value
    },
    {
      title: 'Spaces',
      key: 'spaces',
      dataIndex: 'spaces',
      responsive: ['md'],
      render: spaces => (
        spaces && spaces.map((space, i) => {
          if (space) {
            const { name, type } = space
            const displayName = name?.toUpperCase() ?? '-'
            if (type === c.TYPE_ROOM) return <Tag color='geekblue'> {displayName}</Tag>
            if (type === c.TYPE_FLOOR) return <Tag color='blue'> {displayName}</Tag>
            if (type === c.TYPE_BUILDING) return <Tag color='orange'> {displayName}</Tag>
          }
        })
      )
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size='middle'>
          <div onClick={(e) => e.stopPropagation()}>
            <Popconfirm
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title='Are you sure delete this sensor?'
              onConfirm={() => handleDelete(record)}
              okText='Yes, delete'
              cancelText='No'
            >
              <a href='#'>Delete</a>
            </Popconfirm>
          </div>
          <AdminAddUserModal shouldUpdate={shouldUpdate} tableData={users} record={record} edit />
        </Space>
      )
    }
  ]

  return (
    <AdminList
      handleClick={(e) => handleClick(e)}
      shouldUpdate={shouldUpdate}
      typeName={typeName}
      data={users}
      columns={columns}
      loading={loading}
    />
  )
}

export default AdminUsers
