import { useState } from 'react'
import DashboardLayout from 'src/layouts/DashboardLayout'
import DashboardHeader from 'src/components/DashboardHeader'
import DashboardMenu from 'src/components/DashboardMenu'
import SensorView from 'src/components/SensorView'
import styled from 'styled-components'

import {
  useCurrentState,
  useGroupedSensors
} from 'src/hooks'

const DashboardContent = styled.div`
  flex: 1;
  background: ${props => props.colors['dashboard.content.background'] || '#F0F2F5'};
  height: 100vh;
`

const DashboardMenuContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  height: 100vh;
`

const Content = styled.div`
  width: 100%;
`

const DashboardPage = () => {
  const [collapsedState, setCollapsedState] = useState(false)
  const [sensorId, setSensorId] = useState()
  const [search, setSearch] = useState()

  const { sensorGroups, loading: groupsLoading, groupedSensors, fetchSensorGroup } = useGroupedSensors({ s: search })
  const { client } = useCurrentState()
  const { assets, colors } = client

  const toggleCollaps = () => {
    setCollapsedState(!collapsedState)
  }

  const handleSelect = (sensorId) => {
    setSensorId(sensorId)
  }

  return (
    <DashboardLayout>
      <DashboardMenuContainer>
        <DashboardMenu
          assets={assets}
          groupedSensors={groupedSensors}
          collapsedState={collapsedState}
          onSelect={handleSelect}
          toggleCollaps={toggleCollaps}
          groups={sensorGroups}
          groupsLoading={groupsLoading}
          setSearch={setSearch}
          search={search}
          fetchSensorGroup={fetchSensorGroup}
        />
      </DashboardMenuContainer>
      <DashboardContent colors={colors}>
        <DashboardHeader
          assets={assets}
          collapsMenu={toggleCollaps}
          collapsedState={collapsedState}
        />
        <Content>
          <SensorView sensorId={sensorId} />
        </Content>
      </DashboardContent>
    </DashboardLayout>
  )
}

export default DashboardPage
