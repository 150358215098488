import AdminList from 'src/components/AdminList'
import { Dropdown, Menu } from 'antd'
import styled from 'styled-components'

import DeleteCheckModal from 'src/components/DeleteCheckModal'
import { useAdminEvents } from 'src/hooks/event'
import AdminAddEventModal from 'src/components/AdminAddEvent'

const DropdownLink = styled.a`
  font-size: 1.5rem;
  color: ${props => props?.colors?.['card.menu.color'] || '#C4C4C4'};
  min-width: 25px;
  display: flex;
  justify-content: center;
`

const MenuItem = styled(Menu.Item)`
  text-align: center;

  button {
    width: 100%;
  }
`

const AdminEvent = ({ typeName }) => {
  const { refresh, events } = useAdminEvents()

  const handleDelete = record => {
    deleteEvent(record.id)
      .then(res =>
        refresh()
      )
  }

  const columns = [
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label'
    },
    {
      title: 'Event',
      dataIndex: 'event',
      key: 'event'
    },

    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => {
        const menu = (
          <Menu>
            <MenuItem>
              <AdminAddEventModal
                shouldUpdate={refresh}
                tableData={events}
                record={record}
                edit
              />
            </MenuItem>
            {/* <MenuItem>
              <DeleteCheckModal
                titleText={`Are you sure you want to delete this event (${record?.label})?`}
                deleteFn={() => handleDelete(record)}
                deleteBtnText='Yes, delete'
                cancelBtnText='No, cancel'
              />
            </MenuItem>*/}
          </Menu>
        )

        return (
          <div onClick={(e) => e.stopPropagation()}>
            <Dropdown
              overlay={menu}
              placement='bottomRight'
              trigger={['click']}
            >
              <DropdownLink className='ant-dropdown-link'>
                <i className='fas fa-ellipsis-v' />
              </DropdownLink>
            </Dropdown>
          </div>
        )
      }
    }
  ]

  return (
    <AdminList
      shouldUpdate={refresh}
      typeName={typeName}
      data={events}
      columns={columns}
      loading={false}
    />
  )
}

export default AdminEvent
