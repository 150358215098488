import React, { useState } from 'react'
import {
  Modal,
  Button as AntButton,
  Form,
  Input,
  Radio,
  Select,
  Switch
} from 'antd'

import Button from 'src/components/Button'
import styled from 'styled-components'
import { useAdminSensorConfigurableSetting, useAdminSensorSetting } from 'src/hooks/sensorSetting'
import PresetSchema from 'src/components/AdminSensorSettingModals/AddSensorConfigurableSetting/presetSchema'
import { RADIO_DATA_TYPE_OPTIONS, SENSOR_CONFIG_COMPONENTS } from 'src/constants'
import ComponentFromObject from 'src/components/ComponentFromObject'

const ButtonContainer = styled.div`
  padding: 0.5em;
`

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
`

const TitleContainer = styled.div`
  padding: 1em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #7297ff;
  & h2 {
    color: #fff;
  }
`

const FormContainer = styled.div`
  padding: 2em;
  & .ant-row.ant-form-item {
    flex: 1;
    justify-content: center;
    margin-bottom: 1em;
  }
  & .ant-col-14 {
    max-width: 100%;
  }
  & .ant-col-4 {
    padding-left: 8px;
    max-width: 100%;
    flex: 1;
  }
  & .ant-picker {
    width: 100%;
  }

  & .ant-divider{
    margin: 1em;
  }

  & .ant-form-large .ant-form-item-label > label {
    height: 25px;
  }
`

const RADIO_TYPE_OPTIONS = [
  { label: 'Admin', value: 'admin' },
  { label: 'User', value: 'user' },
  { label: 'Admin sensor setting', value: 'adminSensorSetting'},
  { label: 'Preset', value: 'preset' },
];

/**
 * @typedef AddSensorConfigurableSettingProps
 * @type {object}
 *
 * @param {AddSensorConfigurableSettingProps} props
 * @returns {React.ReactNode}
 */

const AddSensorConfigurableSetting = ({
  edit = false,
  record = {},
  shouldUpdate,
  SensorSettingGroupId
}) => {
  const { addGroupSetting } = useAdminSensorSetting({ fetch: false, id: SensorSettingGroupId })
  const { updateConfigurableSetting } = useAdminSensorConfigurableSetting({ fetch: false, id: record.id })
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()

  const presetOptions = record?.SensorSettingPresets
    ?.map(({ label, SensorConfigurableSettingId: value }) => ({ label, value })) ?? []

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = e => {
    form.resetFields()
    setVisible(false)
  }

  const handleSubmit = async item => {
    if (edit) {
      await updateConfigurableSetting(item)
    } else {
      await addGroupSetting(item)
    }

    form.resetFields()
    setVisible(false)
    shouldUpdate?.()
  }

  const validateMessages = {
    required: "'${label}' is required!",
    types: {
      email: '${label} is not validate email!',
      number: '${label} is not a validate number!'
    }
  }

  return (
    <>
      {edit
        ? (
          <AntButton type='link' onClick={showModal}>
            Edit
          </AntButton>)
        : (
          <Button type='primary' onClick={showModal}>
            Add configurable sensor setting
          </Button>
        )
      }
      <Modal
        closable={false}
        visible={visible}
        destroyOnClose
        onCancel={handleCancel}
        bodyStyle={{ padding: '0' }}
        footer={[
          <FooterContainer key='buttons'>
            <ButtonContainer>
              <Button fill outline key='cancel' onClick={handleCancel}>
                Cancel
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button key='submit' type='primary' htmlType='submit' form='addSensorConfigurableSetting'>
                {edit ? 'Update configurable sensor setting' : 'Add configurable sensor setting'}
              </Button>
            </ButtonContainer>
          </FooterContainer>
        ]}
      >
        <div>
          <TitleContainer>
            <h2>{edit ? 'Edit configurable sensor setting' : 'Add configurable sensor setting'}</h2>
          </TitleContainer>
          <FormContainer>
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              layout='vertical'
              size='large'
              id='addSensorConfigurableSetting'
              onFinish={handleSubmit}
              colon={false}
              form={form}
              validateMessages={validateMessages}
              initialValues={{
                ...record
              }}
            >
              {() => (
                <>
                  <Form.Item
                    label='Label'
                    name='label'
                    required
                    rules={[
                      {
                        required: true
                      }
                    ]}
                    hasFeedback
                  >
                    <Input
                      placeholder='Label'
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item
                    label='Name'
                    name='name'
                    hasFeedback
                    rules={[
                      {
                        required: true
                      }
                    ]}
                  >
                    <Input
                      placeholder='Name'
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item
                    label='Description'
                    name='description'
                    hasFeedback
                  >
                    <Input.TextArea
                      placeholder='Description'
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item
                    label='Type'
                    name='type'
                    required
                    rules={[
                      {
                        required: true
                      }
                    ]}
                    hasFeedback
                  >
                    <Radio.Group
                      options={RADIO_TYPE_OPTIONS}
                      optionType='button'
                      buttonStyle='solid'
                      disabled={edit}
                    />
                  </Form.Item>
                  {(form.getFieldValue('type') === 'admin' || form.getFieldValue('type') === 'user'|| form.getFieldValue('type') === 'adminSensorSetting') && (
                    <Form.Item
                      label='Type'
                      name='dataType'
                      required
                      rules={[
                        {
                          required: true
                        }
                      ]}
                      hasFeedback
                    >
                      <Radio.Group
                        options={RADIO_DATA_TYPE_OPTIONS}
                        buttonStyle='solid'
                        optionType='button'
                        disabled={edit}
                      />
                    </Form.Item>
                  )}
                  {(form.getFieldValue('type') === 'user' || form.getFieldValue('type') === 'adminSensorSetting') && (
                    <Form.Item
                      label='Ui component'
                      name='uiComponent'
                      required
                      rules={[
                        {
                          required: true
                        }
                      ]}
                      hasFeedback
                    >
                      <Select
                        options={SENSOR_CONFIG_COMPONENTS}
                        disabled={edit}
                      />
                    </Form.Item>
                  )}
                  {(form.getFieldValue('type') === 'user' || form.getFieldValue('type') === 'adminSensorSetting') && (
                      <ComponentFromObject
                        componentObject={SENSOR_CONFIG_COMPONENTS}
                        component={form.getFieldValue('uiComponent')}
                        WrapComponent={Form.Item}
                        label='Default value'
                        name='value'
                        allowClear
                      />
                  )}
                  {form.getFieldValue('type') === 'admin' && (
                    <Form.Item
                      label='Admin value'
                      name='value'
                      required={form.getFieldValue('type') === 'admin'}
                      rules={[
                        {
                          required: form.getFieldValue('type') === 'admin'
                        }
                      ]}
                      hasFeedback
                    >
                      <Input placeholder='Admin value' />
                    </Form.Item>
                  )}
                  {form.getFieldValue('type') === 'preset' && (
                    <Form.Item
                      label='Default value'
                      name='value'
                      hasFeedback
                    >
                      <Select
                        options={presetOptions}
                        placeholder='Default value'
                        allowClear
                      />
                    </Form.Item>
                  )}
                  {form.getFieldValue('type') === 'preset' && <PresetSchema form={form} edit={edit} />}
                </>
              )}
            </Form>
          </FormContainer>
        </div>
      </Modal>
    </>
  )
}

export default AddSensorConfigurableSetting