import React from 'react'
import styled from 'styled-components'

const BTN_DEFAULT_STYLE = {
  bgColor: '#fff'
}

const Button = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.3rem;
  background-color: ${props => props?.bgColor ?? BTN_DEFAULT_STYLE.bgColor};
  box-shadow: 0px 0px 10px #00000029;
  border-radius: 5px;
  border: none;
  padding: 0.5em 1em;
  box-sizing: border-box;
  cursor: pointer;

  &[disabled] {
    cursor: not-allowed;
  }

  i {
    font-size: 1.5rem;
    margin-top: 0.5rem;

    svg {
      width: .75em;
    }
  }
`



/**
 * @typedef VerticalBtnProps
 * @type {{
 *  label: string
 *  icon: React.ReactNode
 *  styleProps: typeof BTN_DEFAULT_STYLE
 * }}
 *
 * @param {VerticalBtnProps} props
 * @returns {React.ReactNode}
 */

const VerticalBtn = ({ styleProps = {}, label, icon, ...props }) =>
  <Button {...props} {...styleProps}>
    {label && label}
    {icon && <i>{icon}</i>}
  </Button>

export default VerticalBtn
