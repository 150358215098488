import { message } from 'antd'
import { useCallback, useEffect, useState } from 'react'

import { apiInstance as api } from 'src/api'
import { useTenant } from 'src/hooks'
import { useIsMounted } from 'src/hooks/mounted'

export const useSpaceUser = ({ fetch = true } = {}) => {
  const adminAddUserToSpace = useCallback((payload) => {
    return new Promise((resolve, reject) => {
      return api.adminAddUserToSpace(payload)
        .then((res) => {
          message.success(res.message || 'Added user')
          resolve(res.data)
        })
        .catch((err) => {
          message.error(err.response.data.error || 'Unable to add user')
          reject(err)
        })
    })
  }, [])

  const adminRemoveUserFromSpace = useCallback((spaceAccessId) => {
    return new Promise((resolve, reject) => {
      return api.adminRemoveUserFromSpace(spaceAccessId)
        .then((res) => {
          message.success(res.message || 'Removed user')
          resolve(res.data)
        })
        .catch((err) => {
          message.error(err.response.data.error || 'Unable to remove user')
          reject(err)
        })
    })
  }, [])

  return {
    adminRemoveUserFromSpace,
    adminAddUserToSpace
  }
}

export const useUserWithSpaceAccess = (spaceId) => {
  const mounted = useIsMounted()
  const { tenantId, isValidScopeFetch } = useTenant()
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())

  const refresh = () => setLastUpdate(new Date())

  useEffect(() => {
    setLoading(true)
    api.adminGetUserWithSpaceAccess(spaceId)
      .then(({ data, config }) => {
        if (mounted() && isValidScopeFetch(config)) {
          setUsers(data)
        }
      })
      .catch(e => {
        if (mounted()) {
          setUsers([])
          setError(e)
        }
      })
      .finally(() => {
        if (mounted()) setLoading(false)
      })
  }, [lastUpdate, tenantId])

  return {
    users,
    loading,
    error,
    refresh
  }
}
