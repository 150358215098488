import React, { isValidElement } from 'react'

/**
 * @typedef ComponentFromObjectProps
 * @type {object}
 * @property {object} componentObject
 * @property {string} component
 * @property {React.Node} WrapComponent
 *
 * @param {ComponentFromObjectProps} props
 * @returns {React.ReactNode}
 */

const ComponentFromObject = ({ componentObject, component, WrapComponent, ...props }) => {
  const comp = componentObject
    .find(({ value }) => component === value)
  const defaultComponent = componentObject
    .find(comp => comp?.default === true)

  const { comp: RenderComponent, props: componentProps, wrapperProps } = comp ?? defaultComponent

  if (isValidElement(<WrapComponent />)) {
    return (
      <WrapComponent {...wrapperProps} {...props} >
        <RenderComponent {...componentProps} {...props} />
      </WrapComponent>
    )
  }

  return <RenderComponent {...componentProps ?? {}} {...props} />
}

export default ComponentFromObject
