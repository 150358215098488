import { routes, navigate } from '@redwoodjs/router'
import { message } from 'antd'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'

import { apiInstance as api } from 'src/api'
import { useTenant } from 'src/hooks/tenant'
import { Context } from 'src/context'
import { useIsMounted } from 'src/hooks/mounted'

export * from 'src/hooks/tenant'
export * from 'src/hooks/sensor'
export * from 'src/hooks/user'
export * from 'src/hooks/space'
export * from 'src/hooks/plugin'
export * from 'src/hooks/algorithm'
export * from 'src/hooks/measurements'
export * from 'src/hooks/asset'
export * from 'src/hooks/issue'
export * from 'src/hooks/temperatureControl'
export * from 'src/hooks/admin'
export * from 'src/hooks/userSpace'
export * from 'src/hooks/sensorSpace'
export * from 'src/hooks/sensorUser'
export * from 'src/hooks/sensorPlugin'
export * from 'src/hooks/sensorType'
export * from 'src/hooks/dashboard'
export * from 'src/hooks/auth'
export * from 'src/hooks/mounted'
export * from 'src/hooks/command'

const { localStorage } = window

// hooks
export const useCurrentState = () => {
  const context = useContext(Context)
  return {
    ...context
  }
}

export const useTheme = () => {
  const { client } = useCurrentState()
  return client
}

export const storeUnitsSettings = (units) => {
  localStorage.setItem('units', JSON.stringify(units))
  message.success('update successfully')
}

const useTriad = (getDataPromise, initValue = null) => {
  const mounted = useIsMounted()
  const { tenantId, isValidScopeFetch } = useTenant()
  const [data, setData] = useState(initValue)
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())

  const refresh = () => setLastUpdate(new Date())

  useEffect(() => {
    setLoading(true)
    getDataPromise()
      .then(({ data, config }) => {
        if (mounted() && isValidScopeFetch(config)) {
          setData(data)
        }
      })
      .catch(e => {
        if (mounted()) {
          setData(initValue)
          setError(e)
        }
      })
      .finally(() => {
        if (mounted()) setLoading(false)
      })
  }, [lastUpdate, tenantId])

  return {
    data,
    loading,
    error,
    refresh
  }
}

export const useLogs = () => {
  const {
    data: logs,
    ...defaults
  } = useTriad(() =>
    new Promise((resolve, reject) => {
      api.getLogs()
        .then((res) => resolve(res))
        .catch(reject)
    }), [])

  return {
    ...defaults,
    logs
  }
}
