import styled, { css, ThemeProvider } from 'styled-components'
import { useState, useEffect, useRef } from 'react'
import { subHours, subDays, subWeeks, subMonths, subYears } from 'date-fns'

import { useAuth, useMeasurements, useTenant } from 'src/hooks'
import { getMeasurements } from 'src/libraries/spaces'
import { config, calculateMeta, chartConfig } from 'src/libraries/measurements'
import { Radio, Modal } from 'antd'

import LineChart from 'src/components/LineChartAppZoom'
import RadialChart from 'src/components/RadialChartApp'
import CardWrapper from 'src/components/CardWrapper'
import Button from 'src/components/Button'
import RadarChartApp from 'src/components/RadarChartApp'
import ComfortChartApp from 'src/components/ComfortChartApp'
import ComfortChartStatic from 'src/components/ComfortChartStatic/ComfortChartStatic'
import ColorSpinner from 'src/components/ColorSpinner'

import * as c from 'src/constants'

const HOUR = 1
const DAY = 2
const WEEK = 3
const MONTH = 4
const YEAR = 5

const intervals = {
  default: 3600,
  [HOUR]: 120
}

const Card = styled(CardWrapper)`
  flex: ${props => props.n};
  display: flex;
  flex-direction: column;
  text-align: left;
`

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const RadioText = styled.span`
  font-size: 1rem;
  font-weight: lighter;
  ${props => props.active && css`
    font-weight: bold;
    color: ${props => props.theme.primaryColor};
  `}
`

const ViewWrapper = styled.div`
  flex: 1;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  @media (max-width: ${c.MEDIUM_SCREEN}){
    flex-direction: column;
  }
`

const RangeText = styled.a`
  padding: 0.5em 1em;
  font-weight: 400;
  color: ${props => props.theme.primaryColor};
  &.active{
    background: ${props => props.theme.primaryColor};
    color: white;
    border-radius: 0.5rem;
  }
`

const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

const InfoText = styled.p`
  color: ${props => props.dark ? 'gray' : 'lightgray'};
  font-style: italic;
  align-self: center;
  margin: 0;
`

const CardHeader = styled.p`
  text-align: ${props => props.center ? 'center' : 'left'};
  font-size: 1.8rem;
  font-weight: 300;
  color: black;
`

const FilterGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 1rem;
  flex-wrap: wrap;
`
const Filter = styled.div`
`

const FilterRadio = styled(Radio)`
  & .ant-radio-checked .ant-radio-inner {
    &::after {
      background-color: ${props => props.theme.primaryColor};
    }
    border-color: ${props => props.theme.primaryColor};
  }
`

const RangeContainer = styled.div`
  padding: 0 1rem;
  width: 100%;
  justify-content: flex-end;
  display: flex;
`
const BorderButton = styled.button`
  border: 1px solid ${props => props.theme.primaryColor};
  padding: 0.25rem 0.5rem;
  color: black;
  background-color: transparent;
  border-radius: 4px;
  transition: all 0.25s ease-out;
  cursor: pointer;
  ${props => props.active && css`
    background-color: ${props => props.theme.primaryColor};
    color: ${props => props.theme.primaryThemeColor};
    border-radius: 6px;
  `}
  &:hover {
    background-color: ${props => props.theme.primaryColor};
    border-radius: 6px;
  }
`

const PluginWrapper = styled(Row)`
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  & > * {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    margin-bottom: 0.25rem;
  }
`

const UserDataView = (props) => {
  const {
    space,
    loading,
    showTempControl
  } = props
  const { hasRole } = useAuth()
  const { tenantId, isTenantScope } = useTenant()
  const { display, units } = config
  const [subFunction, setSubFunction] = useState(HOUR)
  const [fromDate, setFromDate] = useState(subHours(new Date(), 4))
  const [allSpaces, setAllSpaces] = useState([])
  const [sensors, setSensors] = useState([])
  const [selectedPlugin, setSelectedPlugin] = useState(0)
  const [keys, setKeys] = useState()
  const [minData, setMinData] = useState()
  const [maxData, setMaxData] = useState()
  const [selectedKey, setSelectedKey] = useState('temp')
  const hasAccess =
    hasRole('superAdmin') ||
    typeof space.accessLevel === 'number' ||
    (
      isTenantScope() &&
      hasRole(`tenant.${tenantId}.${c.ACCESS_READ}`) &&
      typeof space.tenantAccessLevel === 'number'
    )
  const latestMeasurements = getMeasurements(space.sensors)
  const spaceId = hasAccess
    ? space.id
    : null // user has no access, block request
  const isManager =
    hasRole('superAdmin') ||
    space.accessLevel >= c.ACCESS_WRITE ||
    (
      isTenantScope() &&
      hasRole(`tenant.${tenantId}.${c.ACCESS_WRITE}`) &&
      space.tenantAccessLevel >= c.ACCESS_WRITE
    )

  const {
    measurements,
    allMeasurements,
    existingKeys,
    plugins,
    loading: msrLoading
  } = useMeasurements(spaceId, {
    interval: intervals[subFunction], // for high resolution data
    dateFrom: fromDate,
    dateTo: new Date()
  })
  const measurementsExists = measurements && measurements.length > 0
  const latestMeasurementsExists = latestMeasurements && latestMeasurements.temp !== undefined
  useEffect(() => {
    if (!measurements) return
    const { minData, maxData } = calculateMeta(measurements)
    setMaxData(maxData)
    setMinData(minData)
  }, [measurements])
  useEffect(() => {
    if (selectedPlugin == null || plugins == null) return
    const _keys = Object.keys(plugins)
    const keyAtIndex = _keys[selectedPlugin]
    const plugin = plugins[keyAtIndex] || {}
    const { keys = [] } = plugin
    const filtered = keys.filter((msr) => display.sensorBlacklist.indexOf(msr) === -1)
    setSelectedKey(filtered[0])
    setKeys(filtered)
  }, [selectedPlugin, plugins])
  useEffect(() => {
    if (space && loading === false) {
      const { children = [], parents = [], sensors } = space
      const _allSpaces = [...children, ...parents, space]
      setAllSpaces(_allSpaces)
      setSensors(sensors)
    }
  }, [space, loading, fromDate])
  useEffect(() => {
    setSelectedPlugin(0)
    setSelectedKey(null)
  }, [space.id])
  const handleRadioSelect = (event) => {
    const { value } = event.target
    setSelectedKey(value)
  }
  const handleControlClick = () => {
    showTempControl()
  }
  const getSubDays = (value) => {
    switch (value) {
      case HOUR: return subHours(new Date(), 4)
      case DAY: return subDays(new Date(), 1)
      case WEEK: return subWeeks(new Date(), 1)
      case MONTH: return subMonths(new Date(), 1)
      case YEAR: return subYears(new Date(), 1)
    }
  }
  const timeChange = (value) => {
    setSubFunction(value)
    const a = getSubDays(value)
    setFromDate(a)
  }
  return (
    <ViewWrapper>
      {hasAccess
        ? (
          <DataWrapper>
            <Row>
              <Card>
                <RangeContainer>
                  <RangeText className={HOUR === subFunction && 'active'} onClick={() => timeChange(HOUR)}>4H</RangeText>
                  <RangeText className={DAY === subFunction && 'active'} onClick={() => timeChange(DAY)}>24H</RangeText>
                  <RangeText className={WEEK === subFunction && 'active'} onClick={() => timeChange(WEEK)}>1W</RangeText>
                  <RangeText className={MONTH === subFunction && 'active'} onClick={() => timeChange(MONTH)}>1M</RangeText>
                  <RangeText className={YEAR === subFunction && 'active'} onClick={() => timeChange(YEAR)}>1Y</RangeText>
                </RangeContainer>
              </Card>
            </Row>
            <Row>
              <Card n={2} regular>
                {measurementsExists
                  ? (
                    <ChartWrapper>
                      <PluginWrapper>
                        {Object.keys(plugins).map((plugin, i) =>
                          <BorderButton
                            onClick={() => {
                              setSelectedPlugin(i)
                            }}
                            active={i === selectedPlugin}
                            key={plugin}
                          >{plugin === ''
                              ? 'Sensor'
                              : plugin}
                          </BorderButton>
                        )}

                      </PluginWrapper>
                      <LineChart
                        xTickFormatLabel={chartConfig[subFunction]?.xTickFormatLabel}
                        unit={units[selectedKey] || units.default}
                        activeKeys={[selectedKey]}
                        measurements={allMeasurements}
                      />
                      <Filter>
                        <FilterGroup onChange={handleRadioSelect} value={selectedKey}>
                          {keys && keys.map((key) => {
                            const curUnit = units[key] || units.default
                            return (
                              <FilterRadio key={key} value={key}>
                                <RadioText active={key === selectedKey}>{curUnit.displayName || key}</RadioText>
                              </FilterRadio>
                            )
                          }
                          )}
                        </FilterGroup>
                      </Filter>
                    </ChartWrapper>
                  )

                  : msrLoading
                    ? <ColorSpinner color='gray' />
                    : <InfoText>No data available</InfoText>}
              </Card>
              <Card regular>
                {latestMeasurementsExists
                  ? (
                    <>
                      <CardHeader center>Temperature</CardHeader>
                      <RadialChart value={latestMeasurements.temp} />
                      {
                        (
                          hasRole('superAdmin') ||
                          space.accessLevel >= c.ACCESS_READ ||
                          (
                            isTenantScope() &&
                            hasRole(`tenant.${tenantId}.${c.ACCESS_READ}`) &&
                            space.tenantAccessLevel >= c.ACCESS_READ
                          )
                        ) &&
                          <Button backgroundColor='white' color='black' onClick={handleControlClick}>Control</Button>}
                    </>
                  )
                  : msrLoading
                    ? <ColorSpinner color='gray' />
                    : <InfoText>No data available</InfoText>}
              </Card>
            </Row>
            {isManager &&
              <Row>
                <Card regular>
                  {latestMeasurementsExists && measurementsExists
                    ? (
                      <>
                        <CardHeader>Comfortability</CardHeader>
                        <ComfortChartStatic
                          temp={latestMeasurements.temp}
                          hum={latestMeasurements.hum}
                          latestMeasurement={latestMeasurements}
                          data={measurements}
                          minData={minData}
                          maxData={maxData}
                        />
                      </>
                    )
                    : msrLoading
                      ? <ColorSpinner color='gray' />
                      : <InfoText>No data available</InfoText>}
                </Card>
                <Card regular>
                  {latestMeasurementsExists && measurementsExists
                    ? (
                      <>
                        <CardHeader>Raw Data</CardHeader>
                        <RadarChartApp
                          data={measurements}
                          latestMeasurements={latestMeasurements}
                        />
                      </>
                    )
                    : msrLoading
                      ? <ColorSpinner color='gray' />
                      : <InfoText>No data available</InfoText>}
                </Card>
              </Row>}
          </DataWrapper>
        )
        : loading
          ? <ColorSpinner color='gray' />
          : <InfoText dark>Please select a room</InfoText>}
    </ViewWrapper>
  )
}

export default UserDataView
