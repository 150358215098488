const AdminApiKeys = () => {
  return (
    <div>
      <h2>{'AdminApiKeys'}</h2>
      <p>{'Find me in ./web/src/components/AdminApiKeys/AdminApiKeys.js'}</p>
    </div>
  )
}

export default AdminApiKeys
