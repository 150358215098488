import React, { useRef } from 'react'
import { Form, Select, SelectInterface } from 'antd'
import styled from 'styled-components'

import icon from './icon.svg'
import Spacing from '../Spacing'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Label = styled.label`
  color: #000000;
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  font-size: 1rem;
  text-align: left;
  display: inline-block;
  width: 100%;
`

const DropdownContainer = styled.div`
  position: relative;
  box-shadow: 1px 1px 6px #00000029;
  border-radius: 6px;
  width: 100%;

  .ant-select-arrow {
    transition: transform .3s ease-in-out;
  }

  .ant-select-open {
    .ant-select-arrow {
      transform: rotate(-90deg)
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #7297FF;
    box-shadow: none;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 6px;
    border: 0;
  }

  .ant-form-item {
    box-shadow: none;
    border-radius: 30px;
    margin: 0;
    z-index: 9999;
  }

  .ant-select-arrow {
    display: flex;
    align-items: center;
    fill: #86A6FF;
    right: 1rem;

    svg {
      width: 18px;
      height: 21px;
    }
  }

  .ant-select-open .ant-select-selection-item,
  .ant-select-selection-item {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 1rem;
    color: #000000;
  }

  .ant-select {
    padding: 0.5rem 0;
    border-radius: 6px;
    background-color: #fff;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input),
  .ant-select-open:not(.ant-select-customize-input).ant-select:not(.ant-select-customize-input) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .ant-select-open:not(.ant-select-customize-input) {
    border: 1px solid #7297FF;
    border-bottom: 0;
  }

  .ant-select-dropdown.dropdown-select {
    position: absolute;
    left: 0 !important;
    width: 100% !important;
    min-width: auto !important;
    box-shadow: 1px 4px 6px #00000029;
    border: 1px solid #7297FF;
    border-top: 0;
    border-radius: 6px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .ant-select-selection-item,
  .ant-select-item-option-content {
    text-align: left;
  }
`

/**
 * @typedef DropdownValues
 * @type {object}
 * @property {string} label
 * @property {string} value
 *
 * @typedef DropdownProps
 * @type {object}
 * @property {string} label
 * @property {?SelectInterface} selectProps
 * @property {DropdownValues[]} values
 *
 * @param {DropdownProps} props
 * @returns {React.ReactNode}
 */

const Dropdown = ({ label, values = [], ...selectProps }) => {
  const dropdown = useRef()
  return (
    <>
      <Container>
        {label && (
          <Spacing sb='xs'>
            <Label>{label}</Label>
          </Spacing>
        )}
        <DropdownContainer ref={dropdown}>
          <Form.Item>
            <Select
              {...selectProps}
              suffixIcon={icon}
              className='form'
              dropdownClassName='dropdown-select'
              dropdownAlign={{
                points: ['tl', 'bl'],
                offset: [0, 0],
                overflow: {
                  adjustX: 0,
                  adjustY: 0
                }
              }}
              getPopupContainer={() => dropdown.current}
            >
              {values && values.map(({ label = '', value = '' }) => {
                return (
                  <Select.Option key={`${label}${value}`} value={value}>
                    {label}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
        </DropdownContainer>
      </Container>
    </>
  )
}

export default Dropdown
