import { Popover, Tooltip, Divider, Badge } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import React, { memo, useState } from 'react'
import { Handle, Position } from 'react-flow-renderer'
import CardWrapper from 'src/components/CardWrapper/CardWrapper'
import { useAlgorithmFlow } from 'src/hooks/flow'
import styled, { css } from 'styled-components'

const HandleContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  height: 100%;
  z-index: 10;

  ${({ position }) => (position === Position.Top || position === Position.Bottom) && css`
    flex-direction: row;
    left: 0;
    right: 0;
    transform: translateY(${({ position }) => `${position === Position.Bottom ? '50%' : '-50%'}`});
  `}
  ${({ position }) => (position === Position.Right || position === Position.Left) && css`
    flex-direction: column;
    top: 0;
    bottom: 0;
    transform: translateX(${({ position }) => `${position === Position.Right ? '50%' : '-50%'}`});
  `}
  ${({ position }) => `${position}: 0;`}
`

const Body = styled.div`
  position: ${({ relative }) => relative ? 'relative' : 'initial'};
`

const StyledErrorTooltip = styled(Popover)`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  z-index: 20;

  svg {
    font-size: 1.3rem;
  }
`

const StyledHandle = styled(Handle)`
  height: 10px;
  width: 10px;
`

const StyledMultiHandle = styled(StyledHandle)`
  position: unset;
  transform: none;
  border: 0;
`

const BadgeContainer = styled.div`
  position: relative;
`

const StyledCardWrapper = styled(CardWrapper)`
  min-height: ${({ handles = [] }) => (handles.length * 10) + ((handles.length + 2) * 10)}px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledErrorDivider = styled(Divider)`
  .ant-divider-inner-text {
    padding: 0 0.5rem;
  }

  &.ant-divider-horizontal.ant-divider-with-text-left {
    margin: 0.5rem 0;

    &::before {
      width: 0.5rem;
    }
  }
`

const ErrorContentContainer = styled.div`
  padding: 0 1rem;

  ul {
    margin: 0.5rem 0;
  }
`

const StyledBadge = styled(Badge)`
  @keyframes antStatusProcessing {
    0% {
      transform: scale(0.8);
      opacity: 0.5;
    }

    100% {
      transform: scale(2.1);
      opacity: 0;
    }
  }

  --badge-bg-color: ${props => props.success ? '#52c41a' : '#f50'};

  background-color: transparent;

  &.ant-badge-status-processing::after {
    border: 3px solid var(--badge-bg-color);
    border-radius: 10px;
  }

  & > * {
    background-color: var(--badge-bg-color);
    color: #fff;

    ${props => props.success && css`
      border-radius: 50%;
      padding: 3px;
    `}

    svg {
      height: 14px;
      width: 14px;
    }
  }
`

const ErrorContent = ({ errorMsg = [] }) => {
  if (!Array.isArray(errorMsg) || errorMsg.length === 0) return null

  return (
    <BadgeContainer>
      {errorMsg.map(({ title, exp, errors = [] }) => (
        <>
          {title && (
            <StyledErrorDivider orientation='left'>
              {title}
            </StyledErrorDivider>
          )}
          <ErrorContentContainer>
            {exp && <span>{exp}</span>}
            {errors && errors.map(error => (
              <ul>
                <li>{error}</li>
              </ul>
            ))}
          </ErrorContentContainer>
        </>
      ))}
    </BadgeContainer>
  )
}

const ErrorTooltip = ({ errorMsg = [], children }) => {
  const count = errorMsg.reduce((acc, { errorCount = 0 } = {}) => acc + errorCount, 0)

  return (
    <BadgeContainer>
      <StyledErrorTooltip
        title='Errors'
        content={<ErrorContent errorMsg={errorMsg} />}
        color='red'
        overlayInnerStyle={{
          textAlign: 'left'
        }}
        {...count === 0 ? { visible: false } : {}}
      >
        <StyledBadge
          className={count !== 0 ? 'ant-badge-status-processing' : ''}
          count={count === 0
            ? <CheckOutlined height='0.5em' width='0.5em' />
            : count
          }
          success={count === 0}
        />
      </StyledErrorTooltip>
      {children}
    </BadgeContainer>
  )
}

/**
 * @see https://github.com/wbkd/react-flow/blob/main/src/components/Nodes/DefaultNode.tsx
 */
const DefaultNode = ({
  data,
  isConnectable,
  targetPosition = Position.Left,
  sourcePosition = Position.Right
}) => (
  <ErrorTooltip errorMsg={data?.errorMsg}>
    <StyledHandle type='target' position={targetPosition} isConnectable={isConnectable} />
    <Body>
      {data.label}
    </Body>
    <StyledHandle type='source' position={sourcePosition} isConnectable={isConnectable} />
  </ErrorTooltip>
)

/**
 * @typedef DynamicNodeProps
 * @type {object}
 * @property {object} props
 * @property {Array<{[key: string]: any} & {props: import('react-flow-renderer').HandleProps}>} handles
 *
 * @param {DynamicNodeProps & import('react-flow-renderer').NodeComponentProps} props
 * @returns {React.ReactNode}
 */

const DynamicNode = ({ props = {}, handles = [], ...nodeProps }) => {
  const handlesCount = Math.max(handles.filter(({ position }) => position === 'left').length, handles.filter(({ position }) => position === 'right').length)
  const { isValidConnection } = useAlgorithmFlow()

  if (!handles.length) return (
    <StyledCard {...props} noMargin>
      <DefaultNode {...nodeProps} error={nodeProps?.data?.error} />
    </StyledCard>
  )

  return (
    <ErrorTooltip handles={handles} errorMsg={nodeProps?.data?.errorMsg}>
      <StyledCardWrapper {...props} handles={handles} noMargin>
        <Body>
          {nodeProps?.data?.label}
        </Body>
        {Object.values(Position).map(pos => {
          const posHandles = handles.filter(({ position }) => position === pos)

          if (!posHandles?.length) return null

          return (
            /* eslint-disable react/jsx-handler-names */
            <HandleContainer key={pos} position={pos} handles={handlesCount}>
              {posHandles.map((handleProps) => (
                <Tooltip
                  title={handleProps.tag}
                  key={handleProps.id}
                >
                  <div>
                    <StyledMultiHandle
                      {...handleProps}
                      isValidConnection={isValidConnection}
                    />
                  </div>
                </Tooltip>
              ))}
            </HandleContainer>
            /* eslint-enable react/jsx-handler-names */
          )
        })}
      </StyledCardWrapper>
    </ErrorTooltip>
  )
}

export default memo(DynamicNode)
