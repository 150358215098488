import { Collapse, Timeline, Empty } from 'antd'
import React from 'react'
import Spin from 'src/components/Spin/Spin'
import { getLogColor } from 'src/libraries/logs'
import styled from 'styled-components'

/**
 * @typedef LogTimelineProps
 * @type {object}
 *
 * @param {LogTimelineProps} props
 * @returns {React.ReactNode}
 */

const StyledTimeline = styled(Timeline)`
 width: 100%;

 .ant-timeline-item-tail,
 .ant-timeline-item-head {
   margin-top: 12px;
 }
`

const StyledCollapse = styled(Collapse)`
 border: none;
 margin: 0 16px 0 0;

 .ant-collapse-content-box {
    padding-right: 0;
    ${props => props.noPadding ? 'padding: 0;' : ''}
  }

 & > .ant-collapse-item {
   border: none;
 }

 & > .ant-collapse-item > .ant-collapse-header {
   color: rgba(0, 0, 0, 0.85);
 }

  .ant-tabs-nav {
    ${props => props.noPadding ? 'margin-left: 16px;' : ''}
    margin-right: 16px;
  }
`

const Panel = styled(Collapse.Panel)`
 box-shadow: 3px 3px 10px 0 rgb(0 0 0 / 10%);
 border: none;
 background-color: #fff;


 &.ant-collapse-item,
 &.ant-collapse-item:last-child {
   border-radius: 0.5rem;
 }
`

/**
  * @typedef LogTimelineProps
  * @type {object}
  * @property {object[]} logs
  * @property {?boolean} loading
  * @property {React.ReactNode} children
  *
  * @param {LogTimelineProps} props
  * @returns {React.ReactNode}
  */

const LogTimeline = ({ loading, logs, children, noPadding }) => {
  if (loading) return <Spin />

  if (!Array.isArray(logs) || logs.length === 0) return <Empty />

  return (
    <StyledTimeline>
      {logs.map(log => {
        return (
          <Timeline.Item key={log.time} color={getLogColor(log.severity)}>
            <StyledCollapse noPadding={noPadding}>
              <Panel
                header={log.message}
                key={1}
              >
                {children(log)}
              </Panel>
            </StyledCollapse>
          </Timeline.Item>
        )
      })}
    </StyledTimeline>
  )
}

export default LogTimeline
