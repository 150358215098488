import AdminList from 'src/components/AdminList'
import { Space, Popconfirm, Tag, Dropdown, Menu, Button } from 'antd'
import { useSensorTypes } from 'src/hooks'
import { QuestionCircleOutlined } from '@ant-design/icons'
import Bugsnag from '@bugsnag/js'

import AdminAddSensorTypeModal from 'src/components/AdminAddSensorTypeModal/AdminAddSensorTypeModal'
import styled from 'styled-components'
import { useRef } from 'react'
import DeleteCheckModal from '../DeleteCheckModal'

const DropdownLink = styled.a`
  font-size: 1.5rem;
  color: ${props => props?.colors?.['card.menu.color'] || '#C4C4C4'};
`

const MenuItem = styled(Menu.Item)`
  text-align: center;
`

const AdminSensorTypeTab = (props) => {
  const { typeName } = props
  const { refresh, sensorTypes, loading, adminDeleteSensorType } = useSensorTypes()

  const shouldUpdate = () => {
    refresh()
  }

  const handleDelete = (record) => {
    adminDeleteSensorType(record)
      .then(res =>
        refresh()
      )
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => <a>{text}</a>
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      responsive: ['md']
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type - b.type,
      sortDirections: ['descend']
    },
    {
      title: 'FW version',
      dataIndex: 'fwVersion',
      key: 'fwVersion',
      responsive: ['md'],
      sorter: (a, b) => a.fwVersion - b.fwVersion,
      sortDirections: ['descend']
    },
    {
      title: 'Allow auto upgrade',
      dataIndex: 'allowAutoUpgrade',
      key: 'allowAutoUpgrade',
      responsive: ['md'],
      render: allowAutoUpgrade => allowAutoUpgrade ? 'Yes' : 'No'
    },
    {
      title: 'Filename',
      dataIndex: 'filename',
      key: 'filename',
      responsive: ['md']
    },
    {
      title: 'Meta data',
      dataIndex: 'metaData',
      key: 'metaData',
      responsive: ['md'],
      render: (metaDataText, record) => {
        if (metaDataText) {
          console.log('metaData', metaDataText)
          let metaData = {}

          try {
            metaData = JSON.parse(metaDataText)
          } catch (e) {
            Bugsnag.notify(e)
          }

          return Object.keys(metaData).map((key) => {
            const array = metaData[key] || []
            const color = key === 'ports' ? '#2E86C1' : 'orange'
            return array.map((item, i) => {
              return (
                <Tag color={color} key={`${record?.id ?? ''}_${item.key}_${i}`}>
                  {key} : {item.displayName}
                </Tag>
              )
            })
          })
        }
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => {
        const menu = (
          <Menu>
            <MenuItem>
              <AdminAddSensorTypeModal
                shouldUpdate={shouldUpdate}
                tableData={sensorTypes}
                record={record}
                edit
              />
            </MenuItem>
            <MenuItem>
              <DeleteCheckModal
                titleText={`Are you sure you want to delete this sensor type ${record?.name ? `(name: ${record?.name})` : ''}?`}
                deleteFn={() => handleDelete(record)}
                deleteBtnText='Yes, delete'
                cancelBtnText='No, cancel'
              />
            </MenuItem>
          </Menu>
        )

        return (
          <Space size='middle'>
            <div onClick={(e) => e.stopPropagation()}>
              <Dropdown
                overlay={menu}
                placement='bottomRight'
                trigger={['click']}
              >
                <DropdownLink className='ant-dropdown-link' onClick={e => e.stopPropagation()}>
                  <i className='fas fa-ellipsis-v' />
                </DropdownLink>
              </Dropdown>
            </div>
          </Space>
        )
      }
    }
  ]

  return (
    <AdminList shouldUpdate={shouldUpdate} typeName={typeName} data={sensorTypes} columns={columns} loading={loading} />
  )
}

export default AdminSensorTypeTab
