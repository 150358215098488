// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Router, Route, Private, routes } from '@redwoodjs/router'
import React from 'react'

import { ACCESS_READ, ACCESS_WRITE } from 'src/constants'
import AuthCheck from 'src/components/AuthCheck'
import { useAuth } from 'src/hooks'
import AppPage from 'src/pages/AppPage/AppPage'
import LogPage from 'src/pages/LogPage/LogPage'
import AdminUserPage from 'src/pages/AdminUserPage/AdminUserPage'
import SpacePageAdmin from 'src/pages/SpacePageAdmin/SpacePageAdmin'
import AlgorithmPage from 'src/pages/AlgorithmPage/AlgorithmPage'
import AlgorithmNodePage from 'src/pages/AlgorithmNodePage/AlgorithmNodePage'
import AdminSensorPage from 'src/pages/AdminSensorPage/AdminSensorPage'
import AdminCommandPage from 'src/pages/AdminCommandPage/AdminCommandPage'
import AdminSensorConfigSettingGroupPage from 'src/pages/AdminSensorConfigSettingGroupPage/AdminSensorConfigSettingGroupPage'
import AdminSensorConfigSettingPage from 'src/pages/AdminSensorConfigSettingPage/AdminSensorConfigSettingPage'
import AdminPage from 'src/pages/AdminPage/AdminPage'
import BuildingsPage from 'src/pages/BuildingsPage/BuildingsPage'
import SpacePage from 'src/pages/SpacePage/SpacePage'
import DashboardPage from 'src/pages/DashboardPage/DashboardPage'
import FlowPage from 'src/pages/FlowPage/FlowPage'
import { FlowProvider } from 'src/contexts/flow'
import ClientDashboardPage from 'src/pages/ClientDashboardPage/ClientDashboardPage'

const Routes = () => {
  return (
    <Router useAuth={useAuth}>
      {/* eslint-disable no-undef */}
      <Route path='/' page={IndexRedirect} />
      <Route path='/resetPassword/{token}' page={ResetPasswordPage} name='resetPassword' />
      <Route path='/reset/password/{token}' page={ResetPasswordPage} name='resetPassword' />
      <Route path='/t/{tenantId:Int}/resetPassword/{token}' page={ResetPasswordPage} name='resetPassword' />
      <Route path='/t/{tenantId:Int}/reset/password/{token}' page={ResetPasswordPage} name='resetPassword' />
      <Route path='/confirm/emailAddress/{token}' page={ConfirmEmailPage} name='confirmEmail' />
      <Route path='/t/{tenantId:Int}/confirm/emailAddress/{token}' page={ConfirmEmailPage} name='confirmEmail' />
      <Route path='/login' page={LoginPage} name='login' />
      <Route notfound page={NotFoundPage} />

      <Private unauthenticated='login'>
        <Route path='/t' page={ClientDashboardPage} name='v4Dashboard' />
        <Route path='/t/overview' page={AppPage} name='app' />
        <Route path='/t/space/{id:Int}' page={SpacePage} name='space' />
        <Route path='/t/buildings' page={BuildingsPage} name='buildings' />
        <Route path='/t/analytics' page={DashboardPage} name='dashboard' />
        <Route path='/playground' page={PlaygroundPage} name='playground' />
      </Private>

      <Route
        path='/t/{tenantId:Int}'
        page={props => (
          <AuthCheck
            tenantAccess={ACCESS_WRITE}
            unauthenticated={routes.login}
            notWithRole={routes.v4Dashboard}
            page={ClientDashboardPage}
            {...props}
          />
        )}
        name='v4DashboardTenant'
      />

      <Route
        path='/t/{tenantId:Int}/analytics'
        page={props => (
          <AuthCheck
            tenantAccess={ACCESS_READ}
            unauthenticated={routes.login}
            notWithRole={routes.v4Dashboard}
            page={DashboardPage}
            {...props}
          />
        )}
        name='dashboardTenant'
      />

      <Route
        path='/t/manage/commands/{id:Int}'
        page={props => (
          <AuthCheck
            role={['superAdmin']}
            unauthenticated={routes.login}
            notWithRole={routes.app}
            page={AdminCommandPage}
            {...props}
          />
        )}
        name='adminCommand'
      />

      <Route
        path='/t/manage/sensorSettings/groups/{id:Int}'
        page={props => (
          <AuthCheck
            role={['superAdmin']}
            unauthenticated={routes.login}
            notWithRole={routes.app}
            page={AdminSensorConfigSettingGroupPage}
            {...props}
          />
        )}
        name='adminSensorConfigSettingGroup'
      />

      <Route
        path='/t/manage/sensorSettings/configurableSetting/{id:Int}'
        page={props => (
          <AuthCheck
            role={['superAdmin']}
            unauthenticated={routes.login}
            notWithRole={routes.app}
            page={AdminSensorConfigSettingPage}
            {...props}
          />
        )}
        name='adminSensorConfigSetting'
      />

      <Route
        path='/t/manage/logs'
        page={props => (
          <AuthCheck
            role={['superAdmin']}
            unauthenticated={routes.login}
            notWithRole={routes.app}
            page={LogPage}
            {...props}
          />
        )}
        name='log'
      />
      <Route
        path='/t/manage/user'
        page={props => (
          <AuthCheck
            role={['superAdmin']}
            unauthenticated={routes.login}
            notWithRole={routes.app}
            page={AdminUserPage}
            {...props}
          />
        )}
        name='adminUser'
      />
      <Route
        path='/t/manage/space/{id:Int}'
        page={props => (
          <AuthCheck
            role={['superAdmin']}
            unauthenticated={routes.login}
            notWithRole={routes.app}
            page={SpacePageAdmin}
            {...props}
          />
        )}
        name='spacePageAdmin'
      />
      <Route
        path='/t/manage/space/'
        page={props => (
          <AuthCheck
            role={['superAdmin']}
            unauthenticated={routes.login}
            notWithRole={routes.app}
            page={SpacePageAdmin}
            {...props}
          />
        )}
        name='spacePageAdmin'
      />
      <Route
        path='/t/manage/algorithm/{id}'
        page={props => (
          <AuthCheck
            role={['superAdmin']}
            unauthenticated={routes.login}
            notWithRole={routes.app}
            page={AlgorithmPage}
            {...props}
          />
        )}
        name='algorithmPage'
      />

      <Route
        path='/t/manage/flows/{id}'
        page={props => (
          <FlowProvider>
            <AuthCheck
              role={['superAdmin']}
              unauthenticated={routes.login}
              notWithRole={routes.app}
              page={FlowPage}
              {...props}
            />
          </FlowProvider>
        )}
        name='flowPage'
      />
      <Route
        path='/t/manage/algorithm/'
        page={props => (
          <AuthCheck
            role={['superAdmin']}
            unauthenticated={routes.login}
            notWithRole={routes.app}
            page={AlgorithmPage}
            {...props}
          />
        )}
        name='algorithmPage'
      />
      <Route
        path='/t/manage/algorithms/nodes'
        page={props => (
          <AuthCheck
            role={['superAdmin']}
            unauthenticated={routes.login}
            notWithRole={routes.app}
            page={AlgorithmNodePage}
            {...props}
          />
        )}
        name='flowNodePage'
      />

      <Route
        path='/t/manage/flows/nodes/{id}'
        page={props => (
          <AuthCheck
            role={['superAdmin']}
            unauthenticated={routes.login}
            notWithRole={routes.app}
            page={AlgorithmNodePage}
            {...props}
          />
        )}
        name='flowNodePage'
      />
      <Route
        path='/t/manage/sensor/{id:Int}'
        page={props => (
          <AuthCheck
            role={['superAdmin']}
            unauthenticated={routes.login}
            notWithRole={routes.app}
            page={AdminSensorPage}
            {...props}
          />
        )}
        name='adminSensor'
      />
      <Route
        path='/t/manage/{type}'
        page={props => (
          <AuthCheck
            role={['superAdmin']}
            unauthenticated={routes.login}
            notWithRole={routes.app}
            page={AdminPage}
            {...props}
          />
        )}
        name='adminContent'
      />
      <Route
        path='/t/manage'
        page={props => (
          <AuthCheck
            role={['superAdmin']}
            unauthenticated={routes.login}
            notWithRole={routes.app}
            page={AdminPage}
            {...props}
          />
        )}
        name='admin'
      />
      <Route
        path='/t/{tenantId:Int}/manage/logs'
        page={props => (
          <AuthCheck
            tenantAccess={ACCESS_WRITE}
            unauthenticated={routes.login}
            notWithRole={routes.v4Dashboard}
            page={LogPage}
            {...props}
          />
        )}
        name='logTenant'
      />
      <Route
        path='/t/{tenantId:Int}/manage/user'
        page={props => (
          <AuthCheck
            tenantAccess={ACCESS_WRITE}
            unauthenticated={routes.login}
            notWithRole={routes.v4Dashboard}
            page={AdminUserPage}
            {...props}
          />
        )}
        name='adminUserTenant'
      />
      <Route
        path='/t/{tenantId:Int}/manage/space/{id:Int}'
        page={props => (
          <AuthCheck
            tenantAccess={ACCESS_WRITE}
            unauthenticated={routes.login}
            notWithRole={routes.v4Dashboard}
            page={SpacePageAdmin}
            {...props}
          />
        )}
        name='spacePageAdminTenant'
      />
      <Route
        path='/t/{tenantId:Int}/manage/space/'
        page={props => (
          <AuthCheck
            tenantAccess={ACCESS_WRITE}
            unauthenticated={routes.login}
            notWithRole={routes.v4Dashboard}
            page={SpacePageAdmin}
            {...props}
          />
        )}
        name='spacePageAdminTenant'
      />
      <Route
        path='/t/{tenantId:Int}/manage/algorithm/{id}'
        page={props => (
          <AuthCheck
            tenantAccess={ACCESS_WRITE}
            unauthenticated={routes.login}
            notWithRole={routes.v4Dashboard}
            page={AlgorithmPage}
            {...props}
          />
        )}
        name='algorithmPageTenant'
      />
      {/*<Route
        path='/t/{tenantId:Int}/manage/flows/nodes/{id}'
        page={props => (
          <AuthCheck
            tenantAccess={ACCESS_WRITE}
            unauthenticated={routes.login}
            notWithRole={routes.v4Dashboard}
            page={AlgorithmNodePage}
            {...props}
          />
        )}
        name='flowNodePageTenant'
        />*/}
      <Route
        path='/t/{tenantId:Int}/manage/flows/{id}'
        page={props => (
          <FlowProvider>
            <AuthCheck
              tenantAccess={ACCESS_WRITE}
              unauthenticated={routes.login}
              notWithRole={routes.v4Dashboard}
              page={FlowPage}
              {...props}
            />
          </FlowProvider>
        )}
        name='flowPageTenant'
      />
      <Route
        path='/t/{tenantId:Int}/manage/algorithm/'
        page={props => (
          <AuthCheck
            tenantAccess={ACCESS_WRITE}
            unauthenticated={routes.login}
            notWithRole={routes.v4Dashboard}
            page={AlgorithmPage}
            {...props}
          />
        )}
        name='algorithmPageTenant'
      />
      {/*<Route
        path='/t/{tenantId:Int}/manage/flows/nodes'
        page={props => (
          <AuthCheck
            tenantAccess={ACCESS_WRITE}
            unauthenticated={routes.login}
            notWithRole={routes.v4Dashboard}
            page={AlgorithmNodePage}
            {...props}
          />
        )}
        name='flowPageTenant'
        />*/}
      <Route
        path='/t/{tenantId:Int}/manage/sensor/{id:Int}'
        page={props => (
          <AuthCheck
            tenantAccess={ACCESS_WRITE}
            unauthenticated={routes.login}
            notWithRole={routes.v4Dashboard}
            page={AdminSensorPage}
            {...props}
          />
        )}
        name='adminSensorTenant'
      />
      <Route
        path='/t/{tenantId:Int}/manage/{type}'
        page={props => (
          <AuthCheck
            tenantAccess={ACCESS_WRITE}
            unauthenticated={routes.login}
            notWithRole={routes.v4Dashboard}
            page={AdminPage}
            {...props}
          />
        )}
        name='adminContentTenant'
      />
      <Route
        path='/t/{tenantId:Int}/manage'
        page={props => (
          <AuthCheck
            tenantAccess={ACCESS_WRITE}
            unauthenticated={routes.login}
            notWithRole={routes.v4Dashboard}
            page={AdminPage}
            {...props}
          />
        )}
        name='adminTenant'
      />
      <Route
        path='/t/{tenantId:Int}/overview'
        page={props => (
          <AuthCheck
            tenantAccess={ACCESS_READ}
            unauthenticated={routes.login}
            notWithRole={routes.v4Dashboard}
            page={AppPage}
            {...props}
          />
        )}
        name='appTenant'
      />
      <Route
        path='/t/{tenantId:Int}/buildings'
        page={props => (
          <AuthCheck
            tenantAccess={ACCESS_READ}
            unauthenticated={routes.login}
            notWithRole={routes.v4Dashboard}
            page={BuildingsPage}
            {...props}
          />
        )}
        name='buildingsTenant'
      />

      <Route
        path='/t/{tenantId:Int}/space/{id:Int}'
        page={props => (
          <AuthCheck
            tenantAccess={ACCESS_READ}
            unauthenticated={routes.login}
            notWithRole={routes.v4Dashboard}
            page={SpacePage}
            {...props}
          />
        )}
        name='spaceTenant'
      />
      {/* eslint-enable no-undef */}
    </Router>
  )
}

export default Routes
