import { useState } from 'react'
import { Dropdown, Card, Slider, Tabs, Button } from 'antd'
import { LineChartOutlined, DownOutlined } from '@ant-design/icons'
import styled from 'styled-components'
const { TabPane } = Tabs

const TabsContainer = styled.div`
 & .ant-tabs-nav-wrap{

 }
`

const FilterDropdown = (props) => {
  const { units, setRefValues } = props
  const [visible, setVisible] = useState(false)

  const chartMenu = (
    <Card style={{ width: '500px', padding: '1em' }}>
      <TabsContainer>
        <Tabs defaultActiveKey='temp'>
          {Object.keys(units)
            .map((key) => {
              const unit = units[key]
              const {
                displayName,
                acceptableValues: av,
                comfortableValues: cv,
                displayRange
              } = unit
              const [minRange, maxRange] = displayRange
              return (
                <TabPane tab={displayName} key={key}>
                  <div>
                    <p>Acceptable <strong> {av.min}{unit.unit}</strong> - <strong>{av.max}{unit.unit}</strong></p>
                    <Slider
                      range
                      defaultValue={[av.min, av.max]}
                      min={minRange}
                      max={maxRange}
                      step={0.5}
                      onAfterChange={(e) => setRefValues(key, 'acceptableValues', e)}
                    />
                  </div>
                  <div>
                    <p>Comfortable <strong> {cv.min}{unit.unit}</strong> - <strong>{cv.max}{unit.unit}</strong></p>
                    <Slider
                      range
                      defaultValue={[cv.min, cv.max]}
                      min={minRange}
                      max={maxRange}
                      step={0.5}
                      onAfterChange={(e) => setRefValues(key, 'comfortableValues', e)}
                    />
                  </div>
                </TabPane>
              )
            })}
        </Tabs>
      </TabsContainer>
    </Card>
  )

  const handleVisibleChange = (flag) => {
    setVisible(flag)
  }

  return (
    <Dropdown
      overlay={chartMenu}
      trigger={['click']}
      onVisibleChange={handleVisibleChange}
      visible={visible}
    >
      <Button icon={<LineChartOutlined />}><DownOutlined /></Button>
    </Dropdown>
  )
}

export default FilterDropdown
