import React, { useRef } from 'react'
import styled from 'styled-components'
import { useTheme } from 'src/hooks'
import Spacing from '../Spacing'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
`

const Label = styled.label`
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  font-size: 1rem;
`

const InputContainer = styled.div`
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 1px 1px 6px #00000029;
  position: relative;
  min-height: 48px;
`

const Input = styled.input`
  position: absolute;
  border-radius: 6px;
  border: 0;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 1rem;
  color: #000000;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: ${({ spacings }) => spacings?.xs ?? '1rem'} ${({ spacings }) => spacings?.s ?? '1rem'};

  :focus {
    outline: none;
  }
`

const Button = styled.button`
  border: 0;
  background: none;
  cursor: pointer;
  color: #7297FF;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: ${({ spacings }) => spacings?.s ?? '1rem'};
`

/**
 * @typedef SearchBarProps
 * @type {object}
 * @property {string} label
 * @property {?React.InputHTMLAttributes<HTMLInputElement>} inputProps
 * @property {?React.ButtonHTMLAttributes<HTMLButtonElement>} btnProps
 *
 * @param {SearchBarProps} props
 * @returns {React.ReactNode}
 */

const SearchBar = ({ label, btnProps, ...inputProps }) => {
  const { spacings } = useTheme()

  return (
    <Container>
      {label && (
        <Spacing sb='xs'>
          <Label htmlFor={inputProps?.name || ''}>
            {label}
          </Label>
        </Spacing>
      )}
      <InputContainer>
        <Input {...inputProps} spacings={spacings} />
        <Button {...btnProps} spacings={spacings}>
          <i className='fas fa-search' />
        </Button>
      </InputContainer>
    </Container>
  )
}

export default SearchBar
