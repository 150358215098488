import { Form, Input, Switch, Select, Divider } from 'antd'
import React from 'react'
import CronInput from 'src/components/CronInput'
import { useTenant } from 'src/hooks'

const { Item } = Form

/**
 * @typedef FlowSettingsTabProps
 * @type {object}
 *
 * @param {FlowSettingsTabProps} props
 * @returns {React.ReactNode}
 */

const FlowSettingsTab = ({ handleFlowSettingValueUpdate, flowSettingsForm, flow }) => {
  const { isTenantScope } = useTenant()

  return (
    <Form
      form={flowSettingsForm}
      layout='vertical'
      onFieldsChange={handleFlowSettingValueUpdate}
    >
      <Item
        label='Name'
        name='name'
        rules={[
          {
            required: true,
            message: 'Please add a flow name'
          }
        ]}
      >
        <Input placeholder='Name' />
      </Item>
      <Item
        label='Global'
        name='global'
        initialValue={!isTenantScope()}
        valuePropName='checked'
      >
        <Switch />
      </Item>
      <Item
        label='Description'
        name='description'
      >
        <Input.TextArea placeholder='Description' />
      </Item>
      <Divider orientation='left'>
        Run on
      </Divider>
      <Item
        label='Events'
        name={['events', 'webhooks']}
      >
        <Select
          mode='multiple'
          showSearch
          dropdownMatchSelectWidth={false}
          dropdownAlign={{
            points: ['tr', 'br']
          }}
          options={flow?.nodeEvents}
        />
      </Item>
      <Item
        label='Time'
        name={['events', 'cron']}
      >
        <CronInput />
      </Item>
    </Form>
  )
}

export default FlowSettingsTab
