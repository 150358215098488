import React from 'react'
import { icon } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AntdIcon from '@ant-design/icons'

import DisableIcon from 'src/assets/svgs/disable.svg'
import EnableIcon from 'src/assets/svgs/enable.svg'

const icons = {
  enable: EnableIcon,
  disable: DisableIcon
}

/**
 * @typedef IconProps
 * @type {object}
 *
 * @param {IconProps} props
 * @returns {React.ReactNode}
 */

const Icon = ({ iconName }) => {
  console.log(iconName)
  if (icon({ prefix: 'fas', iconName })) {
    return (
      <AntdIcon
        component={props => (
          <FontAwesomeIcon
            {...props}
            icon={['fas', iconName]}
          />
        )}
      />
    )
  }

  const IconComp = icons?.[iconName]

  return IconComp
    ? <AntdIcon component={IconComp} />
    : null
}

export default Icon
