import React, { useEffect, useState } from 'react'
import {
  Modal,
  Button as AntButton,
  Form,
  Input,
  Select,
  Divider,
  message
} from 'antd'

import Button, { BackButton } from 'src/components/Button'
import { useSensorPlugins, usePlugins } from 'src/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styled from 'styled-components'
const { Option } = Select

const UniFont = styled.span`
  font-weight: normal;
  color: ${props => props.color || 'black'};
  font-size: 16px;
`

const ImgWrap = styled.div`
  width: 30px;
  max-height: 20px;

  & img {
    max-width: 100%;
    max-height: 100%100%;
  }
`

const FlexWrap = styled.div`
  display: flex;
`

const SelectContainer = styled.div`
  padding: 1em 0;
  & .ant-form-item{
    display: flex;
    flex-direction: column;
  }
  & .ant-form-item-label{
    text-align: left;
  }
`

const ConfigurePlugin = (props) => {
  const {
    sensor = {},
    plugins,
    formActive,
    setFormActive,
    setPluginSelected,
    pluginSelected,
    handleCancel,
    shouldUpdate,
    metaData,
    selectedPort,
    setSelectedPort,
    plugin,
    settings = {}
  } = props
  const { updateSensorPlugin, addPluginToSensor } = useSensorPlugins({ fetch: false })
  const { getPlugin } = usePlugins({ fetch: false })
  const [fetchedPlugin, setFetchedPlugin] = useState(undefined)
  const [configSettings, setConfigSettings] = useState({})
  const [form] = Form.useForm()

  const handleSubmit = obj => {
    const { port } = obj
    const { id: sensorId } = sensor
    const userSettings = Object.keys(obj)
      .reduce((acc, cur) => {
        const parts = cur.split('^@^')
        const [name, type] = parts
        if (type) {
          return {
            ...acc,
            user: {
              ...acc.user,
              [name]: { name: name, value: obj[cur], type: type }
            }
          }
        } else {
          return {
            ...acc,
            [name]: { name: name, value: obj[cur] }

          }
        }
      }, {})

    if (settings && plugin) {
      const { id: sensorPluginId } = plugin.SensorPlugin
      const payload = { sensorId: sensorId, plugin: { port, userSettings: userSettings } }
      updateSensorPlugin(sensorPluginId, payload)
        .then((res) => {
          shouldUpdate()
          handleReset()
          form.resetFields()
          handleCancel()
        }).catch()
    } else {
      if (!pluginSelected) { return message.error('You need to select a machine') }
      const payload = { plugin: { id: pluginSelected, port, userSettings: userSettings } }
      addPluginToSensor(sensorId, payload)
        .then((res) => {
          shouldUpdate()
          handleReset()
          form.resetFields()
          handleCancel()
        }).catch()
    }
  }

  const onSelect = (id) => {
    setPluginSelected(id)
    getPlugin(id)
      .then((res) => {
        setFetchedPlugin(res)
        form.resetFields()
      }).catch(() => {
      })
  }

  const onPortChange = (value) => {
    form.setFieldsValue({
      port: value
    })
  }
  const handleClear = () => {
    setConfigSettings({})
    form.resetFields()
  }

  const handleReset = () => {
    setPluginSelected()
    setConfigSettings({})
    setSelectedPort()
    setFormActive(1)
  }

  useEffect(() => {
    if (pluginSelected) {
      getPlugin(pluginSelected)
        .then((res) => {
          const { configurableSettings } = res
          const configurableSettingsJson = JSON.parse(configurableSettings)
          setConfigSettings(configurableSettingsJson)
        }).catch(() => {
        })
    }
  }, [formActive])

  useEffect(() => {
    if (fetchedPlugin || plugin) {
      const { configurableSettings: configJson } = fetchedPlugin ?? plugin
      const configurableSettings = JSON.parse(configJson ?? '{}')
      const currSettings = fetchedPlugin ? configurableSettings : settings
      console.log('configurableSettings', configurableSettings)
      setConfigSettings(configurableSettings)
      const userSettings = Object.keys(currSettings)
        .reduce((acc, cur) => {
          const value = currSettings?.[cur]?.value

          if (value) {
            return {
              ...acc,
              [cur]: value
            }
          } else {
            const user = currSettings[cur]
            const userSettings = Object.keys(user)
              .reduce((acc, cur) => {
                const value = user[cur].value
                const type = user[cur].type
                if (value) {
                  return {
                    ...acc,
                    [`${cur}^@^${type}`]: value
                  }
                }
              }, {})
            return {
              ...acc,
              ...userSettings
            }
          }
        }, {})
      form.setFieldsValue({
        ...userSettings,
        Test: 'QWERTY',
        test: 'bajs'
      })
    }
  }, [plugin, fetchedPlugin])

  const validateMessages = {
    required: "'${label}' is required!",
    types: {
      email: '${label} is not validate email!',
      number: '${label} is not a validate number!'
    }
  }

  return (
    <>
      <div>
        {!plugin && <BackButton onClick={() => handleReset()} />}
        <UniFont>{plugin ? 'Edit machine' : 'Link machine'}</UniFont>
      </div>
      {!plugin &&
        <SelectContainer>
          <Form.Item
            label='SELECT MACHINE'
            name='machineId'
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              style={{ width: '100%', textAlign: 'left' }}
              placeholder='Select a machine'
              allowClear
              onSelect={onSelect}
              optionLabelProp='label'
              value={pluginSelected}
              onClear={() => handleClear()}
            >
              {plugins.map(item => {
                return (
                  <Option key={item.id} label={item.name} value={item.id}>
                    <FlexWrap className='demo-option-label-item'>
                      <ImgWrap>
                        <FontAwesomeIcon color='#7f9cf0' style={{ fontSize: '1em' }} icon={['fas', item.coverImage]} />
                      </ImgWrap>
                      {item.name}
                    </FlexWrap>
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </SelectContainer>}
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout='vertical'
        size='large'
        id='addPlugin'
        onFinish={handleSubmit}
        colon={false}
        form={form}
        validateMessages={validateMessages}
        initialValues={{
          ...configSettings,
          port: selectedPort
        }}
      >
        {() => (
          <>
            {Object.entries(configSettings)?.map(([key, setting]) => {
              if (Array.isArray(setting)) {
                return (
                  <>
                    <UniFont>Config Settings</UniFont>
                    {setting.map((item, i) =>
                      <>
                        {Object.entries(item).map(([key, item]) => {
                          const { type, name, required } = item

                          return (
                            <Form.Item
                              key={name}
                              label={name}
                              name={`${name}^@^${type}`}
                              required={required}
                            >
                              <Input type={type} placeholder={key} allowClear />
                            </Form.Item>
                          )
                        })}
                      </>
                    )}
                  </>
                )
              } else {
                return (
                  <Form.Item
                    key={key}
                    label={`${key} - contact admin to update`}
                    name={key}
                  >
                    <Input type='input' disabled allowClear />
                  </Form.Item>
                )
              }
            })}
            {metaData && (
              <SelectContainer>
                <Form.Item
                  label='SELECT PORT'
                  name='port'
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch
                    style={{ width: '100%', textAlign: 'left' }}
                    placeholder='Select a port'
                    allowClear
                    optionLabelProp='label'
                    onChange={onPortChange}
                  >
                    {metaData && metaData.ports.map(item => {
                      return (
                        <Option key={item.key} label={item.displayName} value={item.key}>
                          {item.displayName}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </SelectContainer>
            )}
          </>
        )}
      </Form>
    </>
  )
}

export default ConfigurePlugin
