import { Collapse, Divider, Form, Select } from 'antd'
import { DeleteFilled } from '@ant-design/icons'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import Button from 'src/components/Button'

const Panel = styled(Collapse.Panel)`
  box-shadow: 3px 3px 10px 0 rgb(0 0 0 / 10%);
  margin-bottom: 1em;

  &.ant-collapse-item,
  &.ant-collapse-item:last-child {
    border-radius: 0.5rem;
  }
`

const AddBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1em;
`

/**
 * @typedef EventReplacementSettingsProps
 * @type {object}
 * @property {import('antd/lib/form').FormInstance} form
 * @property {object} events
 * @property {string[]} replacementKeys
 * @property {?import('antd/lib/form').FormListProps['name']} name
 *
 * @param {EventReplacementSettingsProps} props
 * @returns {React.ReactNode}
 */

const EventReplacementSettings = ({ form, name = 'events', events, replacementKeys = [] }) => {
  const settableEvents = useMemo(() => events
    .filter(e => !form.getFieldValue('events')
      ?.find(settedEvent => (
        settedEvent?.event != null &&
        e?.event != null &&
        settedEvent?.event === e?.event
        )
      )
    )
    .map(({ label, event, id }) => ({ label: `${event} (${label})`, value: id })) ?? [],
    [form.getFieldValue('events')]
  )

  const renderDeleteBtn = clickHandler => (
    <DeleteFilled
      style={{
        color: '#d9363e'
      }}
      onClick={e => {
        e.stopPropagation()

        clickHandler()
      }}
    />
  )

  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => (
        <>
          <Collapse defaultActiveKey={[fields?.[fields.length - 1]?.key]} key={fields.length} ghost>
            {fields.map(({ key, ...field }, i) => {
              const { label, event, params } = events.find(e => e.id === form.getFieldValue(['events', field.name, 'EventId'])) ?? {}

              return (
                <Panel
                  header={`Event: ${`${event} (${label})` ?? i + 1}`}
                  key={key}
                  forceRender
                  extra={renderDeleteBtn(() => {
                    remove(i)
                  })}
                >
                  <Form.Item
                    {...field}
                    label='Event'
                    name={[field.name, 'EventId']}
                    fieldKey={[field.fieldKey, 'EventId']}
                  >
                    <Select
                      options={settableEvents}
                    />
                  </Form.Item>
                  {Array.isArray(params) && (
                    <Divider orientation='left'>
                      Replacements
                    </Divider>
                  )}
                  {
                    Array.isArray(params) && params.map(param => (
                      <Form.Item
                        {...field}
                        label={param}
                        name={[field.name, 'replacements', param]}
                        fieldKey={[field.fieldKey, 'replacements', param]}
                      >
                        <Select
                          mode='multiple'
                          options={replacementKeys.map(replacementKey => ({ value: replacementKey }))}
                        />
                      </Form.Item>
                    ))
                  }
                </Panel>
              )
            })}
          </Collapse>
          <AddBtnContainer>
            <Button
              disabled={!settableEvents?.length}
              onClick={e => {
                e.preventDefault()

                add()
              }}
            >
              Add event
            </Button>
          </AddBtnContainer>
        </>
      )}
    </Form.List>
  )
}

export default EventReplacementSettings
