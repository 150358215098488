import { useMemo, useRef,  useState } from 'react'

const DEFAULT_META = {
  totalCount: 0,
  limit: 10
}

export const usePagination = ({ pageInfo }) => {
  const {
    startCursor,
    endCursor,
    hasNextPage,
    hasPreviousPage,
    pageSize,
    totalCount,
  } = pageInfo || DEFAULT_META

  const antdPaginationConfig = useMemo(() => {
    let total
    let current

    if (
      (typeof totalCount !== 'string' && typeof totalCount !== 'number')
      || isNaN(totalCount)
      || +totalCount < 1
    ) {
      total = 0
      current = 1
    } else if (hasNextPage && hasPreviousPage) {
      total = pageSize * 3
      current = 2
    } else if (hasNextPage && !hasPreviousPage) {
      total = pageSize * 2
      current = 1
    } else if (!hasNextPage && hasPreviousPage) {
      total = pageSize * 2
      current = 2
    } else {
      total = pageSize
      current = 1
    }

    return {
      total,
      current
    }
  }, [pageInfo])

  const generatePaginationData = (cursor) => ({
    ...DEFAULT_META,
    ...pageInfo,
    ...cursor || {}
})

  const [paginationData, setPaginationData] = useState(generatePaginationData())

  const goToNextPage = () => {
    if (!hasNextPage) return

    setPaginationData(generatePaginationData({
      after: endCursor
    }))
  }

  const goToPrevPage = () => {
    if (!hasPreviousPage) return

    setPaginationData(generatePaginationData({ before: startCursor }))
  }

  const goToPrevOrNextPage = (page) => {
    if (page < antdPaginationConfig.current) {
      goToPrevPage()
    }

    if (page > antdPaginationConfig.current) {
      goToNextPage()
    }
  }

  const reset = () => {
    setPaginationData({ ...DEFAULT_META })
  }

  return {
    goToNextPage,
    goToPrevPage,
    paginationData,
    goToPrevOrNextPage,
    antdPaginationConfig,
    pageInfo: pageInfo || DEFAULT_META,
    reset
  }
}