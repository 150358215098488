import { useEffect, useState } from 'react'
import {
  Table,
  Tag,
  Space,
  Popconfirm
} from 'antd'
import Button, { BackButton } from 'src/components/Button'
import { QuestionCircleOutlined } from '@ant-design/icons'
import LoadingScreen from 'src/components/LoadingScreen'
import { useUserWithSpaceAccess, useSpaceUser, useTenants, useAuth, useTenant } from 'src/hooks'
import SpaceUserAccessModal from 'src/components/SpaceUserAccessModal'

import styled from 'styled-components'
import SpaceTenantAccessModal from 'src/components/SpaceTenantAccessModal'
import { ACCESS_WRITE } from 'src/constants'
const TopBar = styled.div`
  display: flex;
`

const FullSpace = styled(Space)`
  width: 100%;
`

const SpaceUserAccess = ({ space = {}, refresh: spaceRefresh = () => null }) => {
  const { id: spaceId, tenants } = space
  const { isTenantScope, tenantId } = useTenant()
  const { hasRole } = useAuth()
  const { loading, users, refresh } = useUserWithSpaceAccess(spaceId)
  const { adminRemoveUserFromSpace } = useSpaceUser()
  const { unbindSpaceTenantRel } = useTenants({ fetch: false })
  const readOnly = (
    !hasRole('superAdmin') &&
    isTenantScope() &&
    (
      !hasRole(`tenant.${tenantId}.${ACCESS_WRITE}`) ||
      space.tenantAccessLevel < ACCESS_WRITE
    )
  )

  const handleDeleteTenant = (record) => {
    unbindSpaceTenantRel(record.id, spaceId)
      .then((res) => {
        spaceRefresh()
      })
      .catch((e) => console.log('e', e))
  }

  const handleDelete = (record) => {
    adminRemoveUserFromSpace(record.id)
      .then((res) => {
        refresh()
      }).catch(() => {
      })
  }
  const userColumns = [
    {
      title: 'Username',
      dataIndex: 'user',
      key: 'user',
      render: user => {
        if (user) {
          return user.username
        }
      }
    },
    {
      title: 'Access level',
      dataIndex: 'accessLevel',
      key: 'accessLevel',
      responsive: ['md'],
      render: level => {
        if (level === 1) return <Tag color='geekblue'> {level}</Tag>
        if (level === 2) return <Tag color='orange'> {level}</Tag>
        if (level === 3) return <Tag color='red'> {level}</Tag>
      }
    },
    {
      title: 'Admin',
      dataIndex: 'user',
      key: 'user',
      responsive: ['md'],
      render: user => {
        if (user) {
          return user.admin ? <i className='fas fa-check' /> : ''
        }
      }
    },
    ...!readOnly ? [{
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size='middle'>
          <div onClick={(e) => e.stopPropagation()}>
            <Popconfirm
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title='Are you sure delete this sensor?'
              onConfirm={() => handleDelete(record)}
              okText='Yes, delete'
              cancelText='No'
            >
              <a href='#'>Delete</a>
            </Popconfirm>
          </div>
        </Space>
      )
    } ]: []
  ]

  const tenantColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: name => <a>{name}</a>
    },
    {
      title: 'Access level',
      dataIndex: 'TenantSpace',
      key: 'accessLevel',
      responsive: ['md'],
      render: ({ permission } = {}) => {
        switch (permission) {
          case 1:
            return <Tag color='geekblue'> {permission}</Tag>
          case 2:
            return <Tag color='orange'> {permission}</Tag>
          case 3:
            return <Tag color='red'> {permission}</Tag>
          default:
            return permission ?? '-'
        }
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size='middle'>
          <div onClick={(e) => e.stopPropagation()}>
            <Popconfirm
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title='Are you sure delete this tenant?'
              onConfirm={() => handleDeleteTenant(record)}
              okText='Yes, delete'
              cancelText='No'
            >
              <a href='#'>Delete</a>
            </Popconfirm>
          </div>
        </Space>
      )
    }
  ]

  return (
    <>
      {!readOnly && (
        <TopBar>
          <SpaceUserAccessModal
            refresh={refresh}
            spaceId={spaceId}
            users={users}
          >
            <Button
              style={{ margin: '1rem 0 0 1rem' }}
            >
              Add user
            </Button>
          </SpaceUserAccessModal>
        </TopBar>
      )}
      <FullSpace size='middle' direction='vertical'>
        {loading
          ? <LoadingScreen />
          : (
            <Table
              dataSource={users}
              columns={userColumns}
            />)}
        {!isTenantScope() && hasRole('superAdmin') && (
          <SpaceTenantAccessModal
            refresh={spaceRefresh}
            spaceTenants={tenants}
            spaceId={spaceId}
          >
            <Button
              style={{ margin: '1rem 0 0 1rem' }}
            >
              Add client
            </Button>
          </SpaceTenantAccessModal>
        )}
        {!isTenantScope() && hasRole('superAdmin') && tenants && (
          <Table
            dataSource={tenants}
            columns={tenantColumns}
          />
        )}
      </FullSpace>
    </>
  )
}

export default SpaceUserAccess
