import Bugsnag from '@bugsnag/js'
import { message } from 'antd'
import { useState } from 'react'

import { apiInstance as api } from 'src/api'

export const getUrlBucket = (file) => {
  return new Promise((resolve, reject) => {
    const { uid } = file
    return api.getSignedURL(file)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        Bugsnag.notify(err)
        reject(err)
      })
  })
}

export const deleteImageUrl = (fileUrl, showFeedback = true) => {
  return new Promise((resolve, reject) => {
    const filenameToRemove = fileUrl.split('/').slice(-1)[0]
    return api.deleteImageFromBucket(filenameToRemove)
      .then((res) => {
        showFeedback && message.success(res.message || 'Image deleted')
        resolve(res.data)
      })
      .catch((err) => {
        showFeedback && message.error(err.message || 'Unable to deleted image')
        Bugsnag.notify(err)
        reject(err)
      })
  })
}

export const uploadImageBucket = (url, file, key, callbackProgress) => {
  return new Promise((resolve, reject) => {
    const callback = (value) => {
      callbackProgress(value)
    }
    return api.uploadImageToS3(url, file, callback)
      .then((res) => {
        message.success({ content: (res.message || 'Image was uploaded'), key })
        resolve(res.config.url)
      })
      .catch((err) => {
        message.error({ content: (err.message || 'Unable to upload image'), key })
        Bugsnag.notify(err)
        reject(err)
      })
  })
}

/**
 * @typedef UseAssetMessages
 * @type {object}
 * @property {string} uploadCheckError
 *
 * @typedef UseAssetOptions
 * @type {object}
 * @property {UseAssetMessages} messages
 *
 * @param {UseAssetOptions} options
 */
export const useAsset = (options) => {
  const [loading, setLoading] = useState(false)
  const [urlToImage, setUrlToImage] = useState('')
  const [fileList, setFileList] = useState(options?.fileList ?? [])
  const [progressUpload, setProgressUpload] = useState(/** @type {?boolean} */null)
  const [uploadStatus, setUploadStatus] = useState()
  const [previewBlob, setPreviewBlob] = useState(/** @type {?Blob} */null)

  const deleteAsset = () => {
    if (urlToImage) {
      setLoading(true)
      deleteImageUrl(urlToImage)
        .then((res) => {
          Bugsnag.leaveBreadcrumb(
            'useAsset.deleteAsset',
            { urlToImage, fileList, progressUpload, uploadStatus },
            'log'
          )

          setUrlToImage('')
          setFileList([])
        })
        .catch(err => {
          Bugsnag.notify(err, event => {
            event.addMetadata('state', { urlToImage, fileList, progressUpload, uploadStatus })
          })
        })
        .finally(() => setLoading(false))
    }
  }

  const callbackProgress = (value) => {
    setProgressUpload(value)
  }

  /**
   * @param {UploadRequestOption<any>} options
   */
  const customRequest = async (options) => {
    const { onSuccess, onError, file } = options
    const { name, uid, type } = file
    const fileProps = { name, uid, type }
    const key = uid
    setUploadStatus(true)
    setProgressUpload(1)
    message.loading({ content: `${name} file uploading.`, key, duration: 0 })
    try {
      getUrlBucket(fileProps).then((res) => {
        const { File } = window
        const newFile = new File([file], res.fileName)

        Bugsnag.leaveBreadcrumb(
          'useAsset.customRequest.getUrlBucket',
          { url: res.url, key },
          'log'
        )

        return uploadImageBucket(res.url, newFile, key, callbackProgress)
          .then((res) => {
            const parts = res.split('?')
            const ImageUrl = parts[0]

            Bugsnag.leaveBreadcrumb(
              'useAsset.customRequest.getUrlBucket.uploadImageBucket',
              { urlToImage: ImageUrl, fileList: [{ name, ImageUrl }], uploadStatus },
              'log'
            )

            setFileList([{ name, ImageUrl }])
            setUrlToImage(ImageUrl)
            setProgressUpload(undefined)
            onSuccess('OK')
          })
          .catch((err) => {
            setUploadStatus(false)
            message.loading({
              content: `${name} file uploading.`,
              key,
              duration: 0.001
            })

            Bugsnag.notify(err, event => {
              event.addMetadata('state', { urlToImage, fileList, progressUpload, uploadStatus })
            })

            onError(err)
          })
      })
    } catch (err) {
      setUploadStatus(false)
      message.loading({
        content: `${name} file uploading.`,
        key,
        duration: 0.001
      })

      Bugsnag.notify(err, event => {
        event.addMetadata('state', { urlToImage, fileList, progressUpload, uploadStatus })
      })

      onError(err)
    }
  }

  const uploadCheck = () => {
    if (fileList && fileList.length < 1) {
      return true
    } else {
      message.info(options?.messages?.uploadCheckError ?? 'Something went wrong')
      Bugsnag.leaveBreadcrumb(
        'useAsset.uploadCheck.failed',
        {
          urlToImage,
          fileList,
          progressUpload,
          uploadStatus,
          error: options?.messages?.uploadCheckError ?? 'Something went wrong'
        },
        'log'
      )

      return false
    }
  }

  const beforeUpload = (file) => {
    const blob = URL.createObjectURL(file)
    setPreviewBlob(blob)
    return uploadCheck(file)
  }

  const getPreview = () => urlToImage || previewBlob || options?.fileList?.[0]?.ImageUrl

  return {
    loading,
    fileList,
    progressUpload,
    uploadStatus,
    urlToImage,
    previewBlob,
    getPreview,
    beforeUpload,
    customRequest,
    deleteAsset
  }
}
