import React from 'react'
import { Divider, Select, Space } from 'antd'
import Button from 'src/components/Button'

import { useAdminAllSensors } from 'src/hooks'
import Spin from 'src/components/Spin/Spin'

const SensorSelect = {
  render: ({ optionValueKey, optionLabelKey, ...props }) => {
    const {
      sensors,
      loading,
      searchState,
      paginationState
    } = useAdminAllSensors({
      paginationAppend: true
    })
    const valueKey = optionValueKey && Object.hasOwnProperty.call(sensors?.[0] || {}, optionValueKey)
      ? optionValueKey
      : 'id'
    const labelKey = optionLabelKey && Object.hasOwnProperty.call(sensors?.[0] || {}, optionLabelKey)
      ? optionLabelKey
      : 'id'
    const sensorOptions = sensors.map(sensor => ({
      label: sensor[labelKey],
      value: sensor[valueKey]
    }))

    return (
      <Select
        showSearch
        allowClear
        searchValue={searchState.value}
        onSearch={searchState.setValue}
        mode='multiple'
        placeholder='Select sensor(s)'
        showArrow
        loading={loading}
        options={sensorOptions}
        dropdownRender={menu => (
          <>
            {menu}
            {paginationState.pageInfo.hasNextPage && (
              <>
                <Divider style={{ margin: '8px 0' }} />
                <Space
                  direction='vertical'
                  align="center"
                  style={{
                    padding: '0 8px 4px',
                    width: '100%'
                  }}
                >
                  {loading && <Spin />}
                  {!loading && (
                    <Button
                      onClick={paginationState.goToNextPage}
                    >
                      Load more
                    </Button>
                  )}
                </Space>
              </>
            )}
          </>
        )}
        {...props}
      />
    )
  }
}

export default {
  SensorSelect
}
