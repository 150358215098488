import { routes, navigate } from '@redwoodjs/router'
import styled from 'styled-components'

import AppLayout from 'src/layouts/AppLayout'
import InfoCards from 'src/components/InfoCards'
import ListCard from 'src/components/ListCard'
import AppHeader from 'src/components/AppHeader'
import AppContent from 'src/components/AppContent'
import Button from 'src/components/Button'
import UserAvatar from 'src/components/UserAvatar'
import BuildingsGrid from 'src/components/BuildingsGrid'

import { useUser, useSpaces, useAuth, useTenant } from 'src/hooks'

import PageTitle from 'src/components/AppTitle'
import { ACCESS_WRITE } from 'src/constants'
import { BackButton } from 'src/components/Button/Button'
import Spacing from 'src/components/Spacing'

const Title = styled(PageTitle)`
  padding: 2rem 0;
`

const TenantName = styled.span`
  font-weight: 300;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const LogoContainer = styled.div`
  height: 3rem;

  img {
    height: 100%;
    max-height: 100%;
    width: auto;
  }
`

const AppPage = () => {
  const { tenantId, tenant, loading: tenantLoading, isTenantScope } = useTenant(null, { fetchTenant: true })
  const { hasRole } = useAuth()
  const { user } = useUser()
  const { spaces, refresh, loading } = useSpaces()

  const { displayname = '' } = user
  const [firstName] = displayname.split(' ')

  return (
    <AppLayout>
      <AppHeader
        transparent
        backBtn={(hasRole('superAdmin') || user?.tenants?.length > 1) && (
          <BackButton
            onClick={() => navigate(routes.v4Dashboard())}
          />
        )}
        extra={
          <>
            <UserAvatar
              admin={hasRole('superAdmin') && !isTenantScope()}
              user={user}
              app
            />
            {hasRole('superAdmin') && !isTenantScope() && (
              <Button type='primary' onClick={() => navigate(routes.admin())}>
                Manage
              </Button>
            )}
            {hasRole(`tenant.${tenantId}.${ACCESS_WRITE}`) && isTenantScope() && (
              <Button type='primary' onClick={() => navigate(routes.adminTenant({ tenantId }))}>
                Manage{user?.tenants?.length > 1 || hasRole('superAdmin') ? ' client' : ''}
              </Button>
            )}
          </>
        }
      >
        {tenant?.logoUrl && (
          <Spacing sx='s' spacingType='padding'>
            <LogoContainer>
              <img src={tenant.logoUrl} alt='Logo' />
            </LogoContainer>
          </Spacing>
        )}
        <Title italic>
          Welcome, {firstName}
          {(isTenantScope() && tenant?.name && !tenantLoading
            ? <TenantName>{tenantLoading ? '' : ` - ${tenant.name}`}</TenantName>
            : ''
          )}
          {(!isTenantScope() && hasRole('superAdmin')
            ? <TenantName> - Admin</TenantName>
            : ''
          )}
        </Title>
      </AppHeader>
      <AppContent>
        <InfoCards spaces={spaces} spacesLoading={loading} />
        <Container>
          {hasRole(['superAdmin', `tenant.${tenantId}.${ACCESS_WRITE}`])
            ? <ListCard spaces={spaces} refresh={refresh} loading={loading} />
            : <BuildingsGrid spaces={spaces} loading={loading} hideExtra />}
        </Container>
      </AppContent>
    </AppLayout>
  )
}

export default AppPage
