import { useState, useEffect } from 'react'
import { routes, navigate } from '@redwoodjs/router'
import AppLayout from 'src/layouts/AppLayout'
import AppHeader from 'src/components/AppHeader'
import AppContent from 'src/components/AppContent'
import Button, { BackButton } from 'src/components/Button'
import SpaceUserAccess from 'src/components/SpaceUserAccess'
import AppTitle from 'src/components/AppTitle'
import Spin from 'src/components/Spin'
import {
  Tag,
  Tabs,
  Table,
  Switch,
  Descriptions,
  List,
  Form,
  Select,
  Input,
  Button as AntButton,
  Modal,
  Space
} from 'antd'
import { formatRelative } from 'date-fns'

import {
  useSuSpace,
  useAlgorithms,
  usePlugins,
  useSensorPlugins,
  useTenant,
  useAuth
} from 'src/hooks'
import * as c from 'src/constants'

import styled from 'styled-components'
import { getSpaceNameFromType } from 'src/libraries/spaces'
const { TabPane } = Tabs
const { Option, OptGroup } = Select

const Wrapper = styled.div`
  justify-content: center;
  display: flex;
  flex: 1;
  flex-direction: row;
`

const SmallHeader = styled.p`
  font-weight: bold;
  color: black;
`

const Sidebar = styled.div`
  max-width: 320px;
  padding: 1rem;
  flex: 1;
  background-color: rgba(255,255,255,0.75);
`
const Content = styled.div`
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction:column;
`

const TopBar = styled.div`
  display: flex;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 0.5rem;
  }
`

const Flexy = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`
const SpacePageAdmin = ({ id, tab } = {}) => {
  const { hasRole } = useAuth()
  const { getRoute, isTenantScope, tenantId } = useTenant()
  const { algorithms } = useAlgorithms()
  const { plugins } = usePlugins()
  const { sensorPlugins } = useSensorPlugins()
  const [form] = Form.useForm()
  const { space = {}, loading, addSpaceAlgo, updateSpaceAlgo, refresh } = useSuSpace(id)
  const [currentTab, setCurrentTab] = useState('1')
  const [showAddSpaceAlgo, setShowAddSpaceAlgo] = useState(false)
  const [selectedAlgoId, setSelectedAlgoId] = useState()
  const [selectedAlgo, setSelectedAlgo] = useState()
  const [saConfigurableSettings, setSaConfigurableSettings] = useState()
  const [formData, setFormData] = useState({})
  const [spaceAlgos, setSpaceAlgos] = useState([])
  const readOnly = (
    !hasRole('superAdmin') &&
    isTenantScope() &&
    (
      !hasRole(`tenant.${tenantId}.${c.ACCESS_WRITE}`) ||
      space.tenantAccessLevel < c.ACCESS_WRITE
    )
  )

  useEffect(() => {
    const algo = algorithms.find((algo) => algo.id === selectedAlgoId)
    setSelectedAlgo(algo)
    if (algo) {
      const parsed = JSON.parse(algo.configurableSettings)
      console.log(parsed)
      setSaConfigurableSettings(parsed.user)
    }
  }, [selectedAlgoId])
  useEffect(() => {
    setSpaceAlgos(space.algorithms)
  }, [space])
  const handleTabChange = (tab) => {
    setCurrentTab(tab)
  }
  const handleAddSAClick = (e) => {
    setShowAddSpaceAlgo(true)
  }

  const handleCloseSA = () => {
    resetSA()
    setShowAddSpaceAlgo(false)
  }
  const resetSA = () => {
    setFormData({})
  }
  const handleSAChange = (setting) =>
    (field, opts = {}) =>
      (event) => {
        const { eventBased = true, multiple = false } = opts
        const value = eventBased
          ? event.target.value
          : event
        console.log(field, value)
        if (field === 'algoId') setSelectedAlgoId(value)
        setFormData(formData => ({
          ...formData,
          [field]: setting
            ? { ...setting, value }
            : value
        }))
      }
  const handleSpaceClick = (spaceId) => (e) => {
    navigate(getRoute('spacePageAdmin', { id: spaceId }))
  }
  const handleSAACtiveClick = (spaceAlgo) =>
    (value) => {
      const relationId = spaceAlgo.id
      updateSpaceAlgo(relationId, { active: value })
    }
  const handleSASubmit = () => {
    const { algoId, ...rest } = formData
    addSpaceAlgo(algoId, rest)
      .then(() => {
        refresh()
        setShowAddSpaceAlgo(false)
      })
  }
  const getOptionsFromType = (type) => {
    const _type = type.toLowerCase()
    switch (_type) {
      case 'device':
        return sensorPlugins.map((sp) => ({
          ...sp,
          id: sp.id,
          name: `${sp.id} · ${sp.plugin.name}, ${sp.sensor.name} @ ${sp.sensor.parentSpaceId || '-'}`
        }))
      case 'sensor':
        return space?.sensors?.map((s) => ({
          ...s,
          id: s.id,
          name: `${s.id} · ${s.deviceId} · ${s.name}}`
        }))
    }

    return []
  }
  const getFormFromSetting = (key, setting, _props) => {
    const { type, ...rest } = setting
    const {
      onChange,
      ...props
    } = _props
    const handleChange = onChange(setting)
    if (Array.isArray(type)) {
      const valueType = type[0]
      const options = getOptionsFromType(valueType)
      return (
        <Select
          name={key}
          mode='multiple'
          allowClear
          onChange={handleChange(key, {
            multiple: true,
            eventBased: false
          })} {...props}
        >
          {
            options.map((option) =>
              <Option key={option.id} value={option.id}>{option.name}</Option>
            )
          }
        </Select>
      )
    } else {
      const _type = type.toLowerCase()
      switch (_type) {
        default:
          return <Input name={key} type='number' onChange={handleChange(key)} {...props} />
      }
    }
  }
  const algoColumns = [
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Description',
      dataIndex: 'description'
    },
    {
      title: 'Config',
      dataIndex: 'SpaceAlgorithm',
      render: (sa) => {
        const { userSettings } = sa || {}
        let parsed
        try {
          parsed = JSON.parse(userSettings)
        } catch (e) {
          return <span>No config</span>
        }
        const displayValue = (value) => Array.isArray(value)
          ? value.join(',')
          : value
        const { user = {} } = parsed
        return parsed
          ? Object.entries(user)
            .map(([key, value]) => <Tag color='green' key={key}>{key}: {displayValue(value.value)}</Tag>)
          : <span>None</span>
      }
    },
    {
      title: 'Added',
      dataIndex: 'SpaceAlgorithm',
      render: sa => sa && formatRelative(new Date(sa.createdAt), new Date())
    },
    {
      title: 'Enabled',
      dataIndex: 'SpaceAlgorithm',
      render: sa => sa && <Switch disabled={readOnly} onChange={handleSAACtiveClick(sa)} checked={sa.active} />
    }
  ]
  return (
    <AppLayout>
      <AppHeader noMargin>
        <BackButton onClick={(e) => navigate(getRoute('adminContent', { type: 'spaces' }))} />
        <AppTitle noMargin>{space.name}</AppTitle>
      </AppHeader>
      <AppContent noPadding>
        <Modal
          visible={showAddSpaceAlgo}
          onCancel={handleCloseSA}
          onOk={handleSASubmit}
        >
          <FormWrapper direction='vertical'>
            <p>Set Algorithm to <i>{space.name} ({space.id})</i></p>
            <Select value={formData.algoId} onChange={handleSAChange()('algoId', { eventBased: false })}>
              {algorithms.map((algo) => <Option key={algo.id} value={algo.id}>{algo.name}</Option>)}
            </Select>
            {saConfigurableSettings &&
            Object.entries(saConfigurableSettings)
              .map(([key, setting], i) => {
                return (
                  <Form.Item
                    key={key}
                    label={setting.displayName || key}
                  >
                    {getFormFromSetting(key, setting, { onChange: handleSAChange })}
                  </Form.Item>
                )
              })}
          </FormWrapper>
        </Modal>
        {loading && id
          ? <Spin />
          : (
            <Wrapper>
              <Sidebar>
                <SmallHeader>Children</SmallHeader>
                <List
                  dataSource={space.children || []}
                  renderItem={item =>
                    <List.Item>
                      <Flexy>
                        {item.name}
                        <AntButton
                          type='primary'
                          shape='circle'
                          icon={<i className='fas fa-chevron-right' />}
                          onClick={handleSpaceClick(item.id)}
                        />
                      </Flexy>
                    </List.Item>}
                />
                <SmallHeader>Parents</SmallHeader>
                <List
                  dataSource={space.parents || []}
                  renderItem={item =>
                    <List.Item>
                      <Flexy>
                        {item.name}
                        <AntButton
                          type='primary'
                          shape='circle'
                          icon={<i className='fas fa-chevron-right' />}
                          onClick={handleSpaceClick(item.id)}
                        />
                      </Flexy>
                    </List.Item>}
                />
              </Sidebar>
              <Content>
                <Tabs
                  defaultActiveKey={tab ?? 'information'}
                  onChange={handleTabChange}
                >
                  <TabPane tab='Information' key='information'>
                    <Descriptions
                      title='Space Information'
                      column={1}
                      bordered
                    >
                      {space && Object.entries(space)
                        .filter(([key, value]) => [
                          'algorithms',
                          'children',
                          'parents',
                          'tenants'
                        ].indexOf(key) === -1) // do not show these
                        .map(([key, value]) => {
                          const val = key === 'type' ? getSpaceNameFromType(value) : value
                          return (
                            <Descriptions.Item key={key} label={key}>{JSON.stringify(val)}</Descriptions.Item>
                          )
                        })}
                    </Descriptions>
                  </TabPane>
                  <TabPane tab='Algorithms' key='algorithms'>
                    {!readOnly && (
                      <TopBar>
                        <Button
                          onClick={handleAddSAClick}
                          style={{ margin: '0 0 1rem 1rem' }}
                        >
                          Add
                        </Button>
                      </TopBar>
                    )}
                    <Table
                      dataSource={spaceAlgos}
                      columns={algoColumns}
                    />
                  </TabPane>
                  <TabPane tab='Access' key='access'>
                    <SpaceUserAccess
                      space={space}
                      refresh={refresh}
                    />
                  </TabPane>
                </Tabs>
              </Content>
            </Wrapper>
          )}
      </AppContent>
    </AppLayout>
  )
}

export default SpacePageAdmin
