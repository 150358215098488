
import styled, { css } from 'styled-components'
import * as c from 'src/constants'
import { Menu, Dropdown } from 'antd'
import AdminAddSpaceModal from '../AdminAddSpaceModal'
import { useTheme } from 'src/hooks'

const Card = styled.div`
  display: flex;
  background: white;
  border-radius: 15px;
  box-shadow: 5px 3px 29px 0 ${props => props.selected
    ? props.selectedColor
    : 'rgba(0, 0, 0, 0.16)'};
  margin: 1em;
  background-image: url(${props => props.background ? props.background : ''});
  background-repeat: no-repeat;
  background-size: cover;
  cursor: ${props => props.clickable
    ? 'pointer'
    : 'initial'};
  transition: box-shadow 0.3s ease-out, opacity 0.2s ease-out;
   ${props => props.clickable && css`
    user-select: none;
      &:hover {
        box-shadow: 2px 1px 20px inherit;
        opacity: 0.85;
      }
    `}

`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5em 3em;
  box-sizing: border-box;
  width: 100%;
  @media (max-width: ${c.SMALL_SCREEN}) {
    padding: ${props => props.nomargin && '1em'};
  }
`
const GradientContainer = styled.div`
  width: 1em;
  border-radius: 15px 0 0 15px;
  background-image: linear-gradient(0deg,rgba(0,0,0,0.5),rgba(0,0,0,.0));
  background-color: ${props => props.style.color};
  box-shadow: 5px 0 15px rgba(0,0,0,0.15);
  `

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`

const ExtraContainer = styled.div`
  //padding-top: 1rem;
`

const ChildContainer = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  padding-top: 1.5rem;
  width: 100%;
  color: ${props => props.customStyle.color};
  & h5 {
    color: ${props => props.customStyle.color};
    filter: brightness(75%);
  }
`

const TitleFont = styled.span`
  color: black;
  font-size: ${props => props.titleSize};
  font-weight: ${props => props.titleWeight};
  line-height: 1.15;

  span {
    font-size: 0.8em;
    font-weight: 300;
    font-style: italic;
    color: #A3A3A3;
  }
`

const Edit = styled.span`
  margin-left: auto;
  * {
    font-size: 1.5rem;
    color: ${props => props.colors['card.menu.color'] || '#C4C4C4'};
  }
`

const Icon = styled.i`
  margin-right: 0.5rem;
`

const InfoCard = (props) => {
  const {
    style = {},
    children,
    icon,
    footer,
    title,
    edit,
    titleWeight = 'bold',
    titleSize = '38px',
    subTitle = '',
    border = false,
    extra,
    titleAction,
    background,
    clickable,
    onClick,
    nomargin = false,
    ...rest
  } = props
  const { colors } = useTheme()

  return (
    <Card clickable={clickable} background={background} style={style} onClick={onClick} {...rest}>
      {border &&
        <GradientContainer style={style} />}
      <Content nomargin={nomargin}>
        <HeaderContainer>
          {titleAction &&
            <ExtraContainer>{titleAction}</ExtraContainer>}
          <TitleContainer>
            <TitleFont style={style} titleWeight='bold' titleSize={titleSize}>
              {icon && <Icon className={`fas fa-${icon}`} />}
              {title}
              {subTitle && <span>{` - ${subTitle}`}</span>}
            </TitleFont>
            {edit && (
              <Edit colors={colors}>
                {edit}
              </Edit>
            )}
          </TitleContainer>
          {extra &&
            <ExtraContainer>
              {extra}
            </ExtraContainer>}
        </HeaderContainer>
        <ChildContainer customStyle={style}>
          {children}
        </ChildContainer>
      </Content>
    </Card>
  )
}

export default InfoCard

const CountContainer = styled.div`
  text-align: center;
  padding-bottom: 1em;
  flex: 1;
`
const NumberFont = styled.h2``

const UnitFont = styled.h5`
  font-weight: 300;
`

export const InfoText = styled.p`
  color: gray;
`

const InfoFont = styled.p`
  font-style: italic;
  font-weight: 300;
  display: flex;
  justify-content: center;
`

const ContentDynamic = styled.div`
  display: flex;
  ${NumberFont} {
    color: ${props => props.numberColor};
  }
  ${UnitFont} {
    color: ${props => props.unitColor};
  }
  ${InfoFont} {
    color: ${props => props.infoColor};
  }
`

export const InfoCardDynamic = (props) => {
  const {
    title,
    iconId = '',
    editIcon,
    editCap,
    room,
    color,
    darkColor,
    data = [],
    Bottom, // react component
    onBottomClick: _onBottomClick,
    onClick,
    selected,
    shouldUpdate,
    ...rest
  } = props

  const onBottomClick = (e) => {
    e.stopPropagation()
    _onBottomClick(e)
  }

  const menu = (
    <Menu>
      <Menu.Item>
        <AdminAddSpaceModal
          title='Edit Room'
          edit
          record={room}
          shouldUpdate={shouldUpdate}
        />
      </Menu.Item>
    </Menu>
  )

  const sensorsExists = data && data.length
  return (
    <InfoCard
      clickable={onClick != null}
      onClick={onClick}
      title={title}
      edit={
        editIcon && editCap
          ? (
            <Dropdown
              overlay={menu}
              placement='bottomRight'
              trigger={['click']}
            >
              <a className='ant-dropdown-link' onClick={e => e.stopPropagation()}>
                <i className={`fas fa-${editIcon}`} />
              </a>
            </Dropdown>

          )
          : null
      }
      icon={iconId}
      titleSize='1.5rem'
      color={color}
      selectedColor={color}
      selected={selected}
      style={{ color: props.color }}
      {...rest}
    >
      <ContentDynamic unitColor={color} infoColor={color} numberColor={darkColor}>
        {
          !sensorsExists
            ? <InfoText>No sensors attached to this room</InfoText>
            : data.map((point, i) =>
              <CountContainer key={i}>
                <NumberFont>{point.value}</NumberFont>
                <UnitFont>{point.name}</UnitFont>
              </CountContainer>
            )
        }
      </ContentDynamic>
      {Bottom && <InfoFont onClick={onBottomClick}>{Bottom}</InfoFont>}
    </InfoCard>
  )
}
