/**
 * @param {number} comfortScore
 */
export const averageMessage = (comfortScore) => {
  if (comfortScore <= 70) {
    return 'poor'
  } else if (comfortScore <= 85) {
    return 'mediocre'
  } else {
    return 'good'
  }
}

/**
 * Format comfort value for display in UI
 * @param {Number} comfort
 * @returns {String}
 */
export const formatComfortDisplay = (comfort) => {
  const DEFAULT_RETURN = '-'
  if (comfort == null || isNaN(comfort)) {
    return DEFAULT_RETURN
  }
  const float = parseFloat(comfort)
  const fixed =  float.toFixed(1) // one fixed decimal
  return `${fixed}%`
}
