import {
  Modal,
  Button as AntButton,
  Form,
  Input,
  message,
  Switch,
  Radio
} from 'antd'

import { useState } from 'react'
import Button from 'src/components/Button'
import { useAuth, useTenant, useUsers } from 'src/hooks'
import styled from 'styled-components'
import * as c from '../../constants'

const ButtonContainer = styled.div`
  padding: 0.5em;
`
const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
`
const TitleContainer = styled.div`
  padding: 1em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #7297ff;
  & h2 {
    color: #fff;
  }
`
const FormContainer = styled.div`
  padding: 2em;
  & .ant-row.ant-form-item {
    flex: 1;
    justify-content: center;
    margin-bottom: 1em;
  }
  & .ant-col-14 {
    max-width: 100%;
  }
  & .ant-col-4 {
    padding-left: 8px;
    max-width: 100%;
    flex: 1;
  }
  & .ant-picker {
    width: 100%;
  }

  & .ant-divider{
    margin: 1em;
  }

  & .ant-form-large .ant-form-item-label > label {
    height: 25px;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  & .ant-form-item-control{
    padding: 0 0.5em;
  }
  @media (max-width: ${c.SMALL_SCREEN}){
    flex-direction: column;
  }
`

const AdminAddUserModal = (props) => {
  const {
    parentSpaceId,
    edit = false,
    record = {},
    shouldUpdate
  } = props

  const { hasRole } = useAuth()
  const { tenantId, isTenantScope } = useTenant()
  const { adminAddUser, adminUpdateUser } = useUsers()
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = e => {
    message.destroy(parentSpaceId)
    form.resetFields()
    setVisible(false)
  }

  const handleSubmit = item => {
    const { admin, active, email, notifications, username, terms, password, displayname, permission } = item

    const payload = {
      displayname: displayname,
      email: email,
      ...(password) && { password },
      admin: (admin ? 1 : 0),
      active: (active ? 1 : 0),
      notifications: (notifications ? 1 : 0),
      terms: (terms ? 1 : 0),
      ...isTenantScope() && hasRole(`tenant.${tenantId}.${c.ACCESS_WRITE}`) && typeof permission === 'number'
        ? { permission }
        : {}
    }

    if (edit) {
      const editPayload = { id: record.id, ...payload }
      adminUpdateUser(editPayload)
        .then((res) => {
          form.resetFields()
          setVisible(false)
          shouldUpdate()
        }).catch(() => {})
    } else {
      const editPayload = { ...payload, username: username }
      adminAddUser(editPayload)
        .then((res) => {
          form.resetFields()
          setVisible(false)
          shouldUpdate()
        }).catch(() => {})
    }
  }

  const validateMessages = {
    required: "'${label}' is required!",
    types: {
      email: '${label} is not validate email!',
      number: '${label} is not a validate number!'
    }
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {edit
        ? (
          <AntButton type='link' onClick={showModal}>
        Edit
          </AntButton>)
        : (
          <Button type='primary' onClick={showModal}>
        Add user
          </Button>)}
      <Modal
        closable={false}
        visible={visible}
        destroyOnClose
        onCancel={handleCancel}
        bodyStyle={{ padding: '0' }}
        footer={[
          <FooterContainer key='buttons'>
            <ButtonContainer>
              <Button fill outline key='cancel' onClick={handleCancel}>
              Cancel
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button key='submit' type='primary' htmlType='submit' form='addUser'>
                {edit ? 'Update user' : 'Add user'}
              </Button>
            </ButtonContainer>
          </FooterContainer>
        ]}
      >
        <div>
          <TitleContainer>
            <h2>{edit ? 'Edit user' : 'Add user'}</h2>
          </TitleContainer>
          <FormContainer>
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              layout='vertical'
              size='large'
              id='addUser'
              onFinish={handleSubmit}
              colon={false}
              form={form}
              validateMessages={validateMessages}
              initialValues={{
                permission: c.ACCESS_READ,
                ...record,
                admin: (record.admin),
                active: (record.active ? record.active === 1 : true),
                notifications: (record.notifications === 1),
                terms: (record.terms === 1)
              }}
            >
              {!edit &&
                <Form.Item
                  label='Username'
                  name='username'
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please add username'
                    }
                  ]}
                >
                  <Input
                    placeholder='Username'
                    allowClear
                  />
                </Form.Item>}
              {(hasRole('superAdmin') || !edit) && (
                <>
                  <Form.Item
                    label='Display name'
                    name='displayname'
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please add display name'
                      }
                    ]}
                  >
                    <Input
                      placeholder='Display name'
                      allowClear

                    />
                  </Form.Item>
                  <Form.Item
                    label='User email'
                    name='email'
                    hasFeedback
                    rules={[
                      {
                        type: 'email',
                        required: true,
                        message: 'Please add an Email'
                      }
                    ]}
                  >
                    <Input
                      placeholder='Email'
                      allowClear

                    />
                  </Form.Item>
                  {hasRole('superAdmin') && !isTenantScope() && (
                    <Row>
                      <Form.Item
                        label='Super admin'
                        name='admin'
                        valuePropName='checked'
                      >
                        <Switch
                          checkedChildren='1' unCheckedChildren='0'
                        />
                      </Form.Item>
                    </Row>
                  )}
                  <Form.Item
                    label='Password'
                    name='password'
                    required={!edit}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Row>
                    <Form.Item
                      label='Notifications'
                      name='notifications'
                      valuePropName='checked'
                    >
                      <Switch
                        checkedChildren='1' unCheckedChildren='0'
                      />
                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item
                      label='Accept terms'
                      name='terms'
                      valuePropName='checked'
                    >
                      <Switch
                        checkedChildren='1' unCheckedChildren='0'
                      />
                    </Form.Item>
                  </Row>
                </>
              )}
              <Row>
                <Form.Item
                  label='Active'
                  name='active'
                  valuePropName='checked'
                >
                  <Switch
                    checkedChildren='1' unCheckedChildren='0'
                    defaultValue='1'
                  />
                </Form.Item>
              </Row>
              {isTenantScope() && hasRole(`tenant.${tenantId}.${c.ACCESS_WRITE}`) && (
                <Row>
                  <Form.Item
                    label='Client Access Level'
                    name='permission'
                  >
                    <Radio.Group
                      options={c.TENANT_PERMISSION_OPTIONS}
                      optionType='button'
                      buttonStyle='solid'
                    />
                  </Form.Item>
                </Row>
              )}
            </Form>
          </FormContainer>
        </div>
      </Modal>
    </div>
  )
}

export default AdminAddUserModal
