import { useRef } from 'react'
import AdminList from 'src/components/AdminList'
import { navigate } from '@redwoodjs/router'
import { Tag, Tooltip } from 'antd'

import { useAdminAllSpaces, useTenant } from 'src/hooks'
import * as c from 'src/constants'
import AdminAddSpaceModal from '../AdminAddSpaceModal/AdminAddSpaceModal'
import React from 'react'

/**
 * @typedef SpaceListProps
 * @type {object}
 * @property {string} typeName
 *
 * @param {SpaceListProps} props
 * @returns {React.ReactNode}
 */
const SpaceList = ({ typeName }) => {
  const { getRoute } = useTenant()
  const { spaces, loading, error, refresh } = useAdminAllSpaces()
  const wrapperRef = useRef()

  const handleClick = (record, e) => {
    if (wrapperRef.current.contains(e.target)) {
      const { id } = record
      navigate(getRoute('spacePageAdmin', { id }))
    }
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: type => {
        if (type === c.TYPE_ROOM) return <Tag color='geekblue'>Room</Tag>
        if (type === c.TYPE_FLOOR) return <Tag color='blue'>Floor</Tag>
        if (type === c.TYPE_BUILDING) return <Tag color='orange'>Building</Tag>
      }
    },
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Description',
      dataIndex: 'description'
    },
    {
      title: 'Sensors',
      dataIndex: 'sensors',
      render: sensors => {
        if (!sensors || !sensors.length) return
        return sensors.map((sensor) =>
          <Tooltip key={sensor.id} title={sensor.name}>
            <Tag>{sensor.id}</Tag>
          </Tooltip>
        )
      }
    },
    {
      title: 'Algorithms',
      dataIndex: 'algorithms',
      render: algos => {
        if (!algos || !algos.length) return
        return algos.map((algo) =>
          <Tooltip key={algo.id} title={algo.name}>
            <Tag color='#d0d0d0'>{algo.id}</Tag>
          </Tooltip>
        )
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => (
        <AdminAddSpaceModal
          edit
          stopPropagation
          record={record}
          shouldUpdate={refresh}
          title='Edit space'
        />
      )
    }
  ]

  return (
    <AdminList
      handleClick={handleClick}
      data={spaces}
      columns={columns}
      loading={loading}
      shouldUpdate={refresh}
      typeName={typeName}
      ref={wrapperRef}
    />
  )
}

export default SpaceList
