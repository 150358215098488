import AdminList from 'src/components/AdminList'
import { navigate } from '@redwoodjs/router'

import { useAlgorithms, useTenant } from 'src/hooks'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id'
  },
  {
    title: 'Name',
    dataIndex: 'name'
  },
  {
    title: 'Description',
    dataIndex: 'description'
  },
  {
    title: 'Key',
    dataIndex: 'keyName'
  },
  {
    title: 'Global',
    dataIndex: 'global',
    render: global => {
      return global ? <i className='fas fa-check' /> : ''
    }
  }
]

const AlgorithmsTab = ({ onlyFlows, noFlows, ...props }) => {
  const { getRoute } = useTenant()
  const { algorithms, loading, error } = useAlgorithms({ onlyFlows, noFlows })
  const routeName = onlyFlows
    ? 'flowPage'
    : 'algorithmPage'

  const handleClick = (record) => {
    const { id } = record
    const route = getRoute(routeName, { id })

    navigate(route)
  }

  return (
    <AdminList
      {...props}
      onAddClick={() => navigate(getRoute(routeName, { id: 'add' }))}
      handleClick={handleClick}
      data={algorithms}
      columns={columns}
      loading={loading}
    />
  )
}

export default AlgorithmsTab
