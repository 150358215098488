import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  Radar,
  Tooltip
} from 'recharts'
import ResponsiveContainer from 'src/components/ResponsiveContainer/ResponsiveContainer'
import { Checkbox } from 'antd'
import {
  useTheme,
  useCurrentState
} from 'src/hooks'

const RadarChartTicks = (props) => {
  const {
    onCheckClick,
    checkedKeys,
    data,
    latestDataObj,
    outerRadius = '90%',
    aspectRatio = 4 / 3,
    ...rest
  } = props
  const {
    units
  } = useCurrentState()
  const { colors } = useTheme()
  const {
    keyColors,
    chartColors
  } = colors

  const labelFormatter = (label) => units[label].displayName
  const valueFormatter = (_, key, config = {}) => {
    const { payload, dataKey } = config
    const unit = units[payload.unit]
    let value
    const {
      acceptableValues: av,
      comfortableValues: cv
    } = unit
    if (dataKey === 'comfortable') {
      value = `${cv.min}-${cv.max}`
    } else if (dataKey === 'acceptable') {
      value = `${av.min}-${av.max}`
    } else if (dataKey === 'current') {
      const latest = latestDataObj[payload.unit]
      value = parseInt(latest)
    }
    return `${value} ${unit.unit}`
  }

  return (
    <ResponsiveContainer aspect={aspectRatio} {...rest}>
      <RadarChart
        outerRadius={outerRadius}
        data={data}
      >
        <PolarGrid />
        <PolarAngleAxis
          dataKey='unit'
          tick={({ payload, index: i, textAnchor, ...props }) => {
            const x = textAnchor === 'end'
              ? props.x - 60 // position element to chart axis
              : textAnchor === 'middle'
                ? props.x - 30 // position element to chart axis
                : props.x
            const y = textAnchor !== 'middle'
              ? props.y - 10 // position element to chart axis
              : props.y - 20
            const { value } = payload
            const displayValue = units[value].displayName
            const Text = ({ children }) => <span>&nbsp;{children}&nbsp;</span>
            return (
              <foreignObject {...props} x={x} y={y} width='125' height='20'>
                <div xmlns='http://www.w3.org/1999/xhtml' style={{ color: keyColors[value] }}>
                  {textAnchor === 'end' && <Text>{displayValue}</Text>}
                  <Checkbox
                    onChange={onCheckClick(value, i)}
                    value={checkedKeys[value]}
                    checked={checkedKeys[value]}
                  />
                  {textAnchor !== 'end' && <Text>{displayValue}</Text>}
                </div>
              </foreignObject>
            )
          }}
        />
        <Radar
          name='Current'
          dataKey='current'
          fill={chartColors.current}
          stroke={chartColors.current}
          fillOpacity={0.5}
        />
        <Radar
          name='Acceptable'
          dataKey='acceptable'
          fill={chartColors.acceptable}
          stroke={chartColors.acceptable}
          fillOpacity={0.5}
        />
        <Radar
          name='Comfortable'
          dataKey='comfortable'
          fill={chartColors.comfortable}
          stroke={chartColors.comfortable}
          fillOpacity={0.25}
        />
        <Tooltip
          formatter={valueFormatter}
          labelFormatter={labelFormatter}
        />
      </RadarChart>
    </ResponsiveContainer>
  )
}

export default RadarChartTicks
