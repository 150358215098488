import { useState, useEffect } from 'react'
import { navigate } from '@redwoodjs/router'
import { HomeFilled, MoreOutlined } from '@ant-design/icons'
import Button, { BackButton } from 'src/components/Button'

import { useAuth, useSpaces, useTenant, useUser } from 'src/hooks'

import BuildingsGrid from 'src/components/BuildingsGrid'
import AppHeader from 'src/components/AppHeader'
import AppContent from 'src/components/AppContent'
import AddSpace from 'src/components/AddSpace'
import AppLayout from 'src/layouts/AppLayout'
import * as c from 'src/constants'
import AppTitle from 'src/components/AppTitle'

const BuildingsPage = ({ id } = {}) => {
  const { spaces: _spaces, loading } = useSpaces()
  const { hasRole } = useAuth()
  const { getRoute, isTenantScope, tenantId } = useTenant()
  const [spaces, setSpaces] = useState([])
  const [currentSpaceType, setCurrentSpaceType] = useState(c.TYPE_BUILDING)
  const [addSpaceVisible, setAddSpaceVisible] = useState(false)
  const [editSpace, setEditSpace] = useState()
  const { user } = useUser()

  const handleExtra = (item) => (e) => {
    e.stopPropagation()
    setEditSpace(item)
    setAddSpaceVisible(true)
  }

  const handleBack = () => {
    navigate(getRoute('app'))
  }

  const addSpaces = (newSpace) => {
    setSpaces([...spaces, newSpace])
  }

  const updateSpaces = (updatedSpace) => {
    const updatedSpaces = spaces.map(obj =>
      obj.id === updatedSpace.id ? { ...obj, ...updatedSpace } : obj
    )
    setSpaces(updatedSpaces)
  }

  const removeSpace = (deletedSpace) => {
    const updatedSpaces = spaces.filter(item => item.id !== deletedSpace.id)
    setSpaces(updatedSpaces)
  }

  useEffect(() => {
    setSpaces(_spaces)
  }, [_spaces])

  const addBuilding = () => {
    setEditSpace()
    setAddSpaceVisible(true)
  }

  return (
    <AppLayout>
      <AppHeader
        noMargin
        extra={
          (
            hasRole('superAdmin') ||
            (isTenantScope() && hasRole(`tenant.${tenantId}.${c.ACCESS_WRITE}`))
          ) && (
            <>
              <Button onClick={addBuilding}>Add Building</Button>
              <AddSpace
                onDone={addSpaces}
                onUpdate={updateSpaces}
                onRemove={removeSpace}
                spaceType={currentSpaceType}
                parentSpace={id}
                visible={addSpaceVisible}
                setVisible={setAddSpaceVisible}
                editSpace={editSpace}
                setEditSpace={setEditSpace}
              />
            </>)
        }
      >
        <BackButton onClick={() => handleBack()} />
        <AppTitle noMargin>Buildings Page</AppTitle>
      </AppHeader>
      <AppContent noFlex>
        <BuildingsGrid
          onExtraClick={handleExtra}
          spaces={spaces}
          loading={loading}
        />
      </AppContent>
    </AppLayout>
  )
}

export default BuildingsPage
