import axios from 'axios'
import format from 'date-fns/format'
import urljoin from 'url-join'

/**
 * @typedef PermissionLevels
 * @type {0|1|2|3}
 */

const { localStorage } = window
const { API_ENDPOINT } = process.env

const ENDPOINT = API_ENDPOINT

const storeToken = (token) => {
  localStorage.setItem('token', JSON.stringify(token))
}

const getToken = () => {
  let json = localStorage.getItem('token')
  if (json) {
    json = cleanToken(json)
    return JSON.parse(json)
  } else {
    return null
  }
}

const cleanToken = (token) => {
  return token
    .replace('JWT', '')
    .replace(' ', '')
}

const removeToken = () => {
  localStorage.removeItem('token')
  return true
}

export const getUser = () =>
  new Promise((resolve, reject) => {
    const { localStorage } = window
    const json = localStorage.getItem('user')
    if (json) {
      const user = json.parse(json)
      resolve(user)
    } else {
      reject(new Error('No user stored.'))
    }
  })

export class Api {
  /**
   * @type {string}
   */
  currentBase

  /**
   * @type {import('axios').AxiosInstance}
   */
  #instance

  /**
   * @type {import('axios').AxiosInstance}
   */
   #staticInstance

  constructor (basePath) {
    this.updateBase(basePath)
  }

  updateInstanceAuthToken = (token) => {
    this.#instance.defaults.headers.Authorization = `Bearer ${token}`
  }

  updateBase = (basePath) => {
    const base = basePath ?? '/'
    const token = getToken()
    const baseURL = urljoin(ENDPOINT, base)

    this.currentBase = base
    this.#instance = axios.create({
      baseURL,
      withCredentials: false, // set cookies via ajax,
      headers: token
        ? { Authorization: `Bearer ${token}` }
        : {}
    })

    if (base === '/') {
      this.#staticInstance = this.#instance
    }
  }

  login = (username, password, opts = {}) =>
    new Promise((resolve, reject) => {
      const data = { username, password };
      (opts.api || this.#instance).post('/login', data)
        .then((res) => {
          const { token, user } = res.data
          resolve({
            res,
            user,
            token
          })
        })
        .catch(reject)
    })

  updatePasswordWithKey = (payload, opts = {}) =>
    new Promise((resolve, reject) => {
      (opts.api || this.#instance).post('/user/reset_password', payload)
        .then((res) => {
          resolve(res)
        })
        .catch(reject)
    })

  /**
   * @typedef VerifyEmailReturnType
   * @property {String} message
   * @property {Boolean} success
   *
   * @param {String} token
   *
   * @returns {Promise<VerifyEmailReturnType>}
   */
   verifyEmail = async (token) => {
    /**
     * @type {VerifyEmailReturnType}
     */
    const returnValue = {}

    try {
      const { data } = await this.#instance.post('/users/activate/email', {
        emailConfirmationToken: token
      })

      returnValue.message = data?.message
      returnValue.success = !!data?.success
    } catch (e) {
      /**
       * @type {import('axios').AxiosError}
       */
      const err = e

      if (err?.response?.data != null)  {
        returnValue.success = !!err.response.data?.success
        returnValue.message = err.response.data?.error ?? 'Something went  wrong.'
      }
    }

    return returnValue
  }

  // @todo Acitvate App logs
  getApplicationLogs = (params = {}, opts = {}) => []
    // @todo Acitvate App logs
    // (opts.api || this.#instance).get('/applicationLogs', { params })

  getLogs = (opts = {}) =>
    (opts.api || this.#instance).get('/debugLog')

  updateOrAddAlgoByKeyForSpace = (algoKey, spaceId, spaceAlgorithm = {}, opts = {}) =>
    (opts.api || this.#instance).post(`/spaces/${spaceId}/algorithms/key/${algoKey}`, { spaceAlgorithm })

  getAlgoByKeyForSpace = (algoKey, spaceId, opts = {}) =>
    (opts.api || this.#instance).get(`/spaces/${spaceId}/algorithms/key/${algoKey}`)

  getAlgoByKey = (algoKey, opts = {}) =>
    (opts.api || this.#instance).get(`/algorithms/key/${algoKey}`)

  getMeasurementsForSpace = (spaceId, opts = {}) =>
    new Promise((resolve, reject) => {
      (opts.api || this.#instance).get(`/spaces/${spaceId}/measurements`, {
        params: {
          dateFrom: new Date() - 20,
          dateTo: new Date(),
          interval: 3600,
          ...opts
        }
      }).then((res) => {
        resolve(res)
      }).catch(reject)
    })

  isSignedIn = (opts = {}) =>
    new Promise((resolve, reject) => {
      (opts.api || this.#instance).get('/userping')
        .then((res) => {
          resolve(res.status === 200)
        })
        .catch(reject)
    })

  isSignedInWithObject = (opts = {}) =>
    new Promise((resolve, reject) => {
      (opts.api || this.#instance).get('/users/current')
        .then((res) => {
          resolve(res.data)
        })
        .catch(reject)
    })

  logout = () =>
    new Promise((resolve, reject) => {
      resolve()
    })

  setIssuesAsRead = () =>
    this.#staticInstance.patch('/v3/user', { issuesReadAt: new Date() })

  getSensors = (params = {}, opts = {}) =>
    new Promise((resolve, reject) => {
      (opts.api || this.#instance).get('/sensors', { params })
        .then((res) => resolve({
          res,
          sensors: res.data
        }))
        .catch(reject)
    })

  /**
    * @param {({
    *  by: string
    * })} params
    */
  getSensorGroups = async (params = {}, opts = {}) =>
    await (opts.api || this.#instance).get('/sensors/groups', { params })

  getSensorTypes = async (opts = {}) =>
    await (opts.api || this.#instance).get('/sensorTypes')

  getSensorSettingConfigurableSetting = async (sensorConfigurableSettingId, opts = {}) =>
    await (opts.api || this.#instance).get(`/sensors/sensorSettings/configurableSetting/${sensorConfigurableSettingId}`)

  postSensorSettingConfigurableSettings = async (groupId, setting = {}, opts = {}) =>
    await (opts.api || this.#instance).post(`/sensors/sensorSettings/groups/${groupId}/configurableSettings`, setting)

  patchSensorSensorSettingConfigurableSetting = async (configurableSettingId, setting = {}, opts = {}) =>
    await (opts.api || this.#instance).patch(`/sensors/sensorSettings/configurableSettings/${configurableSettingId}`, setting)

  deleteSensorSensorSettingConfigurableSetting = async (configurableSettingId, opts = {}) =>
    await (opts.api || this.#instance).delete(`/sensors/sensorSettings/configurableSettings/${configurableSettingId}`)

  postSensorSettingConfigurableSettingPreset = async (configurableSettingId, preset = {}, opts = {}) =>
    await (opts.api || this.#instance).post(`/sensors/sensorSettings/configurableSetting/${configurableSettingId}/preset`, preset)

  updateSensorSettingConfigurableSettingPreset = async (presetId, preset = {}, opts = {}) =>
    await (opts.api || this.#instance).patch(`/sensors/sensorSettings/configurableSettings/preset/${presetId}`, preset)

  deleteSensorSettingConfigurableSettingPreset = async (presetId, opts = {}) =>
    await (opts.api || this.#instance).delete(`/sensors/sensorSettings/configurableSettings/preset/${presetId}`)

  getSensorSettingGroup = async (groupId, opts = {}) =>
    await (opts.api || this.#instance).get(`/sensors/sensorSettings/groups/${groupId}`)

  getSensorSettingGroups = async (opts = {}) =>
    await (opts.api || this.#instance).get('/sensors/sensorSettings/groups')

  postSensorSettingGroups = ( sensorSettingGroup= {}, opts = {}) =>
    (opts.api || this.#instance).post('/sensors/sensorSettings/groups', sensorSettingGroup)

  updateSensorSettingGroup = async (groupId, setting, opts = {}) =>
    await (opts.api || this.#instance).patch(`/sensors/sensorSettings/groups/${groupId}`, setting)

  deleteSensorSettingGroup = async (groupId, setting, opts = {}) =>
    await (opts.api || this.#instance).delete(`/sensors/sensorSettings/groups/${groupId}`, setting)

  updateAlgorithm = (algorithmId, newData, opts = {}) => {
    return (opts.api || this.#instance).patch(`/algorithms/${algorithmId}`, newData)
  }
  updateSpaceAlgorithm = (spaceAlgoId, newData, opts = {}) =>
    (opts.api || this.#instance).patch(`/spaceAlgorithms/${spaceAlgoId}`, newData)

  addSpaceAlgo = (spaceId, algorithmId, userSettings, opts = {}) =>
    (opts.api || this.#instance).post(`/spaces/${spaceId}/algorithms`, {
      algorithmId,
      userSettings: {
        user: userSettings
      }
    })

  getSensorStats = (id, opts = {}) =>
    (opts.api || this.#instance).get(`/sensors/${id}/diagnostics`)

  addAlgorithm = (algo, opts = {}) =>
    (opts.api || this.#instance).post('/algorithms', algo)

  getAlgorithmsForSpace = (spaceId, opts = {}) =>
    (opts.api || this.#instance).get(`/spaces/${spaceId}/algorithms`)

  getAllAlgorithms = ({ params = {}, ...opts } = {}) =>
    (opts.api || this.#instance).get('/algorithms', { params })

  getSensorPlugins = (opts = {}) =>
    (opts.api || this.#instance).get('/sensorPlugins')

  getAlgorithm = (algoId, opts = {}) =>
    (opts.api || this.#instance).get(`/algorithms/${algoId}`)

  getFlow = (flowId, opts = {}) =>
    (opts.api || this.#instance).get(`/flows/${flowId}`)

  getFlowLogs = (flowId, params = {}, opts = {}) => []
    // @todo Acitvate App logs
    // (opts.api || this.#instance).get(`/flows/${flowId}/logs`, { params })

  getAlgorithmFlowNodeTypes = (algoId, opts = {}) =>
    (opts.api || this.#instance).get(`/algorithms/${algoId}/flows/nodeTypes`)

  saveFlow = (flowId, payload = {}, opts = {}) =>
    (opts.api || this.#instance).post(`/flows/${flowId}`, payload)

  triggerAlgorithm = (algorithmId, payload = {}, opts = {}) =>
    (opts.api || this.#instance).post(`/algorithms/${algorithmId}/trigger`, payload)

  getAlgorithmNodes = (opts = {}) =>
    (opts.api || this.#instance).get(`/algorithms/nodes`, { params: opts?.params ?? {} })

  getAlgorithmNode = (algoNodeId, opts = {}) =>
    (opts.api || this.#instance).get(`/algorithms/nodes/${algoNodeId}`)

  addAlgorithmNode = (node, opts = {}) =>
    (opts.api || this.#instance).post('/algorithms/nodes', node)

  updateAlgorithmNode = (algoNodeId, node, opts = {}) =>
    (opts.api || this.#instance).patch(`/algorithms/nodes/${algoNodeId}`, node)

  deleteAlgorithmNode = (algoNodeId, opts = {}) =>
    (opts.api || this.#instance).delete(`/algorithms/nodes/${algoNodeId}`)

  getAlgorithmNodeTypes = (opts = {}) =>
    (opts.api || this.#staticInstance).get(`/algorithms/nodeTypes`, { params: opts?.params ?? {} })

  getAlgorithmNodeType = (algoNodeTypeId, opts = {}) =>
    (opts.api || this.#instance).get(`/algorithms/nodeTypes/${algoNodeTypeId}`)

  addAlgorithmNodeType = (nodeType, opts = {}) =>
    (opts.api || this.#instance).post('/algorithms/nodeTypes', nodeType)

  getAlgorithmNodeDataTypes = (opts = {}) =>
    (opts.api || this.#staticInstance).get('/algorithms/nodeDataTypes', { params: opts?.params ?? {} })

  getAlgorithmNodeDataType = (algoNodeDataTypeId, opts = {}) =>
    (opts.api || this.#staticInstance).get(`/algorithms/nodeDataTypes/${algoNodeDataTypeId}`)

  addAlgorithmNodeDataType = (nodeDataType, opts = {}) =>
    (opts.api || this.#staticInstance).post('/algorithms/nodeDataTypes', nodeDataType)

  adminGetAllSensors = (opts = {}) =>
    (opts.api || this.#instance).get('/v4/sensors', { params: opts?.params })

  adminGetSensor = (id, opts = {}) =>
    (opts.api || this.#instance).get(`/sensors/${id}`)

  adminGetAllUsers = (opts = {}) =>
    (opts.api || this.#instance).get('/users')

  adminGetUser = (userId, opts = {}) =>
    (opts.api || this.#instance).get(`/users/${userId}`)

  adminGetUserWithSpaceAccess = (spaceId, opts = {}) =>
    (opts.api || this.#instance).get(`/spaces/${spaceId}/access`)

  adminGetAllSpaces = (opts = {}) =>
    (opts.api || this.#instance).get('/spaces')

  adminAddSensorToUser = (payload, opts = {}) =>
    (opts.api || this.#instance).post('/userSensor', payload)

  adminRemoveSensorFromUser = (userId, sensorId, opts = {}) =>
    (opts.api || this.#instance).delete(`/userSensor/${userId}/${sensorId}`)

  adminAddSensor = (sensor, opts = {}) =>
    (opts.api || this.#instance).post('/sensors', sensor)

  adminUpdateSensor = (sensor, sensorId, opts = {}) =>
    (opts.api || this.#instance).patch(`/sensors/${sensorId ?? sensor.id}`, sensor)

  adminDeleteSensor = (sensorId, opts = {}) =>
    (opts.api || this.#instance).delete(`/sensors/${sensorId}`)

  adminAddUser = (user, opts = {}) =>
    (opts.api || this.#instance).post('/users', user)

  adminUpdateUser = (user, userId, opts = {}) =>
    (opts.api || this.#instance).patch(`/users/${userId ?? user.id}`, user)

  adminDeleteUser = (userId, opts = {}) =>
    (opts.api || this.#instance).delete(`/users/${userId}`)

  adminAddUserToSpace = (user, opts = {}) =>
    (opts.api || this.#instance).post('/spaceAccess', user)

  adminRemoveUserFromSpace = (spaceAccessId, opts = {}) =>
    (opts.api || this.#instance).delete(`/spaceAccess/${spaceAccessId}`)

  adminAddSensorType = (sensorType, opts = {}) =>
    (opts.api || this.#instance).post('/sensorTypes', sensorType)

  adminUpdateSensorType = (sensorType, opts = {}) =>
    (opts.api || this.#instance).put('/sensorTypes', sensorType)

  adminDeleteSensorType = (sensorTypeId, opts = {}) =>
    (opts.api || this.#instance).delete(`/sensorTypes/${sensorTypeId}`)

  adminAddMachine = (machine, opts = {}) =>
    (opts.api || this.#instance).post('/plugins/', machine)

  adminUpdateMachine = (pluginId, plugin, opts = {}) =>
    (opts.api || this.#instance).put(`/plugins/${pluginId}`, plugin)

  adminDeleteMachine = (pluginId, opts = {}) =>
    (opts.api || this.#instance).delete(`/plugins/${pluginId}`)

  getSensorData = (sensorId, opts = {}) =>
    new Promise((resolve, reject) => {
      const {
        dateTimeStart,
        dateTimeEnd,
        interval
      } = opts;

      (opts.api || this.#instance).get(`/sensors/${sensorId}/measurements/environmental`, {
        headers: {
          accept: 'application/json'
        },
        params: {
          datetime_start: dateTimeStart || format(new Date(), 'yyyy-MM-dd 00:00:00'),
          datetime_end: dateTimeEnd || format(new Date(), 'yyyy-MM-dd 23:59'),
          interval
        }
      })
        .then((res) => resolve({
          res,
          ...res.data
        }))
        .catch(reject)
    })

  getSensorInfo = (sensorId, opts = {}) =>
    new Promise((resolve, reject) => {
      (opts.api || this.#instance).get(`/sensors/${sensorId}`)
        .then((res) => resolve({
          res,
          sensor: res.data
        }))
        .catch(reject)
    })

  getSensorDiagnostics = (sensorId, opts = {}) =>
    new Promise((resolve, reject) => {
      (opts.api || this.#instance).get(`/sensor/diagnostics/${sensorId}`)
        .then((res) => resolve({
          res,
          ...res.data
        }))
        .catch(reject)
    })

  getSensorComfort = (sensorId, opts = {}) =>
    new Promise((resolve, reject) => {
      (opts.api || this.#instance).get(`/sensor/stats/${sensorId}`)
        .then((res) => resolve({
          res,
          ...res.data
        }))
        .catch(reject)
    })

  addSensorForUser = (sensor, opts = {}) =>
    (opts.api || this.#instance).put('/users/sensors', sensor)

  addSensorToSpace = (spaceId, sensorId, opts = {}) =>
    (opts.api || this.#instance).post(`/spaces/${spaceId}/sensor`, sensorId)

  resetSensorSpace = (sensorId, opts = {}) =>
    (opts.api || this.#instance).delete(`/sensors/${sensorId}/spaces`)

  deleteSensorFromSpace = (sensorId, opts = {}) =>
    (opts.api || this.#instance).delete(`/sensors/${sensorId}/spaces`)

  addPluginToSensor = (sensorId, PluginId, opts = {}) =>
    (opts.api || this.#instance).post(`/sensors/${sensorId}/plugin`, PluginId)

  updateSensorPlugin = (sensorPluginID, plugin, opts = {}) =>
    (opts.api || this.#instance).patch(`/sensorPlugin/${sensorPluginID}`, plugin)

  forgotPwd = (email, opts = {}) =>
    new Promise((resolve, reject) => {
      (opts.api || this.#instance).post('/user/forgot_password', email)
        .then((res) => {
          resolve({
            res
          })
        })
        .catch(reject)
    })

  getSpaces = (opts = {}) => {
    const url = '/spaces'
    return (opts.api || this.#instance).get(url)
  }

  getSpace = (spaceId, opts = {}) => {
    const url = `/spaces/${spaceId}`
    return (opts.api || this.#instance).get(url)
  }

  getAllIssues = (opts = {}) =>
    (opts.api || this.#instance).get('/issues')

  addSpace = (space, opts = {}) =>
    (opts.api || this.#instance).post('/spaces', space)

  updateSpace = (space, spaceId, opts = {}) =>
    (opts.api || this.#instance).patch(`/spaces/${spaceId ?? space.id}`, space)

  deleteSpace = (spaceId, opts = {}) =>
    (opts.api || this.#instance).delete(`/spaces/${spaceId}`)

  getSignedURL = (body, opts = {}) =>
    (opts.api || this.#instance).post('/bucket/signed-url', body)

  deleteImageFromBucket = (fileName, opts = {}) =>
    (opts.api || this.#instance).delete(`/bucket/${fileName}`)

  uploadImageToS3 = (url, file, callbackProgress) =>
    new Promise((resolve, reject) => {
      const options = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          callbackProgress(percentCompleted)
        },
        headers: {
          'Content-Type': file.type,
          'x-amz-acl': 'public-read'
        }
      }
      axios.put(
        url,
        file,
        options)
        .then((res) => {
          resolve(res)
        })
        .catch(reject)
    })

  getPlugins = (opts = {}) =>
    (opts.api || this.#instance).get('/plugins')

  getPlugin = (id, opts = {}) =>
    (opts.api || this.#instance).get(`/plugins/${id}`)

  // Tenant stuff

  /**
   * Add user to tenant
   *
   * @param {number} tenantId
   * @param {object} user
   * @param {?PermissionLevels} permission
   */
  addUserToTenant = (tenantId, user = {}, permission = 0,  opts = {}) =>
    (opts.api || this.#staticInstance).post(`/tenants/${tenantId}/users`, { user, permission })


  /**
   * List users for tenant
   *
   * @param {number} tenantId
   */
  getUsersForTenant = (tenantId,  opts = {}) =>
    (opts.api || this.#staticInstance).get(`/tenants/${tenantId}/users`)

  /**
   * Update relation of TenantUser
   *
   * @typedef TenantUserPatch
   * @type {object}
   * @property {?PermissionLevels} permission
   * @property {?boolean} active
   *
   * @param {number} tenantId
   * @param {number} userId
   * @param {TenantUserPatch} options
   */
  updateTenantUserRelation = (tenantId, userId, payload, opts = {}) =>
    (opts.api || this.#staticInstance).patch(`/tenants/${tenantId}/users/${userId}`, payload ?? {})

  /**
   * Update relation of TenantUser
   *
   * @typedef TenantUserPatch
   * @type {object}
   * @property {?PermissionLevels} permission
   * @property {?boolean} active
   *
   * @param {number} tenantId
   * @param {number} userId
   * @param {TenantUserPatch} options
   */
  bindOrUpdateUserTenantRel = (tenantId, userId, payload, opts = {}) =>
     (opts.api || this.#staticInstance).put(`/tenants/${tenantId}/users/${userId}`, payload ?? {})

  /**
   * Remove user from Tenant
   *
   * @param {number} tenantId
   * @param {number} userId
   */
  removeUserFromTenant = (tenantId, userId, opts = {}) =>
    (opts.api || this.#staticInstance).delete(`/tenants/${tenantId}/users/${userId}`)

  /**
   * Get one user for tenant
   *
   * @param {number} tenantId
   * @param {number} userId
   * @returns
   */
  getUserForTenant = (tenantId, userId, opts = {}) =>
    (opts.api || this.#staticInstance).get(`/tenants/${tenantId}/users/${userId}`)

  /**
   * Get sensors for tenant
   *
   * @param {number} tenantId
   */
  getTenantsSensors = (tenantId, opts = {}) =>
    (opts.api || this.#staticInstance).get(`/tenants/${tenantId}/sensors`)

  /**
   * Add sensor to tenant
   *
   * @param {number} tenantId
   * @param {object} sensor
   * @returns
   */
  addTenantsSensors = (tenantId, sensor = {}, opts = {}) =>
    (opts.api || this.#staticInstance).post(`/tenants/${tenantId}/sensors`, sensor)

  /**
   * Bind sensor by id to a tenant
   *
   * @param {number} tenantId
   * @param {number} sensorId
   */
  bindTenantToSensor  = (tenantId, sensorId, payload = {}, opts = {}) =>
    (opts.api || this.#staticInstance).put(`/tenants/${tenantId}/sensors/${sensorId}`, payload)

  /**
   * Bind sensor by ids to a tenant
   *
   * @param {number} tenantId
   * @param {{ sensorIds: number[], active: ?boolean }} payload
   */
  bindTenantToSensors  = (tenantId, payload = {}, opts = {}) =>
    (opts.api || this.#staticInstance).put(`/tenants/${tenantId}/sensors`, payload)

  /**
   * Bind sensor by id to a tenant
   *
   * @param {number} tenantId
   * @param {number} sensorId
   */
  bindTenantToSpace  = (tenantId, spaceId, payload = {}, opts = {}) =>
    (opts.api || this.#staticInstance).put(`/tenants/${tenantId}/spaces/${spaceId}`, payload)


  /**
   * Update sensor and sensor relation of tenant
   *
   * @param {number} tenantId
   * @param {number} sensorId
   * @param {object} payload
   */
  updateSensorTenantRelation = (tenantId, sensorId, payload = {}, opts = {}) =>
    (opts.api || this.#staticInstance).patch(`/tenants/${tenantId}/sensors/${sensorId}`, payload)

  /**
   * Remove sensor from Tenant
   *
   * @param {number} tenantId
   * @param {number} sensorId
   */
  removeSensorFromTenant = (tenantId, sensorId, opts = {}) =>
    (opts.api || this.#staticInstance).delete(`/tenants/${tenantId}/sensors/${sensorId}`)

  /**
   * Remove sapce from Tenant
   *
   * @param {number} tenantId
   * @param {number} spaceId
   */
  removeSpaceFromTenant = (tenantId, spaceId, opts = {}) =>
    (opts.api || this.#staticInstance).delete(`/tenants/${tenantId}/spaces/${spaceId}`)

  /**
   * Get all active tenants for the signed in user
   */
  getTenants = (params = {}, opts = {}) =>
    (opts.api || this.#instance).get('/tenants', { params })

  /**
   * Get one tenant by id
   *
   * @param {number} tenantId
   */
  getTenant = (tenantId, opts = {}) =>
    (opts.api || this.#staticInstance).get(`/tenants/${tenantId}`)

  /**
   * Get one tenant emailConfig by id
   *
   * @param {number} tenantId
   */
  getTenantTemplate = (tenantId, opts = {}) =>
    (opts.api || this.#staticInstance).get(`/tenants/${tenantId}/template`)

  /**
   * Update tenant by id
   *
   * @param {number} tenantId
   * @param {object} payload
   * @returns
   */
  updateTenant = (tenantId, payload, opts = {}) =>
    (opts.api || this.#staticInstance).patch(`/tenants/${tenantId}`, payload ?? {})

  /**
   * Delete tenant by id
   *
   * @param {number} tenantId
   */
  deleteTenant = (tenantId, opts = {}) =>
    (opts.api || this.#staticInstance).delete(`/tenants/${tenantId}`)

  /**
   * Create new tenant
   *
   * @param {object} payload
   */
  addTenant = (payload, opts = {}) =>
    (opts.api || this.#instance).post('/tenants', payload ?? {})

  /**
   * Get Dashboard card stats
   */
  getTenantStats = (opts = {}) =>
    (opts.api || this.#instance).get('/stats/tenants')

  /**
   * @typedef PeriodOptions
   * @type {object}
   * @property {?('last-day'|'last-4hours'|'last-week'|'last-month'|'last-year')} period
   * @property {?('minute'|'hour'|'day'|'week'|'month')} interval
   */

  /**
   * Get time series data of comfort for tenant
   *
   * @param {number} tenantId
   * @param {?PeriodOptions} params
   */
  getComfortStatsForUser = (tenantId, params, opts = {}) =>
    (opts.api || this.#staticInstance).get(`/tenants/${tenantId}/stats/comfort`, { params: params ?? {} })

  /**
   * Get time series data of added spaces
   *
   * @param {number} tenantId
   * @param {1|2|3} spaceType
   * @param {?PeriodOptions} params
   */
  getBuildingStatsForUser = (tenantId, spaceType, params, opts = {}) =>
    (opts.api || this.#staticInstance).get(`/tenants/${tenantId}/stats/spaces/${spaceType}`, { params: params ?? {} })

  /**
   * Get time series data of added sensors
   *
   * @param {number} tenantId
   * @param {?PeriodOptions} params
   */
  getSensorStatsForUser = (tenantId, params, opts = {}) =>
    (opts.api || this.#staticInstance).get(`/tenants/${tenantId}/stats/sensors`, { params: params ?? {} })

  /**
   * Send command to sensor
   *
   * @param {number} sensorId
   * @param {object} payload
   */
  sendCommandToSensor = (sensorId, payload, opts = {}) =>
    (opts.api || this.#instance).post(`/sensors/${sensorId}/command`, payload ?? {})

  sendUiCommandToSensor = (sensorId, uiId, payload, opts = {}) =>
    (opts.api || this.#instance).post(`/sensors/${sensorId}/command/${uiId}`, payload ?? {})

  /**
   * Get sensor logs
   *
   * @param {number} sensorId
   * @param {object} params
   */
  getSensorLogs = (sensorId, params = {}, opts = {}) =>
    // TODO: Way to go if flux is enabled in production.
    // (opts.api || this.#instance).get(`/sensors/${sensorId}/logs`, { params })
    (opts.api || this.#instance).get(`/sensors/${sensorId}/issues`, { params })

  getEvents = (opts = {}) =>
    (opts.api || this.#staticInstance).get('/events')

  postEvent = (payload, opts = {}) =>
    (opts.api || this.#staticInstance).post('/events', payload ?? {})

  patchEvent = (eventId, payload, opts = {}) =>
    (opts.api || this.#staticInstance).post(`/events/${eventId}`, payload ?? {})

  getCommands = (opts = {}) =>
    (opts.api || this.#staticInstance).get('/commands')

  getCommand = (commandId, opts = {}) =>
    (opts.api || this.#staticInstance).get(`/commands/${commandId}`)

  postCommand = (payload, opts = {}) =>
    (opts.api || this.#staticInstance).post('/commands', payload ?? {})

  patchCommand = (commandId, payload, opts = {}) =>
    (opts.api || this.#staticInstance).patch(`/commands/${commandId}`, payload ?? {})

  deleteCommand = (commandId, opts = {}) =>
    (opts.api || this.#staticInstance).delete(`/commands/${commandId}`)

  postCommandParamUi = (commandId, payload, opts = {}) =>
    (opts.api || this.#staticInstance).post(`/commands/${commandId}/uis`, payload ?? {})

  patchCommandParamUi = (commandUiId, payload, opts = {}) =>
    (opts.api || this.#staticInstance).patch(`/commands/uis/${commandUiId}`, payload ?? {})

  deleteCommandParamUi = (commandUiId, opts = {}) =>
    (opts.api || this.#staticInstance).delete(`/commands/uis/${commandUiId}`)

  getWebhooks = (opts = {}) =>
    (opts.api || this.#instance).get('/webhooks')
}

export const apiInstance = new Api()
