import React, { useState } from 'react'
import {
  Modal,
  Button as AntButton,
  Form,
  Input
} from 'antd'

import Button from 'src/components/Button'
import styled from 'styled-components'
import { useAdminSensorSetting } from 'src/hooks/sensorSetting'

const ButtonContainer = styled.div`
  padding: 0.5em;
`

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
`

const TitleContainer = styled.div`
  padding: 1em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #7297ff;
  & h2 {
    color: #fff;
  }
`

const FormContainer = styled.div`
  padding: 2em;
  & .ant-row.ant-form-item {
    flex: 1;
    justify-content: center;
    margin-bottom: 1em;
  }
  & .ant-col-14 {
    max-width: 100%;
  }
  & .ant-col-4 {
    padding-left: 8px;
    max-width: 100%;
    flex: 1;
  }
  & .ant-picker {
    width: 100%;
  }

  & .ant-divider{
    margin: 1em;
  }

  & .ant-form-large .ant-form-item-label > label {
    height: 25px;
  }
`

/**
 * @typedef AddSensorSettingGroupProps
 * @type {object}
 *
 * @param {AddSensorSettingGroupProps} props
 * @returns {React.ReactNode}
 */

const AddSensorSettingGroup = ({
  edit = false,
  record = {},
  shouldUpdate,
  btnType
}) => {
  const { addSensorGroup, updateSensorGroup } = useAdminSensorSetting({ fetch: false, id: record.id })
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = e => {
    form.resetFields()
    setVisible(false)
  }

  const handleSubmit = async item => {
    if (edit) {
      await updateSensorGroup(item)
    } else {
      await addSensorGroup(item)
    }

    form.resetFields()
    setVisible(false)
    shouldUpdate?.()
  }

  const validateMessages = {
    required: "'${label}' is required!",
    types: {
      email: '${label} is not validate email!',
      number: '${label} is not a validate number!'
    }
  }

  return (
    <>
      {edit
        ? (
          <Button type={btnType} onClick={showModal}>
            Edit setting group
          </Button>)
        : (
          <Button type={btnType} onClick={showModal}>
            Add setting group
          </Button>)}
      <Modal
        closable={false}
        visible={visible}
        destroyOnClose
        onCancel={handleCancel}
        bodyStyle={{ padding: '0' }}
        footer={[
          <FooterContainer key='buttons'>
            <ButtonContainer>
              <Button fill outline key='cancel' onClick={handleCancel}>
                Cancel
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button key='submit' type='primary' htmlType='submit' form='addSensorSettingGroup'>
                {edit ? 'Update setting group' : 'Add setting group'}
              </Button>
            </ButtonContainer>
          </FooterContainer>
        ]}
      >
        <div>
          <TitleContainer>
            <h2>{edit ? 'Edit setting group' : 'Add setting group'}</h2>
          </TitleContainer>
          <FormContainer>
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              layout='vertical'
              size='large'
              id='addSensorSettingGroup'
              onFinish={handleSubmit}
              colon={false}
              form={form}
              validateMessages={validateMessages}
              initialValues={{
                ...record
              }}
            >
              <Form.Item
                label='Title'
                name='title'
              >
                <Input
                  placeholder='Title'
                  allowClear
                />
              </Form.Item>
              <Form.Item
                label='Name'
                name='name'
                hasFeedback
                rules={[
                  {
                    required: true,
                  }
                ]}
              >
                <Input
                  placeholder='Name'
                  allowClear
                />
              </Form.Item>
              <Form.Item
                label='Description'
                name='description'
                hasFeedback
              >
                <Input.TextArea
                  placeholder='Description'
                  allowClear
                />
              </Form.Item>
            </Form>
          </FormContainer>
        </div>
      </Modal>
    </>
  )
}

export default AddSensorSettingGroup
