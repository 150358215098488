import { useEffect, useState } from 'react'
import {
  Table,
  Tag,
  Space,
  Popconfirm,
  Tooltip
} from 'antd'
import Button from 'src/components/Button'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useSpaceUser, useSensorUser, useTenant, useAuth, useTenants } from 'src/hooks'
import UserSpaceAccessModal from 'src/components/UserSpaceAccessModal'
import UserSensorAccessModal from 'src/components/UserSensorAccessModal'

import * as c from 'src/constants'

import styled from 'styled-components'
import UserTenantAccessModal from 'src/components/UserTenantAccessModal'
const TopBar = styled.div`
  display: flex;
`

const FullSpace = styled(Space)`
  width: 100%;
`

const AdminUserAccess = ({ user, refresh }) => {
  const { isTenantScope } = useTenant()
  const { unbindUserTenantRel } = useTenants({ fetch: false })
  const { hasRole } = useAuth()
  const { id, spaces, sensors, tenants } = user
  const { adminRemoveUserFromSpace } = useSpaceUser()
  const { adminRemoveSensorFromUser } = useSensorUser()
  const handleDeleteSpace = (record) => {
    adminRemoveUserFromSpace(record?.SpaceAccess?.id)
      .then((res) => {
        refresh()
      }).catch(() => {
      })
  }
  const handleDeleteSensor = (record) => {
    const { id: sensorId } = record
    adminRemoveSensorFromUser(id, sensorId)
      .then((res) => {
        refresh()
      }).catch(() => {
      })
  }
  const handleDeleteTenant = (record) => {
    unbindUserTenantRel(record.id, id)
      .then((res) => {
        refresh()
      }).catch(() => {
      })
  }
  const spaceColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: name => <a>{name}</a>
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: type => {
        if (type === c.TYPE_ROOM) return <Tag color='geekblue'>Room</Tag>
        if (type === c.TYPE_FLOOR) return <Tag color='blue'>Floor</Tag>
        if (type === c.TYPE_BUILDING) return <Tag color='orange'>Building</Tag>
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size='middle'>
          <div onClick={(e) => e.stopPropagation()}>
            <Popconfirm
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title='Are you sure delete this sensor?'
              onConfirm={() => handleDeleteSpace(record)}
              okText='Yes, delete'
              cancelText='No'
            >
              <a href='#'>Delete</a>
            </Popconfirm>
          </div>
        </Space>
      )
    }
  ]

  const sensorColumns = [
    {
      title: 'Name',
      dataIndex: 'formattedName',
      key: 'formattedName',
      render: name => <a>{name}</a>
    },
    {
      title: 'Device ID',
      dataIndex: 'deviceId',
      key: 'deviceId',
      responsive: ['md']
    },
    // {
    //   title: 'Type',
    //   dataIndex: 'type',
    //   render: type => {
    //     if (type === c.TYPE_ROOM) return <Tag color='geekblue'>Room</Tag>
    //     if (type === c.TYPE_FLOOR) return <Tag color='blue'>Floor</Tag>
    //     if (type === c.TYPE_BUILDING) return <Tag color='orange'>Building</Tag>
    //   }
    // },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size='middle'>
          <div onClick={(e) => e.stopPropagation()}>
            <Popconfirm
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title='Are you sure delete this sensor?'
              onConfirm={() => handleDeleteSensor(record)}
              okText='Yes, delete'
              cancelText='No'
            >
              <a href='#'>Delete</a>
            </Popconfirm>
          </div>
        </Space>
      )
    }
  ]

  const tenantColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: name => <a>{name}</a>
    },
    {
      title: 'Access level',
      dataIndex: 'TenantUser',
      key: 'accessLevel',
      responsive: ['md'],
      render: ({ permission } = {}) => {
        switch (permission) {
          case 0:
            return <Tag color='geekblue'> {permission}</Tag>
          case 1:
            return <Tag color='orange'> {permission}</Tag>
          case 2:
            return <Tag color='red'> {permission}</Tag>
          default:
            return '-'
        }
      }
    },
    {
      title: 'Active',
      dataIndex: 'TenantUser',
      key: 'active',
      responsive: ['md'],
      render: ({ active }) => active ? <i className='fas fa-check' /> : ''
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size='middle'>
          <div onClick={(e) => e.stopPropagation()}>
            <Popconfirm
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title='Are you sure delete this tenant?'
              onConfirm={() => handleDeleteTenant(record)}
              okText='Yes, delete'
              cancelText='No'
            >
              <a href='#'>Delete</a>
            </Popconfirm>
          </div>
        </Space>
      )
    }
  ]

  return (
    <>
      <TopBar>
        <UserSpaceAccessModal
          refresh={refresh}
          userSpaces={spaces}
          userId={id}
        >
          <Button
            style={{ margin: '1rem 0 0 1rem' }}
          >Add space
          </Button>
        </UserSpaceAccessModal>
      </TopBar>
      <FullSpace size='middle' direction='vertical'>
        {spaces &&
          <Table
            dataSource={spaces}
            columns={spaceColumns}
          />}
        <UserSensorAccessModal
          refresh={refresh}
          userSensors={sensors}
          userId={id}
        >
          <Button
            style={{ margin: '1rem 0 0 1rem' }}
          >Add sensor
          </Button>
        </UserSensorAccessModal>
        {sensors &&
          <Table
            dataSource={sensors}
            columns={sensorColumns}
          />}
        {!isTenantScope() && hasRole('superAdmin') && (
          <UserTenantAccessModal
            refresh={refresh}
            userTenants={tenants}
            userId={id}
          >
            <Button
              style={{ margin: '1rem 0 0 1rem' }}
            >
              Add client
            </Button>
          </UserTenantAccessModal>
        )}
        {!isTenantScope() && hasRole('superAdmin') && tenants && (
          <Table
            dataSource={tenants}
            columns={tenantColumns}
          />
        )}
      </FullSpace>
    </>

  )
}

export default AdminUserAccess
