import { message } from 'antd'
import { useCallback } from 'react'

import { apiInstance as api } from 'src/api'
import { useTenant } from 'src/hooks/tenant'

export const useSensorSpace = () => {
  useTenant()

  const deleteSensorFromSpace = useCallback(deviceId => {
    return new Promise((resolve, reject) => {
      return api.deleteSensorFromSpace(deviceId)
        .then((res) => {
          message.success(res.message || 'Sensor was deleted')
          resolve(res)
        })
        .catch((err) => {
          message.error(err.message || 'Unable to delete sensor')
          reject(err)
        })
    })
  }, [])

  const addSensorToSpace = useCallback((spaceId, sensorId) => {
    return new Promise((resolve, reject) => {
      const sensorObject = { sensorId }
      return api.addSensorToSpace(spaceId, sensorObject)
        .then((res) => {
          message.success(res.message || 'Sensor was added to Space')
          resolve(res.data)
        })
        .catch((err) => {
          message.error(err.response.data.error || 'Unable to add sensor to Space')
          reject(err)
        })
    })
  }, [])

  const resetSensorSpace = useCallback((sensorId) => {
    return new Promise((resolve, reject) => {
      return api.resetSensorSpace(sensorId)
        .then((res) => {
          message.success(res.message || 'Sensor was removed from Space')
          resolve(res.data)
        })
        .catch((err) => {
          message.error(err.response.data.error || 'Unable to remove sensor from Space')
          reject(err)
        })
    })
  }, [])

  return {
    deleteSensorFromSpace,
    resetSensorSpace,
    addSensorToSpace
  }
}
