import { useState } from 'react'
import { Button, Empty } from 'antd'
import InfoCard from 'src/components/InfoCard'
import { CaretRightOutlined } from '@ant-design/icons'
import { RightArrowButton } from 'src/components/Button'

import styled, { css } from 'styled-components'
import { getWeatherString } from 'src/libraries/weather'

const BREAKPOINT_MOBILE = '767px'

const DataDisplay = styled.p`
  margin: 0;
  padding: 0;
  ${props => props.title && css`
    cursor: help;
  `}
`
const Card = styled.div`
  display:flex;
  background: white;
  border-radius: 15px;
  box-shadow: ${props => props.header ? 'none;' : '5px 3px 29px 0 rgba(0, 0, 0, 0.16)'};
  margin: ${props => props.header ? '0em 15px' : '15px;'};
  flex: 1;

  @media (min-width: 768px){
    &.mobile-card{
      display: none;
    }
    max-height: ${props => props.header ? '20px' : '70px'};
  }
  @media (max-width: ${BREAKPOINT_MOBILE}){
    display: ${props => props.header && 'none'};
  }
`

const DesktopCard = styled(Card)`
  cursor: pointer;
  transition: opacity 0.25s ease-out, box-shadow 0.5s ease-out;
  ${props => !props.header && css`
      &:hover {
        opacity: 0.75;
        box-shadow: 0px 0px 15px 0 rgba(0, 0, 0, 0.1);
      }
    `}

  @media (max-width: ${BREAKPOINT_MOBILE}){
    & {
    display: none;
    }
  }
`

const ElemWrap = styled.div`
  display: flex;
  min-width: 0;
  flex: 2;
  align-items: center;
`

const ImageContainer = styled.div`
  display: flex;
  height: 100%;
  padding-right: 1em;
`

const ImgWrap = styled.div`
  height: 70px;
  width: 70px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
  @media (max-width: 767px){
    height: 100%;
    width: 70px;
  }
  & img {
    max-height: 100%;
  }
`

const GradientContainer = styled.div`
  width: 1em;
  height: 100%;
  border-radius: 1em 0 0 1em;
  background-image: ${props => props.header ? 'none;' : 'linear-gradient(0deg,rgba(0,0,0,0.5),rgba(0,0,0,.0));'};
  background-color: ${props => props.color};
`

const CustomFont = styled.h6`
  font-size: ${props => props.header ? '16px;' : '25px;'};
  line-height: ${props => props.header ? '19px;' : '30px;'};
  font-style: ${props => props.header ? 'italic;' : 'normal;'};
  font-weight: ${props => props.header ? 'bold;' : 'normal;'};
  margin-left: 0.25rem;
  margin-bottom: 0;
  color: black;
  ${props => props.ellipsis && css`
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0;
    overflow: hidden;
  `}
  @media (max-width: 767px){
    font-size: 1rem;
    line-height: 1.5rem;
  }

`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`

const EmptyWrap = styled.div`
    display:flex;
    background: white;
    border-radius: 15px;
    box-shadow: 5px 3px 29px 0 rgba(0, 0, 0, 0.16);
    margin: 15px;
    justify-content: center;
`

const ThumbNail = styled.img
  .attrs({
    onError: (e) => {
      e.target.onerror = null
      e.target.src = '/images/placeholder-building.png'
    }
  })`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
`

const IssueCard = (props) => {
  const {
    item: space = {},
    header = false,
    color = 'white',
    onClick: handleClick,
    deleteHandler,
    noData = false,
    scores = {},
    issues = [],
    showWeather
  } = props
  const { comfort } = scores
  const roundedComfort = Math.round(comfort)
  const displayComfort = roundedComfort
    ? `${roundedComfort}%`
    : '-'
  const {
    coverImage,
    name = '-',
    userIssues = '-',
    machineIssues = '-',
    weather
  } = space
  const displayMachineIssues = issues.length || '-'
  const displayWeather = getWeatherString(weather)
  const hasTemp = displayWeather !== '-'

  return (
    <>
      {noData ? (
        <EmptyWrap>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </EmptyWrap>
      )
        : (
          <>
            <DesktopCard header={header} onClick={handleClick}>
              <ElemWrap ellipsis style={{ flex: 7 }}>
                <ImageContainer>
                  <GradientContainer header={header} color={color} />
                  <ImgWrap>
                    {coverImage && <ThumbNail src={coverImage} />}
                  </ImgWrap>
                </ImageContainer>
                <CustomFont header={header} title={name} ellipsis>{name}</CustomFont>
              </ElemWrap>
              <ElemWrap>
                <CustomFont header={header}>{
                  header
                    ? 'Issues'
                    : displayMachineIssues
                }
                </CustomFont>
              </ElemWrap>
              <ElemWrap>
                <CustomFont header={header}>{
                  header
                    ? 'Comfort'
                    : displayComfort
                }
                </CustomFont>
              </ElemWrap>
              {showWeather && (
                <ElemWrap>
                  <CustomFont header={header}>
                    <DataDisplay
                      title={hasTemp || header
                        ? 'Ouside temperature / Outside humidity'
                        : 'Set coordinates for this building to display outside Weather'}
                    >
                      {
                        header
                          ? 'Weather'
                          : displayWeather
                      }
                    </DataDisplay>
                  </CustomFont>
                </ElemWrap>
              )}
              <ElemWrap style={{ display: 'flex', justifyContent: 'center' }}>
                {!header && (
                  <ButtonContainer>
                    {deleteHandler && deleteHandler}
                    <RightArrowButton />
                  </ButtonContainer>
                )}
              </ElemWrap>
            </DesktopCard>
            <Card header={header} className='mobile-card'>

              <ImageContainer>
                <GradientContainer color={color} />
                <ImgWrap>
                  {coverImage && <ThumbNail src={coverImage} />}
                </ImgWrap>
              </ImageContainer>

              <TextWrap>
                <ElemWrap>
                  <CustomFont>{name}</CustomFont>
                </ElemWrap>
                <ElemWrap>
                  <CustomFont>Machine Issues: </CustomFont>
                  <CustomFont>{displayMachineIssues}</CustomFont>
                </ElemWrap>
                <ElemWrap>
                  <CustomFont>comfort: </CustomFont>
                  <CustomFont>{displayComfort}</CustomFont>
                </ElemWrap>

              </TextWrap>
              <ElemWrap style={{ display: 'flex', justifyContent: 'center' }}>
                <ButtonContainer>
                  <RightArrowButton onClick={handleClick} />
                </ButtonContainer>
              </ElemWrap>
            </Card>
          </>
        )}
    </>
  )
}

export default IssueCard
