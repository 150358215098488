import { useEffect, useRef, useState } from 'react'

import { useTenant } from 'src/hooks'
import { apiInstance as api } from 'src/api'
import { useIsMounted } from 'src/hooks/mounted'

const merge = (a = {}, b = {}) =>
  Object.entries({ ...a, ...b })
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value ?? b[key]
      }),
      {}
    )

export const useMeasurements = (spaceId, opts = {}) => {
  const mounted = useIsMounted()
  const { tenantId, isValidScopeFetch } = useTenant()
  const [measurements, setMeasurements] = useState() // measurements from the sensor
  const [allMeasurements, setAllMeasurements] = useState() // includes plugin measurements too, grouped by timestamp
  const [plugins, setPlugins] = useState() // plugins that have measurements in the response from api
  const [existingKeys, setExistingKeys] = useState() // keys that contains non null values
  const [loading, setLoading] = useState()
  const [error, setError] = useState()

  useEffect(() => {
    if (spaceId != null) {
      setLoading(true)
      api.getMeasurementsForSpace(spaceId, {
        ...opts
      })
        .then(({ data: msrs, config }) => {
          if (mounted() && isValidScopeFetch(config)) {
            const onlySensorMsrmts = msrs.filter((msr) => msr.temp != null) // remove objects without sensor data
            const allMeasurementsObj = msrs.reduce((acc, cur) => ({ // group by timestamp
              ...acc,
              [cur.time]: acc[cur.time]
                ? merge(acc[cur.time], cur)
                : cur
            }), {})
            const allMeasurements = Object.values(allMeasurementsObj) // convert grouped to array
            const batch = msrs && msrs.length && msrs.length > 0
              ? merge(merge(msrs[0], msrs[1]), msrs[2])
              : {}
            const existingKeysObj = Object.keys(batch)
              .reduce((acc, cur) => ({ // loop through each key
                ...acc,
                [cur]: msrs.find(
                  (msr) => msr[cur] != null
                ) != null // find at least one measurement not null, set true / false
              }), {})
            const existingKeys = Object.entries(existingKeysObj)
              .filter(([key, value]) => value === true)
              .map(([key]) => key)
            const pluginsObj = msrs.reduce((acc, cur) => ({
              ...acc,
              [cur.pluginUid]: { // store each uid in own object with more info about plugin
                pluginUid: cur.pluginUid,
                pluginName: cur.pluginName,
                keys: [...new Set([...Object.entries(cur)
                  .reduce((acc, [key, value]) =>
                    value != null // create list of keys with values
                      ? [...acc, key] // add key to list
                      : acc
                  , []), ...(acc[cur.pluginUid]?.keys || [])])] // keep old ones
              }
            }), {})

            setPlugins(pluginsObj)
            setExistingKeys(existingKeys)
            setMeasurements(onlySensorMsrmts)
            setAllMeasurements(allMeasurements)
          }
        }, opts)
        .catch(e => {
          if (mounted()) {
            setPlugins()
            setExistingKeys()
            setMeasurements()
            setAllMeasurements()
            setError(e)
          }
        })
        .finally(() => {
          if (mounted()) setLoading(false)
        })
    }
  }, [spaceId, opts.dateFrom, tenantId])

  return {
    existingKeys,
    measurements,
    allMeasurements,
    plugins,
    loading,
    error
  }
}
