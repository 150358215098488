const f = (props) => {
  const {
    column,
    row,
    n, flex: n2,
    h, height: h2,
    w, width: w2,
    display = 'flex',
    ai, alignItems: ai2,
    jc, justifyContent: jc2,
    element = 'div',
    children,
    className,
    ...rest
  } = props

  const alignItems = ai || ai2
  const justifyContent = jc || jc2
  const flex = n || n2 || 1
  const width = w || w2 || '100%'
  const height = h || h2 || '100%'

  return React.createElement(
    element,
    {
      className,
      style: {
        width,
        height,
        display,
        flex,
        flexDirection: column
          ? 'column'
          : 'row',
        alignItems,
        justifyContent,
        ...rest
      }
    },
    children)
}

export default f
