import { Spin } from 'antd'

import styled from 'styled-components'

const SpinContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  widht: 100%;
  min-height: 100vh;
`

const LoadingScreen = (props) => {
  return (
    <SpinContainer>
      <Spin size='large' />
    </SpinContainer>
  )
}

export default LoadingScreen
