import { Form, Input } from 'antd'
import command from './command'
import sensor from './sensor'
import componentsWithCustomProps from './componentsWithCustomProps'
import nodeDataType from './nodeDataType'

const components = {
  ...componentsWithCustomProps,
  ...sensor,
  ...command,
  ...nodeDataType
}

/**
 * @typedef ApiInputComponentFactoryProps
 * @type {{
 *  [key:string]: any
 *  component: keyof components
 *  renderFn: ?string
 * }}
 *
 * @param {ApiInputComponentFactoryProps} props
 * @returns {React.ReactNode}
 */

export const ApiInputComponentFactory = ({ component, renderFn, name, ...props }) => {
  const COMPONENT_NAMES_WITHOUT_FORM_WRAPPER = ['FormList', 'FormItem']

  if (component) {
    if (Object.hasOwnProperty.call(components, component)) {
      const componentObj = components[component] ?? {}
      let item

      if (Object.hasOwnProperty.call(componentObj, renderFn)) {
        item = components[component][renderFn]({ ...props, ...componentObj?.defaultProps || {}, fieldName: name })
      } else if ((renderFn == null || renderFn === 'render') && Object.hasOwnProperty.call(componentObj, 'render')) {
        console.log(`Component "${component}" render fn not specified falling back to default "render".`)
        item = components[component].render({ ...props, fieldName: name })
      }

      if (item?.props?.children) {
        return (
          <>
            {React.Children.map(item.props.children, child => {
              if (!child) return null

              if (child?.type?.name && COMPONENT_NAMES_WITHOUT_FORM_WRAPPER.includes(child?.type?.name)) return child

              return (
                <Form.Item
                  {...child?.props ?? {}}
                  name={[
                    ...Array.isArray(name) ? name : [...name != null ? [name] : []],
                    ...Array.isArray(child?.props?.name) ? child?.props?.name : [child?.props?.name ?? ''],
                  ]}
                >
                  {child}
                </Form.Item>
              )
            })}
          </>
        )
      }

      if (item?.type?.name && COMPONENT_NAMES_WITHOUT_FORM_WRAPPER.includes(item?.type?.name)) return item

      return (
        <Form.Item
          {...item?.props ?? {}}
          name={[
            ...Array.isArray(name) ? name : [...name != null ? [name] : []]
          ]}
        >
          {item}
        </Form.Item>
      )
    }

    console.warn(`Component "${component}" declared but not found. Proceeding with default component`)
  }

  return renderFn == null || renderFn === 'render'
    ? (
      <Form.Item
        {...props}
        name={[
          ...name
        ]}
      >
        <Input {...props} />
      </Form.Item>
    )
    : null
}

export const ApiComponentOptions = [
  { value: 'Input' },
  ...Object.keys(components).map(key => ({ label: key, value: key }))
]
