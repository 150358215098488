import React from 'react'
import styled from 'styled-components'

const StyledOnlineIndicator = styled.span`
  display: inline-block;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  background-color: ${({ color }) => color};
  border-radius: 50%;
`

/**
 * @typedef OnlineIndicatorProps
 * @type {object}
 * @property {?boolean} online
 * @property {?string} color
 * @property {?string} size
 *
 * @param {OnlineIndicatorProps} props
 * @returns {React.ReactNode}
 */

const OnlineIndicator = ({ online = true, color, size = '0.7rem' }) => {
  let indicatorColor

  if (online) {
    indicatorColor = '#52c41a'
  } else {
    indicatorColor = '#ff4d4f'
  }

  if (typeof color === 'string' && color !== '') {
    indicatorColor = color
  }

  return (
    <StyledOnlineIndicator
      color={indicatorColor}
      size={size}
    />
  )
}

export default OnlineIndicator
