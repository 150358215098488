import React, { Fragment, useEffect, useRef, useState } from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons'
import ButtonComponent from 'src/components/Button/Button'
import CardWrapper from 'src/components/CardWrapper/CardWrapper'
import VerticalBtn from 'src/components/VerticalBtn'
import { COMMAND_PARAM_USER_UI_OPTIONS, MEDIUM_SCREEN } from 'src/constants'
import styled from 'styled-components'
import Dropdown from 'src/components/Dropdown'
import { useSensorLogs, useSensors, useTheme } from 'src/hooks'
import { Checkbox, Input, Form, Tooltip, Divider, Empty } from 'antd'
import Spacing from 'src/components/Spacing'
import { format } from 'date-fns'
import { getUTCString } from 'src/libraries/time'
import Icon from 'src/components/Icon'

const WEEK_DAYS = [
  { label: 'Su', value: 0 },
  { label: 'Mo', value: 1 },
  { label: 'Tu', value: 2 },
  { label: 'We', value: 3 },
  { label: 'Th', value: 4 },
  { label: 'Fr', value: 5 },
  { label: 'Sa', value: 6 }
]

const CmdContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  & > * {
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
`
const TopGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: ${({ spacings }) => spacings?.l ?? 0};
`

const CommandContainer = styled(CardWrapper)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: flex-start;
  margin: 0;
`

const Command = styled(Form)`
  .ant-form-item {
    margin-bottom: 0.8rem;
  }

  .ant-form-item-label {
    padding-bottom: 0.3rem;

    label {
      font-size: 1rem;
      font-weight: 300;
      color: #000000;
    }
  }
`

const Log = styled(CardWrapper)`
  margin: 0;
`

const CardTitle = styled.h1`
  text-align: left;
  font: normal normal bold 26px/32px Montserrat;
  color: #000000;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
`

const ButtonGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
`

const HalfWidthBtn = styled(VerticalBtn)`
  width: calc(50% - 0.75rem);
`

const FullWidthBtn = styled(ButtonComponent)`
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 10px #00000029;
  border-radius: 5px;
  color: #000000;
  font-size: 1.3rem;
`

const VOCGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;

  & > * {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
  }

  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
`

const ColContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 1.5rem;
  width: 100%;

  & > * {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
  }

  @media (max-width: 1500px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${MEDIUM_SCREEN}) {
    grid-template-columns: 1fr;
  }
`

const WeekDaysCheckboxes = styled(Checkbox.Group)`
  .ant-checkbox-wrapper {
    font-size: 1.125rem;
    font-weight: 300;
    color: #000000;
  }
`

const LabelSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1rem;
  font-weight: 300;
  color: #000000;
  width: 100%;

  span::not(.ant-checkbox) {
    margin-bottom: 0.8rem;
  }

  .ant-row {
    width: 100%;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`

const LogSection = styled(LabelSection)`
  flex: 1 1 0;
`

const LogContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  flex: 1 0 0;
  box-shadow: 2px 3px 6px #00000029;
  padding: 0.625rem 1.25rem;
  font-size: 1.125rem;
  min-height: 250px;
  overflow: auto;
`

const LogInput = styled(Input)`
  padding: 0.5rem 11px;
  height: calc(30px + 0.5rem * 2);
  width: 100%;
  box-sizing: border-box;
  border: none;
  box-shadow: 2px 3px 6px #00000029;
  border-radius: 6px;
`

const Terminal = styled(CardWrapper)`
  background-color: #373737;
  color: #F6F6F6;
  margin: 0;
`

const TerminalInput = styled.textarea`
  background-color: #373737;
  border: none;
  color: #F6F6F6;
  text-align: left;
  resize: none;
`

const BoldDivider = styled(Divider)`
  .ant-divider-inner-text {
    display: inline-flex;
    align-items: center;
    font-weight: bold;
  }
`

const StyledTooltip = styled(Tooltip)`
  &.anticon.anticon-question-circle,
  .anticon.anticon-question-circle {
    margin-inline-start: ${({ withMargin = true }) => withMargin ? '4px' : 0};
  }
`

/**
 * @typedef CommandCenterProps
 * @type {object}
 *
 * @param {CommandCenterProps} props
 * @returns {React.ReactNode}
 */
const CommandCenter = ({ sensor }) => {
  const { spacings } = useTheme()
  const defaultCmd = { init: 'send command$   ', cmd: '' }
  const [cmd, setCmd] = useState([defaultCmd])
  const [cmdLoading, setCmdLoading] = useState(false)
  const { sendCommand: sendSensorCommand, sendUiCommand } = useSensors({ fetch: false })
  const { filterdLogs, params, updateParams } = useSensorLogs(sensor)
  const textareaRef = useRef()

  const sendCommand = (uiId, params) => sendUiCommand(sensor, uiId, params)

  const handleCommandSubmit = async values => {
    const { uiId, params = [] } = values

    await sendCommand(uiId, params)
  }

  useEffect(() => {
    textareaRef.current.scrollTop = textareaRef.current.scrollHeight
  }, [cmd])

  const handleCmdChange = e => {
    if (cmdLoading) return

    const value = e.target.value.split('\n')
    cmd[cmd.length - 1].cmd = value[value.length - 1].replace(defaultCmd.init, '')

    setCmd([...cmd])
  }

  const handleCmdKeyPress = async e => {
    if (cmdLoading) return e.preventDefault()

    if (e.keyCode === 8) {
      e.preventDefault()
      const val = cmd.pop()

      setCmd([
        ...cmd,
        {
          ...val,
          cmd: val.cmd.slice(0, -1)
        }
      ])
    } else if (e.keyCode === 13) {
      e.preventDefault()

      if (cmd[cmd.length - 1].cmd) {
        setCmdLoading(true)

        try {
          await sendSensorCommand(sensor, cmd[cmd.length - 1].cmd, null, { plain: true })

          setCmd([
            ...cmd,
            { cmd: 'Command successfully send' },
            defaultCmd
          ])
        } catch (err) {
          const errMsg = err?.response?.data?.message || ''

          setCmd([
            ...cmd,
            { cmd: `Error${errMsg ? `: ${errMsg}` : ''}` },
            defaultCmd
          ])
        } finally {
          setCmdLoading(false)
        }
      } else {
        setCmd([
          ...cmd,
          { cmd: 'Type a command' },
          defaultCmd
        ])
      }
    }
  }

  return (
    <CmdContainer>
      <TopGrid spacings={spacings}>
        {!sensor?.sensorType?.Commands?.length && <Empty description='No Commands' />}
        {sensor?.sensorType?.Commands?.length > 0 && sensor.sensorType.Commands.map(({ id, displayName, description = '', command, CommandParamUis = [] } = {}) => (
          <Fragment key={id}>
            {CommandParamUis?.length > 0 && (
              <CommandContainer>
                <CardTitle>
                  {displayName && <span>{displayName} ({command})</span>}
                  {!displayName && <span>{command}</span>}
                  {description && (
                    <StyledTooltip placement='bottomRight' title={description}>
                      <QuestionCircleOutlined />
                    </StyledTooltip>
                  )}
                </CardTitle>
                {CommandParamUis?.map(ui => {
                  return (
                    <div key={ui.id}>
                      <BoldDivider orientation='left' plain>
                        {(ui?.title || ui?.description) && (
                          <>
                            {ui?.title}
                            {ui?.description && (
                              <StyledTooltip title={ui.description} withMargin={ui?.title}>
                                <QuestionCircleOutlined />
                              </StyledTooltip>
                            )}
                          </>
                        )}
                      </BoldDivider>
                      <Command
                        onFinish={handleCommandSubmit}
                        layout='vertical'
                        initialValues={{
                          ...ui,
                          params: ui.params.map(({ defaultValue = null }) => defaultValue),
                          uiId: ui.id
                        }}
                      >
                        <Form.Item name='uiId' hidden>
                          <Input />
                        </Form.Item>
                        {ui.params.map((param, i) => {
                          const { comp: InputComponent = Input } = COMMAND_PARAM_USER_UI_OPTIONS
                            .find(({ value }) => param.uiComponent === value) ?? {}

                          return (
                            <Form.Item
                              key={`param${ui.id}${i}`}
                              label={param?.label}
                              tooltip={{
                                title: param?.description ?? param?.label
                              }}
                              name={['params', i]}
                              hidden={param?.value}
                            >
                              <InputComponent />
                            </Form.Item>
                          )
                        })}
                        <FullWidthBtn
                          key='submit'
                          htmlType='submit'
                          icon={<Icon iconName={ui.buttonIcon} />}
                        >
                          {ui.buttonName}
                        </FullWidthBtn>
                      </Command>
                    </div>
                  )
                })}
              </CommandContainer>
            )}
          </Fragment>
        ))}
      </TopGrid>
      <Log>
        <CardTitle>Log</CardTitle>
        <ColContainer>
          <Form
            initialValues={params}
            onFinish={vals => {
              // const dfUTCFn = getUTCString(null)

              updateParams({
                ...vals,
                days: vals?.days?.join(',')
                // timeFrom: dfUTCFn(vals.timeFrom),
                // timeTo: dfUTCFn(vals.timeTo)
              })
            }}
          >
            <LabelSection>
              <span>Days of the week</span>
              <Form.Item name='days'>
                <WeekDaysCheckboxes options={WEEK_DAYS} />
              </Form.Item>
            </LabelSection>
            {/* <ColContainer>
                <div> */
            }
            <LabelSection>
              <Spacing sb='xs'>
                <span>Van</span>
              </Spacing>
              <Form.Item name='timeFrom'>
                <LogInput placeholder='HH:MM' type='time' />
              </Form.Item>
            </LabelSection>
            {/* </div>
                <div>
                  <Dropdown
                    label='Set send interval'
                  />
                </div>
              </ColContainer>
              <ColContainer>
                <div> */
            }
            <LabelSection>
              <Spacing sb='xs'>
                <span>Tot</span>
              </Spacing>
              <Form.Item name='timeTo'>
                <LogInput placeholder='HH:MM' type='time' />
              </Form.Item>
            </LabelSection>
            <LabelSection>
              <Spacing sb='xs'>
                <span>Weeks to run</span>
              </Spacing>
              <Form.Item name='weeks'>
                <LogInput type='number' />
              </Form.Item>
            </LabelSection>
            {/* </div>
                <div>
                  <Dropdown
                    label='Set send interval'
                  />
                </div>
                </ColContainer> */
            }
            <ButtonComponent fill fontSize='1.25rem'>
                Accept
            </ButtonComponent>
          </Form>
          <div>
            <LogSection>
              <span>Log Messages</span>
              <LogContainer>
                {filterdLogs.length === 0 && 'Log messages will appear here.'}
                {filterdLogs.length > 0 && filterdLogs
                  .map(({ _time, time, _value, message }) => {
                    const date = new Date(_time ?? time)
                    return (
                      <>
                        <span>
                          <span>{format(date, 'eeeeee dd-MM-yy HH:mm:ss')} </span>
                          <span>{_value ?? message}</span>
                        </span>
                        <br />
                      </>
                    )
                  })}
              </LogContainer>
            </LogSection>
          </div>
        </ColContainer>
      </Log>
      <Terminal>
        <TerminalInput
          onInput={handleCmdChange}
          onKeyDown={handleCmdKeyPress}
          rows='10'
          value={
            cmd
              .map(({ init = '', cmd = '' }) => `${init}${cmd}`)
              .join('\n') +
              (cmdLoading ? '\n' : '')
          }
          ref={textareaRef}
        />
      </Terminal>
    </CmdContainer>
  )
}

export default CommandCenter
