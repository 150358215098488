import { navigate } from '@redwoodjs/router'
import { useState, useEffect } from 'react'
import AppLayout from 'src/layouts/AppLayout'
import AppHeader from 'src/components/AppHeader'
import AppContent from 'src/components/AppContent'
import { BackButton } from 'src/components/Button'
import AdminCards from 'src/components/AdminCards'
import PageTitle from 'src/components/AppTitle'
import AdminSensor from 'src/components/AdminSensor'
import AdminApiKeys from 'src/components/AdminApiKeys'
import AdminUsers from 'src/components/AdminUsers'
import AdminSensorTypes from 'src/components/AdminSensorTypes'
import AdminErrors from 'src/components/AdminErrors'
import AdminMissingData from 'src/components/AdminMissingData'
import AdminAlgorithms from 'src/components/AdminAlgorithms'
import SpaceList from 'src/components/SpaceList'
import AdminMachine from 'src/components/AdminMachine'

import styled from 'styled-components'
import { useAuth, useTenant, useUser } from 'src/hooks'
import AdminCommand from 'src/components/AdminCommand'
import AdminEvent from 'src/components/AdminEvent'
import AdminFlows from 'src/components/Flows'

const CardsWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: flex-start;
  align-content: flex-start;
`

const TenantName = styled.span`
  font-weight: 300;
`

const AdminPage = (props) => {
  const { type, redirectTo } = props
  const { tenant, loading: tenantLoading, getRoute, isTenantScope, tenantId } = useTenant(null, { fetchTenant: true })
  const { hasRole } = useAuth()
  const { getRedirectRoute } = useUser()
  const [parsedType, setParsedType] = useState('')

  const handleBack = async () => {
    if (redirectTo) {
      navigate(getRoute(redirectTo))
    } else if (type) {
      navigate(getRoute('admin'))
    } else {
      const route = await getRedirectRoute({ login: true, tenantId })
      navigate(route)
    }
  }

  useEffect(() => {
    if (type) {
      setParsedType(type.replace(/([a-z])([A-Z])/g, '$1 $2'))
    }
  }, [type])

  const renderComponent = () => {
    switch (type) {
      case 'machines': return <AdminMachine typeName={parsedType} />
      case 'sensors': return <AdminSensor typeName={parsedType} />
      case 'algorithms': return <AdminAlgorithms typeName={parsedType} />
      case 'spaces': return <SpaceList typeName={parsedType} />
      case 'apiKeys': return <AdminApiKeys typeName={parsedType} />
      case 'users': return <AdminUsers typeName={parsedType} />
      case 'sensorTypes': return <AdminSensorTypes typeName={parsedType} />
      case 'errors': return <AdminErrors typeName={parsedType} />
      case 'missingData': return <AdminMissingData typeName={parsedType} />
      case 'commands': return <AdminCommand typeName={parsedType} />
      case 'events': return <AdminEvent typeName={parsedType} />
      case 'flows': return <AdminFlows typeName={parsedType} />
      default: return <AdminCards />
    }
  }

  const NO_MARGIN_AND_PADDING_TYPES = ['flows']

  return (
    <AppLayout>
      <AppHeader noMargin={!type || NO_MARGIN_AND_PADDING_TYPES.includes(type)} transparent={!type}>
        <BackButton onClick={(e) => handleBack()} />
        <PageTitle noMargin italic={!type}>
          {type ? parsedType : 'Manage'}
          {(isTenantScope() && tenant?.name && !tenantLoading
            ? <TenantName>{tenantLoading ? '' : ` - ${tenant.name}`}</TenantName>
            : ''
          )}
          {(!isTenantScope() && hasRole('superAdmin')
            ? <TenantName> - Admin</TenantName>
            : ''
          )}
        </PageTitle>
      </AppHeader>
      <AppContent noPadding={NO_MARGIN_AND_PADDING_TYPES.includes(type)}>
        <CardsWrap>
          {type
            ? renderComponent()
            : <AdminCards />}
        </CardsWrap>
      </AppContent>
    </AppLayout>
  )
}

export default AdminPage
