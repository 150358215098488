import { Space, Statistic } from 'antd'
import { format } from 'date-fns'
import styled from 'styled-components'

const StyledStatistic = styled(Statistic)`
  width: 100%;
`

const dateFormatter = (val) => format(new Date(val), 'dd LLL yyyy \'at\' HH:mm:ss:SSSS')

/**
 * @typedef StatsTabProps
 * @type {object}
 * @property {string | Date} creationDate
 * @property {string | Date} executionStartedAt
 * @property {string | Date} executionFinishedAt
 * @property {number} executionTime
 * @property {string} trigger
 *
 * @param {StatsTabProps} props
 * @returns {React.ReactNode}
 */

const StatsTab = ({ creationDate, executionStartedAt, executionFinishedAt, executionTime, trigger }) => {
  return (
    <Space
      direction='vertical'
      style={{
        width: '100%'
      }}
    >
      {trigger && (
        <StyledStatistic
          title='Triggered by'
          value={trigger}
        />
      )}
      {creationDate && (
        <StyledStatistic
          title='Created at'
          value={creationDate}
          formatter={dateFormatter}
        />
      )}
      {executionStartedAt && (
        <StyledStatistic
          title='Execution started at'
          value={executionStartedAt}
          formatter={dateFormatter}
        />
      )}
      {executionFinishedAt && (
        <StyledStatistic
          title='Execution finished at'
          value={executionFinishedAt}
          formatter={dateFormatter}
        />
      )}
      {executionTime && (
        <StyledStatistic
          title='Execution time'
          value={executionTime / 1000}
          precision={4}
          suffix='s'
        />
      )}
    </Space>
  )
}

export default StatsTab
