export const getUserObject = (user = {}) => ({
  tenants: [],
  ...user,
  admin: !!(user?.admin),
  superAdmin: !!(user?.admin)
})

export const getCachedUser = () => {
  // eslint-disable-next-line no-undef
  const cachedUser = localStorage.getItem('user')
  const defaultUser = cachedUser ? JSON.parse(cachedUser) : {}

  return getUserObject(defaultUser)
}

export class CachedUser {
  #user

  constructor() {
    this.getCachedUser()
  }

  getUserObject = (user = {}) => ({
    tenants: [],
    roles: [],
    ...user,
    admin: !!(user?.admin),
    superAdmin: !!(user?.admin)
  })

  getCachedUser = () => {
    const cachedUser = localStorage.getItem('user')
    const defaultUser = cachedUser ? JSON.parse(cachedUser) : {}

    this.#user = cachedUser  ? this.getUserObject(defaultUser) : null
  }

  set = user => {
    const cachedUser = user ? this.getUserObject(user) : null
    user ? localStorage.setItem('user', JSON.stringify(cachedUser)) : this.remove()

    this.#user = cachedUser

    return this.#user
  }

  get = () => this.#user

  remove = () => {
    localStorage.removeItem('user')
    this.#user = null

    return this.#user
  }
}

export default new CachedUser()
