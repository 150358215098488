import { useState, useEffect } from 'react'
import Charts from 'src/components/Charts'
import StatsBar from 'src/components/StatsBar'
import FilterDropdown from 'src/components/FilterDropdown'
import { useSensor, useCurrentState, storeUnitsSettings } from 'src/hooks'
import styled from 'styled-components'
import { Select, DatePicker, Space, Tooltip, Empty, Spin, Button } from 'antd'
import { subDays, subMonths, subWeeks, subYears, subHours } from 'date-fns'
import { reduceToCSV } from 'src/utils'
import { saveAs } from 'file-saver'
import { DownloadOutlined } from '@ant-design/icons'

const { Option } = Select
const { RangePicker } = DatePicker

const Right = styled.div`
  flex: 2;
  display: flex;
  justify-content: flex-end;
`

const Center = styled.div`
  display: flex;
  justify-content: center;
`
const Left = styled.div`
  flex: 1;
  display: flex;
`

const Title = styled.p`
  font-size: 1.5rem;
  color: gray;
  padding-left: 1rem;
  font-weight: 300;
  margin: 0;
`

const SensorHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
  z-index: -1;
  box-shadow: 0 5px 5px rgba(0,0,0,0.05);
`

const intervals = {
  h4: 15,
  week: 3600 * 24 * 7,
  day: 3600 * 24,
  month: 3600 * 24 * 30,
  year: 3600 * 24 * 365,
  hour: 3600,
  minute: 60,
  second: 1
}

const periodPresets = {
  h4: {
    subFunc: (date) => subHours(date, 4),
    dateFormat: 'HH:mm',
    groupByDefault: 'minute'
  },
  minute: {
    dateFormat: 'HH:mm',
    groupByDefault: 'second'
  },
  hour: {
    dateFormat: 'HH:mm',
    groupByDefault: 'minute'
  },
  week: {
    subFunc: subWeeks,
    groupByDefault: 'day',
    dateFormat: 'd MMM'
  },
  month: {
    subFunc: subMonths,
    groupByDefault: 'week',
    dateFormat: "'W'w"
  },
  year: {
    subFunc: subYears,
    groupByDefault: 'month',
    dateFormat: 'MMM'
  },
  day: {
    subFunc: subDays,
    groupByDefault: 'hour',
    dateFormat: 'HH:mm'
  }
}

const SensorView = (props) => {
  const { sensorId } = props
  const { units, setUnits } = useCurrentState()

  const [period, setPeriod] = useState('h4')
  const isCustom = period === 'custom'
  const periodConfig = isCustom
    ? periodPresets.hour
    : periodPresets[period]
  const [groupedBy, setGroupedBy] = useState(periodConfig.groupByDefault)

  const [opts, setOpts] = useState({})
  const [dateRange, setDateRange] = useState([])
  const [momentStart, momentEnd] = dateRange

  const sensor = useSensor(sensorId, opts)
  const { loading } = sensor
  let dateTimeEnd
  let dateTimeStart
  const interval = intervals[groupedBy]
  useEffect(() => {
    if (!isCustom) {
      dateTimeEnd = new Date()
      dateTimeStart = periodConfig.subFunc(dateTimeEnd, 1)
    } else {
      dateTimeEnd = momentEnd && momentEnd.toDate()
      dateTimeStart = momentStart && momentStart.toDate()
    }
    if (dateTimeEnd && dateTimeStart) {
      setOpts({
        dateTimeEnd,
        dateTimeStart,
        interval
      })
    }
  }, [period, momentStart, momentEnd, interval])

  const handleCSVDownload = () => {
    const { File } = window
    const { temp, humidity } = sensor?.currentWeather?.main ?? {}
    const content = reduceToCSV(sensor.data, {
      location_temp: parseInt(Math.round(temp)),
      location_hum: parseInt(Math.round(humidity))
    })
    const fileName = `${sensor.name}-${new Date().toISOString()}.csv`
    const file = new File([content], fileName, { type: 'text/plain;charset=utf-8' })

    saveAs(file)
  }
  const handleDateChange = (dates) => {
    setDateRange(dates == null
      ? []
      : dates
    )
  }
  const handleGroupingChange = (groupBy) => {
    setGroupedBy(groupBy)
  }

  const handlePeriodChange = (period) => {
    setPeriod(period)
    const presetName = (period === 'custom')
      ? 'hour'
      : period
    const preset = periodPresets[presetName]
    setGroupedBy(preset.groupByDefault)
  }

  // elem = temp, hum, co2, etc...
  // type = comfortvalue, acceptable
  // values is generated from slider
  const setRefValues = (elem, type, values) => {
    const tempObject = {
      ...units,
      [elem]: {
        ...units[elem],
        [type]: { min: values[0], max: values[1] }
      }
    }
    setUnits(tempObject)
  }

  return (
    <>
      <SensorHeader>
        <Left>
          <Title>{sensor?.location || 'No Location'}</Title>
        </Left>
        <Right>
          <Space>
            {isCustom && (
              <Space>
                <RangePicker onChange={handleDateChange} value={dateRange} showTime />
              </Space>
            )}
            <Select defaultValue='day' value={groupedBy} onChange={handleGroupingChange}>
              <Option value='minute'>By Minute</Option>
              <Option value='hour'>By Hour</Option>
              <Option value='day'>By Day</Option>
              <Option value='week'>By Week</Option>
              <Option value='month'>By Month</Option>
              <Option value='year'>By Year</Option>
            </Select>
            <Select value={period} onChange={handlePeriodChange}>
              <Option value='h4'>Last 4H</Option>
              <Option value='day'>Last day</Option>
              <Option value='week'>Last week</Option>
              <Option value='month'>Last month</Option>
              <Option value='year'>Last year</Option>
              <Option value='custom'>Custom</Option>
            </Select>
            <FilterDropdown
              units={units}
              setRefValues={setRefValues}
            />
            <Tooltip placement='left' title='Download as CSV'>
              <Button shape='circle' icon={<DownloadOutlined />} onClick={handleCSVDownload} />
            </Tooltip>
          </Space>
        </Right>
      </SensorHeader>
      {(!loading)
        ? (sensor.data.length > 0)
          ? (
            <>
              <StatsBar sensor={sensor} />
              <Charts
                sensor={sensor}
                periodConfig={periodConfig}
              />
            </>
          )
          : <Center><Empty style={{ margin: '3rem 0' }} description='No data available in the selected period' /></Center>
        : <Center><Spin style={{ margin: '3rem auto' }} /></Center>}
    </>
  )
}

export default SensorView
