import { navigate, routes } from '@redwoodjs/router'
import React from 'react'
import { Tag, Dropdown, Menu } from 'antd'

import AppContent from 'src/components/AppContent/AppContent'
import AppHeader from 'src/components/AppHeader/AppHeader'
import AppTitle from 'src/components/AppTitle'
import AppLayout from 'src/layouts/AppLayout/AppLayout'
import { BackButton } from 'src/components/Button/Button'
import { useAdminCommands } from 'src/hooks'
import styled from 'styled-components'
import AdminList from 'src/components/AdminList/AdminList'
import AdminAddParamUiModal from 'src/components/AdminAddParamUiModal'
import DeleteCheckModal from 'src/components/DeleteCheckModal'

const Title = styled(AppTitle)`
  display: inline-flex;
  align-items: center;
`

const CmdTag = styled(Tag)`
  display: inline-block;
  margin: 0 1em;
  font-size: 0.5em;
  padding: 0.4em 0.5em;
  line-height: 0.5em;
  text-transform: lowercase;
`

const Content = styled(AppContent)`
  padding: 1rem;
  justify-content: center;
`

const DropdownLink = styled.a`
  font-size: 1.5rem;
  color: ${props => props?.colors?.['card.menu.color'] || '#C4C4C4'};
  min-width: 25px;
  display: flex;
  justify-content: center;
`

const MenuItem = styled(Menu.Item)`
  text-align: center;

  button {
    width: 100%;
  }
`

/**
 * @typedef AdminCommandPageProps
 * @type {object}
 *
 * @param {AdminCommandPageProps} props
 * @returns {React.ReactNode}
 */

const AdminCommandPage = ({ id }) => {
  const { loading, command, refresh, deleteCommandParamUi } = useAdminCommands({ id })

  const handleDelete = (record) => {
    deleteCommandParamUi(record.id)
      .then(() => refresh())
  }

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id'
    },
    {
      title: 'Command',
      dataIndex: 'params',
      render: params => `${command.command} ${params?.map?.(({ value }) => value ?? '{user_input}')?.join?.(' ') ?? ''}`
    },
    {
      title: 'Button name',
      dataIndex: 'buttonName'
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => {
        const menu = (
          <Menu>
            <MenuItem>
              <AdminAddParamUiModal
                shouldUpdate={refresh}
                tableData={command?.CommandParamUis}
                record={record}
                edit
              />
            </MenuItem>
            <MenuItem>
              <DeleteCheckModal
                titleText={`Are you sure you want to delete this command ${record?.displayName ? ` ${record?.displayName} (${record?.command})` : record?.command}?`}
                deleteFn={() => handleDelete(record)}
                deleteBtnText='Yes, delete'
                cancelBtnText='No, cancel'
              />
            </MenuItem>
          </Menu>
        )

        return (
          <div onClick={(e) => e.stopPropagation()}>
            <Dropdown
              overlay={menu}
              placement='bottomRight'
              trigger={['click']}
            >
              <DropdownLink className='ant-dropdown-link' onClick={e => e.stopPropagation()}>
                <i className='fas fa-ellipsis-v' />
              </DropdownLink>
            </Dropdown>
          </div>
        )
      }
    }
  ]

  return (
    <AppLayout>
      <AppHeader noMargin>
        <BackButton
          onClick={
            (e) => navigate(
              routes.adminContent({
                type: 'commands'
              }))
          }
        />
        <Title noMargin>
          {command?.displayName}
          <CmdTag color='blue'>
            {command?.command}
          </CmdTag>
        </Title>
      </AppHeader>
      <Content noPadding>
        <AdminList
          typeName='commandParamUis'
          data={command?.CommandParamUis ?? []}
          columns={columns}
          loading={loading}
          shouldUpdate={refresh}
          modalProps={{
            commandId: id
          }}
        />
      </Content>
    </AppLayout>
  )
}

export default AdminCommandPage
