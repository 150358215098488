import { createContext, useState, useEffect } from 'react'

export const Context = createContext()
export const Provider = (props) => {
  const { children } = props
  const [tenantId, setTenantId] = useState()
  const value = {
    tenantId,
    setTenantId
  }
  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )
}
