import { Collapse, Empty, Space, Statistic, Tabs } from 'antd'
import { format } from 'date-fns'
import React from 'react'
import ErrorTab from 'src/components/FlowEditor/SettingsDrawer/ErrorTab'
import MetaTab from 'src/components/FlowEditor/SettingsDrawer/MetaTab'
import StatsTab from 'src/components/FlowEditor/SettingsDrawer/StatsTab'
import LogTimeline from 'src/components/LogTimeline'
import Spin from 'src/components/Spin/Spin'
import { useAlgorithmFlowNodeLogs } from 'src/hooks/flow'
import styled from 'styled-components'

const StyledStatistic = styled(Statistic)`
  width: 100%;
`

const StyledCollapse = styled(Collapse)`
 border: none;
 margin: 0 20px 16px 4px;
 width: 100%;

 & > .ant-collapse-item {
   border: none;
 }

 & > .ant-collapse-item > .ant-collapse-header {
   color: rgba(0, 0, 0, 0.85);
 }
`

const Panel = styled(Collapse.Panel)`
 box-shadow: 3px 3px 10px 0 rgb(0 0 0 / 10%);
 border: none;
 background-color: #fff;
 margin-bottom: 16px;

 &.ant-collapse-item,
 &.ant-collapse-item:last-child {
   border-radius: 0.5rem;
 }
`

const StyledTabPane = styled(Tabs.TabPane)`
  padding-right: 16px;
`

const NotScrollableTab = styled(StyledTabPane)`
  overflow-y: hidden;
`

const NodeLogLogsTab = ({ creationDate, flowId, nodeId, nodeCreationDate }) => {
  const { nodeLogs: logs, loading } = useAlgorithmFlowNodeLogs(
    flowId,
    {
      limit: -1,
      context: 'flowNodeLog',
      order: 'ASC'
    },
    { creationDate, nodeId, nodeCreationDate }
  )

  if (loading) return <Spin />

  if (!Array.isArray(logs) || logs.length < 1) return <Empty />

  return (
    <StyledCollapse>
      {logs.map(log => (
        <Panel
          header={log.message}
          key={`${log.context}${log.contextId}`}
        >
          <Tabs>
            <StyledTabPane
              tab='Info'
              key='info'
            >
              <Space
                direction='vertical'
                style={{
                  width: '100%'
                }}
              >
                <StyledStatistic
                  title='Type'
                  value={log?.severity}
                />
                <StyledStatistic
                  title='Created at'
                  value={log?.time}
                  formatter={val => format(new Date(val), 'dd LLL yyyy \'at\' HH:mm:ss:SSSS')}
                />
              </Space>
            </StyledTabPane>
            {log?.meta && (
              <NotScrollableTab
                tab='Meta'
                key='meta'
              >
                <MetaTab value={log?.meta} />
              </NotScrollableTab>
            )}
            {log?.error && (
              <StyledTabPane
                tab='Error'
                key='error'
              >
                <ErrorTab {...log?.error} />
              </StyledTabPane>
            )}
          </Tabs>
        </Panel>
      ))}
    </StyledCollapse>
  )
}

/**
 * @typedef NodeLogTabProps
 * @type {object}
 *
 * @param {NodeLogTabProps} props
 * @returns {React.ReactNode}
 */

const NodeLogTab = ({ creationDate, flowId }) => {
  const { nodeLogs, loading } = useAlgorithmFlowNodeLogs(
    flowId,
    {
      limit: -1,
      context: 'flowNode',
      order: 'ASC'
    },
    { creationDate }
  )

  return (
    <LogTimeline logs={nodeLogs} loading={loading}>
      {log => (
        <Tabs>
          <StyledTabPane
            tab='Info'
            key='info'
          >
            <Space
              direction='vertical'
              style={{
                width: '100%'
              }}
            >
              <StyledStatistic
                title='Node Id'
                value={log?.meta?.node?.nodeId}
              />
              <StyledStatistic
                title='Node name'
                value={log?.meta?.node?.nodeName}
              />
            </Space>
          </StyledTabPane>
          <StyledTabPane
            tab='Stats'
            key='stats'
          >
            <StatsTab
              {...log?.meta?.stats ?? {}}
            />
          </StyledTabPane>
          <Tabs.TabPane
            tab='Logs'
            key='logs'
          >
            <NodeLogLogsTab
              flowId={flowId}
              creationDate={creationDate}
              nodeId={log?.meta?.node?.nodeId}
              nodeCreationDate={log?.meta?.stats?.creationDate}
            />
          </Tabs.TabPane>
          {log?.meta && (
            <NotScrollableTab
              tab='Meta'
              key='meta'
            >
              <MetaTab value={log?.meta} />
            </NotScrollableTab>
          )}
          {log?.error && (
            <StyledTabPane
              tab='Error'
              key='error'
            >
              <ErrorTab {...log.error} />
            </StyledTabPane>
          )}
        </Tabs>
      )}
    </LogTimeline>
  )
}

export default NodeLogTab
