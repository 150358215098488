import { message } from 'antd'
import { useCallback, useEffect, useRef, useState } from 'react'

import { useTenant } from 'src/hooks'
import { apiInstance as api } from 'src/api'
import { useIsMounted } from 'src/hooks/mounted'

export const usePlugins = (props = {}) => {
  const { fetch = true } = props
  const mounted = useIsMounted()
  const { tenantId, isValidScopeFetch } = useTenant()
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [plugins, setPlugins] = useState()

  const adminAddMachine = useCallback((machine) => {
    return new Promise((resolve, reject) => {
      return api.adminAddMachine(machine)
        .then((res) => {
          message.success(res.message || 'Added machine')
          resolve(res.data)
        })
        .catch((err) => {
          message.error(err?.response?.data?.errors?.[0]?.msg || 'Unable to add machine')
          reject(err)
        })
    })
  }, [])

  const adminUpdateMachine = useCallback((machineID, machine) => {
    return new Promise((resolve, reject) => {
      return api.adminUpdateMachine(machineID, machine)
        .then(res => {
          message.success(res.message || 'Machine updated')
          resolve(res.data)
        }).catch(err => {
          message.error(err?.response?.data?.errors?.[0]?.msg || 'Unable to update machine')
          reject(err)
        })
    })
  }, [])

  const adminDeleteMachine = useCallback((machine) => {
    return new Promise((resolve, reject) => {
      const { id } = machine
      return api.adminDeleteMachine(id)
        .then((res) => {
          message.success(res.message || 'Deleted machine')
          resolve(res.data)
        })
        .catch((err) => {
          message.error(err?.response?.data?.errors?.[0]?.msg || 'Unable to delete machine')
          reject(err)
        })
    })
  }, [])

  const getPlugins = useCallback(() => {
    return new Promise((resolve, reject) => {
      return api.getPlugins()
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  })

  const getPlugin = useCallback((id) => {
    return new Promise((resolve, reject) => {
      return api.getPlugin(id)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }, [])

  useEffect(() => {
    if (!fetch) return
    setLoading(true)
    api.getPlugins()
      .then(({ data, config }) => {
        if (mounted() && isValidScopeFetch(config)) {
          setPlugins(data)
        }
      })
      .catch(e => {
        if (mounted()) {
          setPlugins([])
          setError(e)
        }
      })
      .finally(() => {
        if (mounted()) setLoading(false)
      })
  }, [tenantId])

  return {
    adminDeleteMachine,
    adminUpdateMachine,
    getPlugin,
    getPlugins,
    adminAddMachine,
    loading,
    error,
    plugins
  }
}
