import { useEffect } from 'react'
import styled from 'styled-components'
import {
  Empty,
  Button as AntButton
} from 'antd'
import { navigate } from '@redwoodjs/router'
import { HomeFilled, MoreOutlined } from '@ant-design/icons'

import { getSpaceRelations } from 'src/libraries/spaces'

import BuildingCard from 'src/components/BuildingCard'
import ColorSpinner from 'src/components/ColorSpinner'
import { useAuth, useTenant } from 'src/hooks'
import { ACCESS_WRITE } from 'src/constants'

const COLOR_GOOD = '#8cca92'
const COLOR_BAD = '#ffb8b8'

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 767px){
    justify-content: center;
  }
`

const StatWrap = styled.div`
  margin-top: auto; /* push down remaining space of flex */
  display: flex;
  justify-self: flex-end;
  justify-content: space-between;
`

const StatContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  color:${props => props.color || 'white'};
`

const EmptyWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`
const TopRight = styled.div`
  position: absolute;
  top: 1em;
  right: 0;
`

const InfoRow = styled.div`
  display: flex;
  flex: 1;
  & > p {
    margin: 0;
    font-size: 1rem;
    font-style: italic;
  }
`

const InfoValue = styled.p`
  flex: 1;
  color: white;
`

const InfoKey = styled.p`
  flex: 3;
  color: lightgray;
`

const Value = styled.p`
  font-size: 2rem;
  margin: 0;
  font-style: italic;
  font-weight: 500;
`
const Subtitle = styled.p`
  font-size: 1rem;
  margin: 0;
`

const InfoWrap = styled.div`

`

const BuildingsGrid = ({
  spaces = [],
  loading,
  hideExtra = false,
  onExtraClick: handleExtraClick
}) => {
  const { getRoute, isTenantScope, tenantId } = useTenant()
  const { hasRole } = useAuth()
  const buildings = spaces && spaces.filter((s) => s.parentSpaceId == null)

  const handleClick = ({ id }) => (e) => {
    navigate(getRoute('space', { id: id }))
  }

  return (
    <CardContainer>
      {buildings && buildings.length > 0
        ? buildings.map((building, i) => {
          const relations = getSpaceRelations(spaces, building.id)
          const { rooms, floors, latestData, scores, issues } = relations
          const nRooms = rooms.length
          const nFloors = floors.length
          const nIssues = issues.length
          const displayIssues = issues.length || '-'
          const { comfort } = scores
          const comfortLevel = comfort
            ? `${parseInt(comfort, 10)}%`
            : '-'
          const { accessLevel, tenantAccessLevel } = building
          const editAccess =
            hasRole('superAdmin') ||
            (accessLevel && accessLevel >= ACCESS_WRITE) ||
            (
              isTenantScope() &&
              hasRole(`tenant.${tenantId}.${ACCESS_WRITE}`) &&
              tenantAccessLevel >= ACCESS_WRITE
            )
          return (
            <div key={i} onClick={handleClick(building)}>
              <BuildingCard
                background={building.coverImage}
                title={building.name}
                style={{
                  width: '300px',
                  position: 'relative'
                }}
                extra={
                  editAccess && !hideExtra &&
                    <TopRight>
                      <AntButton type='link' onClick={handleExtraClick(building)}>
                        <MoreOutlined style={{ color: 'white', fontSize: '1.5rem' }} />
                      </AntButton>
                    </TopRight>
                }
              >
                <InfoWrap>
                  <InfoRow>
                    <InfoValue>{nRooms}</InfoValue>
                    <InfoKey>Rooms</InfoKey>
                  </InfoRow>
                  <InfoRow>
                    <InfoValue>{nFloors}</InfoValue>
                    <InfoKey>Floors</InfoKey>
                  </InfoRow>
                </InfoWrap>
                <StatWrap>
                  {editAccess && (
                    <StatContainer color={nIssues > 0 ? COLOR_BAD : COLOR_GOOD}>
                      <Value>{displayIssues}</Value>
                      <Subtitle>Issues</Subtitle>
                    </StatContainer>
                  )}
                  <StatContainer color={comfort < 80 ? COLOR_BAD : COLOR_GOOD}>
                    <Value>{comfortLevel}</Value>
                    <Subtitle>Comfort</Subtitle>
                  </StatContainer>
                </StatWrap>
              </BuildingCard>
            </div>
          )
        }
        )
        : loading
          ? <ColorSpinner color='darkgray' />
          : (
            <EmptyWrap>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </EmptyWrap>
          )}
    </CardContainer>
  )
}

export default BuildingsGrid
