import { useState, useEffect } from 'react'
import { navigate } from '@redwoodjs/router'
import AppLayout from 'src/layouts/AppLayout'
import AppHeader from 'src/components/AppHeader'
import AppContent from 'src/components/AppContent'
import Button, { BackButton } from 'src/components/Button'
import AppTitle from 'src/components/AppTitle'
import Spin from 'src/components/Spin'
import CodeEditor from 'src/components/CodeEditor'
import { Tag as _Tag, Input, Switch, Tabs, Form, Collapse, Select, Divider } from 'antd'
import { DeleteFilled } from '@ant-design/icons'

import { useAuth, useTenant } from 'src/hooks'
import styled from 'styled-components'
import { useAlgorithmNode } from 'src/hooks/algorithmNodes'
import ButtonComponent from 'src/components/Button/Button'
import ConfigurableSettingFields from 'src/pages/AlgorithmNodePage/ConfigurableSettingFields'
import { useAlgorithmNodeTypes } from 'src/hooks/algorithmNodeTypes'
import ColorSpinner from 'src/components/ColorSpinner/ColorSpinner'
import { useAlgorithmNodeDataTypes } from 'src/hooks/algorithmNodeDataTypes'
import EventReplacementSettings from 'src/components/EventReplacmentSettings'
import { useAdminEvents } from 'src/hooks/event'

const TopRight = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
`

const Spacing = styled.div`
  margin-top: 1rem;
  display: flex;

`

const StyledCodeEditor = styled(CodeEditor)`
  background-color: #0d0d0d;
  border-radius: 10px;
  & > pre span.token {
    background-color: unset;
  }
`

const Wrapper = styled.div`
  justify-content: center;
  display: flex;
  flex: 1;
`

const StyledForm = styled(Form)`
  display: flex;
  flex: 1;
`

const StyledTabs = styled(Tabs)`
  flex: 1;

  .ant-tabs-nav {
    padding: 0 1rem;
    margin-bottom: 0;
  }

  .ant-tabs-content-holder {
    display: flex;
  }
`

const PaperTitle = styled.p`
  font-family: 'Brush Script MT';
  font-size: 2rem;
  margin: 0;
`
const PaperDesc = styled.p`
  font-weight: normal;
  font-size: 1rem;
  margin-bottom: 2rem;
  color: #ebfdff;
`
const FormLabel = styled.p`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`

const FormDescription = styled.p`
  font-weight: normal;
  color: white;
`

const Space = styled.div`
  padding-top: 2rem;
`
const Paper = styled.div`
  position: relative;
  padding: 1rem;
  flex: 1;
  color: white;
  max-width: 1280px;
  margin: 0 auto;
  font-weight: bold;
  box-shadow: 0 10px 25px rgba(0,0,0,0.25);
  border-radius: 14px;
  background-color: #fff;
`

const SaveBtn = styled(ButtonComponent)`
  margin-left: auto;
`

const Panel = styled(Collapse.Panel)`
  box-shadow: 3px 3px 10px 0 rgb(0 0 0 / 10%);
  margin-bottom: 1em;
  background-color: #fff;

  &.ant-collapse-item,
  &.ant-collapse-item:last-child {
    border-radius: 0.5rem;
  }
`

const AddBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1em;
`

const types = {
  UserFunction: `
    declare module "types/api" {
        export interface IApi {
        }
        export interface IGetSensorDataOptions {
            params: {
                datetime_start?: string;
                datetime_end?: string;
                interval?: number;
            };
        }
    }
    declare module "constants" {
        export const PROJECT_DIR: string;
        export const BASE_DIR: string;
    }
    declare module "libraries/env" { }
    declare module "config" {
        import "libraries/env";
        export const API_USERNAME: string, API_PASSWORD: string, API_ENDPOINT: string, PORT: string, HOSTNAME: string;
        const _default: {
            cloudgardenApi: {
                username: string;
                password: string;
                endpoint: string;
            };
            server: {
                port: number;
                host: string;
            };
        };
        export default _default;
    }
    declare module "services/BaseService" {
        import ServiceCollection from "services/ServiceCollection";
        export default abstract class BaseService {
            private readonly services;
            constructor(services: ReturnType<typeof ServiceCollection>);
            private readonly handleStart;
            start(): Promise<void>;
            protected getServices(): ReturnType<typeof ServiceCollection>;
            protected abstract init(): Promise<boolean> | boolean;
        }
    }
    declare module "services/api/BaseApi" {
        import { AxiosInstance, AxiosRequestConfig } from 'axios';
        import BaseService from "services/BaseService";
        export abstract class BaseApi extends BaseService {
            private api;
            private authenticated;
            protected init(): Promise<boolean>;
            protected abstract getConfig(): AxiosRequestConfig<any> | undefined;
            protected abstract authenticate(): Promise<void>;
            protected getApi(): AxiosInstance;
            getAuthenticated(): boolean;
        }
    }
    declare module "services/api/Cloudgarden/index" {
        import { AxiosInstance, AxiosRequestConfig } from 'axios';
        import ServiceCollection from "services/ServiceCollection";
        import { BaseApi } from "services/api/BaseApi";
        export class CouldgardenApiBase extends BaseApi {
            private static cloudgardenApi;
            private static autenticationClassName;
            private static authenticated;
            constructor(services: ReturnType<typeof ServiceCollection>);
            protected getConfig(): AxiosRequestConfig<any> | undefined;
            protected authenticate(): Promise<void>;
            protected getApi(): AxiosInstance;
            getAuthenticated(): boolean;
        }
        export default CouldgardenApiBase;
    }
    declare module "services/api/Cloudgarden/public" {
        import { IGetSensorDataOptions } from "types/api";
        import { CouldgardenApiBase } from "services/api/Cloudgarden/index";
        import { AxiosResponse } from 'axios';
        export class CouldgardenApiPublic extends CouldgardenApiBase {
            getSensorById(id: string | number | Array<string | number>): Promise<AxiosResponse>;
            getSensorData(sensorIds: string | string[], { params: { datetime_start, datetime_end, interval } }: IGetSensorDataOptions): Promise<AxiosResponse>;
            postCommandParamUi(sensorId: number, commandId: number, payload: {}): Promise<AxiosResponse>;
            updateSensor(sensor: Record<string, any>, { formSettings }?: Record<string, any>): Promise<AxiosResponse>;
        }
        export default CouldgardenApiPublic;
    }
    declare module "services/api/Cloudgarden/private" {
        import { Axios, AxiosResponse } from 'axios';
        import CouldgardenApiPublic from "services/api/Cloudgarden/public";
        export class CouldgardenApiPrivate extends CouldgardenApiPublic {
            postLog(payload: any): ReturnType<Axios['post']>;
            getAllAlgorithms({ params }?: {
                params?: {} | undefined;
            }): Promise<AxiosResponse>;
        }
        export default CouldgardenApiPrivate;
    }
    declare module "types/remoteLogger" {
        export interface ITags {
            context?: string;
            contextId?: string | number;
            severity?: 'log' | 'warning' | 'error' | 'success';
            source?: '@cloudgarden/algorithm-runner';
        }
        export interface ILog {
            message?: string;
            meta?: {
                [key: string | number]: any;
            };
            error?: Error;
        }
        export interface ILogQueue {
            points: {
                fields: ILog;
            };
            tags: ITags;
        }
    }
    declare module "services/RemoteLogger/RemoteLoggerBase" {
        import { ILog, ITags } from "types/remoteLogger";
        import BaseService from "services/BaseService";
        export default class RemoteLoggerBase extends BaseService {
            private static logQueue;
            private readonly defaultTags;
            protected init(): boolean;
            protected _log(fields: ILog, tags?: ITags): Promise<void>;
        }
    }
    declare module "services/RemoteLogger/RemoteLoggerPublic" {
        import { ILog, ITags } from "types/remoteLogger";
        import RemoteLoggerBase from "services/RemoteLogger/RemoteLoggerBase";
        export default class RemoteLoggerPublic extends RemoteLoggerBase {
            log(log: Omit<ILog, 'error'>, tags: Pick<ITags, 'context' | 'contextId'>): Promise<void>;
            success(log: Omit<ILog, 'error'>, tags: Pick<ITags, 'context' | 'contextId'>): Promise<void>;
            warn(log: Omit<ILog, 'error'>, tags: Pick<ITags, 'context' | 'contextId'>): Promise<void>;
            error(log: ILog, tags: Pick<ITags, 'context' | 'contextId'>): Promise<void>;
        }
    }
    declare module "services/RemoteLogger/RemoteLoggerPrivate" {
        import { ILog, ITags } from "types/remoteLogger";
        import RemoteLoggerPublic from "services/RemoteLogger/RemoteLoggerPublic";
        export default class RemoteLoggerPrivate extends RemoteLoggerPublic {
            extendedLog(log: ILog, tags: ITags): Promise<void>;
        }
    }
    declare module "services/index" {
        import CouldgardenApiPublic from "services/api/Cloudgarden/public";
        import CouldgardenApiPrivate from "services/api/Cloudgarden/private";
        import RemoteLoggerPublic from "services/RemoteLogger/RemoteLoggerPublic";
        import RemoteLoggerPrivate from "services/RemoteLogger/RemoteLoggerPrivate";
        const _default_1: {
            public: {
                CouldgardenApiPublic: typeof CouldgardenApiPublic;
            };
            private: {
                CouldgardenApiPrivate: typeof CouldgardenApiPrivate;
                RemoteLoggerPublic: typeof RemoteLoggerPublic;
                RemoteLoggerPrivate: typeof RemoteLoggerPrivate;
            };
        };
        export default _default_1;
    }
    declare module "types/collection" {
        export type InstanceOrType<T, D = undefined> = T extends new (...args: any) => infer R ? R : D;
        export type CollectionInstanceType<T> = {
            [K in keyof T extends string ? keyof T : never]: InstanceOrType<T[K], never>;
        };
        export type NamedCollectionInstanceType<T extends CollectionInstanceType<keyof T>> = {
            readonly [K in keyof T]: CollectionInstanceType<T[K]>;
        };
    }
    declare module "types/service" {
        import services from "services/index";
        import { NamedCollectionInstanceType } from "types/collection";
        export type ServicesType = typeof services;
        export type ServicesInstanceType = NamedCollectionInstanceType<ServicesType>;
        export type PublicServicesType = ServicesType['public'];
        export type PublicServicesInstanceType = ServicesInstanceType['public'];
        export type PublicServicesKeyUnion = keyof PublicServicesType;
        export type PublicServicesUnion = PublicServicesType[PublicServicesKeyUnion];
        export type PublicServicesInstanceUnion = PublicServicesInstanceType[PublicServicesKeyUnion];
        export type PrivateServicesType = ServicesType['private'];
        export type PrivateServicesInstanceType = ServicesInstanceType['private'];
        export type PrivateServicesKeyUnion = keyof PrivateServicesType;
        export type PrivateServicesUnion = PrivateServicesType[PrivateServicesKeyUnion];
        export type PrivateServicesInstanceUnion = PrivateServicesInstanceType[PrivateServicesKeyUnion];
    }
    declare module "services/ServiceCollection" {
        import { PrivateServicesInstanceType, PrivateServicesKeyUnion, PublicServicesInstanceType, PublicServicesKeyUnion } from "types/service";
        class ServiceCollection {
            private static instance;
            private readonly publicServices;
            private readonly privateServices;
            private ready;
            static getInstance: () => ServiceCollection;
            start(): Promise<void>;
            get isReady(): boolean;
            getPublicService<T extends PublicServicesKeyUnion>(key: T): PublicServicesInstanceType[T];
            getPrivateService<T extends PrivateServicesKeyUnion>(key: T): PrivateServicesInstanceType[T];
            getAllPublicService: () => PublicServicesInstanceType;
        }
        const _default_2: () => ServiceCollection;
        export default _default_2;
    }
    declare module "types/package" {
        import { Algorithm, AlgorithmNodeVariant } from "types/algorithm";
        export interface IPackageWrapperContext {
            algorithm: Algorithm;
            node: AlgorithmNodeVariant;
        }
    }
    declare module "packages/timeFns" {
        export type PeriodType = '4h' | 'day' | 'week' | 'month' | 'year';
        export interface IPeriodPreset {
            [key: string]: {
                subFunc: (date: Date, amount?: number) => Date;
            };
        }
        export const getStartDateFromPeriod: (endDate: Date, period: PeriodType) => Date;
    }
    declare module "packages/index" {
        import * as mathjs from 'mathjs';
        import { IPackageWrapperContext } from "types/package";
        import { ILog } from "types/remoteLogger";
        import * as timeFns from "packages/timeFns";
        type MathJsStatic = mathjs.MathJsStatic;
        const packages: {
            mathjs: mathjs.MathJsStatic;
            timeFns: typeof timeFns;
        };
        export type PackageType = typeof packages & {
            mathjs: MathJsStatic;
        };
        const _default_3: (ctx: IPackageWrapperContext) => {
            logger: {
                log: (log: Omit<ILog, 'error'>) => Promise<void>;
                success: (log: Omit<ILog, 'error'>) => Promise<void>;
                warn: (log: Omit<ILog, 'error'>) => Promise<void>;
                error: (log: ILog) => Promise<void>;
            };
            mathjs: mathjs.MathJsStatic;
            timeFns: typeof timeFns;
            CouldgardenApiPublic: import("services/api/Cloudgarden/public").CouldgardenApiPublic;
        };
        export default _default_3;
    }
    declare module "cache/NodeFunctionCache" {
        import { ExecutionFunction } from "types/algorithm";
        class NodeFunctionCache extends Map<number, ExecutionFunction> {
            add(key: number, code: string): ExecutionFunction | undefined;
        }
        const _default_4: NodeFunctionCache;
        export default _default_4;
    }
    declare module "models/ExecutionTree/ExecutionNode" {
        import { AlgorithmEdge, AlgorithmNodeHandleWithNodes, AlgorithmNodeVariant, ExecutionFunction, Algorithm } from "types/algorithm";
        import packages from "packages/index";
        export class ExecutionNode {
            private readonly algorithm;
            node: AlgorithmNodeVariant;
            handles: AlgorithmNodeHandleWithNodes;
            settings: {};
            fn: ExecutionFunction;
            children: ExecutionNode[];
            edges: AlgorithmEdge[];
            packages: ReturnType<typeof packages>;
            constructor(node: AlgorithmNodeVariant, settings: {}, algorithm: Algorithm, children?: ExecutionNode | ExecutionNode[], edges?: AlgorithmEdge[]);
            private getCompiledFn;
        }
    }
    declare module "types/algorithm" {
        import { Edge, HandleProps, Node } from 'react-flow-renderer';
        import { ExecutionNode } from "models/ExecutionTree/ExecutionNode";
        export interface AlgorithmNodeData {
            label: string;
        }
        export type AlgorithmNodeHandle = Omit<HandleProps, 'isValidConnection'> & {
            tag?: string;
        };
        export interface AlgorithmNodeType {
            id: number;
            name: string;
            handles?: AlgorithmNodeHandle[];
        }
        export interface AlgorithmNodeDataType {
            id: number;
            name: string;
            active: boolean;
        }
        export interface ConfigurableSetting {
            name: string;
            label: string;
            uiComponent: string;
            props: {};
        }
        export type AlgorithmNode = Pick<Node<AlgorithmNodeData>, 'data' | 'position'> & {
            AlgorithmNodeType: AlgorithmNodeType;
            name: string;
            id: number;
            description?: string;
            code: string;
            configurableSettings: ConfigurableSetting[];
            algorithmNodeTypeId: number;
            active: boolean;
            approved: boolean;
            passArray: boolean;
            saveNodeInDataType: boolean;
            inputAlgorithmNodeDataTypeId: number;
            outputAlgorithmNodeDataTypeId: number;
            OutputDataType?: AlgorithmNodeDataType;
            InputDataType?: AlgorithmNodeDataType;
        };
        export type AlgorithmNodeVariant = Pick<Node<AlgorithmNodeData>, 'id' | 'position' | 'data' | 'type'> & {
            AlgorithmNode: AlgorithmNode;
            algorithmId: number;
            algorithmNodeId: number;
        };
        export type AlgorithmEdge = Pick<Edge, 'id' | 'source' | 'sourceHandle' | 'target' | 'targetHandle'> & {
            algorithmId: number;
        };
        export interface Algorithm {
            AlgorithmEdges: AlgorithmEdge[];
            AlgorithmNodeVariants: AlgorithmNodeVariant[];
            nodeSettings: {
                [key: string]: any;
            };
            flow: boolean;
            active: boolean;
            global: boolean;
            id: number;
        }
        export interface AlgorithmNodeHandleWithNodes {
            [key: string]: AlgorithmNodeHandle & {
                nodes: ExecutionNode[];
            };
        }
        export interface ExecutionFunctionData {
            [key: AlgorithmNode['id']]: any;
        }
        export type ExecutionFunction = (this: ExecutionNode, data: ExecutionFunctionData, prevData: any, dataTypeValues: {
            [key: string]: any;
        }) => any;
    }
    declare module "types/userFunction" {
        import { ExecutionFunction } from "types/algorithm";
        export type UserFunctionType = ExecutionFunction;
    }


    type UserFunctionParameters = Parameters<import('types/algorithm').ExecutionFunction>

    declare const ctx: ThisParameterType<import('types/algorithm').ExecutionFunction>
    declare let data: UserFunctionParameters['0']
    declare let prevData: UserFunctionParameters['1']
    declare let dataTypeValues: UserFunctionParameters['2']
`
}

const AlgorithmNodePage = ({ id }) => {
  const isAddMode = id === 'add'
  const [form] = Form.useForm()
  const { getRoute, isTenantScope } = useTenant()
  const { hasRole } = useAuth()
  const { events, loading: eventsLoading } = useAdminEvents()
  const { algorithmNode, loading, addAlgorithmNode, updateAlgorithmNode } = useAlgorithmNode(id)
  const { algorithmNodeTypes, loading: algorithmNodeTypesLoading } = useAlgorithmNodeTypes()
  const { algorithmNodeDataTypes, loading: algorithmNodeDataTypesLoading } = useAlgorithmNodeDataTypes()

  const typeDropdownOptions = algorithmNodeTypes.map(({ name: label, id: value }) => ({ label, value }))
  const dataTypeDropdownOptions = algorithmNodeDataTypes.map(({ name: label, id: value }) => ({ label, value }))
  const outputAlgorithmNodeDataTypeName = algorithmNodeDataTypes
    .find(({ id }) => id === form.getFieldValue('outputAlgorithmNodeDataTypeId'))?.name ?? ''
  const dynamicOutputTypeKeyOptions = algorithmNode?.configurableSettings
    ?.map(({ label, name: value }) => ({ label, value })) ?? []
  const showDynamicOutputTypeKeyOptions = form.getFieldValue('outputAlgorithmNodeDataTypeId') != null &&
    (
      form.getFieldValue('inputAlgorithmNodeDataTypeId') === form.getFieldValue('outputAlgorithmNodeDataTypeId') ||
      form.getFieldValue('DataTypeDependencies')?.includes(form.getFieldValue('outputAlgorithmNodeDataTypeId')) > 0
    ) &&
    dynamicOutputTypeKeyOptions?.length > 0 &&
    !form.getFieldValue('saveNodeInDataType')

  console.log({ algorithmNode, dynamicOutputTypeKeyOptions },form.getFieldValue('DataTypeDependencies'))
  useEffect(() => {
    form.setFieldsValue({
      DataTypeDependencies: algorithmNode?.DataTypeDependencies?.map(({ id }) => id) ?? []
    })
  }, [algorithmNode?.DataTypeDependencies])

  const handleSave = values => {
    console.log({ values })
    if (id) {
      if (isAddMode) {
        addAlgorithmNode(values)
      } else {
        updateAlgorithmNode(id, values)
      }
    }
  }

  const renderDeleteBtn = clickHandler => (
    <DeleteFilled
      style={{
        color: '#d9363e'
      }}
      onClick={e => {
        e.stopPropagation()

        clickHandler()
      }}
    />
  )

  if (loading || eventsLoading) return <ColorSpinner />

  return (
    <StyledForm
      layout='vertical'
      id='addNodeType'
      onFinish={handleSave}
      form={form}
      initialValues={{
        ...algorithmNode,
        DataTypeDependencies: algorithmNode?.DataTypeDependencies?.map(({ id }) => id) ?? []
      }}
    >
      {() => (
        <AppLayout>
          <AppHeader noMargin>
            <BackButton onClick={(e) => navigate(getRoute('adminContent', { type: 'flows' }))} />
            <AppTitle noMargin>{form.getFieldValue('name')}</AppTitle>
            <SaveBtn
              key='submit'
              type='primary'
              htmlType='submit'
              form='addNodeType'
            >
              {isAddMode ? 'Add' : 'Save'}
            </SaveBtn>
          </AppHeader>
          <StyledTabs>
            <Tabs.TabPane tab='Settings' key='settings' forceRender>
              <AppContent>
                {loading && id
                  ? <Spin />
                  : (
                    <Wrapper>
                      <Paper>
                        <Form.Item
                          label='Name'
                          name='name'
                        >
                          <Input />
                        </Form.Item>
                        {hasRole('superAdmin') && (
                          <Form.Item
                            label='Approved'
                            name='approved'
                            valuePropName='checked'
                            tooltip={{
                              title: 'If false the node will not be compiled and all flows with that node will fail'
                            }}
                          >
                            <Switch />
                          </Form.Item>
                        )}
                        <Form.Item
                          label='Type'
                          name='algorithmNodeTypeId'
                        >
                          <Select
                            loading={algorithmNodeTypesLoading}
                            options={typeDropdownOptions}
                            placeholder='Type'
                          />
                        </Form.Item>
                        <Form.Item
                          label='Description'
                          name='description'
                          hasFeedback
                        >
                          <Input.TextArea
                            placeholder='Description'
                            allowClear
                          />
                        </Form.Item>
                        <Form.Item
                          label='Pass type through'
                          name='passThroughType'
                          hasFeedback
                          valuePropName='checked'
                          tooltip={{
                            title: 'If the node should use the next nodes input type for type checking. Like a if/else node.'
                          }}
                        >
                          <Switch />
                        </Form.Item>
                        {!form.getFieldValue('passThroughType') && (
                          <>
                            <Form.Item
                              label='Depends on node data types'
                              name='DataTypeDependencies'
                              tooltip={{
                                title: 'All node data types the node depends on.'
                              }}
                            >
                              <Select
                                mode='multiple'
                                placeholder='Depends on node data types'
                                options={dataTypeDropdownOptions}
                                loading={algorithmNodeDataTypesLoading}
                                allowClear
                              />
                            </Form.Item>
                            <Form.Item
                              label='Input type'
                              name='inputAlgorithmNodeDataTypeId'
                              hasFeedback
                              tooltip={{
                                title: 'The data type of prevData'
                              }}
                            >
                              <Select
                                placeholder='Input type'
                                options={dataTypeDropdownOptions}
                                loading={algorithmNodeDataTypesLoading}
                                allowClear
                              />
                            </Form.Item>
                            <Form.Item
                              label='Output type'
                              name='outputAlgorithmNodeDataTypeId'
                              hasFeedback
                            >
                              <Select
                                placeholder='Output type'
                                options={dataTypeDropdownOptions}
                                loading={algorithmNodeDataTypesLoading}
                                allowClear
                              />
                            </Form.Item>
                            {(
                                form.getFieldValue(['dynamicOutputTypeConfig', 'configSettingsKey']) == null &&
                                form.getFieldValue('outputAlgorithmNodeDataTypeId') != null
                              ) && (
                              <Form.Item
                                label={`Save node output as ${outputAlgorithmNodeDataTypeName}`}
                                name='saveNodeInDataType'
                                initialValue={false}
                                valuePropName='checked'
                                tooltip={{
                                  title: 'If activated the return value of this node will be saved in the dataTypeValues object'
                                }}
                              >
                                <Switch />
                              </Form.Item>
                            )}
                            {showDynamicOutputTypeKeyOptions && (
                              <Form.Item
                                label='Get output type field key from settings'
                                name={['dynamicOutputTypeConfig', 'configSettingsKey']}
                                tooltip={{
                                  title: `If set the output type of this node will be determined based on the (data type) "${outputAlgorithmNodeDataTypeName}" field with the name of the user value of the setting with the label of this field.`
                                }}
                              >
                                <Select
                                  placeholder='Dynamic output type'
                                  options={dynamicOutputTypeKeyOptions}
                                  loading={algorithmNodeDataTypesLoading}
                                  allowClear
                                />
                              </Form.Item>
                            )}
                          </>
                        )}
                        <Form.Item
                          label='Process array'
                          name='passArray'
                          hasFeedback
                          valuePropName='checked'
                        >
                          <Switch />
                        </Form.Item>
                        <Divider orientation='left'>
                          Configurable settings
                        </Divider>
                        <Form.List name='configurableSettings'>
                          {(fields, { add, remove }) => (
                            <>
                              <Collapse defaultActiveKey={[fields?.[fields.length - 1]?.key]} key={fields.length} ghost>
                                {fields.map(({ key, ...field }, i) => {
                                  return (
                                    <Panel
                                      header={`Setting: ${i + 1}`}
                                      key={key}
                                      forceRender
                                      extra={renderDeleteBtn(() => {
                                        remove(i)
                                      })}
                                    >
                                      <ConfigurableSettingFields form={form} field={field} currentIndex={i} />
                                    </Panel>
                                  )
                                })}
                              </Collapse>
                              <AddBtnContainer>
                                <Button
                                  onClick={e => {
                                    e.preventDefault()

                                    add()
                                  }}
                                >
                                    Add setting
                                </Button>
                              </AddBtnContainer>
                            </>
                          )}
                        </Form.List>
                        {events?.length > 0 && (
                    <Divider orientation='left'>
                      Event settings
                    </Divider>
                  )}
                  <EventReplacementSettings
                    events={events}
                    form={form}
                    replacementKeys={form.getFieldValue('configurableSettings')?.length > 0
                      ? form.getFieldValue('configurableSettings').map((setting = {}) => setting?.name)
                      : []
                    }
                  />
                      </Paper>
                    </Wrapper>
                  )}
              </AppContent>
            </Tabs.TabPane>
            <Tabs.TabPane tab='Code' key='code' forceRender>
              {loading && id
                ? <Spin />
                : (
                  <Form.Item
                    name='code'
                    noStyle
                  >
                    <CodeEditor types={types} />
                  </Form.Item>
                )}
            </Tabs.TabPane>
          </StyledTabs>
        </AppLayout>
      )}
    </StyledForm>
  )
}

export default AlgorithmNodePage
