import { Drawer, Empty } from 'antd'
import styled from 'styled-components'

import CardWrapper from 'src/components/CardWrapper/CardWrapper'
import Spin from 'src/components/Spin/Spin'

const ValidNode = styled(CardWrapper)`
  margin-left: 0;
  margin-right: 0;
`

const AvailableNodesDrawer = ({ drawerProps, currentValidNodes, loading, add }) => {
  const onDragStart = (event, node) => {
    event.dataTransfer.setData('application/reactflow', JSON.stringify(node))
    event.dataTransfer.effectAllowed = 'move'
  }

  return (
    <Drawer
      {...drawerProps}
      title='Nodes'
    >
      {!loading && !currentValidNodes?.length && <Empty />}
      {!loading && currentValidNodes?.length && currentValidNodes.map((node) => (
        <ValidNode
          key={node.id}
          className='dndnode'
          onDragStart={e => onDragStart(e, node)}
          draggable
        >
          {node.name}
        </ValidNode>
      ))}
      {loading && <Spin />}
    </Drawer>
  )
}

export default AvailableNodesDrawer
