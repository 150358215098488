import { message } from 'antd'
import { useContext, useEffect, useRef, useState } from 'react'
import Bugsnag from '@bugsnag/js'

import { FlowContext } from 'src/contexts/flow'
import { apiInstance as api } from 'src/api'
import { useAlgorithmNodes } from 'src/hooks/algorithmNodes'
import { useIsMounted, useTenant } from 'src/hooks'

export const useValidNodes = () => {
  const { algorithmNodes, loading: nodesLoading } = useAlgorithmNodes()
  const {
    currentNodes: [currentValidNodes, setCurrentNodes]
  } = useContext(FlowContext)

  const [loading, setLoading] = useState(false)

  const getNodes = async type => {
    setLoading(true)

    setCurrentNodes(algorithmNodes)

    setLoading(false)
  }

  const removeNodes = () => {
    setCurrentNodes([])
  }

  return {
    currentValidNodes,
    loading: loading || nodesLoading,
    getNodes,
    removeNodes
  }
}

export const useAlgorithmFlow = (flowId) => {
  const {
    nodeSettingsForm,
    flowSettingsForm,
    rfInstance: [rfInstance, setRfInstance],
    nodes: [nodes],
    settings: [settings],
    nodeTypes,
    nodesLoading,
    nodeTypesLoading,
    fetchNodes,
    fetchNodeTypes,
    handleAddNode,
    handleConnect,
    handleSave: save,
    handleDeleteNode,
    fetchLogs,
    flow,
    ...context
  } = useContext(FlowContext)

  const handleSave = () => {
    save(flowId)
  }

  const handleTrigger = async () => {
    if (flowId == null) throw new Error('Flow id can\'t be null')
    const hide = message.loading('Flow is running...')

    try {
      await nodeSettingsForm.validateFields()
      await flowSettingsForm.validateFields()

      const { data } = await api.triggerAlgorithm(flowId)

      hide()
      message.success('Flow run successful')
    } catch (e) {
      console.log(e)
      Bugsnag.notify(e)

      hide()
      message.error('Flow run failed')
    }
  }

  useEffect(() => {
    if (flowId == null || flowId == flow.id) return

    fetchNodes(flowId)
    fetchLogs(flowId)
  }, [flowId])

  return {
    nodeSettingsForm,
    flowSettingsForm,
    nodes,
    settings,
    nodeTypes,
    nodesLoading,
    nodeTypesLoading,
    loading: nodesLoading || nodeTypesLoading,
    rfInstance,
    setRfInstance,
    handleConnect,
    handleAddNode,
    handleSave,
    handleTrigger,
    handleDeleteNode,
    flow,
    ...context
  }
}

/**
  * @typedef useAlgorithmFlowNodeLogsParams
  * @type {object}
  * @property {?('flow' | 'flowNode' | 'flowNodeLog')} context
  * @property {?string} severity
  * @property {?number} limit
  * @property {?('ASC' | 'DESC')} order
  *
  * @typedef useAlgorithmFlowNodeLogsContextIdParams
  * @type {object}
  * @property {string} creationDate
  * @property {string | number} nodeId
  * @property {string} nodeCreationDate
  *
  * @param {string | number} flowId
  * @param {useAlgorithmFlowNodeLogsParams} params
  * @param {?useAlgorithmFlowNodeLogsContextIdParams} contextIdParams
  */
export const useAlgorithmFlowNodeLogs = (flowId, params, contextIdParams) => {
  const { isValidScopeFetch } = useTenant()
  const isMounted = useIsMounted()
  const [loading, setLoading] = useState(false)
  const [nodeLogs, setNodeLogs] = useState([])


  const fetchNodeLogs = async (id, flowParams, flowContextIdParams) => {
    id = id ?? flowId
    flowParams = flowParams ?? params
    contextIdParams = flowContextIdParams ?? contextIdParams

    if (id == null) return

    const { creationDate, nodeId, nodeCreationDate } = contextIdParams ?? {}

    const contextId = ([creationDate, nodeId, nodeCreationDate])
      .filter(Boolean)
      .join('|')

    setLoading(true)

    try {
      const { data = {}, config = {} } = await api.getFlowLogs(id, {
        ...flowParams,
        context: flowParams?.context ?? 'flow',
        contextId
      })

      if (isMounted() && isValidScopeFetch(config)) {
        setNodeLogs(data)
      }
    } catch (e) {
      Bugsnag.notify(e)
    } finally {
      if (isMounted()) setLoading(false)
    }
  }

  // @todo Acitvate App logs
  /* useEffect(() => {
    if (flowId == null) return

    fetchNodeLogs()
  }, [flowId, ...Object.values(params), ...Object.values(contextIdParams)]) */

  return {
    loading,
    nodeLogs
  }
}
