import Bugsnag from '@bugsnag/browser'
import { navigate } from '@redwoodjs/router'
import { message } from 'antd'
import jwtDecode from 'jwt-decode'

import { apiInstance as api } from 'src/api'
import { getRedirectRoute } from 'src/libraries/redirect'
import CachedToken from 'src/libraries/token'
import CachedUser from 'src/libraries/user'

/**
 *
 * @param {
 * {
 *    type: 'custom'
 *    client: import('src/api').apiInstance
 * } & import('@redwoodjs/auth').AuthClient
 * } authClient
 * @returns
 */
export const customAuth = () => ({
  type: 'custom',
  login: async ({ username, password }) => {
    try {
      const { token, user } = await api.login(username, password)
      const ctoken = CachedToken.set(token)
      CachedUser.set(user)
      api.updateInstanceAuthToken(ctoken)
      const route = await getRedirectRoute({ login: true })
      navigate(route)
    } catch (err) {
      message.error(err?.response?.status === 401
        ? 'Username and/or password incorrect'
        : 'Something went wrong'
      )

      Bugsnag.notify(err)
    }
  },
  logout: async () => {
    await api.logout()
    CachedUser.remove()
    CachedToken.remove()
    const route = await getRedirectRoute({ logout: true })
    navigate(route)
  },
  getToken: async () => CachedToken.get(),
  restoreAuthState: async () => {
    try {
      // eslint-disable-next-line no-undef
      const { exp } = jwtDecode(CachedToken.get())
      const margin = 86400000 // A Day

      if (exp && (exp * 1000 - margin) > Date.now()) {
        return CachedUser.get() ?? null
      }
    } catch {}

    return null
  },
  currentUser: async () => CachedUser.get() ?? null,
  getUserMetadata: async () => CachedUser.get() ?? null
})
