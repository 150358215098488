import { useEffect, useState } from 'react'

import { useIsMounted } from 'src/hooks'
import { apiInstance as api } from 'src/api'
import Bugsnag from '@bugsnag/browser'
import { message } from 'antd'

export const useAdminCommands = ({ fetch = true, id } = {}) => {
  const mounted = useIsMounted()
  const [commands, setCommands] = useState([])
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())

  const refresh = () => {
    if (mounted()) setLastUpdate(new Date())
  }

  const getCommands = async () => {
    setLoading(true)

    try {
      const { data } = typeof id === 'number'
        ? await api.getCommand(id)
        : await api.getCommands()

      if (mounted()) {
        setCommands(
          Array.isArray(data)
            ? data
            : [data]
        )
      }
    } catch (error) {
      Bugsnag.notify(error)
      if (mounted()) {
        setCommands([])
        setError(error)
      }
    } finally {
      if (mounted()) setLoading(false)
    }
  }

  useEffect(() => {
    if (!fetch) return

    getCommands()
  }, [lastUpdate])

  const addCommand = async payload => {
    setLoading(true)
    const hide = message.loading('Adding command...')

    try {
      await api.postCommand(payload)

      hide()
      message.success('Successfully added command')
    } catch (error) {
      Bugsnag.notify(error)
      message.error('Command couldn\'t be added')
      if (mounted()) setError()
    } finally {
      hide()
      setLoading(false)
    }
  }

  const updateCommand = async (payload, commandId = id) => {
    setLoading(true)
    const hide = message.loading('Updating command...')

    try {
      await api.patchCommand(commandId, payload)

      hide()
      message.success('Successfully updated command')
    } catch (error) {
      Bugsnag.notify(error)
      message.error('Command couldn\'t be updated')
      if (mounted()) setError()
    } finally {
      hide()
      setLoading(false)
    }
  }

  const deleteCommand = async (commandId = id) => {
    setLoading(true)
    const hide = message.loading('Deleting command...')

    try {
      await api.deleteCommand(commandId)

      hide()
      message.success('Successfully deleted command')
    } catch (error) {
      Bugsnag.notify(error)
      message.error('Command couldn\'t be deleted')
      if (mounted()) setError()
    } finally {
      hide()
      setLoading(false)
    }
  }

  const addCommandParamUi = async (payload, commandId = id) => {
    setLoading(true)
    const hide = message.loading('Adding param ui...')

    try {
      await api.postCommandParamUi(commandId, payload)

      hide()
      message.success('Successfully added param ui')
    } catch (error) {
      Bugsnag.notify(error)
      message.error('Param ui couldn\'t be added')
      if (mounted()) setError()
    } finally {
      hide()
      setLoading(false)
    }
  }

  const updateCommandParamUi = async (commandUiId, payload) => {
    console.log(commandUiId, payload)
    setLoading(true)
    const hide = message.loading('Updating command param ui...')

    try {
      await api.patchCommandParamUi(commandUiId, payload)

      hide()
      message.success('Successfully updated command param ui')
    } catch (error) {
      Bugsnag.notify(error)
      message.error('Command param ui couldn\'t be updated')
      if (mounted()) setError()
    } finally {
      hide()
      setLoading(false)
    }
  }

  const deleteCommandParamUi = async (commandUiId) => {
    setLoading(true)
    const hide = message.loading('Deleting command param ui...')

    try {
      await api.deleteCommandParamUi(commandUiId)

      hide()
      message.success('Successfully deleted command param ui')
    } catch (error) {
      Bugsnag.notify(error)
      message.error('Command param ui couldn\'t be deleted')
      if (mounted()) setError()
    } finally {
      hide()
      setLoading(false)
    }
  }

  return {
    commands,
    command: typeof id === 'number'
      ? commands?.[0]
      : null,
    loading,
    error,
    refresh,
    addCommand,
    updateCommand,
    deleteCommand,
    addCommandParamUi,
    updateCommandParamUi,
    deleteCommandParamUi
  }
}
