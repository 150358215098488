import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Checkbox, Radio, Space, Empty, Input } from 'antd'

import ClientCard from '../../components/ClientCard'
import Spacing from '../../components/Spacing'
import Dropdown from '../../components/Dropdown'
import SearchBar from '../../components/SearchBar'
import AddClientModal from '../../components/AddClientModal'
import Button from '../../components/Button/Button'
import { useDashboardStats, useTenants, useTheme, useUser, useTenantChart, useAuth, useCurrentState, useTenant } from 'src/hooks'
import ClientInfoCards from '../../components/InfoCards/ClientInfoCards'
import AppLayout from 'src/layouts/AppLayout'
import AppContent from 'src/components/AppContent'
import CardWrapper from 'src/components/CardWrapper/CardWrapper'
import ClientDashboardChart from 'src/components/LineChart/ClientDashboardChart'
import { SMALL_SCREEN, MIN_SMALL_SCREEN } from 'src/constants'
import ColorSpinner from 'src/components/ColorSpinner'
import { navigate, routes } from '@redwoodjs/router'
import UserAvatar from 'src/components/UserAvatar/UserAvatar'
import { getWeekOfMonth } from 'date-fns'
import * as c from 'src/constants'

const Content = styled(AppContent)`
  padding: ${({ spacings }) => spacings?.l ?? '1rem'} ${({ spacings }) => spacings?.l ?? '1rem'} 0;

  @media (max-width: ${SMALL_SCREEN}) {
    > * {
      width: 100%;
    }
  }
`

const Header = styled.header`
  padding: ${({ spacings }) => spacings?.l ?? '1rem'} ${({ spacings }) => spacings?.l ?? '1rem'} 0;
  display: flex;
  align-items: center;
  max-width: 1800px;
  margin: 0 auto;
  width: 100%;
  justify-content: flex-start;

  h1 {
    flex: 1 1 0;
  }

  @media(max-width: 990px) {
    flex-flow: column;
  }
`

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  flex: 0 0;

  @media(max-width: 990px) {
    margin-left: 0;
  }
`

const SpacedButton = styled(Button)`
  margin: 0.5em;
`

const H1 = styled.h1`
  font-family: 'Montserrat';
  font-size: 2.8rem;
  font-style: italic;
  font-weight: bold;
  color: #000000;
  margin-bottom: ${({ spacings }) => spacings?.l ?? '0'};
  padding: 2rem 0;

  span {
    font-weight: 300;
  }

  @media (max-width: ${SMALL_SCREEN}) {
    font-size: 1.75rem;
  }
`

const ChartContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;

  @media (max-width: 975px) {
    flex-direction: column;
  }
`

const ChartContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const ChartLeft = styled(ChartContent)`
  flex: 1 0 220px;

  @media (max-width: 975px) {
    flex: 0 0;
  }
`

const ChartRight = styled(ChartContent)`
  flex: 1 1 1000px;

  @media (max-width: 975px) {
    flex: 0 0;
  }
`

const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: ${({ spacings }) => spacings?.xs ?? 0} ${({ spacings }) => spacings?.l ?? 0};

  > * {
    flex: 1 1 300px;
  }
`

const CheckboxContainer = styled(Checkbox)`
  display: inline-flex;
  align-items: center;
  min-height: 48px;
`

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: ${({ spacings }) => spacings?.l ?? 0};
`

const BtnSpinnerContainer = styled.div`
  display: flex;
  padding-top: ${({ spacings }) => spacings?.l ?? '1rem'};
  justify-content: center;

  .ant-empty-normal {
    color: rgba(0, 0, 0, 0.6);
  }
`

const RadioGroupStyle = styled(Radio.Group)`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex: 0 1 100%;

  .ant-space-vertical {
    flex: 1 1 250px;
    overflow-y: auto;
    width: 100%;
  }
`

const RadioStyle = styled(Radio)`
  font-weight: 300;
`

const ChartRadioGroup = styled(Radio.Group)`
  margin-left: auto;
  min-height: 48px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 975px) {
    padding-top: 0.5rem;
    justify-content: center;
  }
`

const ChartRadioBtn = styled(Radio.Button)`
  font-weight: 300;
  color: #7297FF;

  &,
  &.ant-radio-button-wrapper:first-child{
    border: 0;
  }

  &.ant-radio-button-wrapper {
    border-radius: 4px;
  }

  &.ant-radio-button-wrapper:not(:first-child)::before {
    width: 0px;
  }
`

const Clients = styled.section`
  padding: ${({ spacings }) => spacings?.l ?? '1rem'};
  max-width: 1800px;
  margin: 0 auto;
  width: 100%;
`

const ClientsHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: ${({ spacings }) => spacings?.l ?? '1rem'};

  h1 {
    flex: 1 1 0;
  }
`

const ChartCard = styled(CardWrapper)`
  @media (min-width: ${MIN_SMALL_SCREEN}) {
    margin-right: 0;
  }
`

const Modal = styled(AddClientModal)`
  margin-left: auto;
  flex: 0 0;
`

const Logo = styled.img`
    height: 100%;
    max-height: 3em;
    width: auto;
`

const ClientDashboardPage = () => {
  const { client: { assets } } = useCurrentState()
  const { hasRole } = useAuth()
  const dropdownValues = [
    { label: 'Sensors', value: 'sensors' },
    { label: 'Comfort', value: 'comfort' },
    { label: 'Buildings', value: 'buildings' },
    { label: 'Floors', value: 'floors' },
    { label: 'Rooms', value: 'rooms' }
  ]
  const [params, setParams] = useState({
    s: '',
    order: 'name:ASC',
    onlyIssues: false
  })
  const [chartOptions, setChartOptions] = useState({
    type: 'sensors',
    period: 'last-week'
  })
  const [chartParams, setChartParams] = useState({
    s: ''
  })
  const { spacings, colors } = useTheme()
  const { stats, loading: statsLoading } = useDashboardStats()
  const {
    isTenantScope,
    tenantId,
    tenant,
    loading: tenantLoading
  } = useTenant(null, { fetchTenant: true })
  const { tenants: { data: tenantsForChart = [] }, loading: tenantsForChartLoading } = useTenants({ params: { ...chartParams, noPagination: 1 } })
  const { tenants: { data: tenants = [], meta }, loading: tenantsLoading, refresh, loadMore } = useTenants({ params })
  const { loading: chartLoading, data: chartData, lineKey, XDataKey, interval } = useTenantChart(chartOptions, tenantsForChartLoading)
  const { user, getRedirectRoute } = useUser()

  useEffect(() => {
    setChartOptions({ ...chartOptions, tenantId: tenantsForChart?.[0]?.id ?? null })
  }, [tenantsForChart])

  const getTimeFormatString = interval => date => {
    switch (interval) {
      case 'minute':
        return 'HH:mm'
      case 'hour':
        return 'HH:00'
      case 'week':
        return `0${getWeekOfMonth(date)}-MM`
      case 'month':
        return 'MM-yy'
      default:
        return null
    }
  }

  const tooltipFormatter = value => {
    switch (chartOptions.type) {
      case 'sensors':
        return [`${value} Sensor(s)`]
      case 'comfort':
        return [`${value}% Comfort`]
      case 'buildings':
        return [`${value} Building(s)`]
      case 'floors':
        return [`${value} Floor(s)`]
      case 'rooms':
        return [`${value} Room(s)`]
      default:
        return null
    }
  }

  return (
    <AppLayout>
      <Header spacings={spacings}>
        <Spacing sr='s' spacingType='padding'>
          <Logo src={assets?.logoSmall} alt='Logo' />
        </Spacing>
        <H1>
          Welcome{user?.displayname ? `, ${user.displayname}` : ''}
          <span>
            {`${(isTenantScope() && tenant?.name && !tenantLoading
              ? tenantLoading ? '' : ` - ${tenant.name} Clients`
              : ' - Clients'
            )}`}
          </span>
        </H1>
        <HeaderRight>
          <UserAvatar user={user} admin={hasRole('superAdmin')} app />
          {isTenantScope() && (
            <SpacedButton type='primary' onClick={() => navigate(routes.appTenant({ tenantId }))}>
              Client overview
            </SpacedButton>
          )}
          {hasRole('superAdmin') && !isTenantScope() && (
            <SpacedButton type='primary' onClick={() => navigate(routes.admin())}>
              Manage
            </SpacedButton>
          )}
          {hasRole(`tenant.${tenantId}.${c.ACCESS_WRITE}`) && isTenantScope() && (
            <SpacedButton type='primary' onClick={() => navigate(routes.adminTenant({ tenantId }))}>
              Manage{user?.tenants?.length > 1 || hasRole('superAdmin') ? ' client' : ''}
            </SpacedButton>
          )}
        </HeaderRight>
      </Header>
      <Content spacings={spacings}>
        <ClientInfoCards stats={stats} loading={statsLoading} />
        <ChartCard>
          <ChartContainer>
            <ChartLeft>
              <Spacing sb='l' spacingType='padding'>
                <Dropdown
                  defaultValue={chartOptions.type}
                  values={dropdownValues}
                  onChange={value => setChartOptions({ ...chartOptions, type: value })}
                />
              </Spacing>
              <Spacing sb='s' spacingType='padding'>
                <Input
                  placeholder='Search client'
                  name='search client chart'
                  value={chartParams.s}
                  onChange={e => {
                    e.preventDefault()
                    setChartParams({ ...chartParams, s: e.target.value })
                  }}
                />
              </Spacing>
              {!tenantsLoading && tenantsForChart.length < 1 && (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={`It looks like, you have no clients${chartParams.s ? ' matching your search' : ''}`}
                />
              )}
              {tenantsLoading && <ColorSpinner />}
              {!tenantsForChartLoading && chartOptions?.tenantId && (
                <RadioGroupStyle
                  onChange={e => setChartOptions({ ...chartOptions, tenantId: e.target.value })}
                  value={chartOptions.tenantId}
                >
                  <Space align='start' direction='vertical'>
                    {tenantsForChart.map(({ id, name = '' }) => (
                      <RadioStyle
                        key={'chart' + id}
                        value={id}
                      >
                        {name}
                      </RadioStyle>
                    ))}
                  </Space>
                </RadioGroupStyle>
              )}
            </ChartLeft>
            <ChartRight>
              <Spacing sb='l' spacingType='padding'>
                <ChartRadioGroup
                  defaultValue={chartOptions.period}
                  onChange={e => setChartOptions({ ...chartOptions, period: e.target.value })}
                  buttonStyle='solid'
                >
                  <ChartRadioBtn value='last-4hours'>4h</ChartRadioBtn>
                  <ChartRadioBtn value='last-day'>24h</ChartRadioBtn>
                  <ChartRadioBtn value='last-week'>1w</ChartRadioBtn>
                  <ChartRadioBtn value='last-month'>1m</ChartRadioBtn>
                  <ChartRadioBtn value='last-year'>1y</ChartRadioBtn>
                </ChartRadioGroup>
              </Spacing>
              <ClientDashboardChart
                loading={chartLoading}
                data={chartData}
                lineKey={lineKey}
                XDataKey={XDataKey}
                getTimeFormatString={getTimeFormatString(interval)}
                tooltipFormatter={tooltipFormatter}
                chartName={dropdownValues.find(({ value }) => value === chartOptions.type)?.label}
                yAxisProps={{
                  allowDecimals: chartOptions.type === 'comfort'
                }}
              />
            </ChartRight>
          </ChartContainer>
        </ChartCard>
      </Content>
      <Clients spacings={spacings}>
        <ClientsHeader spacings={spacings}>
          <H1>Clients</H1>
          {(
            hasRole('superAdmin') ||
            (isTenantScope() && hasRole(`tenant.${tenantId}.${c.ACCESS_WRITE}`))
          ) && (
              <Modal shouldUpdate={refresh} />
            )}
        </ClientsHeader>
        <Spacing sb='m'>
          <OptionsContainer spacings={spacings}>
            <SearchBar
              label='Search client'
              name='search'
              value={params.s}
              onChange={e => {
                e.preventDefault()
                setParams({ ...params, s: e.target.value })
              }}
            />
            <Dropdown
              label='Sort by'
              defaultValue={params.order}
              values={[
                { label: '↑ Name', value: 'name:ASC' },
                { label: '↓ Name', value: 'name:DESC' },
                { label: '↑ Creation date', value: 'createdAt:ASC' },
                { label: '↓ Creation date', value: 'createdAt:DESC' },
                { label: '↑ Update date', value: 'updatedAt:ASC' },
                { label: '↓ Update date', value: 'updatedAt:DESC' }
              ]}
              onChange={value => setParams({ ...params, order: value })}
            />
            <CheckboxContainer
              checked={!!params?.onlyIssues}
              onChange={e => setParams({ ...params, onlyIssues: !!e?.target?.checked })}
            >
              Only show issues
            </CheckboxContainer>
          </OptionsContainer>
        </Spacing>
        {tenants?.length > 0 && (
          <CardGrid spacings={spacings}>
            {tenants.map(tenant => (
              <ClientCard
                key={'grid' + tenant?.id}
                title={tenant?.name ?? ''}
                logo={tenant?.logoUrl}
                record={tenant}
                shouldUpdate={refresh}
                stats={[
                  { label: 'Buildings', count: tenant?.buildingCount ?? 0 },
                  { label: 'Floors', count: tenant?.floorCount ?? 0 },
                  { label: 'Rooms', count: tenant?.roomCount ?? 0 }
                ]}
                btn={(
                  <Button
                    full
                    onClick={async () => {
                      const route = await getRedirectRoute({ login: true, tenantId: tenant.id, allowSubTenants: tenant?.allowSubTenants })
                      console.log({ route })
                      navigate(route)
                    }}
                  >
                    View client
                  </Button>
                )}
                btnSecondary={(
                  <Button
                    outline
                    onClick={() =>
                      navigate(routes.adminContentTenant({
                        tenantId: tenant?.id,
                        type: 'errors'
                      }))}
                  >
                    View issues
                  </Button>
                )}
              />
            ))}
          </CardGrid>
        )}
        <BtnSpinnerContainer spacings={tenants?.length > 0 ? spacings : null}>
          {!tenantsLoading && tenants.length < 1 && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={`It looks like, you have no clients${params.s ? ' matching your criterias' : ''}`}
            />
          )}
          {tenantsLoading && <ColorSpinner color={colors['card.menu.color']} />}
          {!tenantsLoading && meta?.nextPage && (
            <Button
              type='primary'
              onClick={() => loadMore()}
            >
              Load more
            </Button>
          )}
        </BtnSpinnerContainer>
      </Clients>
    </AppLayout>
  )
}

export default ClientDashboardPage
