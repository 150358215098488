/**
 *
 * @param {import('react-flow-renderer').Node} node
 * @returns {boolean}
 */
export const hasNodeTargetHandles = node => !!node?.AlgorithmNode?.AlgorithmNodeType?.handles
  ?.find(handle => handle?.type === 'target')

/**
 *
 * @param {import('react-flow-renderer').Node} node
 * @param {?Array} errors
 * @param {?number} errorIndex
 */
export const setNodeError = (node, error, errorIndex) => {
  if (errorIndex != null && errorIndex !== -1) {
    node.data.errorMsg[errorIndex] = error
  } else {
    node.data = {
      ...node?.data ?? {},
      errorMsg: [
        ...node?.data?.errorMsg ?? [],
        ...error != null ? [error] : []
      ]
    }
  }
}

export const deleteNodeError = (node, errorIndex) => {
  if (!Array.isArray(node?.data?.errorMsg)) return

  if (errorIndex !== -1) {
    node?.data.errorMsg?.splice(errorIndex, 1)
  }

  if (node?.data?.errorMsg?.length === 0) {
    delete node.data.errorMsg
  }
}