import { Input } from 'antd'
import React from 'react'
import Cron from 'react-js-cron'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;

  & > .ant-input,
  & > label {
      margin-bottom: 10px;
    }
`

const StyledCron = styled(Cron)`
  &,
  & > div,
  .react-js-cron-field {
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
  }

  .react-js-cron-field {
    width: 100%;

    & > span {
      margin-bottom: 10px;
    }
  }

  .react-js-cron-clear-button {
    width: 100%;
    margin: 0;
  }

  .react-js-cron-select {
    margin-left: 0;
  }
`

/**
 * @typedef CronInputProps
 * @type {object}
 * @property {?string} cronLabel
 *
 * @param {CronInputProps} props
 * @returns {React.ReactNode}
 */

const CronInput = ({
  cronLabel = 'Cron expression',
  onChange,
  ...props
}) => {
  return (
    <Container>
      <label>{cronLabel}</label>
      <Input {...props} onChange={onChange} />
      <StyledCron {...props} setValue={onChange} />
    </Container>
  )
}

export default CronInput
