import { Form, Empty, Switch, Divider, Button, Input } from 'antd'
import React from 'react'
import styled from 'styled-components'

import { ApiInputComponentFactory } from 'src/components/ApiInputComponents'
import { useAuth, useTenant } from 'src/hooks'
import * as c from 'src/constants'
import { isNode } from 'react-flow-renderer'
import { useAlgorithmFlow } from 'src/hooks/flow'

const SettingsContainer = styled.div`
  flex: 1;
`

const NodeContainer = styled.div`
  display: ${({ visible }) => visible ? 'flex' : 'none'};
  flex-direction: column;
  flex: 1;
`

/**
 * @typedef NodeSettingsTabProps
 * @type {object}
 *
 * @param {NodeSettingsTabProps} props
 * @returns {React.ReactNode}
 */

const NodeSettingsTab = ({ handleNodeSettingValueUpdate, visibleNodeId, nodes = [], handleDelete, settings, nodeSettingsForm, flowId }) => {
  const { handleNodeUpdateByKey, nodeVariantsForm } = useAlgorithmFlow(flowId)
  const { isTenantScope, tenantId } = useTenant()
  const { hasRole } = useAuth()
  const showDefaultSwitch = hasRole('superAdmin') || (isTenantScope() && hasRole(`tenant.${tenantId}.${c.ACCESS_WRITE}`))

  if (nodes.length === 0) return <Empty description='No nodes.' />

  if (!settings && flowId !== 'add') return <Empty description='No Settings' />

  return (
    <>
      {
        nodes.map(node => {
          if (!isNode(node)) return null

          const settingsConfig = node?.AlgorithmNode?.configurableSettings ?? []

          return (
            <NodeContainer key={`NodeContainer${node.id}`} visible={visibleNodeId === node.id}>
              <SettingsContainer>
                <Form
                  key={`nodeVariantsForm${node.id}`}
                  form={nodeVariantsForm}
                  layout='vertical'
                  validateTrigger={[]}
                  /**
                   * @todo Causes performance issues. Activate when fixed
                   */
                  /*onFieldsChange={vals => {
                    console.log({ vals })
                    vals.forEach(({ name: [key, fieldKey], value }) => handleNodeUpdateByKey(
                      key,
                      {
                        [fieldKey]: value,
                        ...fieldKey === 'name'
                          ? {
                            data: {
                              label: value
                            }
                          }
                          : {}
                      }
                    ))
                  }}*/
                >
                  <Form.Item
                    label='Node name'
                    name={[node.id, 'name']}
                    initialValue={node.name}
                  >
                    <Input placeholder='Custom name' />
                  </Form.Item>
                </Form>
                <Divider orientation='left'>
                  Settings
                </Divider>
                <Form
                  key={`nodeSettingsForm${node.id}`}
                  form={nodeSettingsForm}
                  layout='vertical'
                  onValuesChange={() => handleNodeSettingValueUpdate()}
                  validateTrigger={[]}
                >
                  {() => (
                    <>
                      {settingsConfig?.length > 0 && settingsConfig.map(({ label, name = '', uiComponent, allowNull, props }, i) => {
                        const { value, setByRole, default: defaultValue } = settings?.[node.id]?.[name] ?? {}
                        const editable = hasRole('superAdmin') || !value || (value && (hasRole(setByRole) || defaultValue))

                        return (
                          <>
                            <ApiInputComponentFactory
                              component={uiComponent}
                              {...props ?? {}}
                              label={label ?? name}
                              name={[node.id, name, 'value']}
                              {...editable ? { initialValue: value } : {}}
                              {...!editable ? { defaultValue: value } : {}}
                              disabled={!editable}
                              formInstance={nodeSettingsForm}
                              required={!allowNull}
                              rules={[
                                {
                                  required: !allowNull,
                                  message: `The field "${label ?? name}" is required`
                                }
                              ]}
                            />
                            {showDefaultSwitch && editable && (
                              <Form.Item
                                key={`${name}Default`}
                                label='Default value'
                                name={[node.id, name, 'default']}
                                valuePropName='checked'
                                initialValue={defaultValue}
                              >
                                <Switch />
                              </Form.Item>
                            )}
                            {i < settingsConfig.length - 1 && <Divider />}
                          </>
                        )
                      })}
                    </>
                  )}
                </Form>
              </SettingsContainer>
              <Button
                danger
                type='ghost'
                onClick={() => handleDelete(node)}
              >
                {`Delete ${node?.AlgorithmNode?.name ?? 'Node'}`}
              </Button>
            </NodeContainer>
          )
        })
      }
    </>
  )
}

export default NodeSettingsTab
