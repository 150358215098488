import Editor from '@monaco-editor/react'
import React from 'react'

/**
 * @param {import('@monaco-editor/react').EditorProps} props
 * @returns {React.ReactNode}
 */
const CodeEditor = (props) => {
  const { defaultProps = {}, onChange, types = {}, ...editorPropsWithoutDefaults } = props
  const propsWithDefaults = {
    defaultLanguage: 'javascript',
    defaultValue: '',
    theme: 'vs-dark',

    onChange: typeof onChange === 'function'
      ? onChange
      : console.log,
    ...defaultProps
  }

  const handleEditorWillMount = (monaco) => {
    Object.entries(types).forEach(([name, type]) => monaco.languages.typescript.javascriptDefaults.addExtraLib(type, name))
  }

  return (
    <Editor
      {...propsWithDefaults}
      {...editorPropsWithoutDefaults}
      beforeMount={handleEditorWillMount}
    />
  )
}

export default CodeEditor
