import { useEffect, useState } from 'react'

import { apiInstance as api } from 'src/api'
import { useIsMounted } from 'src/hooks/mounted'
import { useIssues, useUser } from 'src/hooks'

export const useDashboardStats = () => {
  const isMounted = useIsMounted()
  const [loading, setLoading] = useState(false)
  const [stats, setStats] = useState({})
  const { user } = useUser()
  const { issueCount } = useIssues({ user })
  const updateStats = async () => {
    setLoading(true)

    try {
      const { data } = await api.getTenantStats()
      if (isMounted()) setStats(data)
    } catch (e) {
      console.log(e)
    } finally {
      if (isMounted()) setLoading(false)
    }
  }

  useEffect(() => {
    updateStats()
  }, [])

  return {
    loading,
    stats: {
      ...stats,
      machineIssues: issueCount
    }
  }
}
