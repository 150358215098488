import { Dropdown, Menu } from 'antd'
import React from 'react'
import styled from 'styled-components'

import { SMALL_SCREEN } from '../../constants'
import AddClientModal from '../AddClientModal'
import CardWrapper from '../CardWrapper'
import DeleteCheckModal from '../DeleteCheckModal'
import Spacing from '../Spacing'
import { useTenants } from 'src/hooks'
import Bugsnag from '@bugsnag/js'

const Card = styled(CardWrapper)`
  position: relative;
  margin: 0;
`

const DropdownLink = styled.a`
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  color: ${props => props?.colors?.['card.menu.color'] || '#C4C4C4'};
`

const Row = styled.div`
  display:flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  * {
    width: 100%;
  }
`

const UnderRow = styled(Row)`
  margin-top: auto;
`

const ImageContainer = styled.div`
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Image = styled.img`
  height: auto;
  max-width: 100px;
`

const Title = styled.h2`
  &,
  #redwood-app & {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 2rem;
    text-align: center;
    text-transform: uppercase;
    margin: 0;
    color: #292929;

    @media(max-width: ${SMALL_SCREEN}) {
      font-size: 1.7rem;
    }
  }
`

const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
`

const Stat = styled.div`
  display:flex;
  flex-direction: column;
`

const Count = styled.span`
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 2rem;
  color: ${props => props.theme.primaryColor || '#7297ff'};
  font-style: italic;

  @media(max-width: ${SMALL_SCREEN}) {
    font-size: 1.7rem;
  }
`

const Label = styled.span`
  font-family: 'Montserrat';
  font-size: 0.8rem;
  color: #444444;
  font-style: italic;
  font-weight: 300;
`

const FullWidthBtn = styled.div`
  button {
    width: 100%;
  }
`

const MenuItem = styled(Menu.Item)`
  text-align: center;
  border-bottom: 1px solid ${props => props?.colors?.['card.menu.color'] || '#C4C4C4'};

  :last-child {
    border-bottom: 0;
  }
`

/**
 * @typedef StatsType
 * @type {object}
 * @property {string} label
 * @property {string} count
 *
 * @typedef ClientCardProps
 * @type {object}
 * @property {string} title
 * @property {React.ReactNode} logo
 * @property {React.ReactNode} btn
 * @property {React.ReactNode} btnSecondary
 * @property {StatsType[]} stats
 * @property {object} record
 *
 * @param {ClientCardProps} props
 * @returns {React.ReactNode}
 */

const ClientCard = ({ title, logo, btn, btnSecondary, stats = [], record, shouldUpdate }) => {
  const { deleteTenant } = useTenants({ fetch: false })
  const menu = (
    <Menu>
      <MenuItem>
        <AddClientModal
          record={record}
          edit
          shouldUpdate={shouldUpdate}
        />
      </MenuItem>
      <MenuItem>
        <DeleteCheckModal
          deleteObj={record}
          shouldUpdate={shouldUpdate}
          deleteFn={async (deleteObject) => {
            try {
              await deleteTenant(deleteObject)
            } catch (e) {
              Bugsnag.notify(e)
            }
          }}
        />
      </MenuItem>
    </Menu>
  )

  return (
    <Card>
      <Dropdown
        overlay={menu}
        placement='bottomRight'
        trigger={['click']}
      >
        <DropdownLink className='ant-dropdown-link' onClick={e => e.stopPropagation()}>
          <i className='fas fa-ellipsis-v' />
        </DropdownLink>
      </Dropdown>
      <Row>
        <Spacing sy='s' spacingType='padding'>
          <ImageContainer>
            <Image src={logo || '/images/tenant-placeholder.png'} alt={`${title} logo`} />
          </ImageContainer>
        </Spacing>
        <Title uppercase>{title}</Title>
        <UnderRow>
          <Spacing sy='l' spacingType='padding'>
            <Grid>
              {
                stats.map(({ label, count }) => (
                  <Stat key={label + title}>
                    <Count>{count}</Count>
                    <Label>{label}</Label>
                  </Stat>
                ))
              }
            </Grid>
          </Spacing>
          <Spacing sb='s' spacingType='padding'>
            <FullWidthBtn>{btn}</FullWidthBtn>
          </Spacing>
          <FullWidthBtn>{btnSecondary}</FullWidthBtn>
        </UnderRow>
      </Row>
    </Card>
  )
}

export default ClientCard
