import styled from 'styled-components'

const StatCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;
  min-width: 100px;
  justify-content: center;
`

const ValueContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
`

const DataText = styled.p`
  padding: 0;
  margin: 0;
  font-weight: bold;
`

const ValueText = styled.h2`
  color: ${props => props.color};
  margin: 0%;
`

const UnitText = styled.p`
  color: ${props => props.color};
  margin: 0.25rem;
`

const StatsCard = (props) => {
  const { unit, value, color, name, titleColor, unitColor } = props

  const displayValue = isNaN(value)
    ? '-'
    : value
  // const current = data.current ? (Math.round(data.current * 100) / 100) : '?'

  return (
    <StatCardContainer>
      <DataText style={{ color: color || 'black' }}>
        {name}
      </DataText>
      <ValueContainer>
        <ValueText color={titleColor}>{displayValue}</ValueText>
        <UnitText color={unitColor}>{unit}</UnitText>
      </ValueContainer>
    </StatCardContainer>
  )
}

export default StatsCard
