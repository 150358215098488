import InfoCard from 'src/components/InfoCard'
import ColorSpinner from 'src/components/ColorSpinner'
import { averageMessage, formatComfortDisplay } from 'src/libraries/comfort'

import { SMALL_SCREEN } from 'src/constants'

import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const CountContainer = styled.div`
  text-align: center;
  padding-bottom: 1em;
  flex: 1;
`

const NumberFont = styled.h2`
  user-select: none;
  cursor: normal;
`

const UnitFont = styled.h5`
  font-weight: 300;
`

const InfoFont = styled.p`
  font-style: italic;
  font-weight: 300;
`

const Content = styled.div`
  display: flex;
  ${NumberFont} {
    color: ${props => props.numberColor};
  }
  ${UnitFont} {
    color: ${props => props.unitColor};
  }
  ${InfoFont} {
    color: ${props => props.infoColor};
  }
`

const StyledCard = styled(InfoCard)`
  margin-left: 0;

  @media (max-width: ${SMALL_SCREEN}) {
    margin-right: 0;
  }
`

const StyledInfoCard = (props) => {
  return <StyledCard title='Comfort' titleSize='1.5rem' border style={{ color: props.color }} {...props} />
}

const ClientInfoCards = ({
  stats: {
    spaces: { buildings = 0, floors = 0, rooms = 0 } = {},
    machineIssues = 0,
    comfort: {
      comfort
    } = {}
  } = {},
  loading
}) => {
  return (
    <Container>
      <StyledInfoCard
        icon='building' title='Buildings' color='#d46eea'
      >
        <Content numberColor='#471651'>
          {loading
            ? (
              <CountContainer>
                <ColorSpinner color='#471651' />
              </CountContainer>
            )
            : (
              <>
                <CountContainer>
                  <NumberFont>{buildings}</NumberFont>
                  <UnitFont>Buildings</UnitFont>
                </CountContainer>
                <CountContainer>
                  <NumberFont>{floors}</NumberFont>
                  <UnitFont>Floors</UnitFont>
                </CountContainer>
                <CountContainer>
                  <NumberFont>{rooms}</NumberFont>
                  <UnitFont>Rooms</UnitFont>
                </CountContainer>
              </>
            )}
        </Content>
      </StyledInfoCard>
      <StyledInfoCard title='Issues' icon='exclamation-circle' color='#7f9cf0'>
        <Content unitColor='#7f9cf0' infoColor='#7f9cf0' numberColor='#233c84'>
          <CountContainer>
            <NumberFont>
              {
                loading
                  ? <ColorSpinner color='#7f9cf0' />
                  : machineIssues || '-'
              }
            </NumberFont>
            <UnitFont>Machine Issues</UnitFont>
          </CountContainer>
        </Content>
        <InfoFont>The amount  issues are <strong>TBD</strong></InfoFont>
      </StyledInfoCard>
      <StyledInfoCard title='Comfort' icon='mug-hot' color='#7edcb4'>
        <Content numberColor='#135a3c'>
          <CountContainer>
            <NumberFont>
              {
                loading
                  ? <ColorSpinner color='#135a3c' />
                  : formatComfortDisplay(comfort)
              }
            </NumberFont>
            <UnitFont>Comfort</UnitFont>
          </CountContainer>
        </Content>
        <InfoFont>The average levels of comfort are <strong>{averageMessage(comfort)}</strong></InfoFont>
      </StyledInfoCard>
    </Container>
  )
}

export default ClientInfoCards
