import { useState, useEffect } from 'react'
import AdminList from 'src/components/AdminList'
import { Tag, Space, Tooltip, Popconfirm } from 'antd'
import { routes, navigate } from '@redwoodjs/router'
import { QuestionCircleOutlined } from '@ant-design/icons'
import AdminAddMachineModal from 'src/components/AdminAddMachineModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core'
import styled from 'styled-components'

import { useAdminAllMachines, usePlugins } from 'src/hooks'
import * as c from 'src/constants'

const MachineImage = styled.img`
  height: 2em;
  width: auto;
`

const AdminMachine = (props) => {
  const { typeName } = props
  const { adminDeleteMachine } = usePlugins({ fetch: false })
  const { refresh, machines, loading } = useAdminAllMachines()
  const [parsedMachines, setParsedMachines] = useState()

  const shouldUpdate = () => {
    refresh()
  }

  const handleDelete = (record) => {
    adminDeleteMachine(record)
      .then(res =>
        refresh()
      )
  }

  useEffect(() => {
    if (machines) {
      const newMachines = machines.map(item => {
        const { configurableSettings } = item
        const config = JSON.parse(configurableSettings)
        return { ...item, config: { ...config } }
      })
      setParsedMachines(newMachines)
    }
  }, [machines])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => <a>{text}</a>
    },
    {
      title: 'Image',
      dataIndex: 'coverImage',
      key: 'image',
      render: text => {
        if (!text) return null

        return icon({ prefix: 'fas', iconName: text })
          ? (
            <FontAwesomeIcon
              color='#7f9cf0'
              style={{
                fontSize: '2em',
                margin: '0.5em',
                alignSelf: 'center'
              }}
              icon={['fas', text]}
            />
          )
          : <MachineImage src={text} alt='Image of the machine' />
      }
    },
    {
      title: 'Admin config',
      dataIndex: 'config',
      key: 'variables',
      responsive: ['md'],
      render: items => {
        if (items) {
          const color = 'orange'
          return Object.keys(items).map((key) => {
            if (!Array.isArray(items[key])) {
              const value = items[key]
              if (!(typeof value === 'object')) {
                return (
                  <Tag color={color} key={key}>
                    {key} : {value}
                  </Tag>
                )
              }
            }
          })
        }
      }
    },
    {
      title: 'User config',
      dataIndex: 'config',
      key: 'variables',
      responsive: ['md'],
      render: items => {
        if (items && items.user) {
          const temp = Object.assign({}, ...items.user)
          const color = '#2E86C1'
          return Object.keys(temp).map((key) => {
            return (
              <Tag color={color} key={key}>
                {key} : {temp[key].type}
              </Tag>
            )
          })
        }
      }
    },

    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size='middle'>
          <div onClick={(e) => e.stopPropagation()}>
            <Popconfirm
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title='Are you sure delete this sensor?'
              onConfirm={() => handleDelete(record)}
              okText='Yes, delete'
              cancelText='No'
            >
              <a href='#'>Delete</a>
            </Popconfirm>
          </div>
          <AdminAddMachineModal
            edit
            record={record}
            shouldUpdate={shouldUpdate}
          />
        </Space>
      )
    }
  ]

  return (
    <AdminList
      // handleClick={(e) => handleClick(e)}
      shouldUpdate={shouldUpdate}
      typeName={typeName}
      data={parsedMachines}
      columns={columns}
      loading={loading}
    />
  )
}

export default AdminMachine
