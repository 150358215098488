import { message } from 'antd'
import { useCallback, useEffect, useRef, useState } from 'react'

import { useTenant } from 'src/hooks/tenant'
import { apiInstance as api } from 'src/api'
import { getSpaceRelations } from 'src/libraries/spaces'
import Bugsnag from '@bugsnag/js'
import { useIsMounted } from 'src/hooks/mounted'

export const useSpaces = (props = {}) => {
  const {
    fetch = true
  } = props
  const { tenantId, isValidScopeFetch } = useTenant()
  const mounted = useIsMounted()
  const [spaces, setSpaces] = useState([])
  const [loading, setLoading] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())

  // @Todo: Check that https://github.com/sun-labs/cloudgarden-frontend/pull/129 gets merged correctly
  const refresh = () => setLastUpdate(new Date())

  const updateSpace = useCallback((updatedSpace) => {
    return new Promise((resolve, reject) => {
      return api.updateSpace(updatedSpace)
        .then((res) => {
          message.success(res.message || 'Space was updated')
          resolve(res)
        })
        .catch((err) => {
          message.error(err.message || 'Unable to update space')
          reject(err)
        })
    })
  }, [tenantId])

  const deleteSpace = useCallback((spaceId) => {
    return new Promise((resolve, reject) => {
      return api.deleteSpace(spaceId)
        .then((res) => {
          message.success(res.message || 'Space was deleted')
          resolve(res.data[0])
        })
        .catch((err) => {
          message.error(err.message || 'Unable to delete space')
          reject(err)
        })
    })
  }, [tenantId])

  const addSpace = useCallback((newSpace) => {
    return new Promise((resolve, reject) => {
      return api.addSpace(newSpace)
        .then((res) => {
          message.success(res.message || 'Space was added')
          resolve(res)
        })
        .catch((err) => {
          message.error(err.message || 'Unable to add space')
          reject(err)
        })
    })
  }, [tenantId])

  useEffect(() => {
    if (!fetch) return
    setLoading(true)
    api.getSpaces()
      .then(({ data, config }) => {
        if (mounted() && isValidScopeFetch(config)) {
          setSpaces(data)
        }
      })
      .catch(() => {
        if (mounted()) setSpaces([])
      })
      .finally(() => {
        if (mounted()) setLoading(false)
      })
  }, [lastUpdate, tenantId])

  return {
    loading,
    spaces,
    addSpace,
    deleteSpace,
    updateSpace,
    refresh
  }
}

export const useSpace = (spaceId) => {
  const { spaces, loading, refresh: refreshSpaces } = useSpaces()
  const [space, setSpace] = useState()
  const [_loading, setLoading] = useState(loading)
  const [lastUpdate, setLastUpdate] = useState(new Date())

  const refresh = refreshSpaces

  useEffect(() => {
    setLoading(loading)
    if (!loading) {
      const newSpace = spaces.find((space) => space.id === spaceId)

      const {
        children,
        parents,
        rooms,
        floors,
        sensors,
        latestData
      } = getSpaceRelations(spaces, spaceId)

      if (newSpace) {
        setSpace({
          ...newSpace,
          children: (
            children.length === 1 &&
            children.find((space) => space?.id === spaceId)
          ) ? [] : children,
          parents,
          sensors,
          rooms,
          floors,
          latestData
        })
      }
    }
  }, [loading, spaces, lastUpdate])

  return {
    space: space || {},
    spaces,
    refresh,
    loading: _loading
  }
}

export const useSensorStats = (id) => {
  const { tenantId, isValidScopeFetch } = useTenant()
  const mounted = useIsMounted()
  const [sensorStats, setSensorStats] = useState([])
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())

  const refresh = () => setLastUpdate(new Date())

  useEffect(() => {
    if (!id) return

    setLoading(true)
    api.getSensorStats(id)
      .then(({ data, config }) => {
        if (mounted() && isValidScopeFetch(config)) {
          setSensorStats(data)
        }
      })
      .catch(e => {
        if (mounted()) setSensorStats([])
        if (mounted()) setError(e)
      })
      .finally(() => {
        if (mounted()) setLoading(false)
      })
  }, [lastUpdate, tenantId, id])

  return {
    sensorStats,
    loading,
    error,
    refresh
  }
}

export const useSuSpace = (spaceId) => {
  const { tenantId, isValidScopeFetch } = useTenant()
  const [loading, setLoading] = useState(false)
  const mounted = useIsMounted()
  const [error, setError] = useState()
  const [space, setSpace] = useState({})
  const [algos, setAlgos] = useState([])
  const [lastUpdate, setLastUpdate] = useState(new Date())

  const refresh = () => setLastUpdate(new Date())

  const updateSpaceAlgo = (spaceAlgoId, relationData) =>
    new Promise((resolve, reject) => {
      const hide = message.loading('Updating algo for space..')
      api.updateSpaceAlgorithm(spaceAlgoId, relationData)
        .then(() => {
          message.success('Successfully updated spaceAlgo')
          refresh()
          resolve()
        })
        .catch((err) => {
          message.error(err.message)
          reject(err)
        })
        .finally(() => hide())
    })

  const addSpaceAlgo = (algorithmId, relationData = {}) =>
    new Promise((resolve, reject) => {
      const hide = message.loading('Adding algo to space..')
      api.addSpaceAlgo(spaceId, algorithmId, relationData)
        .then(() => {
          message.success('Successfully added to space')
          resolve()
        })
        .catch((err) => {
          message.error(err.message)
          reject(err)
        })
        .finally(() => hide())
    })

  useEffect(() => {
    setLoading(true)
    setError()
    Promise.all([
      api.getAlgorithmsForSpace(spaceId),
      api.getSpace(spaceId)

    ])
      .then(([
        { data: algos, config: algoConfig },
        { data: space, config: spaceConfig }
      ]) => {
        if (mounted()) {
          if (isValidScopeFetch(algoConfig)) setAlgos(algos)
          if (isValidScopeFetch(spaceConfig)) setSpace(space)
        }
      })
      .catch(e => {
        if (mounted()) {
          setAlgos([])
          setSpace({})
          setError(e)
        }
        Bugsnag.notify(e)
      })
      .finally(() => {
        if (mounted()) setLoading(false)
      })
  }, [lastUpdate, tenantId])

  return {
    space: {
      ...space,
      algorithms: algos || []
    },
    loading,
    error,
    addSpaceAlgo,
    updateSpaceAlgo,
    refresh
  }
}
