import { useEffect, useState } from 'react'

import { useIsMounted } from 'src/hooks'
import { apiInstance as api } from 'src/api'
import Bugsnag from '@bugsnag/browser'
import { message } from 'antd'

export const useAdminEvents = ({ fetch = true, id } = {}) => {
  const mounted = useIsMounted()
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())

  const refresh = () => {
    if (mounted()) setLastUpdate(new Date())
  }

  const getEvents = async () => {
    setLoading(true)

    try {
      const { data } = await api.getEvents()

      if (mounted()) {
        setEvents(
          Array.isArray(data)
            ? data
            : [data]
        )
      }
    } catch (error) {
      Bugsnag.notify(error)
      if (mounted()) {
        setEvents([])
        setError(error)
      }
    } finally {
      if (mounted()) setLoading(false)
    }
  }

  useEffect(() => {
    if (!fetch) return

    getEvents()
  }, [lastUpdate])

  const addEvent = async payload => {
    setLoading(true)
    const hide = message.loading('Adding event...')

    try {
      await api.postEvent(payload)

      hide()
      message.success('Successfully added event')
    } catch (error) {
      Bugsnag.notify(error)
      message.error('Event couldn\'t be added')
      if (mounted()) setError()
    } finally {
      hide()
      setLoading(false)
    }
  }

  const updateEvent = async (payload, eventId = id) => {
    setLoading(true)
    const hide = message.loading('Updating event...')

    try {
      await api.patchEvent(eventId, payload)

      hide()
      message.success('Successfully updated event')
    } catch (error) {
      Bugsnag.notify(error)
      message.error('Event couldn\'t be updated')
      if (mounted()) setError()
    } finally {
      hide()
      setLoading(false)
    }
  }

  const deleteEvent = async (eventId = id) => {
    setLoading(true)
    const hide = message.loading('Deleting event...')

    try {
      await api.deleteEvent(eventId)

      hide()
      message.success('Successfully deleted event')
    } catch (error) {
      Bugsnag.notify(error)
      message.error('Event couldn\'t be deleted')
      if (mounted()) setError()
    } finally {
      hide()
      setLoading(false)
    }
  }

  return {
    events,
    loading,
    error,
    refresh,
    addEvent,
    updateEvent,
    // deleteEvent
  }
}
