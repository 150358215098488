import {
  Modal,
  Button as AntButton,
  Form,
  Input,
  message,
  Switch,
  Radio
} from 'antd'

import { useState } from 'react'
import Button from 'src/components/Button'
import { useAdminCommands } from 'src/hooks'
import styled from 'styled-components'
import * as c from '../../constants'

const ButtonContainer = styled.div`
  padding: 0.5em;
`
const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
`
const TitleContainer = styled.div`
  padding: 1em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #7297ff;
  & h2 {
    color: #fff;
  }
`
const FormContainer = styled.div`
  padding: 2em;
  & .ant-row.ant-form-item {
    flex: 1;
    justify-content: center;
    margin-bottom: 1em;
  }
  & .ant-col-14 {
    max-width: 100%;
  }
  & .ant-col-4 {
    padding-left: 8px;
    max-width: 100%;
    flex: 1;
  }
  & .ant-picker {
    width: 100%;
  }

  & .ant-divider{
    margin: 1em;
  }

  & .ant-form-large .ant-form-item-label > label {
    height: 25px;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  & .ant-form-item-control{
    padding: 0 0.5em;
  }
  @media (max-width: ${c.SMALL_SCREEN}){
    flex-direction: column;
  }
`

const AdminAddCommandModal = ({
  edit = false,
  record = {},
  shouldUpdate
}) => {
  const { addCommand, updateCommand } = useAdminCommands({ fetch: false, id: record.id })
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = e => {
    form.resetFields()
    setVisible(false)
  }

  const handleSubmit = async item => {
    if (edit) {
      const editPayload = { id: record.id, ...item }
      await updateCommand(editPayload)
    } else {
      await addCommand(item)
    }

    form.resetFields()
    setVisible(false)
    shouldUpdate?.()
  }

  const validateMessages = {
    required: "'${label}' is required!",
    types: {
      email: '${label} is not validate email!',
      number: '${label} is not a validate number!'
    }
  }

  return (
    <>
      {edit
        ? (
          <AntButton type='link' onClick={showModal}>
            Edit
          </AntButton>)
        : (
          <Button type='primary' onClick={showModal}>
            Add command
          </Button>)}
      <Modal
        closable={false}
        visible={visible}
        destroyOnClose
        onCancel={handleCancel}
        bodyStyle={{ padding: '0' }}
        footer={[
          <FooterContainer key='buttons'>
            <ButtonContainer>
              <Button fill outline key='cancel' onClick={handleCancel}>
              Cancel
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button key='submit' type='primary' htmlType='submit' form='addCommand'>
                {edit ? 'Update command' : 'Add command'}
              </Button>
            </ButtonContainer>
          </FooterContainer>
        ]}
      >
        <div>
          <TitleContainer>
            <h2>{edit ? 'Edit command' : 'Add command'}</h2>
          </TitleContainer>
          <FormContainer>
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              layout='vertical'
              size='large'
              id='addCommand'
              onFinish={handleSubmit}
              colon={false}
              form={form}
              validateMessages={validateMessages}
              initialValues={{
                minAccessLevel: c.COMMAND_ACCESS_SUPER_ADMIN,
                ...record
              }}
            >
              {() => (
                <>
                  <Form.Item
                    label='Command'
                    name='command'
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please add command'
                      }
                    ]}
                  >
                    <Input
                      placeholder='Command'
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item
                    label='Display name'
                    name='displayName'
                  >
                    <Input
                      placeholder='Display name'
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item
                    label='Description'
                    name='description'
                    hasFeedback
                  >
                    <Input.TextArea
                      placeholder='Description'
                      allowClear
                    />
                  </Form.Item>
                  <Row>
                    <Form.Item
                      label='Min. access level'
                      name='minAccessLevel'
                    >
                      <Radio.Group
                        options={c.COMMAND_PERMISSION_OPTIONS}
                        optionType='button'
                        buttonStyle='solid'
                      />
                    </Form.Item>
                  </Row>
                </>
              )}
            </Form>
          </FormContainer>
        </div>
      </Modal>
    </>
  )
}

export default AdminAddCommandModal
