import AdminList from 'src/components/AdminList'
import { navigate, routes } from '@redwoodjs/router'
import { Space, Tag, Dropdown, Menu } from 'antd'
import styled from 'styled-components'

import { useAdminCommands } from 'src/hooks'
import { COMMAND_ACCESS_MAP } from 'src/constants'
import AdminAddCommandModal from 'src/components/AdminAddCommandModal'
import DeleteCheckModal from 'src/components/DeleteCheckModal'

const DropdownLink = styled.a`
  font-size: 1.5rem;
  color: ${props => props?.colors?.['card.menu.color'] || '#C4C4C4'};
  min-width: 25px;
  display: flex;
  justify-content: center;
`

const MenuItem = styled(Menu.Item)`
  text-align: center;

  button {
    width: 100%;
  }
`

const AdminCommand = ({ typeName }) => {
  const { refresh, commands, loading, deleteCommand } = useAdminCommands()

  const handleClick = (record) => {
    const { id } = record
    navigate(routes.adminCommand({ id }))
  }

  const shouldUpdate = () => {
    refresh()
  }

  const handleDelete = (record) => {
    deleteCommand(record.id)
      .then(res =>
        refresh()
      )
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'displayName',
      key: 'displayName',
      render: text => <a>{text}</a>
    },
    {
      title: 'Command',
      dataIndex: 'command',
      key: 'command',
      render: text => <Tag color='blue'>{text}</Tag>
    },

    {
      title: 'Access level',
      dataIndex: 'minAccessLevel',
      key: 'minAccessLevel',
      responsive: ['md'],
      render: level => COMMAND_ACCESS_MAP?.[level] ?? 'None'
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => {
        const menu = (
          <Menu>
            <MenuItem>
              <AdminAddCommandModal
                shouldUpdate={shouldUpdate}
                tableData={commands}
                record={record}
                edit
              />
            </MenuItem>
            <MenuItem>
              <DeleteCheckModal
                titleText={`Are you sure you want to delete this command ${record?.displayName ? ` ${record?.displayName} (${record?.command})` : record?.command}?`}
                deleteFn={() => handleDelete(record)}
                deleteBtnText='Yes, delete'
                cancelBtnText='No, cancel'
              />
            </MenuItem>
          </Menu>
        )

        return (
          <div onClick={(e) => e.stopPropagation()}>
            <Dropdown
              overlay={menu}
              placement='bottomRight'
              trigger={['click']}
            >
              <DropdownLink className='ant-dropdown-link'>
                <i className='fas fa-ellipsis-v' />
              </DropdownLink>
            </Dropdown>
          </div>
        )
      }
    }
  ]

  return (
    <AdminList
      handleClick={(e) => handleClick(e)}
      shouldUpdate={shouldUpdate}
      typeName={typeName}
      data={commands}
      columns={columns}
      loading={loading}
    />
  )
}

export default AdminCommand
