import { useCallback, useEffect, useRef, useState } from 'react'
import { navigate } from '@redwoodjs/router'

import AppLayout from 'src/layouts/AppLayout'
import AppHeader from 'src/components/AppHeader'
import AppTitle from 'src/components/AppTitle'
import Spin from 'src/components/Spin'
import { Tag as _Tag, Drawer, Empty, Tooltip } from 'antd'

import * as c from 'src/constants'

import styled from 'styled-components'
import ReactFlow, { addEdge, Background, Controls, isNode, MiniMap, useStoreActions, useStoreState } from 'react-flow-renderer'
import ButtonComponent, { BackButton } from 'src/components/Button/Button'
import { useAlgorithm, useTenant } from 'src/hooks'
// import { customNodeTypes } from 'src/components/FlowNodes'
import { useAlgorithmFlow, useValidNodes } from 'src/hooks/flow'
import FlowEditor from 'src/components/FlowEditor'

const Content = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  position: relative;
`

const BtnContainer = styled.div`
  margin-left: auto;
  display: flex;
  gap: 1rem;
`

const FlowPage = ({ id }) => {
  const { algorithm } = useAlgorithm(id)
  const { handleSave, settingFormErrorCount, handleTrigger, hasNodesWithErrors: [hasNodesWithErrors] } = useAlgorithmFlow(id)
  const { getRoute } = useTenant()

  return (
    <AppLayout>
      <AppHeader noMargin>
        <BackButton onClick={(e) => navigate(getRoute('adminContent', { type: 'flows' }))} />
        <AppTitle noMargin>{algorithm?.name}</AppTitle>
        <BtnContainer>
          <ButtonComponent outline onClick={handleTrigger}>
            Run
          </ButtonComponent>
          <Tooltip
            title={hasNodesWithErrors ? 'Please resolve all node errors first!' : ''}
            placement='bottomRight'
            color='red'
          >
            <span>
            <ButtonComponent
              onClick={handleSave}
              disabled={hasNodesWithErrors || settingFormErrorCount > 0}
            >
              Save
            </ButtonComponent>
            </span>
          </Tooltip>
        </BtnContainer>
      </AppHeader>
      <Content>
        <FlowEditor flowId={id} />
      </Content>
    </AppLayout>
  )
}

export default FlowPage
