import React, { useState } from 'react'
import { Menu, Dropdown } from 'antd'
import { DownOutlined, GlobalOutlined } from '@ant-design/icons'

import styled from 'styled-components'

const Container = styled.div`
  height: 100%;
  & > :hover {
    transition: all 0.3s;
    background-color: #f5f5f5;
  }
`

const DropDownContainer = styled.div`
  height: 100%;
  padding: 1em;
  display: flex;
  & a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const DropDownMenuContainer = styled.div`
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  @media (min-width: 768px) {
    max-width: 250px;
  }
`

const LangDropdown = (props) => {
  const menu = (
    <DropDownMenuContainer>
      <Menu>
        <Menu.Item key='eng'>
          <span role='img' aria-label='english'>🇬🇧</span> <span>English</span>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item disabled key='dutch'>
          <span role='img' aria-label='dutch'>🇳🇱</span> <span>Dutch (comming soon)</span>
        </Menu.Item>
      </Menu>
    </DropDownMenuContainer>
  )

  return (
    <Container>
      <Dropdown
        overlay={menu}
      >
        <DropDownContainer>
          <a className='ant-dropdown-link' onClick={e => e.preventDefault()}>
            <GlobalOutlined style={{ fontSize: '18px' }} />
          </a>
        </DropDownContainer>
      </Dropdown>
    </Container>
  )
}

export default LangDropdown
