import React from 'react'
import styled from 'styled-components'
import { useTheme } from 'src/hooks'

/**
 * @typedef SpacingComponentPropsValue
 * @type {('xs' | 's' | 'm' | 'l' | 'xl')}
 *
 * @typedef SpacingComponentPropsType
 * @type {('padding' | 'margin')}
 *
 * @typedef SpacingComponentProps
 * @type {object}
 * @property {?SpacingComponentPropsValue} s
 * @property {?SpacingComponentPropsValue} sy
 * @property {?SpacingComponentPropsValue} sx
 * @property {?SpacingComponentPropsValue} st
 * @property {?SpacingComponentPropsValue} sl
 * @property {?SpacingComponentPropsValue} sb
 * @property {?SpacingComponentPropsValue} sr
 * @property {?SpacingComponentPropsType} spacingType
 *
 * @param {SpacingComponentProps} props
 * @return {ThemedStyledFunction<"div", SpacingComponentProps>}
 */

 const Component = styled.div`
 box-sizing: border-box;
 ${({ s, sx, sy, st, sl, sb, sr, spacingType = 'margin', spacings }) => (`
   ${(spacings[s] && !(sx && sy && st && sl && sb && sr)) ? `${spacingType}: ${spacings[s]};` : ''}
   ${(spacings[sy] || spacings[st]) ? `${spacingType}-top: ${(spacings[st] || spacings[sy])};` : ''}
   ${(spacings[sx] || spacings[sl]) ? `${spacingType}-left: ${(spacings[sl] || spacings[sx])};` : ''}
   ${(spacings[sy] || spacings[sb]) ? `${spacingType}-bottom: ${(spacings[sb]) || spacings[sy]};` : ''}
   ${(spacings[sx] || spacings[sr]) ? `${spacingType}-right: ${(spacings[sr] || spacings[sx])};` : ''}
 `)}
`

const Spacing = (props) => {
  const theme = useTheme()
  const spacings = theme?.spacings ?? {
    xs: '0.5rem',
    s: '1rem',
    m: '1.5rem',
    l: '2rem',
    xl: '3rem'
  }

  return <Component {...props} spacings={spacings} />
}

export default Spacing
