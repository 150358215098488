import {
  Modal,
  Form,
  Input,
  Radio,
  message,
  Alert,
  Select,
  Empty,
  Divider,
  Space
} from 'antd'

import { useEffect, useState } from 'react'
import Button from 'src/components/Button'
import { getSpaceRelations } from 'src/libraries/spaces'
import { useSensorUser, useSensorSpace, useAuth, useTenant, useAdminAllSensors } from 'src/hooks'
import QrScanner from 'src/components/QrScanner'
import styled from 'styled-components'
import * as c from '../../constants'
import Spin from 'src/components/Spin/Spin'

const REGEX_MAC = /^(([A-Fa-f0-9]{2}[:]){5}[A-Fa-f0-9]{2}[,]?)+$/i

const INPUT_ID = 1
const INPUT_QR_CODE = 2
const INPUT_SELECT_FROM_SENSOR_LIST = 3

const ButtonContainer = styled.div`
  padding: 0.5em;
`
const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
`
const TitleContainer = styled.div`
  padding: 1em 2em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: ${props => props.theme.primaryColor || '#7297ff'};
  & h2 {
    color: #fff;
  }
`
const FormContainer = styled.div`
  padding: 2em;
  & .ant-row.ant-form-item {
    justify-content: center;
  }
  & .ant-col-14 {
    max-width: 100%;
  }
`

const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  & .ant-radio-wrapper{
    display: flex;
    align-items: center;
  }
`

const AlertContainer = styled.div`
  margin: 1em 0;
`

const UniFont = styled.span`
  font-weight: normal;
  color: ${props => props.color || 'black'};
  font-size: 20px;
`

const SubTitle = styled.p`
  margin-bottom: 0;
  color: #f0f0f0;
`

const InfoText = styled.i`
  @media (max-width: ${c.SMALL_SCREEN}){
    max-width: 100px;
  }
`

const StyledEmpty = styled(Empty)`
  color: #000;
`

const AddSensorModal = ({ spaceId, parentSpaceId, shouldUpdate, spaces }) => {
  const selectedSpace = spaces.find((space) => space.id === spaceId)

  const { isTenantScope, tenantId } = useTenant()
  const { hasRole } = useAuth()
  const isManager = selectedSpace && (
    hasRole('superAdmin') ||
    (
      !isTenantScope() &&
      selectedSpace?.accessLevel >= c.ACCESS_WRITE
    ) ||
    (
      isTenantScope() &&
      (
        (
          hasRole(`tenant.${tenantId}.${c.ACCESS_WRITE}`) &&
          selectedSpace?.tenantAccessLevel >= c.ACCESS_WRITE
        ) ||
        (
          hasRole(`tenant.${tenantId}.${c.ACCESS_READ}`) &&
          selectedSpace?.tenantAccessLevel >= c.ACCESS_WRITE &&
          selectedSpace?.accessLevel >= c.ACCESS_WRITE
        )
      )
    )
  )
  const { addSensorToSpace } = useSensorSpace()
  const { addSensorForUser } = useSensorUser()
  const [visible, setVisible] = useState(false)
  const [showQR, setShowQR] = useState(true)
  const {
    sensors,
    loading,
    refresh,
    searchState,
    paginationState
  } = useAdminAllSensors({
    fetch: isManager,
    fetchAll: true,
    paginationAppend: true
  })
  const [inputType, setInputType] = useState(INPUT_ID)
  const [cameraError, setCameraError] = useState()
  const [rooms, setRooms] = useState([])
  const [floors, setFloors] = useState([])
  const [form] = Form.useForm()
  const [maxAccessLevel, setMaxAccessLevel] = useState(0)


  const isRoom = selectedSpace && selectedSpace?.type === c.TYPE_ROOM

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = e => {
    message.destroy(spaceId)
    form.resetFields()
    setVisible(false)
  }

  const handleSubmit = _item => {
    const item = {
      ..._item,
      spaceId: selectedSpace.id
    }
    addSensorForUser(item)
      .then((res) => {
        const { spaceId } = item
        const { idSensor } = res
        addSensorToSpace(spaceId, idSensor)
          .then((res) => {
            form.resetFields()
            setVisible(false)
            setInputType(INPUT_ID)
            shouldUpdate()
            refresh()
          }).catch(() => {})
      }).catch(() => {
      })
  }

  const handleQR = code => {
    const codeSplit = code.split('-')
    form.setFieldsValue({ deviceId: codeSplit[0], name: codeSplit[1] })
    setShowQR(false)
  }

  const updateRadio = e => {
    message.destroy(spaceId)
    setShowQR(true)
    const { target = {} } = e
    setInputType(target.value)
  }

  const handleSelectChange = key => value => {
    const { name, deviceId } = sensors.find(sensor => sensor[key] === value) || {}

    form.setFieldsValue({ name, deviceId })
    form.validateFields()
  }

  const options = sensors
    .filter(
      ({ deviceId, name }) =>
        REGEX_MAC.test(deviceId) &&
        typeof name === 'string' &&
        name !== ''
    )
    .map(sensor => ({ ...sensor, disabled: typeof sensor.spaceId === 'number' }))

  console.log({ options})
  useEffect(() => {
    if (spaces && parentSpaceId) {
      const { floors, rooms, maxAccessLevel } = getSpaceRelations(spaces, parentSpaceId)
      setMaxAccessLevel(maxAccessLevel)
      setRooms(rooms)
      setFloors(floors)
    }
  }, [spaces])

  const emptyMsg = (
    <StyledEmpty
      description={
        <span>
          {'Looks like you got no sensors, try to '}
          <a
            onClick={e => {
              e.preventDefault()
              setInputType(INPUT_ID)
            }}
          >
            type in the sensor id
          </a>
          {' or '}
          <a
            onClick={e => {
              e.preventDefault()
              setInputType(INPUT_QR_CODE)
            }}
          >
            scan the QR code
          </a>
          .
        </span>
      }
    />
  )

  return (
    <>
      {isManager && (
        !isRoom
          ? <InfoText>Select a room to add device</InfoText>
          : <Button type='primary' onClick={showModal}>Add sensor</Button>
      )}
      <Modal
        closable={false}
        visible={visible}
        onCancel={handleCancel}
        destroyOnClose
        keyboard
        bodyStyle={{ padding: '0' }}
        footer={[
          <FooterContainer key='buttons'>
            <ButtonContainer>
              <Button fill outline key='cancel' onClick={handleCancel}>
              Cancel
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button key='submit' type='primary' htmlType='submit' form='addSensor'>
                {'Add sensor'}
              </Button>
            </ButtonContainer>
          </FooterContainer>
        ]}
      >
        <div>
          <TitleContainer>
            <h2>Add sensor</h2>
            <SubTitle>Sensor will be added to {selectedSpace?.name} ({selectedSpace?.id})</SubTitle>
          </TitleContainer>
          <FormContainer>
            <Radio.Group
              onChange={(e) => updateRadio(e)}
              style={{ width: '100%' }}
              value={inputType}
              size='large'
              defaultValue={INPUT_ID}
            >
              <RadioContainer>
                <Radio value={INPUT_ID}>
                  <UniFont>Fill in ID</UniFont>
                </Radio>
                <Radio value={INPUT_QR_CODE}>
                  <UniFont>Scan QR code</UniFont>
                </Radio>
                <Radio value={INPUT_SELECT_FROM_SENSOR_LIST}>
                  <UniFont>Select from my sensors</UniFont>
                </Radio>
              </RadioContainer>
            </Radio.Group>
            {showQR && inputType && inputType === INPUT_QR_CODE &&
             (
               cameraError
                 ? (
                   <AlertContainer>
                     <Alert message='Camera error' description={cameraError.message || 'Unable to find camera'} type='error' showIcon />
                   </AlertContainer>)
                 : <QrScanner setData={handleQR} messageKey={spaceId} showIcon setCameraError={setCameraError} />
             )}
            <Form
              labelCol={{ span: 14 }}
              wrapperCol={{ span: 14 }}
              layout='vertical'
              size='large'
              id='addSensor'
              onFinish={handleSubmit}
              colon={false}
              form={form}
            >
              <Form.Item
                label='NAME ON THE SENSOR'
                name='name'
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please add sensor name'
                  },
                  ({ getFieldValue }) => ({
                    validator (rule, value) {
                      if (!value || ((value.length <= 21) && (value.length >= 14))) {
                        return Promise.resolve()
                      }
                      return Promise.reject('The sensor name needs to be between 14-21 characters')
                    }
                  })
                ]}
              >
                {inputType === INPUT_SELECT_FROM_SENSOR_LIST
                  ? (
                    <Select
                      showSearch
                      allowClear
                      searchValue={searchState.value}
                      onSearch={searchState.setValue}
                      loading={loading}
                      placeholder='Search to Select'
                      options={options.map(({ name, disabled }) => ({ label: name, value: name, disabled }))}
                      notFoundContent={emptyMsg}
                      onChange={handleSelectChange('name')}
                      dropdownRender={menu => (
                        <>
                          {menu}
                          {paginationState.pageInfo.hasNextPage && (
                            <>
                              <Divider style={{ margin: '8px 0' }} />
                              <Space
                                direction='vertical'
                                align="center"
                                style={{
                                  padding: '0 8px 4px',
                                  width: '100%'
                                }}
                              >
                                {loading && <Spin />}
                                {!loading && (
                                  <Button
                                    onClick={paginationState.goToNextPage}
                                  >
                                    Load more
                                  </Button>
                                )}
                              </Space>
                            </>
                          )}
                        </>
                      )}
                    />
                  )
                  : (
                    <Input
                      placeholder='CG-TRHCV-00.0000'
                      allowClear
                    />
                  )
                }
              </Form.Item>
              <Form.Item
                label='ID'
                name='deviceId'
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please add sensor ID'
                  },
                  ({ getFieldValue }) => ({
                    validator (rule, value) {
                      if (!value || REGEX_MAC.test(value)) {
                        return Promise.resolve()
                      }
                      return Promise.reject('The sensor ID is not valid')
                    }
                  })
                ]}
              >
                {inputType === INPUT_SELECT_FROM_SENSOR_LIST
                  ? (
                    <Select
                      showSearch
                      allowClear
                      searchValue={searchState.value}
                      onSearch={searchState.setValue}
                      loading={loading}
                      placeholder='Search to Select'
                      options={options.map(({ deviceId, disabled }) => ({ label: deviceId, value: deviceId, disabled }))}
                      onChange={handleSelectChange('deviceId')}
                      notFoundContent={emptyMsg}
                      dropdownRender={menu => (
                        <>
                          {menu}
                          {paginationState.pageInfo.hasNextPage && (
                            <>
                              <Divider style={{ margin: '8px 0' }} />
                              <Space
                                direction='vertical'
                                align="center"
                                style={{
                                  padding: '0 8px 4px',
                                  width: '100%'
                                }}
                              >
                                {loading && <Spin />}
                                {!loading && (
                                  <Button
                                    onClick={paginationState.goToNextPage}
                                  >
                                    Load more
                                  </Button>
                                )}
                              </Space>
                            </>
                          )}
                        </>
                      )}
                    />
                  )
                  : (
                    <Input
                      placeholder='a1:b2:c3:d4:e5:f6'
                      allowClear
                      onChange={(e) => {
                        if (e.target.value === '') {
                          setShowQR(true)
                        }
                      }}
                    />
                  )
                }
              </Form.Item>
              {/* <Form.Item
                label='Room'
                name='spaceId'
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please assign a room for the sensor'
                  }]}
              >
                {floors && floors.length > 0
                  ? (
                    <Select
                      showSearch
                      allowClear
                      placeholder='Select a Room'
                      optionFilterProp='room'
                      filterOption={(input, option) =>
                        option && option.room && option.room.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {floors.map((parent, i) => {
                        return (
                          <>
                            <OptGroup key={i} label={parent.name}>
                              {rooms.map((item, i) => {
                                if (item.parentSpaceId === parent.id && item.accessLevel >= c.ACCESS_WRITE) {
                                  return <Option key={item.id} value={item.id}>{item.name}</Option>
                                }
                              })}
                            </OptGroup>
                          </>
                        )
                      }
                      )}
                    </Select>)
                  : (
                    <Alert
                      message='Warning'
                      description='You need to create a room in order to add a sensor'
                      type='error'
                      showIcon
                    />)}
              </Form.Item> */}
            </Form>
          </FormContainer>
        </div>
      </Modal>
    </>
  )
}

export default AddSensorModal
