import { Spin } from 'antd'
import styled, { css } from 'styled-components'

const ColorSpinner = styled(Spin)`
  margin-top: 1rem;
  margin-bottom: 1rem;
  ${props => props.center && css`
    justify-self: center;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
  `}
  .ant-spin-dot-item {
    background-color: ${props => props.color};
  }
`
export default ColorSpinner
