import { useEffect, useContext, useCallback, useState, useRef } from 'react'
import { Context as TenantContext } from 'src/contexts/tenant'
import { navigate, routes, useLocation } from '@redwoodjs/router'
import { message } from 'antd'
import { getUnixTime } from 'date-fns'
import Bugsnag from '@bugsnag/js'

import { apiInstance as api } from 'src/api'
import { useIsMounted } from 'src/hooks/mounted'


/**
 * @param {number} tenantId
 * @returns {string|null}
 */
const getBase = tenantId => typeof tenantId === 'number' ? `/tenants/${tenantId}` : null

/**
  *
  * @returns {({
  *  setTenantId: (tenantId: Number) => Any,
  *  tenantId: Number,
  *  api: import('axios').AxiosInstance,
  *  openTenant: (tenantId: Number) => void
  *  resetTenant: () => void
  *  isValidScopeFetch: (config: import('axios').AxiosRequestConfig) => boolean
  *  getRoute: (name: string, opts: ?object) => any
  *  isTenantScope: () => boolean
  * })}
  * @todo set tenant route from redwood router obj
  */
export const useTenant = (currTenantId, {
  fetchTenant,
  fetchTenantTemplate,
  populateTenantId,
  unsetTenantId
} = {}) => {
  const mounted = useIsMounted()
  const {
    tenantId,
    setTenantId
  } = useContext(TenantContext)
  const [tenantIdLoading, setTenantIdLoading] = useState(populateTenantId)
  const [currentTenant, setCurrentTenant] = useState()
  const [loading, setLoading] = useState(fetchTenant)

  const fetchTenantFunc = async () => {
    if (mounted()) setLoading(true)

    try {
      const { data } = await api.getTenant(tenantId)

      if (mounted()) setCurrentTenant(data)
    } catch {
      if (mounted()) setCurrentTenant(null)
    }

    if (mounted()) setLoading(false)
  }

  const fetchTenantTemplateFunc = useCallback(async () => {
    if (mounted()) setLoading(true)

    try {
      const { data } = await api.getTenantTemplate(tenantId)

      if (mounted()) setCurrentTenant(data)
    } catch {
      if (mounted()) setCurrentTenant(null)
    }

    if (mounted()) setLoading(false)
  }, [tenantId])

  useEffect(() => {
    if (currTenantId && populateTenantId) setTenantId(currTenantId)
    setTenantIdLoading(false)

    return () => {
      if (unsetTenantId) {
        setTenantId(undefined)
        api.updateBase(getBase())
      }
    }
  }, [currTenantId])

  useEffect(() => {
    api.updateBase(getBase(tenantId))
  }, [tenantId])

  useEffect(() => {
    if (typeof tenantId === 'number') {
      if (fetchTenantTemplate && !fetchTenant) {
        fetchTenantTemplateFunc()
      } else if (fetchTenant) {
        fetchTenantFunc()
      }
    }
  }, [tenantId, fetchTenant])

  const getRoute = (name, opts = {}) => tenantId
    ? routes[`${name}Tenant`]({ tenantId, ...opts })
    : routes[name](opts)

  const openTenant = useCallback((tenantId) => {
    api.updateBase(getBase(tenantId))
    setTenantId(tenantId)
    navigate(`/t/${tenantId}`)
  }, [navigate, setTenantId])

  const resetTenant = useCallback(() => {
    api.updateBase(getBase())
    setTenantId()
    navigate('/t/')
  })

  const isValidScopeFetch = config => {
    if (!config) throw Error('You have to provide an axois response config!')

    return (!tenantId && !tenantIdLoading && !config?.baseURL?.includes(`/tenants/${tenantId}`)) ||
    (typeof tenantId === 'number' && !tenantIdLoading && config?.baseURL?.includes(`/tenants/${tenantId}`))
  }

  const isTenantScope = useCallback(() => typeof tenantId === 'number', [tenantId])

  return {
    openTenant,
    resetTenant,
    setTenantId,
    tenantId,
    tenant: currentTenant,
    loading,
    api,
    isValidScopeFetch,
    getRoute,
    isTenantScope
  }
}

/**
 * @param {{ fetch: ?boolean, params: {} } } param0
 * @returns {{
 *   loading,
 *   tenants,
 *   refresh,
 *   loadMore,
 *   deleteTenant,
 *   addTenant,
 *   updateTenant,
 *   bindOrUpdateUserTenantRel,
 *   bindOrUpdateSensorTenantRel: (tenantId: number, sensorId: number|string, payload) => Promise<{}>,
 *   unbindUserTenantRel,
 *   unbindSensorTenantRel,
 *   bindOrUpdateSpaceTenantRel,
 *   unbindSpaceTenantRel
 * }}
 */
export const useTenants = ({ fetch = true, params = {} } = {}) => {
  useTenant()
  const isMounted = useIsMounted()
  const { s = '', order = '', limit = 10, onlyIssues = false, noPagination = 0 } = params
  const [page, setPage] = useState(1)
  const [timeoutState, setTimeoutState] = useState()
  const [loading, setLoading] = useState(false)
  const [tenants, setTenants] = useState({})

  const fetchData = async (append = true, preserveMeta = false, options = {}) => {
    setLoading(true)
    const splitedOrder = order.split(':')
    const sort = order && (splitedOrder[1] === 'ASC' || splitedOrder[1] === 'DESC')
      ? { orderBy: splitedOrder[0], order: splitedOrder[1] }
      : {}
    const params = { s, limit, page, onlyIssues: +onlyIssues, ...sort, ...options, noPagination }

    try {
      const { data = {} } = await api.getTenants(params)
      const updated = preserveMeta ? { meta: tenants?.meta ?? {} } : {}

      if (isMounted()) {
        setTenants({
          ...page > 1 && append
            ? { ...data, data: [...tenants?.data ?? [], ...data?.data ?? []], ...updated }
            : { ...data, ...updated }
        })
      }
    } catch (e) {
      Bugsnag.notify(e)
    } finally {
      setLoading(false)
    }
  }

  const refresh = () => {
    fetchData(false, true, { page: 0, limit: page * (tenants?.meta?.limit ?? 10) })
  }

  useEffect(() => {
    if (fetch && s) {
      setPage(1)
      setLoading(true)
      if (timeoutState) clearTimeout(timeoutState)

      setTimeoutState(setTimeout(fetchData, 1000))

      return () => clearTimeout(timeoutState)
    }
  }, [s, fetch])

  useEffect(() => {
    if (fetch) {
      setPage(1)
      fetchData()
    }
  }, [limit, onlyIssues, noPagination, fetch])

  useEffect(() => {
    if (fetch) {
      refresh()
    }
  }, [order])

  useEffect(() => {
    if (fetch) fetchData()
  }, [page])

  const loadMore = () => setPage(page + 1)

  const bindOrUpdateUserTenantRel = (tenantId, userId, payload) => new Promise(
    (resolve, reject) => api.bindOrUpdateUserTenantRel(tenantId, userId, payload)
      .then((res) => {
        message.success(res.message || 'Added/Updated client user relation')
        resolve(res.data)
      })
      .catch((err) => {
        message.error(err.response.data.error || 'Unable to add/update client user relation')
        reject(err)
      })
  )

  const unbindUserTenantRel = (tenantId, userId) => new Promise(
    (resolve, reject) => api.removeUserFromTenant(tenantId, userId)
      .then((res) => {
        message.success(res.message || 'Deleted client user relation')
        resolve(res.data)
      })
      .catch((err) => {
        message.error(err.response.data.error || 'Unable to delete client user relation')
        reject(err)
      })
  )

  const bindOrUpdateSensorTenantRel = (tenantId, sensorId, payload) => new Promise(
    (resolve, reject) => api.bindTenantToSensor(tenantId, sensorId, payload)
      .then((res) => {
        message.success(res.message || 'Added/Updated client sensor relation')
        resolve(res.data)
      })
      .catch((err) => {
        message.error(err.response.data.error || 'Unable to add/update client sensor relation')
        reject(err)
      })
  )

  const bindOrUpdateSensorsTenantRel = (tenantId, sensorIds = [], payload) => new Promise(
    (resolve, reject) => api.bindTenantToSensors(tenantId, { sensorIds, ...payload })
      .then((res) => {
        message.success(res.message || 'Added/Updated client sensor relation(s)')
        resolve(res.data)
      })
      .catch((err) => {
        message.error(err.response.data.error || 'Unable to add/update client sensor relation(s)')
        reject(err)
      })
  )

  const unbindSensorTenantRel = (tenantId, sensorId) => new Promise(
    (resolve, reject) => api.removeSensorFromTenant(tenantId, sensorId)
      .then((res) => {
        message.success(res.message || 'Deleted client sensor relation')
        resolve(res.data)
      })
      .catch((err) => {
        message.error(err.response.data.error || 'Unable to delete client sensor relation')
        reject(err)
      })
  )

  const bindOrUpdateSpaceTenantRel = (tenantId, spaceId, payload) => new Promise(
    (resolve, reject) => api.bindTenantToSpace(tenantId, spaceId, payload)
      .then((res) => {
        message.success(res.message || 'Added/Updated client space relation')
        resolve(res.data)
      })
      .catch((err) => {
        message.error(err.response.data.error || 'Unable to add/update client space relation')
        reject(err)
      })
  )

  const unbindSpaceTenantRel = (tenantId, spaceId) => new Promise(
    (resolve, reject) => api.removeSpaceFromTenant(tenantId, spaceId)
      .then((res) => {
        message.success(res.message || 'Deleted client space relation')
        resolve(res.data)
      })
      .catch((err) => {
        message.error(err.response.data.error || 'Unable to delete client space relation')
        reject(err)
      })
  )

  const addTenant = (tenant) => {
    return new Promise((resolve, reject) => {
      return api.addTenant(tenant)
        .then((res) => {
          message.success(res.message || 'Added client')
          resolve(res.data)
        })
        .catch((err) => {
          message.error(err.response.data.error || 'Unable to add client')
          reject(err)
        })
    })
  }

  const updateTenant = (tenant) => {
    return new Promise((resolve, reject) => {
      const { id } = tenant
      return api.updateTenant(id, tenant)
        .then((res) => {
          message.success(res.message || 'Updated client')
          resolve(res.data)
        })
        .catch((err) => {
          message.error(err.response.data.error || 'Unable to update client')
          reject(err)
        })
    })
  }

  const deleteTenant = (tenant) => {
    return new Promise((resolve, reject) => {
      const { id } = tenant
      return api.deleteTenant(id)
        .then((res) => {
          message.success(res.message || 'Deleted client')
          resolve(res.data)
        })
        .catch((err) => {
          message.error(err.response.data.error || 'Unable to delete client')
          reject(err)
        })
    })
  }

  return {
    loading,
    tenants,
    refresh,
    loadMore,
    deleteTenant,
    addTenant,
    updateTenant,
    bindOrUpdateUserTenantRel,
    bindOrUpdateSensorTenantRel,
    bindOrUpdateSensorsTenantRel,
    unbindUserTenantRel,
    unbindSensorTenantRel,
    bindOrUpdateSpaceTenantRel,
    unbindSpaceTenantRel
  }
}

/**
 * @typedef UseTenantChartOptions
 * @type {object}
 * @property {?('sensors'|'comfort'|'buildings'|'floors'|'rooms')} type
 * @property {?('last-day'|'last-4hours'|'last-week'|'last-month'|'last-year')} period
 * @property {number} tenantId
 *
 * @param {UseTenantChartOptions} options
 */
export const useTenantChart = ({ type = 'sensors', period = 'last-week', interval: intervalParam, tenantId, lineKeyDefault, XDataKeyDefault }, extraLoading = false) => {
  const periodArr = ['last-4hours', 'last-day', 'last-week', 'last-month', 'last-year']
  const intervalArr = ['minute', 'hour', 'day', 'week', 'month']
  const [loading, setLoading] = useState(false)
  const [lineKey, setLineKey] = useState('count')
  const [XDataKey, setXDataKey] = useState('createdAtUnix')
  const [data, setData] = useState([])

  const fetchChartData = async () => {
    if (tenantId) {
      setLoading(true)

      try {
        let data
        const interval = intervalParam ?? intervalArr[periodArr.indexOf(period)] ?? 'minute'

        switch (type) {
          case 'sensors':
            ({ data } = await api.getSensorStatsForUser(tenantId, { period, interval }))
            setLineKey('count')
            setXDataKey('createdAtUnix')
            break
          case 'comfort':
            ({ data = [] } = await api.getComfortStatsForUser(tenantId, { period, interval }))
            setLineKey('comfort')
            setXDataKey('createdAtUnix')
            data = data.map(item => ({ ...item, createdAtUnix: getUnixTime(new Date(item.time)) }))
            break
          case 'buildings':
            ({ data } = await api.getBuildingStatsForUser(tenantId, 1, { period, interval }))
            setLineKey('count')
            setXDataKey('createdAtUnix')
            break
          case 'floors':
            ({ data } = await api.getBuildingStatsForUser(tenantId, 2, { period, interval }))
            setLineKey('count')
            setXDataKey('createdAtUnix')
            break
          case 'rooms':
            ({ data } = await api.getBuildingStatsForUser(tenantId, 3, { period, interval }))
            setLineKey('count')
            setXDataKey('createdAtUnix')
            break
        }

        if (XDataKeyDefault) setXDataKey(XDataKeyDefault)
        if (lineKeyDefault) setLineKey(lineKeyDefault)
        setData(data ?? [])
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    fetchChartData()
  }, [type, period, intervalParam, tenantId, lineKeyDefault, XDataKeyDefault])

  return {
    loading: loading || extraLoading,
    data,
    interval: intervalArr[periodArr.indexOf(period)] ?? 'minute',
    period,
    lineKey,
    XDataKey
  }
}
