import { Redirect } from '@redwoodjs/router'
import React, { useEffect, useState } from 'react'
import { useAuth, useTenant } from 'src/hooks'

/**
 * @typedef AuthCheckProps
 * @type {object}
 * @property {React.ReactNode} page
 * @property {?string|string[]} role
 * @property {?number} tenantAccess
 * @property {() => string} unauthenticated - Route for unautheticated users
 * @property {() => string} notWithRole - Route for users which are autenticated but have not the needed role
 *
 * @param {AuthCheckProps} props
 * @returns {React.ReactNode}
 */

const AuthCheck = ({ page: Page, role, unauthenticated, notWithRole, tenantAccess, ...props }) => {
  const { tenantId: tenantIdParam } = props
  const { tenantId } = useTenant(tenantIdParam, { populateTenantId: true, unsetTenantId: true })
  const { loading, isAuthenticated, hasRole } = useAuth()
  const [roles, setRoles] = useState([])

  useEffect(() => {
    if (role || (tenantIdParam && tenantAccess)) {
      let newRoles = []

      if (role) {
        newRoles = [...Array.isArray(role) ? role : [role]]
      }

      if (tenantId && tenantAccess) {
        newRoles.push(`tenant.${tenantId}.${tenantAccess}`)
      }

      setRoles(newRoles)
    }
  }, [tenantId, tenantIdParam, tenantAccess, role])

  if (loading || (tenantAccess && tenantIdParam && !tenantId)) return null

  if (isAuthenticated && roles.length > 0 && !hasRole(roles)) {
    return (
      <Redirect
        to={`${notWithRole()}`}
      />
    )
  } else if (!isAuthenticated) {
    return (
      <Redirect
        to={`${unauthenticated()}?redirectTo=${window.location.pathname}`}
      />
    )
  } else {
    return <Page {...props} />
  }
}

export default AuthCheck
