import StatsCard from 'src/components/StatsCard'

import styled from 'styled-components'
import { useTheme, useCurrentState } from 'src/hooks'
import { mapKeys } from 'src/utils'

const StatBarContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: white;
  border-radius: 0.5rem;
  margin: 1rem;
`

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: 1480px) {
    display: inline-flex;
    justify-content: flex-start;
    width: auto;
  }
`

const StatsBar = (props) => {
  const { sensor } = props
  const { latestDataObj } = sensor
  const { colors } = useTheme()
  const { units } = useCurrentState()

  return (
    <StatBarContainer>
      <Container>
        {mapKeys(latestDataObj, (value, key) => {
          const unit = units[key] || units.default
          const displayValue = value?.toFixed(1)
          return (
            <StatsCard
              key={key}
              name={unit.displayName || key}
              unit={unit.unit}
              value={displayValue}
              color={colors.keyColors[key]}
              unitColor={colors['sensor.statCard.unit']}
              titleColor={colors['sensor.statCard.value']}
            />
          )
        })}
      </Container>
    </StatBarContainer>
  )
}

export default StatsBar
