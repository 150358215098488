
import { useState, useEffect, useContext } from 'react'
import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  Dot,
  ReferenceArea
} from 'recharts'

import styled, { ThemeContext } from 'styled-components'
import ResponsiveContainer from 'src/components/ResponsiveContainer/ResponsiveContainer'
import {
  xTickStyle
} from 'src/chartUtils'

import { format } from 'date-fns'

const moment = require('moment')

const OFFSET = 2

const RelativeWrapper = styled.div`
  position: relative;
`

const StyledLineChart = styled(LineChart)`
  .recharts-yAxis
  .recharts-cartesian-axis-tick-value {
    transform: translateX(-5px);
  }
  .recharts-xAxis
  .recharts-cartesian-axis-tick-value {
    transform: translateY(7.5px);
  }
  .recharts-cartesian-axis-tick-line {
    stroke: #cbcbcb;
  }
  .recharts-cartesian-axis-line {
    stroke: #cbcbcb;
  }
  .recharts-cartesian-axis-tick-value {
    fill: black;
  }
  .recharts-dot.recharts-line-dot {
    fill: ${props => props.theme.primaryColor};
    stroke: ${props => props.theme.primaryColor};
  }
`

const ZoomOutBtn = styled.a`
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.primaryColor};
  color: black;
  pointer-events: all;
`

const Top = styled.div`
    position: absolute;
    left: 100px;
    top: 0px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 100px);
    user-select: none;
    pointer-events: none;
`

const DataText = styled.span`
  font-size: 48px;
  color: rgb(81, 88, 107);
  font-weight: 400;
`

const UnitText = styled.span`
  font-size: 24px;
`

const DataWrap = styled.div`
  pointer-events: none;
`

const tooltipFormatter = (tick) => {
  const date = new Date(tick)
  return format(date, 'yyyy-MM-dd, hh:mm')
}

const legendValueFormatter = (tick) => {
  return parseFloat(tick).toFixed(2)
}

const LineChartAppZoom = (props, ref) => {
  const {
    activeKeys,
    measurements,
    unit,
    xTickFormatLabel
  } = props
  const activeKey = activeKeys[0] || 'temp'
  const [data, setData] = useState(measurements)
  const [refAreaLeft, setrefAreaLeft] = useState('')
  const [refAreaRight, setrefAreaRight] = useState('')
  const [left, setLeft] = useState('dataMin')
  const [right, setRight] = useState('dataMax')
  const [top, setTop] = useState('dataMax+1')
  const [bottom, setBottom] = useState('dataMin-1')
  const [showAnimate, setShowAnimate] = useState(true)
  const [latestValue, setlatestValue] = useState()
  const [zoomed, setZoomed] = useState(false)
  const theme = useContext(ThemeContext)

  const displayLatestValue = latestValue && latestValue.toFixed(1)

  const getAxisYDomain = (ref, offset, from, to) => {
    const fromDate = moment(from)
    const stopDate = moment(to)
    let refData = []
    if (from && to) {
      refData = measurements.filter(d => {
        var time = new Date(d.time).getTime()
        return (fromDate <= time && time <= stopDate)
      })
    } else {
      refData = measurements
    }
    if (measurements.length > refData.length) { setZoomed(true) }

    let [bottomT, topT] = [refData[0][ref], refData[0][ref]]
    refData.forEach(d => {
      if (d[ref] > topT) topT = d[ref]
      if (d[ref] < bottomT) bottomT = d[ref]
    })

    return { refData, bottomT: (bottomT - offset), topT: (topT + offset) }
  }

  const handleLatestData = (d) => {
    const latestValue = d.slice(-1).pop()
    setlatestValue(latestValue[activeKey])
  }

  const zoom = () => {
    if (refAreaLeft === refAreaRight || refAreaRight === '') {
      setrefAreaLeft('')
      setrefAreaRight('')
      return
    }

    let temprefAreaLeft = refAreaLeft
    let temprefAreaRight = refAreaRight
    // xAxis domain
    if (moment(refAreaLeft) > moment(refAreaRight)) {
      temprefAreaLeft = refAreaRight
      temprefAreaRight = refAreaLeft
    }

    // yAxis domain
    const { refData, bottomT, topT } = getAxisYDomain(activeKey, OFFSET, temprefAreaLeft, temprefAreaRight)
    handleLatestData(refData)
    setShowAnimate(false)
    setrefAreaLeft('')
    setrefAreaRight('')
    setData(refData)
    setLeft(temprefAreaLeft)
    setRight(temprefAreaRight)
    setBottom(bottomT)
    setTop(topT)
  }

  const zoomOut = () => {
    handleLatestData(measurements)
    setrefAreaLeft('')
    setrefAreaRight('')
    setData(measurements)
    setLeft('')
    setRight('')
    setZoomed(false)
  }

  const xTickFormatter = (tick) => {
    const date = new Date(tick)

    const leftTemp = moment(left)
    const rightTemp = moment(right)
    const daysBetween = rightTemp.diff(leftTemp, 'days')
    if (daysBetween > 30) {
      return format(date, 'dd MMM')
    } else if (daysBetween > 7) {
      return format(date, 'dd HH:mm')
    } else if (daysBetween > 1) {
      return format(date, 'dd HH:mm')
    } else if (daysBetween < 1) {
      return format(date, 'HH:mm')
    } else {
      if (xTickFormatLabel) {
        return format(date, xTickFormatLabel)
      }
      return format(date, 'dd MMM') // default
    }
  }

  const yTickFormatter = (tick) => {
    const float = parseFloat(tick)
    const str = float.toFixed(1)
    const displayStr = str.replace('.0', '')
    return displayStr
  }

  useEffect(() => {
    if (measurements && measurements.length > 0) {
      setData(measurements)
      const { bottomT, topT } = getAxisYDomain(activeKey, OFFSET)
      setBottom(bottomT)
      setTop(topT)
      handleLatestData(measurements)
    }
  }, [measurements, activeKey])

  return (
    <RelativeWrapper>
      <ResponsiveContainer
        aspect={7 / 3}
      >
        <StyledLineChart
          syncId='timeSeries'
          data={data}
          onMouseDown={(e) => (e && e.activeLabel && setrefAreaLeft(e.activeLabel))}
          onMouseMove={(e) => (e && e.activeLabel && refAreaLeft && refAreaLeft.length > 0 && setrefAreaRight(e.activeLabel))}
          onMouseUp={(e) => e && zoom(e)}
          isAnimationActive={showAnimate}
        >
          <XAxis
            allowDataOverflow
            dataKey='time'
            tick={xTickStyle}
            tickFormatter={xTickFormatter}
            domain={[left, right]}
            style={{ userSelect: 'none' }}
          />
          <YAxis
            allowDataOverflow
            yAxisId='1'
            tickFormatter={yTickFormatter}
            domain={[bottom, top]}
            style={{ userSelect: 'none' }}

          />
          <Tooltip
            labelFormatter={t => tooltipFormatter(t)}
            formatter={legendValueFormatter}
          />
          <Line
            type='monotone'
            name={unit.displayName}
            dataKey={activeKey}
            dot={(props) => <Dot {...props} r={1} />}
            unit={unit.unit}
            stroke={theme.primaryColor}
            strokeWidth={2.5}
            yAxisId='1'
          />
          {
            (refAreaLeft && refAreaRight) &&
              <ReferenceArea yAxisId='1' x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />
          }
        </StyledLineChart>
      </ResponsiveContainer>
      <Top>
        <DataWrap>
          <DataText>{displayLatestValue} <UnitText>{unit.unit}</UnitText></DataText>
        </DataWrap>
        {zoomed &&
          <div>
            <ZoomOutBtn onClick={() => zoomOut()}>Zoom Out</ZoomOutBtn>
          </div>}
      </Top>
    </RelativeWrapper>
  )
}

export default LineChartAppZoom
