import { Button } from 'antd'
import { routes, navigate } from '@redwoodjs/router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styled from 'styled-components'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em 3em;
  box-sizing: border-box;
  width: 100%;
`

const Card = styled.div`
  display: flex;
  background: white;
  border-radius: 15px;
  box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.4);
  margin: 1em;
  min-height: 300px;
  background-image: url('${props => props.background ? props.background : ''}');
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity 0.25s ease-out, box-shadow 0.4s ease-out;
  cursor: pointer;
  & ${Content} {
    border-radius: 15px;
    background-color: rgba(64,64,64,0.75);
    color: white;
  }
  &:hover {
    opacity: 0.85;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  }

`
const GradientContainer = styled.div`
  width: 1em;
  border-radius: 15px 0 0 15px;
  background-image: linear-gradient(0deg,rgba(0,0,0,0.5),rgba(0,0,0,.0));
  background-color: ${props => props.style.color};
  box-shadow: 5px 0 15px rgba(0,0,0,0.15);
  `

const TitleContainer = styled.div`
  flex: 1;
`

const HeaderContainer = styled.div`
  display: flex;
`

const ExtraContainer = styled.div`

`

const ChildContainer = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  height: 100%;
  flex-direction: column;
  width: 100%;
  color: ${props => props.customStyle.color};
  & h5 {
    color: ${props => props.customStyle.color};
    filter: brightness(75%);
  }
`

const TitleFont = styled.span`
  font-size: ${props => props.titleSize};
  font-weight: 600;
`

const InfoCard = (props) => {
  const {
    style = {},
    children,
    icon,
    footer,
    title,
    titleSize = '2rem',
    subTitle = '',
    border = false,
    extra,
    titleAction,
    background
  } = props
  return (
    <Card background={background} style={style}>
      {border &&
        <GradientContainer style={style} />}
      <Content>
        <HeaderContainer>
          {titleAction &&
            <ExtraContainer>{titleAction}</ExtraContainer>}
          <TitleContainer>
            <TitleFont style={style} titleSize={titleSize}>
              {icon && <i className={`fas fa-${icon}`} style={{ marginRight: '0.5em' }} />}{title}
              <h4>{subTitle}</h4>
            </TitleFont>
          </TitleContainer>
          {extra &&
            <ExtraContainer>
              {extra}
            </ExtraContainer>}
        </HeaderContainer>
        <ChildContainer customStyle={style}>
          {children}
        </ChildContainer>
      </Content>
    </Card>
  )
}

export default InfoCard
