import Bugsnag from '@bugsnag/js'
import { message } from 'antd'
import { useEffect, useState } from 'react'

import { apiInstance as api } from 'src/api'
import { useIsMounted } from 'src/hooks/mounted'
import { useTenant } from 'src/hooks/tenant'
import { getFormattedSensorName } from 'src/libraries/sensor'
import { usePagination } from 'src/hooks/pagination'
import { useTypeTimeout } from 'src/hooks/typeTimeout'

/**
 * @param {{formattedName: import('src/libraries/sensor').FormattedSensorOptions}} param0
 */
export const useAdminAllSensors = ({
  formattedName,
  fetch = true,
  fetchAll = false,
  excludeTenantSensors = false,
  paginationLimit,
  paginationAppend = false,
  includeSettings
} = {}) => {
  const mounted = useIsMounted()
  const { tenantId, isValidScopeFetch } = useTenant()
  const [sensors, setSensors] = useState([])
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())

  const [paginationMeta, setPaginationMeta] = useState()
  const paginationState = usePagination({ pageInfo: paginationMeta })

  const searchState = useTypeTimeout({ timeout: 1000})

  const { paginationData } = paginationState

  const refresh = () => {
    setLastUpdate(new Date())
  }

  useEffect(() => {
    paginationState.reset()
  }, [
    searchState?.deferredValue,
    paginationLimit,
    lastUpdate,
    tenantId
  ])

  useEffect(() => {
    if (!fetch) return

    setLoading(true)

    api.adminGetAllSensors({
      params: {
        ...paginationData,
        limit: paginationLimit || 10,
        fetchAll,
        excludeTenantSensors,
        s: searchState?.deferredValue,
        includeSettings
      }
    })
      .then(({ data, config }) => {
        if (mounted() && isValidScopeFetch(config)) {
          const newSensors = data?.edges?.map(({ node }) => ({
            ...node,
            formattedName: getFormattedSensorName(node, formattedName)
          }))

          const sensorsState = paginationAppend
            ? [
              ...sensors || [],
              ...newSensors || []
            ]
            : newSensors

          setSensors(sensorsState || [])

          setPaginationMeta({ ...data?.pageInfo, totalCount: data?.totalCount })
        }
      })
      .catch(e => {
        if (mounted()) {
          setError(e)
          setSensors([])
        }
      })
      .finally(() => {
        if (mounted()) setLoading(false)
      })
  }, [paginationData])

  return {
    sensors,
    loading,
    error,
    refresh,
    paginationState,
    searchState
  }
}

export const useAdminSensor = (id, { fetch = true } = {}) => {
  const mounted = useIsMounted()
  const { tenantId, isValidScopeFetch } = useTenant()
  const [sensor, setSensor] = useState([])
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())

  const refresh = () => setLastUpdate(new Date())

  useEffect(() => {
    if (fetch) {
      setLoading(true)
      api.adminGetSensor(id)
        .then(({ data, config }) => {
          if (mounted() && isValidScopeFetch(config)) {
            setSensor(data)
          }
        })
        .catch(e => {
          if (mounted()) {
            setSensor([])
            setError(e)
          }
        })
        .finally(() => {
          if (mounted()) setLoading(false)
        })
    }
  }, [tenantId, lastUpdate])

  return {
    sensor,
    loading,
    error,
    refresh
  }
}

export const useAdminAllUsers = () => {
  const mounted = useIsMounted()
  const { tenantId, isValidScopeFetch } = useTenant()
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())

  const refresh = () => setLastUpdate(new Date())

  useEffect(() => {
    setLoading(true)
    api.adminGetAllUsers()
      .then(({ data, config }) => {
        if (mounted() && isValidScopeFetch(config)) {
          setUsers(data)
        }
      })
      .catch(e => {
        if (mounted()) {
          setUsers([])
          setError(e)
        }
      })
      .finally(() => {
        if (mounted()) setLoading(false)
      })
  }, [lastUpdate, tenantId])

  return {
    users,
    loading,
    error,
    refresh
  }
}

/**
 * @param {number} userId
 * @param {import('src/libraries/sensor').FormattedSensorOptions} formattedSensorName
 */
export const useAdminUser = (userId, formattedSensorName) => {
  const mounted = useIsMounted()
  const { tenantId, isValidScopeFetch } = useTenant()
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())

  const refresh = () => setLastUpdate(new Date())

  const sendForgotPwdEmail = async () => {
    if (!user?.email) {
      return message.error('Unable to send reset password email')
    }

    try {
      await api.forgotPwd({ email: user.email })
      message.success(`Successfully sent reset password email to ${user.email}.`)
    } catch (e) {
      message.error('Unable to send reset password email')
      Bugsnag.notify(e)
    }
  }

  useEffect(() => () => {
    mounted.current = false
  }, [])

  useEffect(() => {
    setLoading(true)
    api.adminGetUser(userId)
      .then(({ data, config }) => {
        if (mounted() && isValidScopeFetch(config)) {
          const sensors = data.sensors.map(sensor => ({
            ...sensor,
            formattedName: getFormattedSensorName(sensor, formattedSensorName)
          }))

          setUser({
            ...data,
            sensors
          })
        }
      })
      .catch(e => {
        setUser([])
        if (mounted()) setError(e)
      })
      .finally(() => {
        if (mounted()) setLoading(false)
      })
  }, [lastUpdate, tenantId])

  return {
    user,
    loading,
    error,
    refresh,
    sendForgotPwdEmail
  }
}

export const useAdminAllSpaces = () => {
  const mounted = useIsMounted()
  const { tenantId, isValidScopeFetch } = useTenant()
  const [spaces, setSpaces] = useState([])
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())

  const refresh = () => setLastUpdate(new Date())

  useEffect(() => {
    setLoading(true)
    api.adminGetAllSpaces()
      .then(({ config, data }) => {
        if (mounted() && isValidScopeFetch(config)) {
          setSpaces(data)
        }
      })
      .catch(e => {
        if (mounted()) {
          setError(e)
          setSpaces([])
        }
      })
      .finally(() => {
        if (mounted()) setLoading(false)
      })
  }, [lastUpdate, tenantId])

  return {
    spaces,
    loading,
    error,
    refresh
  }
}

export const useAdminAllMachines = () => {
  const mounted = useIsMounted()
  const { tenantId, isValidScopeFetch } = useTenant()
  const [machines, setMachines] = useState([])
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())

  const refresh = () => setLastUpdate(new Date())

  useEffect(() => {
    setLoading(true)
    api.getPlugins()
      .then(({ data, config }) => {
        if (mounted() && isValidScopeFetch(config)) {
          setMachines(data)
        }
      })
      .catch(e => {
        if (mounted()) {
          setMachines([])
          setError(e)
        }
      })
      .finally(() => {
        if (mounted()) setLoading(false)
      })
  }, [lastUpdate, tenantId])

  return {
    machines,
    loading,
    error,
    refresh
  }
}
