import { message } from 'antd'
import { useEffect, useState } from 'react'

import { apiInstance as api } from 'src/api'
import { useIsMounted, useTenant } from 'src/hooks'

export const useAlgorithmNode = (algorithmNodeId) => {
  const mounted = useIsMounted()
  const { tenantId, isValidScopeFetch } = useTenant()
  const [algorithmNode, setAlgorithmNode] = useState({})
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())

  const refresh = () => {
    if (mounted()) {
      setLastUpdate(new Date())
    }
  }

  const updateAlgorithmNode = (algoNodeId = algorithmNodeId, newData) => {
    const hide = message.loading('Updating algorithm node...')

    api.updateAlgorithmNode(algoNodeId, newData)
      .then(() => {
        hide()
        message.success('Successfully updated algorithm node.')

        refresh()
      })
      .catch(() => {
        message.error('Algorithm node updating failed.')
        if (mounted()) setError()
      })
      .finally(() => hide())
  }

  const addAlgorithmNode = node => {
    const hide = message.loading('Adding algorithm node...')
    api.addAlgorithmNode(node)
      .then(() => {
        hide()
        message.success('Successfully added algorithm node')
        if (mounted()) setAlgorithmNode(node)
      })
      .catch(() => {
        if (mounted()) setError()
      })
      .finally(() => hide())
  }

  useEffect(() => {
    if (!algorithmNodeId || algorithmNodeId === 'add') return

    setLoading(true)

    api.getAlgorithmNode(algorithmNodeId)
      .then(({ data, config }) => {
        if (mounted() && isValidScopeFetch(config)) {
          console.log('data', data)
          setAlgorithmNode(data)
        }
      })
      .catch((e) => {
        if (mounted()) {
          setAlgorithmNode({})
          setError(e)
        }
      })
      .finally(() => {
        if (mounted()) setLoading(false)
      })
  }, [lastUpdate, tenantId])

  return {
    algorithmNode,
    loading,
    error,
    refresh,
    updateAlgorithmNode,
    addAlgorithmNode
  }
}

export const useAlgorithmNodes = () => {
  const mounted = useIsMounted()
  const { tenantId, isValidScopeFetch } = useTenant()
  const [algorithmNodes, setAlgorithmNodes] = useState([])
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())

  const refresh = () => {
    if (mounted()) {
      setLastUpdate(new Date())
    }
  }

  const deleteAlgorithmNode = (algoNodeId = algorithmNodeId) => {
    const hide = message.loading('Deleting algorithm node...')

    api.deleteAlgorithmNode(algoNodeId)
      .then(() => {
        hide()
        message.success('Successfully deleted algorithm node.')

        refresh()
      })
      .catch(() => {
        message.error('Algorithm node deletion failed.')
        if (mounted()) setError()
      })
      .finally(() => hide())
  }

  useEffect(() => {
    setLoading(true)
    api.getAlgorithmNodes()
      .then(({ data, config }) => {
        if (mounted() && isValidScopeFetch(config)) {
          setAlgorithmNodes(data)
        }
      })
      .catch(e => {
        if (mounted()) {
          setAlgorithmNodes([])
          setError(e)
        }
      })
      .finally(() => {
        if (mounted()) setLoading(false)
      })
  }, [lastUpdate, tenantId])

  return {
    algorithmNodes,
    loading,
    error,
    deleteAlgorithmNode,
    refresh
  }
}
