import styled from 'styled-components'
import './app.css'
import { Spin } from 'antd'
import {
  useCurrentState
} from 'src/hooks'

const Container = styled.div`
  display: flex;
  height: 100%;
  min-height: 100vh;
  flex: 1;
  flex-direction: column;
  background-repeat: no-repeat;
  background-image: url('${props => props.background}');
  background-size: cover;
`

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`
const Footer = styled.div`
   height:30px;
   width:100%;
   justify-content: center;
   align-items: center;
   display: flex;
  `

const SpinContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const AppLayout = (props) => {
  const { children } = props
  const { client } = useCurrentState()
  const { assets } = client
  const { background } = assets
  return (
    <Container background={background}>
      <Content>
        {children || (
          <SpinContainer>
            <Spin size='large' />
          </SpinContainer>
        )}
      </Content>
      {/* <Footer /> */}
    </Container>
  )
}

export default AppLayout
