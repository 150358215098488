import { Tabs } from 'antd'
import React from 'react'
import ErrorTab from 'src/components/FlowEditor/SettingsDrawer/ErrorTab'
import MetaTab from 'src/components/FlowEditor/SettingsDrawer/MetaTab'
import NodeLogTab from 'src/components/FlowEditor/SettingsDrawer/NodeLogTab'
import StatsTab from 'src/components/FlowEditor/SettingsDrawer/StatsTab'
import LogTimeline from 'src/components/LogTimeline'
import { useAlgorithmFlow } from 'src/hooks/flow'
import styled from 'styled-components'

const StyledTabPane = styled(Tabs.TabPane)`
  padding-right: 16px;
`

const NotScrollableTab = styled(StyledTabPane)`
  overflow-y: hidden;
`

/**
 * @typedef LogTabProps
 * @type {object}
 *
 * @param {LogTabProps} props
 * @returns {React.ReactNode}
 */

const LogTab = ({ flowId }) => {
  const { logs, logsLoading } = useAlgorithmFlow(flowId)

  return (
    <LogTimeline logs={logs} loading={logsLoading}>
      {log => (
        <Tabs>
          <StyledTabPane
            tab='Stats'
            key='stats'
          >
            <StatsTab
              {...log?.meta ?? {}}
            />
          </StyledTabPane>
          <Tabs.TabPane
            tab='Logs'
            key='executionLogs'
          >
            <NodeLogTab flowId={flowId} creationDate={log?.meta?.creationDate} />
          </Tabs.TabPane>
          {log?.meta && (
              <NotScrollableTab
                tab='Meta'
                key='meta'
              >
                <MetaTab value={log?.meta} />
              </NotScrollableTab>
            )}
          {log?.error && (
            <StyledTabPane
              tab='Error'
              key='error'
            >
              <ErrorTab {...log} />
            </StyledTabPane>
          )}
        </Tabs>
      )}
    </LogTimeline>
  )
}

export default LogTab
