import {
  ScatterChart,
  Tooltip,
  YAxis,
  XAxis,
  ReferenceArea,
  Scatter,
  CartesianGrid,
  Label
} from 'recharts'
import styled from 'styled-components'
import ResponsiveContainer from 'src/components/ResponsiveContainer/ResponsiveContainer'
import {
  valueFormatter,
  xTickStyle
} from 'src/chartUtils'

import { config } from 'src/libraries/measurements'

const StyledScatterChart = styled(ScatterChart)`
  .recharts-cartesian-axis-tick-value {
    fill: #707070;
  }
  .recharts-cartesian-axis-line {
    stroke: #f6f6f6;
  }
  .recharts-cartesian-axis-tick-line {
    display: none;
  }
`

const SC_OFFSET = config.display.chartOffset

const ComfortChart = (props) => {
  const {
    aspectRatio = 4 / 3,
    data = {},
    minData = {},
    maxData = {},
    latestMeasurement,
    ...rest
  } = props
  const { units } = config

  const { temp, hum } = units

  const domain = (minData && latestMeasurement && temp && hum)
    ? {
      x: {
        min: parseInt(Math.min(minData.temp, latestMeasurement.temp, temp.acceptableValues.min, temp.comfortableValues.min)) - SC_OFFSET,
        max: parseInt(Math.max(maxData.temp, latestMeasurement.temp, temp.acceptableValues.max, temp.comfortableValues.max)) + SC_OFFSET
      },
      y: {
        min: parseInt(Math.min(minData.hum, latestMeasurement.hum, hum.acceptableValues.min, hum.comfortableValues.min)) - SC_OFFSET,
        max: parseInt(Math.max(maxData.hum, latestMeasurement.hum, hum.acceptableValues.max, hum.comfortableValues.max)) + SC_OFFSET
      }
    }
    : {
      x: {},
      y: {}
    }

  const scatterData = [
    { x: domain.x.min, y: domain.y.min },
    { x: domain.x.max, y: domain.y.max }
  ]

  const referenceAreas = [
    {
      x: temp.acceptableValues,
      y: hum.acceptableValues,
      fill: '#96b8f7',
      stroke: 'transparent',
      fillOpacity: 0.5,
      label: <Label fill='black'>Acceptable</Label>,
      shape: true && (
        ({ x, y, x1, x2, y1, y2, height, width, ...props }) =>
          <svg width={width} height={height} x={x} y={y} preserveAspectRatio='none' viewBox='0 0 317 288' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M0.409729 67.5739L45.9317 0.842743L125.595 29.294L267.852 115.165L316.477 240.868L267.852 287.942H115.249L28.3437 232.074L0.409729 67.5739Z'
              fill={props.fill}
              stroke={props.stroke}
              fillOpacity={props.fillOpacity}
            />
          </svg>
      )
    },
    {
      x: temp.comfortableValues,
      y: hum.comfortableValues,
      fill: '#7297ff',
      stroke: 'transparent',
      fillOpacity: 0.5,
      label: <Label fill='white'>Comfortable</Label>,
      shape: true && (
        ({ x, y, x1, x2, y1, y2, height, width, ...props }) =>
          <svg width={width} height={height} x={x} y={y} preserveAspectRatio='none' viewBox='0 0 174 164' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M126.153 29.3679L0.966979 0.916641L30.9701 145.242L173.226 163.347L126.153 29.3679Z'
              fill={props.fill}
              fillOpacity={props.fillOpacity}
              stroke={props.stroke}
            />
          </svg>
      )
    },
    {
      x: {
        min: parseInt(minData.temp),
        max: parseInt(maxData.temp)
      },
      y: {
        min: parseInt(minData.hum),
        max: parseInt(maxData.hum)
      },
      fill: '#4262bc',
      stroke: 'transparent',
      fillOpacity: 0.5,
      label: <Label fill='white'>Current</Label>,
      shape: true && (
        ({ x, y, x1, x2, y1, y2, height, width, ...props }) =>
          <ellipse
            cx={x + (width / 2)}
            cy={y + (height / 2)}
            rx={(width / 2 < 30)
              ? 30
              : (width / 2)}
            ry={(height / 2 < 13)
              ? 13
              : (height / 2)}
            stroke={props.stroke}
            fill={props.fill}
            fillOpacity={props.fillOpacity}
          />
      )
    }
  ]

  return (
    <ResponsiveContainer aspect={aspectRatio} {...rest}>
      <StyledScatterChart margin={{ bottom: 50 }}>
        <CartesianGrid stroke={false} />
        <XAxis
          type='number'
          dataKey='x'
          name='Temperature'
          label={<Label position='bottom'>Temperature</Label>}
          unit={units.temp.unit}
          tick={xTickStyle}
          domain={[domain.x.min, domain.x.max]}
        />
        <YAxis
          type='number'
          dataKey='y'
          name='Humidity'
          label={{ value: 'Humidity', angle: -90, position: 'insideLeft' }}
          unit={units.hum.unit}
          domain={[domain.y.min, domain.y.max]}
        />
        {referenceAreas.map(({ x, y, ...props }, i) =>
          <ReferenceArea {...props} key={i} x1={x.min} x2={x.max} y1={y.min} y2={y.max} />)}
        <Scatter data={scatterData} display={false} fill='transparent' />
        <Scatter
          data={[{
            x: data.temp,
            y: data.hum
          }]}
          fill='transparent'
        />
        <Tooltip formatter={valueFormatter} />
      </StyledScatterChart>
    </ResponsiveContainer>
  )
}

export default ComfortChart
