import { message } from 'antd'
import { useEffect, useRef, useState } from 'react'

import { useTenant } from 'src/hooks/tenant'
import { apiInstance as api } from 'src/api'
import { useIsMounted } from 'src/hooks/mounted'

export const useAlgorithm = (algorithmId) => {
  const mounted = useIsMounted()
  const { tenantId, isValidScopeFetch } = useTenant()
  const [algorithm, setAlgorithm] = useState([])
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())

  const refresh = () => setLastUpdate(new Date())

  const updateAlgorithm = (algorithmId, newData) => {
    const hide = message.loading('Updating algorithm...')

    api.updateAlgorithm(algorithmId, newData)
      .then(() => {
        hide()
        message.success('Successfully updated algorithm.')

        if (mounted()) {
          setAlgorithm({
            id: algorithmId,
            ...newData
          })
        }
      })
      .catch(() => {
        if (mounted()) setError()
      })
      .finally(() => hide())
  }

  const addAlgorithm = (algo) => {
    const hide = message.loading('Adding algorithm...')
    api.addAlgorithm(algo)
      .then(() => {
        hide()
        message.success('Successfully added algorithm')
        if (mounted()) setAlgorithm(algo)
      })
      .catch(() => {
        if (mounted()) setError()
      })
      .finally(() => hide())
  }

  useEffect(() => {
    if (!algorithmId || algorithmId === 'add') return

    setLoading(true)
    api.getAlgorithm(algorithmId)
      .then(({ data, config }) => {
        if (mounted() && isValidScopeFetch(config)) {
          setAlgorithm(data)
        }
      })
      .catch((e) => {
        if (mounted()) {
          setAlgorithm([])
          setError(e)
        }
      })
      .finally(() => {
        if (mounted()) setLoading(false)
      })
  }, [lastUpdate, tenantId])

  return {
    algorithm,
    loading,
    error,
    refresh,
    updateAlgorithm,
    addAlgorithm
  }
}

export const useAlgorithms = ({ onlyFlows = false, noFlows = false } = {}) => {
  const mounted = useIsMounted()
  const { tenantId, isValidScopeFetch } = useTenant()
  const [algorithms, setAlgorithms] = useState([])
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())

  const refresh = () => setLastUpdate(new Date())

  useEffect(() => {
    setLoading(true)
    api.getAllAlgorithms({ params: { onlyFlows, noFlows } })
      .then(({ data, config }) => {
        if (mounted() && isValidScopeFetch(config)) {
          setAlgorithms(data)
        }
      })
      .catch(e => {
        if (mounted()) {
          setAlgorithms([])
          setError(e)
        }
      })
      .finally(() => {
        if (mounted()) setLoading(false)
      })
  }, [lastUpdate, tenantId, onlyFlows])

  return {
    algorithms,
    loading,
    error,
    refresh
  }
}
