import AdminList from 'src/components/AdminList'
import { Space, Popconfirm, Tag, Dropdown, Menu, Button } from 'antd'
import { useAdminSensorSetting } from 'src/hooks/sensorSetting'
import { QuestionCircleOutlined } from '@ant-design/icons'
import Bugsnag from '@bugsnag/js'

import styled from 'styled-components'
import { useRef } from 'react'
import DeleteCheckModal from '../DeleteCheckModal'
import { navigate, routes } from '@redwoodjs/router'
import AddSensorSettingGroup from 'src/components/AdminSensorSettingModals/AddSensorSettingGroup'

const DropdownLink = styled.a`
  font-size: 1.5rem;
  color: ${props => props?.colors?.['card.menu.color'] || '#C4C4C4'};
`

const MenuItem = styled(Menu.Item)`
  text-align: center;
`

const ConfigSettingsTab = (props) => {
  const { refresh, loading, sensorSettings, deleteSensorSettingGroup } = useAdminSensorSetting()

  const handleClick = (record) => {
    const { id } = record
    navigate(routes.adminSensorConfigSettingGroup({ id }))
  }

  const shouldUpdate = () => {
    refresh()
  }

  const handleDelete = (record) => {
    deleteSensorSettingGroup(record.id)
      .then(res =>
        refresh()
      )
  }

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: text => <a>{text}</a>
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      responsive: ['md']
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => {
        const menu = (
          <Menu>
            <MenuItem>
            <AddSensorSettingGroup
                shouldUpdate={refresh}
                record={record}
                edit
                btnType='link'
              />
            </MenuItem>
            <MenuItem>
              <DeleteCheckModal
                titleText={`Are you sure you want to delete this setting ${record?.name ? `(name: ${record?.title})` : ''}?`}
                deleteFn={() => handleDelete(record)}
                deleteBtnText='Yes, delete'
                cancelBtnText='No, cancel'
              />
            </MenuItem>
          </Menu>
        )

        return (
          <Space size='middle'>
            <div onClick={(e) => e.stopPropagation()}>
              <Dropdown
                overlay={menu}
                placement='bottomRight'
                trigger={['click']}
              >
                <DropdownLink className='ant-dropdown-link' onClick={e => e.stopPropagation()}>
                  <i className='fas fa-ellipsis-v' />
                </DropdownLink>
              </Dropdown>
            </div>
          </Space>
        )
      }
    }
  ]

  return (
    <AdminList
      handleClick={handleClick}
      typeName='sensorConfigSettingGroup'
      shouldUpdate={shouldUpdate}
      data={sensorSettings}
      columns={columns}
      loading={loading}
    />
  )
}

export default ConfigSettingsTab
