import { useState, useEffect, forwardRef } from 'react'
import { Table, Input, Spin } from 'antd'
import styled, { css } from 'styled-components'
import Button from 'src/components/Button'
import * as c from 'src/constants'

import AdminAddSensorModal from 'src/components/AdminAddSensorModal'
import AdminAddUserModal from 'src/components/AdminAddUserModal'
import AdminAddSensorTypeModal from 'src/components/AdminAddSensorTypeModal'
import AdminAddMachineModal from 'src/components/AdminAddMachineModal'
import AdminAddSpaceModal from '../AdminAddSpaceModal/AdminAddSpaceModal'
import AdminAddCommandModal from '../AdminAddCommandModal'
import BatchBindSensor from 'src/components/BatchBindSensors'
import { useAuth, useTenant } from 'src/hooks'
import AdminAddParamUiModal from 'src/components/AdminAddParamUiModal'
import AdminAddAlgorithmNodeType from 'src/components/AdminAddAlgorithmNodeType'
import AdminAddAlgorithmNodeDataType from 'src/components/AdminAddAlgorithmNodeDataType'
import AddSensorSettingGroup from 'src/components/AdminSensorSettingModals/AddSensorSettingGroup'
import AddSensorConfigurableSetting from 'src/components/AdminSensorSettingModals/AddSensorConfigurableSetting'
import AddSensorSettingPreset from 'src/components/AdminSensorSettingModals/AddSensorSettingPreset'
import AdminAddEventModal from 'src/components/AdminAddEvent'

const { Search } = Input

const TableWrap = styled.div`
 & table {
    border-spacing: 0 1em;
    border-radius: 30px;
    border-collapse: separate;
   }
 & .ant-table {
   background: transparent;
 }

 & .ant-table-thead > tr > th {
  background: transparent;
  border-bottom: none;
  padding: 0 16px;
  color: black;
  font-weight: 300;
  font-style: italic;
  text-align: center;
 }

 & .ant-table-row{
  background: white;
  box-shadow: 5px 0 15px rgba(0,0,0,0.15);
  text-align: center;
  transition: all .3s;
  ${props => props.clickable && css`
  cursor: pointer;
  `}
 }

 & tr.ant-table-rowt:hover{
  box-shadow: 20px 20px 50px 0 rgba(0, 0, 0, 0.4);
  cursor: pointer;
 }
`

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  /* align-items: flex-start;
  align-content: flex-start; */
`

const ListWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`

const SpinWrap = styled.div`
  justify-content: center;
  display: flex;
`
const SearchWrap = styled.div`
  display: flex;
 & .ant-input-search{
  max-width: 250px;
  border-radius: 0.5em;
  @media (max-width: ${c.SMALL_SCREEN}){
    max-width: 150px;
  }
}
`

const StyledTable = styled(Table)`
  .ant-pagination-item {
    ${props =>  props.hidePaginationNumbers ? 'display: none !important;': ''}
  }
`

const AdminList = forwardRef((props, ref) => {
  const {
    addAction = true,
    search = true,
    searchProps,
    typeName = '',
    columns = [],
    data,
    loading = false,
    handleClick,
    shouldUpdate,
    onAddClick: handleAddClick = () => null,
    sensorTypes,
    users,
    spaces,
    modalProps,
    tableProps,
  } = props
  const { isTenantScope } = useTenant()

  const [tableData, setTableData] = useState([])
  const [dataParsed, setdataParsed] = useState([])
  const [searchLoading, setsearchLoading] = useState(false)

  const SearchAction = (searchInput) => {
    setsearchLoading(true)
    const filteredData = dataParsed.filter((data) => JSON.stringify(data).toLowerCase().indexOf(searchInput.toLowerCase()) !== -1)
    setTableData(filteredData)
    setsearchLoading(false)
  }

  useEffect(() => {
    if (data) {
      const dataParsed = data.map((item, i) => {
        item.key = i // Adding key to each element since antd table needs it to search
        return item
      })
      setTableData(dataParsed)
      setdataParsed(dataParsed)
    }
  }, [data])

  const addButton = () => {
    switch (typeName) {
      case 'sensors': return !isTenantScope()
        ? (
          <AdminAddSensorModal
            shouldUpdate={shouldUpdate}
            tableData={tableData}
            sensorTypes={sensorTypes}
            users={users}
            spaces={spaces}
          />
        )
        : (
          <BatchBindSensor
            refresh={shouldUpdate}
          />
        )
      case 'users': return <AdminAddUserModal shouldUpdate={shouldUpdate} tableData={tableData} />
      case 'sensor Types': return <AdminAddSensorTypeModal shouldUpdate={shouldUpdate} tableData={tableData} />
      case 'machines': return <AdminAddMachineModal shouldUpdate={shouldUpdate} tableData={tableData} />
      case 'spaces': return <AdminAddSpaceModal shouldUpdate={shouldUpdate} tableData={tableData} />
      case 'commands': return <AdminAddCommandModal shouldUpdate={shouldUpdate} tableData={tableData} />
      case 'commandParamUis': return <AdminAddParamUiModal shouldUpdate={shouldUpdate} tableData={tableData} {...modalProps} />
      case 'flowNodeTypes': return <AdminAddAlgorithmNodeType shouldUpdate={shouldUpdate} tableData={tableData} {...modalProps} />
      case 'flowNodeDataTypes': return <AdminAddAlgorithmNodeDataType shouldUpdate={shouldUpdate} tableData={tableData} {...modalProps} />
      case 'sensorConfigSettingGroup': return <AddSensorSettingGroup shouldUpdate={shouldUpdate} tableData={tableData} {...modalProps} />
      case 'sensorConfigurableSetting': return <AddSensorConfigurableSetting shouldUpdate={shouldUpdate} tableData={tableData} {...modalProps} />
      case 'sensorConfigurableSettingPreset': return <AddSensorSettingPreset shouldUpdate={shouldUpdate} tableData={tableData} {...modalProps} />
      case 'events': return <AdminAddEventModal shouldUpdate={shouldUpdate} tableData={tableData} {...modalProps} />
      default: return <Button onClick={handleAddClick}>Add {typeName}</Button>
    }
  }

  return (
    <ListWrap ref={ref}>
      <HeaderWrap>
        {addAction &&
          addButton()}
        {(search || searchProps) &&
          <SearchWrap>
            <Search
              placeholder='Search'
              onChange={e => SearchAction(e.target.value)}
              onSearch={value => SearchAction(value)}
              allowClear
              loading={searchLoading}
              {...searchProps || {}}
            />
          </SearchWrap>}
      </HeaderWrap>
      <TableWrap clickable={handleClick != null}>
        {loading
          ? <SpinWrap><Spin /></SpinWrap>
          : (
            <StyledTable
              onRow={(record, rowIndex) => {
                if (handleClick) {
                  return {
                    onClick: (e) => {
                      handleClick(record, e)
                    }
                  }
                }
              }}
              columns={columns}
              dataSource={tableData}
              {...tableProps || {}}
              {...tableProps?.hidePaginationNumbers
                ? {
                  pagination: {
                    position: ['none', 'bottomCenter'],
                    ...tableProps?.pagination || {}
                  }
                }
                : {}
              }
            />)}
      </TableWrap>
    </ListWrap>
  )
})

export default AdminList
