import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { message } from 'antd'

import { useCurrentState, useUser, useTenant } from 'src/hooks'
import { apiInstance as api } from 'src/api'
import { reduceToOne, mapEachObjKeys, filterObj, normalize, sortObj, mapObjValues } from 'src/utils'
import { MAX_RETRIES } from 'src/constants'
import { useIsMounted } from 'src/hooks/mounted'
import { isWithinInterval } from 'date-fns'
import Bugsnag from '@bugsnag/browser'

export const useSensor = (sensorId, opts = {}) => {
  const mounted = useIsMounted()
  const { tenantId, isValidScopeFetch } = useTenant()
  const { config, units } = useCurrentState()
  const { dateTimeStart, dateTimeEnd, interval } = opts
  const [data, setData] = useState([]) // raw data from backend
  const [latestDataObj, setLatestDataObj] = useState({}) // the latest data point from the backend
  const [avgObj, setAvgObj] = useState([]) // object with average data for each field
  const [keyIndex, setKeyIndex] = useState([]) // sorted keys in order by weight and filtered by blacklist

  const [splitData, setSplitData] = useState([])
  const [normData, setNormData] = useState([]) // normalized data (0 - 100)
  const [minData, setMinData] = useState({}) // object containing the minimum values from the data fetched from backend
  const [maxData, setMaxData] = useState({}) // same as above but with maximum values

  const [radarLatestData, setRadarLatestData] = useState([]) // for radar chart, last data point normalized

  const [info, setInfo] = useState({}) // info about the sensor

  const [loading, setLoading] = useState()
  const [error, setError] = useState()

  const resetData = () => {
    setError()
    setNormData([])
    setRadarLatestData([])
    setData([])
    setLatestDataObj({})
  }

  const updateSensorData = () => {
    resetData()
    setLoading(true)
    api.getSensorData(sensorId, {
      ...opts
    })
      .then(({ results }) => setData(results))
      .catch(setError)
      .finally(() => setLoading(false))
  }

  const sortByWeight = (keys, values) => {
    const [a, b] = keys
    const unitA = units[a]
    const unitB = units[b]
    if (unitA && unitB) {
      return unitB.orderWeight - unitA.orderWeight
    } else {
      return -1
    }
  }

  useEffect(() => {
    if (sensorId) {
      updateSensorData()
      api.getSensorInfo(sensorId)
        .then((info) => {
          if (mounted() && isValidScopeFetch(info.res.config)) {
            setInfo(info.sensor)
          }
        })
    }
  }, [sensorId, tenantId])

  useEffect(() => {
    if (sensorId) {
      resetData()
      updateSensorData()
    }
  }, [dateTimeStart, dateTimeEnd, interval])

  useEffect(() => {
    if (data && data.length > 0) {
      const lastRaw = data[data.length - 1]
      const filteredLast = filterObj(lastRaw, (value, key, i) => {
        const { sensorBlacklist } = config.display
        return sensorBlacklist.indexOf(key) === -1
      })
      const last = sortObj(filteredLast, sortByWeight)
      setKeyIndex(Object.keys(last))
      setLatestDataObj(last)
      const maxObj = reduceToOne(data, (key, acc, cur) =>
        acc[key]
          ? Math.max(cur[key], acc[key])
          : cur[key])
      const minObj = reduceToOne(data,
        (key, acc, cur) =>
          acc[key]
            ? Math.min(cur[key], acc[key])
            : cur[key])
      const avgObj = mapObjValues(
        reduceToOne(data, (key, acc, cur) => (acc[key] || 0) + cur[key]),
        (val, key) => key !== 'time'
          ? val / data.length
          : lastRaw.time
      ) // 💪 macho programming mvh victor
      const normData = mapEachObjKeys(data,
        (key, acc, cur) => {
          const unit = units[key]
          const [min, max] = (unit && unit.displayRange) || []
          return normalize(cur[key], max, min)
        }
      )
      const splitData = data.map(
        (obj) => mapObjValues(
          obj,
          (value, key) => {
            const unit = units[key]
            if (unit) {
              const { acceptableValues: av } = unit
              return {
                value: value > av.max
                  ? av.max
                  : value,
                overflow: value > av.max
                  ? value - av.max
                  : 0
              }
            } else {
              return value
            }
          }
        ))
      setSplitData(splitData)
      setAvgObj(avgObj)
      setMaxData(maxObj)
      setMinData(minObj)
      setNormData(normData)
    }
  }, [data, units])
  useEffect(() => {
    const rawLast = normData[normData.length - 1]
    if (rawLast) {
      const last = sortObj(rawLast, sortByWeight)
      const formatted = Object.keys(last)
        .filter((key) => !isNaN(last[key])) // remove non numeric values
        .map((key) => {
          const unit = units[key]
          const { displayRange, acceptableValues, comfortableValues } = unit
          const [min, max] = displayRange
          const normAcc = normalize(acceptableValues.max, max, min)
          const normComfort = normalize(comfortableValues.max, max, min)
          const current = last[key]
          return {
            unit: key,
            current: current,
            acceptable: unit && normAcc,
            comfortable: unit && normComfort
          }
        })
      setRadarLatestData(formatted)
    }
  }, [normData])
  return {
    ...info,
    data,
    radarLatestData,
    normData,
    minData,
    maxData,
    latestDataObj,
    loading,
    error,
    keyIndex,
    avgObj,
    splitData
  }
}

export const useSensors = ({ fetch = true } = {}) => {
  const mounted = useIsMounted()
  const { tenantId, isValidScopeFetch } = useTenant()
  const [sensors, setSensors] = useState([])
  const [lastUpdate, setLastUpdate] = useState(new Date())
  const [error, setError] = useState()
  const [loading, setLoading] = useState()
  const [tries, setTries] = useState(0)
  const [done, setDone] = useState(false)
  const refresh = () => setLastUpdate(new Date())
  const { user } = useUser()

  const adminUpdateSensor = useCallback((sensor) => {
    return new Promise((resolve, reject) => {
      return api.adminUpdateSensor(sensor)
        .then((res) => {
          message.success(res.message || 'Updated sensor')
          resolve(res.data)
        })
        .catch((err) => {
          message.error(err.response.data.error || 'Unable to update sensor')
          reject(err)
        })
    })
  }, [])

  const adminDeleteSensor = useCallback((sensor) => {
    return new Promise((resolve, reject) => {
      const { id } = sensor
      return api.adminDeleteSensor(id)
        .then((res) => {
          message.success(res.message || 'Deleted sensor')
          resolve(res.data)
        })
        .catch((err) => {
          message.error(err.response.data.error || 'Unable to delete sensor')
          reject(err)
        })
    })
  }, [])

  const addSensor = useCallback((sensor) => {
    return new Promise((resolve, reject) => {
      return api.adminAddSensor(sensor)
        .then((res) => {
          message.success(res.message || 'Added sensor')
          resolve(res.data)
        })
        .catch((err) => {
          const errData = err.response.data
          const { errors = [] } = errData
          message.error(errors.map(error => error.message) || 'Unable to add sensor')
          reject(err)
        })
    })
  }, [])

  const sendCommand = async (sensor, command, params, { plain = false } = {}) => new Promise((resolve, reject) => {
    let sendableParams

    if (Array.isArray(params)) {
      sendableParams = ` ${params.join(' ')}`
    } else {
      sendableParams = typeof params === 'string' && params
        ? ` ${params}`
        : ''
    }

    const sendableCommand = `${command}${sendableParams}`

    return api.sendCommandToSensor(sensor.id, { command: sendableCommand })
      .then((res) => {
        if (!plain) message.success(res.message || 'Command successfully send')

        resolve(res.data)
      })
      .catch((err) => {
        if (!plain) {
          const errData = err.response.data
          message.error(errData?.message || 'Unable to send command')
        }

        Bugsnag.notify(err)

        reject(err)
      })
  })

  const sendUiCommand = async (sensor, uiId, params, { plain = false } = {}) => new Promise((resolve, reject) => {
    return api.sendUiCommandToSensor(sensor.id, uiId, { params })
      .then((res) => {
        if (!plain) message.success(res.message || 'Command successfully send')

        resolve(res.data)
      })
      .catch((err) => {
        if (!plain) {
          const errData = err.response.data
          message.error(errData?.message || 'Unable to send command')
        }

        Bugsnag.notify(err)

        reject(err)
      })
  })

  useEffect(() => {
    if (error && tries < MAX_RETRIES) {
      setTries(tries + 1)
      refresh()
    } else if (error) {
      setDone(true)
    }
  }, [error])

  useEffect(() => {
    if (!fetch) return
    setError(null)
    setLoading(true)
    api.getSensors(user.username)
      .then((res) => {
        if (mounted() && isValidScopeFetch(res.res.config)) {
          setSensors(res.sensors)
        }
      })
      .catch(e => {
        if (mounted()) {
          setError(e)
          setSensors([])
        }
      })
      .finally(() => {
        if (mounted()) setLoading(false)
      })
  }, [lastUpdate, tenantId])

  return {
    refresh,
    sensors,
    done,
    addSensor,
    adminUpdateSensor,
    adminDeleteSensor,
    sendCommand,
    sendUiCommand,
    error,
    loading
  }
}

export const useSensorLogs = (sensor) => {
  const mounted = useIsMounted()
  const [params, updateParams] = useState({ weeks: 1 })
  const { isValidScopeFetch } = useTenant()
  const [loading, setLoading] = useState(false)
  const [logs, setLogs] = useState([])

  // TODO: Way to go if flux is enabled in production.
  /* useEffect(() => {
    setLoading(true)

    api.getSensorLogs(sensor.id, params)
      .then(res => {
        if (mounted() && isValidScopeFetch(res.config)) {
          setLogs(res.data)
        }
      })
      .catch(e => {
        if (mounted()) {
          //setError(e)
          setLogs([])
        }
      })
      .finally(() => {
        if (mounted()) setLoading(false)
      })
  }, [sensor, params]) */

  const getHMDate = date => {
    const hMDate = new Date()
    if (typeof date === 'string' && date.includes(':')) {
      const [h, m] = date.split(':')

      hMDate.setHours(h)
      hMDate.setMinutes(m)
    } else {
      hMDate.setHours(date.getHours())
      hMDate.setMinutes(date.getMinutes())
    }

    return hMDate
  }

  useEffect(() => {
    setLoading(true)

    api.getSensorLogs(sensor.id, params)
      .then(res => {
        if (mounted() && isValidScopeFetch(res.config)) {
          setLogs(res.data)
        }
      })
      .catch(e => {
        Bugsnag.notify(e)

        if (mounted()) {
          setLogs([])
        }
      })
      .finally(() => {
        if (mounted()) setLoading(false)
      })
  }, [sensor, params.weeks])

  const filterdLogs = useMemo(() => logs
    .filter(log => {
      const logDate = new Date(log.time)
      const days = params?.days?.split(',')
      const hMDate = getHMDate(logDate)
      const start = getHMDate(params?.timeFrom || '00:00')
      const end = getHMDate(params?.timeTo || '23:59')

      return (!params?.days || days.includes('' + logDate.getDay())) &&
        isWithinInterval(hMDate, { start, end })
    }), [logs, params])

  return {
    loading,
    logs,
    filterdLogs,
    params,
    updateParams
  }
}

export const useGroupedSensors = ({ by = 'location', s = '' } = {}) => {
  const defaultName = `No ${by}`

  const initialLoad = useRef(true)

  const mounted = useIsMounted()
  const { tenantId, isValidScopeFetch } = useTenant()
  const [sensorGroups, setSensorGroups] = useState([])
  const [groupedSensors, setGroupedSensors] = useState({})

  const [timeoutState, setTimeoutState] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())
  const [loading, setLoading] = useState(false)

  const fetchData = async () => {
    setLoading(true)
    setGroupedSensors({})
    setSensorGroups([])

    const { data: groups } = await api.getSensorGroups({ by, s })

    const groupValue = groups?.[0]

    const { sensors } = await api.getSensors({ by, s, groupValue })

    if (mounted()) {
      setSensorGroups(groups || [])
      setGroupedSensors({
        [groupValue || defaultName]: sensors || []
      })
    }

    setLoading(false)
    initialLoad.current = false
  }

  const fetchSensorGroup = async groupValue => {
    setLoading(true)

    const { sensors } = await api.getSensors({ by, s, groupValue })

    if (mounted()) {
      setGroupedSensors({
        ...groupedSensors,
        [groupValue || defaultName]: sensors || []
      })
    }

    setLoading(false)
  }

  useEffect(() => {
    if (typeof s === 'string' && !initialLoad.current) {
      setLoading(true)
      setSensorGroups([])
      setGroupedSensors({})

      if (timeoutState) clearTimeout(timeoutState)

      setTimeoutState(setTimeout(fetchData, 1000))

      return () => clearTimeout(timeoutState)
    }
  }, [s])

  useEffect(() => {
    fetchData()
  }, [by])

  return {
    sensorGroups,
    groupedSensors,
    loading,
    fetchSensorGroup
  }
}
