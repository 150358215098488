import {
  Modal,
  Form,
  Select,
  Alert,
  Radio,
  Switch,
  Divider
} from 'antd'
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import React, { useState } from 'react'
import Button from 'src/components/Button'
import LoadingScreen from 'src/components/LoadingScreen'
import { useTenants } from 'src/hooks'

import styled from 'styled-components'
import * as c from 'src/constants'

const { Option } = Select

const ButtonContainer = styled.div`
  padding: 0.5em;
`
const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
`
const TitleContainer = styled.div`
  padding: 1em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #7297ff;
  & h2 {
    color: #fff;
  }
`
const FormContainer = styled.div`
  padding: 2em;
  & .ant-row.ant-form-item {
    flex: 1;
    justify-content: center;
    margin-bottom: 1em;
  }
  & .ant-col-14 {
    max-width: 100%;
  }
  & .ant-col-4 {
    padding-left: 8px;
    max-width: 100%;
    flex: 1;
  }
  & .ant-picker {
    width: 100%;
  }

  & .ant-divider{
    margin: 1em;
  }

  & .ant-form-large .ant-form-item-label > label {
    height: 25px;
  }
`

const AdvancedWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`

const AdvancedFormWrap = styled(Form.Item)`
  &.ant-form-item {
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;

    .ant-col-14 {
      flex: 0 0 0;
    }

    .ant-form-item-label {
      padding: 0 0 0 1rem;
    }
  }
`

const AdvancedHeader = styled.h3`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
`

/**
 * @typedef UserTenantAccessModalProps
 * @type {object}
 *
 * @param {UserTenantAccessModalProps} props
 * @returns {React.ReactNode}
 */

const UserTenantAccessModal = ({
  userTenants,
  userId,
  edit = false,
  refresh,
  children
}) => {
  const {
    tenants: { data: tenants } = {},
    loading,
    bindOrUpdateUserTenantRel
  } = useTenants({ params: { noPagination: 1 } })
  const [visible, setVisible] = useState(false)
  const [showAdvanced, setShowAdvanced] = useState(false)
  const [form] = Form.useForm()

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = e => {
    form.resetFields()
    setVisible(false)
  }

  const handleSubmit = obj => {
    const { tenantId: { value: tenantId }, permission = 1, active, ...importOpts } = obj
    const payload = { permission, active: !!active, ...importOpts }
    bindOrUpdateUserTenantRel(tenantId, userId, payload)
      .then((res) => {
        form.resetFields()
        setVisible(false)
        refresh()
      }).catch(() => {})
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <div onClick={showModal}>
        {children ?? (
          <Button type='primary'>
            Add client
          </Button>
        )}
      </div>
      {visible &&
        loading
        ? <LoadingScreen />
        : (
          <Modal
            closable={false}
            visible={visible}
            destroyOnClose
            onCancel={handleCancel}
            bodyStyle={{ padding: '0' }}
            footer={[
              <FooterContainer key='buttons'>
                <ButtonContainer>
                  <Button fill outline key='cancel' onClick={handleCancel}>
                    Cancel
                  </Button>
                </ButtonContainer>
                <ButtonContainer>
                  <Button key='submit' type='primary' htmlType='submit' form='addTenant' disabled={!tenants}>
                    {edit ? 'Update client' : 'Add client'}
                  </Button>
                </ButtonContainer>
              </FooterContainer>
            ]}
          >
            <div>
              <TitleContainer>
                <h2>{edit ? 'Edit client' : 'Add client'}</h2>
              </TitleContainer>
              <FormContainer>
                <Form
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 14 }}
                  layout='vertical'
                  size='large'
                  id='addTenant'
                  onFinish={handleSubmit}
                  colon={false}
                  form={form}
                  initialValues={{
                    spacePermission: c.ACCESS_READ,
                    permission: c.ACCESS_READ,
                    active: 'checked'
                  }}
                >
                  {() => (
                    <>
                      <Form.Item
                        name='permission'
                        label='Select accessLevel'
                      >
                        <Radio.Group
                          options={c.TENANT_PERMISSION_OPTIONS}
                          optionType='button'
                          buttonStyle='solid'
                        />
                      </Form.Item>
                      <Form.Item
                        name='active'
                        label='active'
                        valuePropName='checked'
                      >
                        <Switch
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                        />
                      </Form.Item>
                      <Form.Item
                        label='Select client'
                        name='tenantId'
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: `Please select a client to add to user ${userId}`
                          }]}
                      >
                        {tenants
                          ? (
                            <Select
                              labelInValue
                              placeholder='Select client'
                              notFoundContent={null}
                              style={{ width: '100%' }}
                              showSearch
                              optionFilterProp='children'
                              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                              {tenants.map(tenant => {
                                const found = userTenants.some(userTenant => userTenant.id === tenant.id)
                                return <Option disabled={found} key={tenant.id} value={tenant.id}>{tenant.name}</Option>
                              })}
                            </Select>
                          )
                          : (
                            <Alert
                              message='Warning'
                              description='No clients found'
                              type='error'
                              showIcon
                            />)}
                      </Form.Item>
                      <AdvancedWrap>
                        <p>Advanced setup</p>
                        <Switch
                          checkedChildren='1' unCheckedChildren='0'
                          style={{ margin: '0 1em' }}
                          onChange={state => setShowAdvanced(state)}
                        />
                      </AdvancedWrap>
                      {showAdvanced && (
                        <>
                          <Divider />
                          <AdvancedHeader>Import this user to client including all of it's</AdvancedHeader>
                          <AdvancedFormWrap
                            name='importSpaces'
                            label='Spaces'
                            valuePropName='checked'
                          >
                            <Switch
                              checkedChildren='1'
                              unCheckedChildren='0'
                            />
                          </AdvancedFormWrap>
                          {form.getFieldValue('importSpaces') && (
                            <Form.Item
                              name='spacePermission'
                              label='Import with Permisson'
                            >
                              <Select
                                style={{ width: '100%' }}
                                options={[
                                  { value: c.ACCESS_READ, label: 'User' },
                                  { value: c.ACCESS_WRITE, label: 'Installer' },
                                  { value: c.ACCESS_ADMIN, label: 'Admin' },
                                  { value: 'userPerms', label: 'Inherit from user' }
                                ]}
                              />
                            </Form.Item>
                          )}
                          <AdvancedFormWrap
                            name='importSensors'
                            label='Sensors'
                            valuePropName='checked'
                          >
                            <Switch
                              checkedChildren='1'
                              unCheckedChildren='0'
                            />
                          </AdvancedFormWrap>
                          <AdvancedFormWrap
                            name='importAlgos'
                            label='Algorithms'
                            valuePropName='checked'
                          >
                            <Switch
                              checkedChildren='1'
                              unCheckedChildren='0'
                            />
                          </AdvancedFormWrap>
                          <AdvancedFormWrap
                            name='importPlugins'
                            label='Machines'
                            valuePropName='checked'
                          >
                            <Switch
                              checkedChildren='1'
                              unCheckedChildren='0'
                            />
                          </AdvancedFormWrap>
                        </>
                      )}
                    </>
                  )}
                </Form>
              </FormContainer>
            </div>
          </Modal>
        )}
    </div>
  )
}

export default UserTenantAccessModal
