import { useState, useEffect } from 'react'
import { navigate } from '@redwoodjs/router'
import InfoCard from 'src/components/InfoCard'
import Button from 'src/components/Button'
import { useAuth, useIssues, useTenant, useUser } from 'src/hooks'
import ColorSpinner from 'src/components/ColorSpinner'
import { averageMessage } from 'src/libraries/comfort'

import * as c from 'src/constants'
import { groupSpacesByType, getSpaceRelations } from 'src/libraries/spaces'

import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const CountContainer = styled.div`
  text-align: center;
  padding-bottom: 1em;
  flex: 1;
`

const NumberFont = styled.h2`
  user-select: none;
  cursor: normal;
`

const UnitFont = styled.h5`
  font-weight: 300;
`

const InfoFont = styled.p`
  font-style: italic;
  font-weight: 300;
`

const Content = styled.div`
  display: flex;
  ${NumberFont} {
    color: ${props => props.numberColor};
  }
  ${UnitFont} {
    color: ${props => props.unitColor};
  }
  ${InfoFont} {
    color: ${props => props.infoColor};
  }
`

const StyledInfoCard = (props) => {
  return <InfoCard title='Comfort' titleSize='1.5rem' border style={{ color: props.color }} {...props} />
}

const InfoCards = (props) => {
  const { tenantId, getRoute } = useTenant()
  const { hasRole } = useAuth()
  const { spaces, spacesLoading } = props
  const [buldings, setBuildings] = useState('')
  const [floors, setFloors] = useState('')
  const [rooms, setRooms] = useState('')
  const [sensors, setSensors] = useState([])
  const [comfort, setComfort] = useState()
  const { user } = useUser()
  const { issueCount, issuesLoading } = useIssues({ user })

  useEffect(() => {
    if (!spacesLoading) { // as its fetched from parent
      const { scores, rooms, floors, sensors } = getSpaceRelations(spaces)
      const groupedByType = groupSpacesByType(spaces)
      const buildings = groupedByType[c.TYPE_BUILDING]
      const nBuildings = (buildings && buildings.length) || 0
      const nFloors = (floors && floors.length) || 0
      const nRooms = (rooms && rooms.length) || 0

      const { comfort } = scores
      const displayComfort = Math.round(comfort)
      setSensors(sensors)
      setComfort(displayComfort)
      setBuildings(nBuildings)
      setFloors(nFloors)
      setRooms(nRooms)
    }
  }, [spaces, spacesLoading, tenantId])

  const displayComfort = comfort
    ? `${comfort}%`
    : '-'
  const displaySensors = sensors.length > 0
    ? sensors.length
    : 'None'

  return (
    <Container>
      <StyledInfoCard
        icon='building' title='Buildings' color='#d46eea'
      >
        <Content numberColor='#471651'>
          {spacesLoading
            ? (
              <CountContainer>
                <ColorSpinner color='#471651' />
              </CountContainer>
            )

            : (
              <>
                <CountContainer>
                  <NumberFont>{buldings}</NumberFont>
                  <UnitFont>Buildings</UnitFont>
                </CountContainer>
                <CountContainer>
                  <NumberFont>{floors}</NumberFont>
                  <UnitFont>Floors</UnitFont>
                </CountContainer>
                <CountContainer>
                  <NumberFont>{rooms}</NumberFont>
                  <UnitFont>Rooms</UnitFont>
                </CountContainer>
              </>
            )}
        </Content>
        {hasRole(`tenant.${tenantId}.${c.ACCESS_WRITE}`) && (
          <Button
            size='large'
            type='primary'
            backgroundColor='#ac47c2'
            onClick={(e) => navigate(getRoute('buildings'))}
          >Buildings
          </Button>
        )}
        {/* <AntButton size='large' type='primary' style={{ background: '#ac47c2', borderColor: '#ac47c2', width: '100%', maxWidth: '400px', margin: '0 auto' }} onClick={(e) => navigate(routes.buildings())}>buildings</AntButton> */}
      </StyledInfoCard>
      {hasRole(`tenant.${tenantId}.${c.ACCESS_WRITE}`) && (
        <StyledInfoCard title='Issues' icon='exclamation-circle' color='#7f9cf0'>
          <Content unitColor='#7f9cf0' infoColor='#7f9cf0' numberColor='#233c84'>
            <CountContainer>
              <NumberFont>{
                issuesLoading
                  ? <ColorSpinner color='#7f9cf0' />
                  : issueCount
              }
              </NumberFont>
              <UnitFont>Machine Issues</UnitFont>
            </CountContainer>
          </Content>
          {hasRole(`tenant.${tenantId}.${c.ACCESS_WRITE}`)
            ? (
              <Button
                backgroundColor='#7f9cf0'
                onClick={(e) => navigate(getRoute('adminContent', {
                  type: 'errors',
                  redirectTo: 'app'
                }))}
              >
                Manage issues
              </Button>
            )
            : <InfoFont>The amount  issues are <strong>TBD</strong></InfoFont>}
        </StyledInfoCard>
      )}
      <StyledInfoCard title='Comfort' icon='mug-hot' color='#7edcb4'>
        <Content numberColor='#135a3c'>
          <CountContainer>
            <NumberFont>
              {spacesLoading
                ? <ColorSpinner color='#135a3c' />
                : displayComfort}
            </NumberFont>
            <UnitFont>Comfort</UnitFont>
          </CountContainer>
        </Content>
        <InfoFont>The average levels of comfort are <strong>{averageMessage(comfort)}</strong></InfoFont>
      </StyledInfoCard>
      <StyledInfoCard title='Devices' icon='mug-hot' color='#fdab63'>
        <Content numberColor='#b96d2a'>
          <CountContainer>
            <NumberFont>
              {spacesLoading
                ? <ColorSpinner color='#b96d2a' />
                : displaySensors}
            </NumberFont>
            <UnitFont>Devices</UnitFont>
          </CountContainer>
        </Content>
      </StyledInfoCard>
    </Container>
  )
}

export default InfoCards
