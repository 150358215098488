import { message } from 'antd'
import { useEffect, useState } from 'react'

import { apiInstance as api } from 'src/api'
import { useIsMounted, useTenant } from 'src/hooks'

export const useAlgorithmNodeDataType = (algorithmNodeDataTypeId) => {
  const mounted = useIsMounted()
  const { tenantId, isValidScopeFetch } = useTenant()
  const [algorithmNodeDataType, setAlgorithmNodeDataType] = useState({})
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())

  const refresh = () => setLastUpdate(new Date())

  const updateAlgorithmNodeDataType = (algoNodeId = algorithmNodeDataTypeId, newData) => {
    const hide = message.loading('Updating node data type...')

    api.updateAlgorithm(algoNodeId, newData)
      .then(() => {
        hide()
        message.success('Successfully updated node data type.')

        if (mounted()) {
          setAlgorithmNodeDataType({
            id: algoNodeId,
            ...newData
          })
        }
      })
      .catch(() => {
        if (mounted()) setError()
      })
      .finally(() => hide())
  }

  const addAlgorithmNodeDataType = node => {
    const hide = message.loading('Adding node data type...')
    api.addAlgorithmNodeDataType(node)
      .then(() => {
        hide()
        message.success('Successfully added node data type')
        if (mounted()) setAlgorithmNodeDataType(node)
      })
      .catch(() => {
        if (mounted()) setError()
      })
      .finally(() => hide())
  }

  useEffect(() => {
    if (!algorithmNodeDataTypeId) return

    setLoading(true)

    api.getAlgorithmNode(algorithmNodeDataTypeId)
      .then(({ data, config }) => {
        if (mounted() && isValidScopeFetch(config)) {
          console.log('data', data)
          setAlgorithmNodeDataType(data)
        }
      })
      .catch((e) => {
        if (mounted()) {
          setAlgorithmNodeDataType({})
          setError(e)
        }
      })
      .finally(() => {
        if (mounted()) setLoading(false)
      })
  }, [lastUpdate, tenantId])

  return {
    algorithmNodeDataType,
    loading,
    error,
    refresh,
    updateAlgorithmNodeDataType,
    addAlgorithmNodeDataType
  }
}

export const useAlgorithmNodeDataTypes = () => {
  const mounted = useIsMounted()
  const [algorithmNodeDataTypes, setAlgorithmNodeDataTypes] = useState([])
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())

  const refresh = () => setLastUpdate(new Date())

  useEffect(() => {
    setLoading(true)
    api.getAlgorithmNodeDataTypes()
      .then(({ data }) => {
        if (mounted()) {
          setAlgorithmNodeDataTypes(data)
        }
      })
      .catch(e => {
        if (mounted()) {
          setAlgorithmNodeDataTypes([])
          setError(e)
        }
      })
      .finally(() => {
        if (mounted()) setLoading(false)
      })
  }, [lastUpdate])

  return {
    algorithmNodeDataTypes,
    loading,
    error,
    refresh
  }
}
