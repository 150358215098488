import { useState, useEffect } from 'react'
import Bugsnag from '@bugsnag/browser'

import { useIsMounted } from 'src/hooks'
import { apiInstance as api } from 'src/api'
import { message } from 'antd'

export const useAdminSensorSetting = ({ fetch = true, id } = {}) => {
  const mounted = useIsMounted()

  const [loading, setLoading] = useState(false)
  const [sensorSettings, setSensorSettings] = useState([])
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())

  const refresh = () => {
    if (mounted()) setLastUpdate(new Date())
  }

  const getSensorSettings = async () => {
    setLoading(true)

    try {
      const { data } = typeof id === 'number'
        ? await api.getSensorSettingGroup(id)
        : await api.getSensorSettingGroups()

      console.log({data })

      if (mounted()) {
        setSensorSettings(
          Array.isArray(data)
            ? data
            : [data]
        )
      }
    } catch (error) {
      Bugsnag.notify(error)
      if (mounted()) {
        setSensorSettings([])
        setError(error)
      }
    } finally {
      if (mounted()) setLoading(false)
    }
  }

  useEffect(() => {
    if (!fetch) return

    getSensorSettings()
  }, [lastUpdate])

  const addSensorGroup = async payload => {
    setLoading(true)
    const hide = message.loading('Adding setting group...')

    try {
      await api.postSensorSettingGroups(payload)

      hide()
      message.success('Successfully added setting group')
    } catch (error) {
      Bugsnag.notify(error)
      message.error('Setting group couldn\'t be added')
      if (mounted()) setError()
    } finally {
      hide()
      setLoading(false)
    }
  }

  const updateSensorGroup = async (payload, settingId = id) => {
    setLoading(true)
    const hide = message.loading('Updating setting group...')
    console.log({ payload, settingId})

    try {
      await api.updateSensorSettingGroup(settingId, payload)

      hide()
      message.success('Successfully updated setting group')
    } catch (error) {
      Bugsnag.notify(error)
      message.error('Setting group couldn\'t be updated')
      if (mounted()) setError()
    } finally {
      hide()
      setLoading(false)
    }
  }

  const deleteSensorSettingGroup = async (settingId) => {
    setLoading(true)
    const hide = message.loading('Deleting setting group...')

    try {
      await api.deleteSensorSettingGroup(settingId)

      hide()
      message.success('Successfully deleted setting group')
    } catch (error) {
      Bugsnag.notify(error)
      message.error('Setting group couldn\'t be deleted')
      if (mounted()) setError()
    } finally {
      hide()
      setLoading(false)
    }
  }

  const addGroupSetting = async payload => {
    setLoading(true)
    const hide = message.loading('Adding setting...')

    try {
      await api.postSensorSettingConfigurableSettings(id, payload)

      hide()
      message.success('Successfully added setting')
    } catch (error) {
      Bugsnag.notify(error)
      message.error('Setting couldn\'t be added')
      if (mounted()) setError()
    } finally {
      hide()
      setLoading(false)
    }
  }

  return {
    sensorSettings,
    sensorSetting: typeof id === 'number'
      ? sensorSettings?.[0]
      : null,
    loading,
    error,
    refresh,
    addSensorGroup,
    updateSensorGroup,
    addGroupSetting,
    deleteSensorSettingGroup
  }
}

export const useAdminSensorConfigurableSetting = ({ fetch = true, id } = {}) => {
  const mounted = useIsMounted()

  const [loading, setLoading] = useState(false)
  const [configurableSetting, setConfigurableSetting] = useState({})
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())

  const refresh = () => {
    if (mounted()) setLastUpdate(new Date())
  }

  const getSensorConfigurableSettings = async () => {
    setLoading(true)

    try {
      const { data } = await api.getSensorSettingConfigurableSetting(id)

      if (mounted()) {
        setConfigurableSetting(data)
      }
    } catch (error) {
      Bugsnag.notify(error)
      if (mounted()) {
        setConfigurableSetting({})
        setError(error)
      }
    } finally {
      if (mounted()) setLoading(false)
    }
  }

  const addPreset = async payload => {
    setLoading(true)
    const hide = message.loading('Adding preset...')

    try {
      await api.postSensorSettingConfigurableSettingPreset(id, payload)

      hide()
      message.success('Successfully added preset')
    } catch (error) {
      Bugsnag.notify(error)
      message.error('Preset couldn\'t be added')
      if (mounted()) setError()
    } finally {
      hide()
      setLoading(false)
    }
  }

  const updateConfigurableSetting = async (payload, settingId = id) => {
    setLoading(true)
    const hide = message.loading('Updating setting...')

    try {
      await api.patchSensorSensorSettingConfigurableSetting(settingId, payload)

      hide()
      message.success('Successfully updated setting')
    } catch (error) {
      Bugsnag.notify(error)
      message.error('Setting couldn\'t be updated')
      console.log({ error })
      if (mounted()) setError()
    } finally {
      hide()
      setLoading(false)
      refresh()
    }
  }

  const deleteConfigurableSetting = async settingId => {
    setLoading(true)
    const hide = message.loading('Deleting setting...')

    try {
      await api.deleteSensorSensorSettingConfigurableSetting(settingId)

      hide()
      message.success('Successfully deleted setting')
    } catch (error) {
      Bugsnag.notify(error)
      message.error('Setting couldn\'t be deleted')
      console.log({ error })
      if (mounted()) setError()
    } finally {
      hide()
      setLoading(false)
      refresh()
    }
  }

  useEffect(() => {
    if (!fetch) return

    getSensorConfigurableSettings()
  }, [lastUpdate])

  return {
    configurableSetting,
    loading,
    error,
    refresh,
    addPreset,
    updateConfigurableSetting,
    deleteConfigurableSetting
  }
}
export const useAdminSensorConfigurableSettingPreset = ({ id } = {}) => {
  const mounted = useIsMounted()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  const updatePreset = async (payload, presetId = id) => {
    setLoading(true)
    const hide = message.loading('Updating preset...')

    try {
      await api.updateSensorSettingConfigurableSettingPreset(presetId, payload)

      hide()
      message.success('Successfully updated preset')
    } catch (error) {
      Bugsnag.notify(error)
      message.error('Preset couldn\'t be updated')
      if (mounted()) setError(error)
    } finally {
      hide()
      setLoading(false)
    }
  }

  const deletePreset = async presetId => {
    setLoading(true)
    const hide = message.loading('Deleting preset...')

    try {
      await api.deleteSensorSettingConfigurableSettingPreset(presetId)

      hide()
      message.success('Successfully deleted preset')
    } catch (error) {
      Bugsnag.notify(error)
      message.error('Preset couldn\'t be deleted')
      if (mounted()) setError(error)
    } finally {
      hide()
      setLoading(false)
    }
  }

  return {
    loading,
    error,
    updatePreset,
    deletePreset
  }
}
