import { useState, useEffect } from 'react'
import { navigate } from '@redwoodjs/router'
import AppLayout from 'src/layouts/AppLayout'
import AppHeader from 'src/components/AppHeader'
import AppContent from 'src/components/AppContent'
import Button, { BackButton } from 'src/components/Button'
import AppTitle from 'src/components/AppTitle'
import Spin from 'src/components/Spin'
import CodeEditor from 'src/components/CodeEditor'
import { Tag as _Tag, Input, Switch, Tooltip, Alert } from 'antd'

import * as c from 'src/constants'

import { useAlgorithm, useAuth, useTenant } from 'src/hooks'
import styled from 'styled-components'

const Tag = styled(_Tag)`
  cursor: normal;
  &:hover {
    opacity: initial;
  }
`

const TopRight = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
`

const Spacing = styled.div`
  margin-top: 1rem;
  display: flex;

`

const CodeEditorContainer = styled.div`
  background-color: #0d0d0d;
  height: 500px;
`

const Wrapper = styled.div`
  justify-content: center;
  display: flex;
  flex: 1;
`

const PaperTitle = styled.p`
  font-family: 'Brush Script MT';
  font-size: 2rem;
  margin: 0;
`
const PaperDesc = styled.p`
  font-weight: normal;
  font-size: 1rem;
  margin-bottom: 2rem;
  color: #ebfdff;
`
const FormLabel = styled.p`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`

const FormDescription = styled.p`
  font-weight: normal;
  color: white;
`

const Space = styled.div`
  padding-top: 2rem;
`
const Paper = styled.div`
  position: relative;
  padding: 1rem;
  flex: 1;
  color: white;
  max-width: 1280px;
  margin: 0 auto;
  font-weight: bold;
  box-shadow: 0 10px 25px rgba(0,0,0,0.25);
  border-radius: 14px;
  background-image: url('https://www.graphicdesignjournal.com/wp-content/uploads/2019/04/blueprint_texture.jpeg');
`
const mockFuncs = {
  measurements: {
    avg: {}
  },
  user: {
    triggerDevices: []
  },
  push: {
    send: () => null
  }
}

const AlgorithmPage = ({ id }) => {
  const isAddMode = id === 'add'
  const { getRoute, isTenantScope } = useTenant()
  const { hasRole } = useAuth()
  const { algorithm, loading, error, addAlgorithm, updateAlgorithm } = useAlgorithm(id)
  const [edit, setEdit] = useState(isAddMode)

  const TYPING_TIMEOUT = 350

  const [name, setName] = useState()
  const [description, setDescription] = useState()
  const [jsTresholdFunc, setJsTresholdFunc] = useState()
  const [configurableSettings, setConfigurableSettings] = useState()

  // test functionality before saving
  const [csError, setCsError] = useState()
  const [algoError, setAlgoError] = useState()
  const [parsedCs, setParsedCs] = useState()

  const syntaxChecksOut = !csError && !algoError

  const handleSave = (e) => {
    if (id) {
      if (isAddMode) {
        addAlgorithm({
          name,
          description,
          jsTresholdFunc,
          configurableSettings
        })
      } else {
        updateAlgorithm(id, {
          name,
          description,
          jsTresholdFunc,
          configurableSettings
        })
      }
      setEdit(false)
    }
  }

  const parseJSON = (jsonStr) => {
    return JSON.parse(jsonStr)
  }

  const runJS = (jsStr) => {
    const res = new Function(...Object.keys(mockFuncs), jsStr)
    return //res(...Object.values(mockFuncs))
  }

  const handleChange = (setter) =>
    (value) => {
      if (value.target) {
        setter(value.target.value)
      } else {
        setter(value)
      }
    }
  useEffect(() => {
    setCsError()
    const id = setTimeout(() => { // wait three sec before validating
      try {
        const parsed = parseJSON(configurableSettings)
        setParsedCs(parsed)
      } catch (e) {
        setCsError(e)
        setParsedCs()
      }
    }, TYPING_TIMEOUT)
    return () => clearTimeout(id)
  }, [configurableSettings])
  useEffect(() => {
    setAlgoError()
    const id = setTimeout(() => {
      try {
        //runJS(jsTresholdFunc)
      } catch (e) {
        setAlgoError(e)
      }
    }, TYPING_TIMEOUT)
    return () => clearTimeout(id)
  }, [jsTresholdFunc])
  useEffect(() => {
    if (algorithm) {
      const {
        jsTresholdFunc,
        name,
        description,
        configurableSettings
      } = algorithm
      setName(name)
      setDescription(description)
      setJsTresholdFunc(jsTresholdFunc)
      setConfigurableSettings(configurableSettings || '{}')
    }
  }, [algorithm])
  const confs = parsedCs && parsedCs.user
    ? Object.entries(parsedCs.user)
    : []
  return (
    <AppLayout>
      <AppHeader>
        <BackButton onClick={(e) => navigate(getRoute('adminContent', { type: 'algorithms' }))} />
        <AppTitle noMargin>{name}</AppTitle>
      </AppHeader>
      <AppContent>
        {loading && id
          ? <Spin />
          : (
            <Wrapper>
              <Paper>
                <TopRight
                  onClick={() => {
                    if (hasRole('superAdmin') || (isTenantScope() && !algorithm?.global)) {
                      setEdit(!edit)
                    }
                  }}
                >
                  {(hasRole('superAdmin') || (isTenantScope() && !algorithm?.global)) && (
                    <>
                      <i color='white' className='fas fa-pen' style={{ marginRight: '0.5rem' }} />
                      <Switch
                        size='small'
                        checked={edit}
                      />
                    </>
                  )}
                </TopRight>
                {edit && <Space />}
                {edit
                  ? <Input value={name} placeholder='Name' onChange={handleChange(setName)} />
                  : <PaperTitle>{name}</PaperTitle>}
                {edit
                  ? (
                    <Input.TextArea
                      value={description}
                      style={{ marginTop: '0.5rem' }}
                      placeholder='Description'
                      onChange={handleChange(setDescription)}
                    />
                  )
                  : <PaperDesc>{description}</PaperDesc>}

                <FormLabel>Settings for the Algorithm</FormLabel>
                <FormDescription>
                  Following JSON will dictate the configurable variables of the algo.
                </FormDescription>
                {csError && <Alert message={csError.message} type='error' />}
                <CodeEditorContainer>
                  <CodeEditor
                    defaultLanguage='json'
                    onChange={handleChange(setConfigurableSettings)}
                    value={configurableSettings}
                  />
                </CodeEditorContainer>

                <FormLabel>Body of Algorithm</FormLabel>
                <FormDescription>
                  The following variables and functions are available in the scope of the algorithm.
                </FormDescription>
                <FormDescription>
                  <Tag color='gold'>push.send</Tag>
                  <Tag color='gold'>issues.add</Tag>
                  <Tag color='gold'>notifications.add</Tag>
                  <Tag color='gold'>measurements.add</Tag>
                  <Tag color='gold'>device.send</Tag>
                </FormDescription>
                <FormDescription>
                  <Tag color='geekblue'>sensors</Tag>
                  <Tag color='geekblue'>measurements[msr]</Tag>
                  <Tag color='geekblue'>measurements.avg[msr]</Tag>
                  <Tag color='geekblue'>user[conf]</Tag>
                </FormDescription>
                <FormDescription>
                  <span>msr: </span>
                  <Tag color='green'>temp</Tag>
                  <Tag color='green'>hum</Tag>
                  <Tag color='green'>co2</Tag>
                  <Tag color='green'>tvoc</Tag>
                </FormDescription>
                <FormDescription>
                  <span>conf: </span>
                  {confs.map(([key, value]) =>
                    <Tooltip key={key} title={value.description}>
                      <Tag color='green'>{key} : {
                        Array.isArray(value.type)
                          ? `[${value.type}]`
                          : value.type
                      }
                      </Tag>
                    </Tooltip>)}
                </FormDescription>
                {algoError && <Alert message={algoError.message} type='error' />}
                <CodeEditorContainer>
                  <CodeEditor
                    onChange={handleChange(setJsTresholdFunc)}
                    value={jsTresholdFunc}
                  />
                </CodeEditorContainer>
                <Spacing>
                  {syntaxChecksOut && (
                    <Button
                      onClick={handleSave}
                      color='white'
                      outline
                    >{isAddMode ? 'Add' : 'Save'}
                    </Button>
                  )}
                </Spacing>
              </Paper>
            </Wrapper>
          )}
      </AppContent>
    </AppLayout>
  )
}

export default AlgorithmPage
