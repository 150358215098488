import React from 'react';
import { Button } from 'antd'
import {
  getBezierPath,
  getEdgeCenter,
  getMarkerEnd,
} from 'react-flow-renderer';
import { DeleteOutlined } from '@ant-design/icons'

import styled from 'styled-components';
import { useAlgorithmFlow } from 'src/hooks/flow';

const StyledBody = styled.body`
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`

const foreignObjectSize = 70;

export default ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  arrowHeadType,
  markerEndId,
}) => {
  const { handleDeleteNode } = useAlgorithmFlow()
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const handleDelete = (event, id) => {
    event.stopPropagation()

    handleDeleteNode({ id })
  };

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={edgeCenterX - foreignObjectSize / 2}
        y={edgeCenterY - foreignObjectSize / 2}
        className="edgebutton-foreignobject"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <StyledBody>
          <Button
            shape='circle'
            icon={<DeleteOutlined />}
            onClick={event => handleDelete(event, id)}
          />
        </StyledBody>
      </foreignObject>
    </>
  );
}