import { format } from 'date-fns'

export const valueFormatter = (value, key, config = {}) => {
  return parseInt(value) + ' '
}

export const getFormatters = (periodConfig) => ({
  labelFormatter: (label) => periodConfig
    ? format(new Date(label), 'HH:mm · dd-MM-yyyy')
    : '',
  tickFormatter: (tick) => periodConfig
    ? format(new Date(tick), periodConfig.dateFormat)
    : ''
})

export const xTickStyle = { fill: 'lightgray' }

export const memoMapDisplay = (keyIndex, units) => (displayKeys, cb) => {
  const keys = keyIndex
  const length = keys.length
  return keys
    .map((key, i) => {
      if (displayKeys[key] === true) {
        const yAxisId = (key === 'co2' || key === 'tvoc')
          ? 'threeDigits'
          : 'twoDigits'
        return cb(key, {
          yAxisId,
          unit: units[key],
          index: i,
          length
        })
      }
    })
}
