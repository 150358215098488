import styled from 'styled-components'
import Button from 'src/components/Button'
import { useAuth } from 'src/hooks'

const TableWrap = styled.div`
  padding: 1em;
`
const DataWrap = styled.div`
  display: flex;
  width: 100%;
`

const DataHeader = styled.h3`
  font-size: 25px;
  font-weight: bold;
  font-style: italic;
  line-height: 1.2;
`

const DataText = styled.div`
  font-size: 20px;
  text-transform: capitalize;
  &.data{
    font-weight: bold;
  }
`

const InfoWrap = styled.div`
  min-width: 225px;
`

const Center = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em 0 ;
`

const AdminUserStats = (props) => {
  const { user } = props

  return (
    <div>
      <TableWrap>
        <DataHeader>Properties</DataHeader>
        {user.id != null && (
          <DataWrap>
            <InfoWrap><DataText>user ID</DataText></InfoWrap>
            <DataText className='data'>{user.id}</DataText>
          </DataWrap>
        )}
        {user.username != null && (
          <DataWrap>
            <InfoWrap><DataText>Username</DataText></InfoWrap>
            <DataText className='data'>{user.username}</DataText>
          </DataWrap>
        )}
        {user.displayname != null && (
          <DataWrap>
            <InfoWrap><DataText>Displayname</DataText></InfoWrap>
            <DataText className='data'>{user.displayname}</DataText>
          </DataWrap>
        )}
        {user.email != null && (
          <DataWrap>
            <InfoWrap><DataText>Email</DataText></InfoWrap>
            <DataText className='data'>{user.email}</DataText>
          </DataWrap>
        )}
        {user.terms != null && (
          <DataWrap>
            <InfoWrap><DataText>Terms</DataText></InfoWrap>
            <DataText className='data'>{user.terms}</DataText>
          </DataWrap>
        )}
        {user.userSource != null && (
          <DataWrap>
            <InfoWrap><DataText>User Source</DataText></InfoWrap>
            <DataText className='data'>{user.userSource}</DataText>
          </DataWrap>
        )}
        {user.updatedAt != null && (
        <DataWrap>
          <InfoWrap><DataText>Updated At</DataText></InfoWrap>
          <DataText className='data'>{user.updatedAt}</DataText>
        </DataWrap>
        )}
        {user.created != null && (
          <DataWrap>
            <InfoWrap><DataText>Created at</DataText></InfoWrap>
            <DataText className='data'>{user.created}</DataText>
          </DataWrap>
        )}
        {/* <Center>
          <Button outline style={{ width: '100%' }}>More information</Button>
        </Center> */}
      </TableWrap>
    </div>
  )
}

export default AdminUserStats
