import { useEffect, useState } from 'react'

export const useTypeTimeout = ({ timeout } = {}) => {
  const [value, setValue] = useState('')
  const [deferredValue, setDeferredValue] = useState('')
  const [timeoutState, setTimeoutState] = useState()

  const cancelTimeout = () => {
    if (timeoutState) clearTimeout(timeoutState)
    setDeferredValue(value)
  }

  useEffect(() => {
    if (typeof value === 'string' || typeof value === 'number' || value == null) {
      if (timeoutState) clearTimeout(timeoutState)

      setTimeoutState(setTimeout(() => setDeferredValue(value), timeout ?? 1000))

      return () => clearTimeout(timeoutState)
    }
  }, [value])

  return {
    value,
    deferredValue,
    cancelTimeout,
    setValue
  }
}
