import AdminList from 'src/components/AdminList'
import AppTitle from 'src/components/AppTitle'
import Button from 'src/components/Button'
import styled from 'styled-components'
import { format } from 'date-fns'

import { useIssues, useUser } from 'src/hooks'

const ListWrap = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;
  flex-direction: column;
  margin: 0 auto;
`

const HoverText = styled.p`
  cursor: help;
  transition: opacity 0.25s ease-out;
  &:hover {
    opacity: 0.9;
  }
`

const ActionWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const StateLabel = styled.p`
  margin: 0;
  color: ${props => props.notice
  ? 'black'
  : 'lightgray'};
`

const AdminError = (props) => {
  const { typeName } = props
  const { user } = useUser()
  const {
    issuesByType,
    markIssuesAsRead,
    issuesReadAt
  } = useIssues({ user })
  const categories = issuesByType
    ? Object.entries(issuesByType)
    : []
  return (
    <ListWrap>
      <ActionWrap>
        <Button onClick={markIssuesAsRead}>Mark all as read</Button>
      </ActionWrap>
      {categories.map(([key, value]) =>
        <div key={key}>
          <AppTitle noMargin>{key === 'null' ? 'Uncategorized' : key}</AppTitle>
          <AdminList
            addAction={false}
            search={false}
            typeName={typeName}
            data={value}
            columns={[
              {
                title: 'Timestamp',
                dataIndex: 'time',
                render: time => {
                  const date = new Date(time)
                  const formatted = format(date, 'yyyy-MM-dd, hh:mm')
                  const shouldNotice = date > issuesReadAt
                  return <StateLabel notice={shouldNotice}>{formatted}</StateLabel>
                }
              },
              {
                title: 'Sensor',
                dataIndex: 'deviceId'
              },
              {
                title: 'Message',
                dataIndex: 'message'
              },
              {
                title: 'Code',
                dataIndex: 'code',
                responsive: ['md']
              },
              {
                title: 'Value',
                dataIndex: 'value',
                responsive: ['md']
              }
            ]}
          />
        </div>
      )}
    </ListWrap>
  )
}

export default AdminError
