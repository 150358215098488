import React from 'react'
import { Select } from 'antd'

import { useAlgorithmFlow } from 'src/hooks/flow'

const OutputDataTypeFieldSelect = {
  render: props => {
    const { nodes, loading } = useAlgorithmFlow()

    const { AlgorithmNode } = nodes?.find(({ id }) => id === props?.fieldName?.[0]) ?? {}
    const fieldOptions = AlgorithmNode?.OutputDataType?.fields?.map(field => ({
      value: field?.name
    })) ?? []

    return (
      <Select
        placeholder='Select field key'
        showSearch
        showArrow
        loading={loading}
        options={fieldOptions}
        {...props}
      />
    )
  }
}

export default {
  OutputDataTypeFieldSelect
}
