import { useState } from 'react'
import { Button as AntButton } from 'antd'
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import LangDropdown from 'src/components/LangDropdown'
import UserAvatar from 'src/components/UserAvatar'
import NotificationBadge from 'src/components/NotificationBadge'
import { useUser } from 'src/hooks'
import Button from 'src/components/Button'

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 60px;
  background: white;

@media (min-width: 769px) {
    & .mobile-content {
      display: none;
    }
  }
`

const ContentWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const LogoContainer = styled.div`
  min-height: 56px;
  padding: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    min-height: 50px;
    padding: 0 0 0 0.5em
  }
`
const Logo = styled.img`
  max-width: 100%;
  max-height: 40px;

  @media (max-width: 768px) {
    max-height: 35px;
  }
`

const DashboardHeader = (props) => {
  const {
    assets
  } = props

  const { user } = useUser()

  // const errorData = [{ id: 1, type: 'failure', title: 'error one', info: 'info text about error' }, { id: 2, type: 'failure', title: 'error two', info: 'info text about error' }]
  const errorData = []

  return (
    <HeaderContainer>
      <ContentWrap>
        <LogoContainer className='mobile-content'>
          <Logo src={assets.logoSmall} />
        </LogoContainer>
        <AntButton size='large' type='link' onClick={() => props.collapsMenu()}>
          {props.collapsedState
            ? <MenuUnfoldOutlined style={{ fontSize: '18px' }} />
            : <MenuFoldOutlined style={{ fontSize: '18px' }} />}
        </AntButton>
      </ContentWrap>
      <ContentWrap>
        <NotificationBadge data={errorData} />
        <UserAvatar user={user} dashboard />
        <LangDropdown />
      </ContentWrap>
    </HeaderContainer>
  )
}

export default DashboardHeader
