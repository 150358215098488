import React from 'react'
import { Tabs } from 'antd'
import styled from 'styled-components'

import AlgorithmTab from 'src/components/AdminAlgorithmsList'
import NodesTab from './NodesTab'
import NodeTypesTab from './NodeTypesTab'
import DataTypesTab from 'src/components/Flows/DataTypes'

const StyledTabs = styled(Tabs)`
  padding: 1rem;
  width: 100%;
  max-width: 1200px;
`

/**
 * @typedef AdminFlowsProps
 * @type {object}
 *
 * @param {AdminFlowsProps} props
 * @returns {React.ReactNode}
 */

const AdminFlows = ({ typeName }) => {
  return (
    <StyledTabs>
      <Tabs.TabPane tab='Flows' key='flows'>
        <AlgorithmTab onlyFlows />
      </Tabs.TabPane>
      <Tabs.TabPane tab='Nodes' key='nodes'>
        <NodesTab />
      </Tabs.TabPane>
      <Tabs.TabPane tab='Node Types' key='nodeTypes'>
        <NodeTypesTab />
      </Tabs.TabPane>
      <Tabs.TabPane tab='Data Types' key='dataTypes'>
        <DataTypesTab />
      </Tabs.TabPane>
    </StyledTabs>
  )
}

export default AdminFlows
