import { Input, Switch } from 'antd'
import { Position } from 'react-flow-renderer'
import { ApiComponentOptions } from 'src/components/ApiInputComponents'

export const TYPE_BUILDING = 1
export const TYPE_FLOOR = 2
export const TYPE_ROOM = 3

export const TAB_USER_DATA = 1
export const TAB_MACHINE_DATA = 2

export const SMALL_SCREEN = '767px'
export const MIN_SMALL_SCREEN = '768px'
export const MEDIUM_SCREEN = '1200px'

export const ACCESS_READ = 1
export const ACCESS_WRITE = 2
export const ACCESS_ADMIN = 3

export const ROOM_KITCHEN = 1
export const ROOM_BEDROOM = 2
export const ROOM_BATHROOM = 3
export const ROOM_TOILET = 4
export const ROOM_HALL = 5
export const ROOM_OFFICE = 6

export const SPACE_USER = 1
export const SPACE_INSTALLER = 2
export const SPACE_OWNER = 3

export const CHART_HOUR = 1
export const CHART_DAY = 2
export const CHART_WEEK = 3
export const CHART_MONTH = 4
export const CHART_YEAR = 5

export const MAX_RETRIES = 3

export const TENANT_PERMISSION_OPTIONS = [
  { label: 'User', value: ACCESS_READ },
  { label: 'Admin', value: ACCESS_WRITE }
]

export const SPACE_PERMISSION_OPTIONS = [
  { label: 'User', value: SPACE_USER },
  { label: 'Installer', value: SPACE_INSTALLER },
  { label: 'Admin', value: SPACE_OWNER }
]

export const COMMAND_PARAM_USER_UI_OPTIONS = [
  { label: 'Input', value: 'Input', comp: Input }
]

export const SENSOR_CONFIG_COMPONENTS = [
  { value: 'Input', comp: Input, default: true },
  {
    value: 'Switch',
    comp: Switch,
    wrapperProps: {
      valuePropName: 'checked'
    }
  }
]

export const COMMAND_ACCESS_USER = 1
export const COMMAND_ACCESS_TENANT_ADMIN = 2
export const COMMAND_ACCESS_SUPER_ADMIN = 3

export const COMMAND_ACCESS_MAP = {
  [COMMAND_ACCESS_USER]: 'User',
  [COMMAND_ACCESS_TENANT_ADMIN]: 'Tenant Admin',
  [COMMAND_ACCESS_SUPER_ADMIN]: 'Super admin'
}

export const COMMAND_PERMISSION_OPTIONS = [
  { label: COMMAND_ACCESS_MAP[COMMAND_ACCESS_USER], value: COMMAND_ACCESS_USER },
  { label: COMMAND_ACCESS_MAP[COMMAND_ACCESS_TENANT_ADMIN], value: COMMAND_ACCESS_TENANT_ADMIN },
  { label: COMMAND_ACCESS_MAP[COMMAND_ACCESS_SUPER_ADMIN], value: COMMAND_ACCESS_SUPER_ADMIN }
]

export const CUSTOM_NODE_TYPE_COMPONENT_BODYS = {}

export const REACT_FLOW_HANDLE_POSITION_OPTIONS = Object.keys(Position).map(key => ({ label: key, value: Position[key] }))

export const RADIO_DATA_TYPE_OPTIONS = [
  { label: 'Number', value: 'number' },
  { label: 'String', value: 'string' },
  { label: 'Boolean', value: 'boolean' },
]

export const NODE_SETTING_COMPONENTS = [
  ...ApiComponentOptions
]

export const EMAIL_TYPES = [
  {
    typeName: 'passwordReset',
    title: 'Password reset email'
  },
  {
    typeName: 'passwordResetSuccess',
    title: 'Password reset success email'
  },
  {
    typeName: 'emailConfirmation',
    title: 'Email confirmation email'
  },
  {
    typeName: 'passwordlessLogin',
    title: 'Passwordless login email'
  }
]
