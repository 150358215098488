import styled, { css } from 'styled-components'
import * as c from 'src/constants'

const Title = styled.h1`
  padding: 2rem 0 1.5rem 0;
  ${props => props.noMargin && css`
    padding: 0;
    margin: 0;
  `}
  font-style: ${({ italic }) => italic ? 'italic' : 'normal'};
  color: black;
  font-size: 2.6rem;
  font-weight: bold;
  line-height: 1.22;
  text-transform: capitalize;
  @media (max-width: ${c.SMALL_SCREEN}) {
    font-size: 1.75rem;
  }
`

export default Title
