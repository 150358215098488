import {
  Form,
  Input
} from 'antd'
import DeleteSensor from 'src/components/DeleteSensor'
import Button, { BackButton } from 'src/components/Button'

import styled from 'styled-components'

const UniFont = styled.span`
  font-weight: normal;
  color: ${props => props.color || 'black'};
  font-size: 20px;
`

const ConfigureSwitch = (props) => {
  const { sensor, setFormActive } = props
  const [form] = Form.useForm()

  return (
    <>
      <div>
        <BackButton onClick={() => setFormActive(1)} />
        <UniFont>Switch settings</UniFont>
      </div>
      <div>
        <span>FORM TBD</span>
      </div>
      <DeleteSensor sensor={sensor} />

    </>
  )
}

export default ConfigureSwitch
