import AdminList from 'src/components/AdminList'

import { useAlgorithmNodeDataTypes } from 'src/hooks/algorithmNodeDataTypes'
import { Menu, Dropdown } from 'antd'
import AdminAddAlgorithmNodeType from 'src/components/AdminAddAlgorithmNodeType'
import styled from 'styled-components'
import DeleteCheckModal from 'src/components/DeleteCheckModal'
import AdminAddAlgorithmNodeDataType from 'src/components/AdminAddAlgorithmNodeDataType'

const DropdownLink = styled.a`
  font-size: 1.5rem;
  color: ${props => props?.colors?.['card.menu.color'] || '#C4C4C4'};
  min-width: 25px;
  padding: 0 1rem;
`

const MenuItem = styled(Menu.Item)`
  text-align: center;

  button {
    width: 100%;
  }
`

const DropdownTrigger = styled.span`
  display: flex;
  justify-content: flex-end;
`

const DataTypesTab = (props) => {
  const { algorithmNodeDataTypes, loading, refresh } = useAlgorithmNodeDataTypes()

  const handleDelete = (record) => {
    /* deleteNodeType(record.id)
      .then(() => refresh()) */
  }

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => {
        const menu = (
          <Menu>
            <MenuItem>
              <AdminAddAlgorithmNodeDataType
                shouldUpdate={refresh}
                tableData={algorithmNodeDataTypes}
                record={record}
                edit
              />
            </MenuItem>
            <MenuItem>
              <DeleteCheckModal
                titleText='Are you sure you want to delete this node type?'
                deleteFn={() => handleDelete(record)}
                deleteBtnText='Yes, delete'
                cancelBtnText='No, cancel'
              />
            </MenuItem>
          </Menu>
        )

        return (
          <DropdownTrigger onClick={(e) => e.stopPropagation()}>
            <Dropdown
              overlay={menu}
              placement='bottomRight'
              trigger={['click']}
            >
              <DropdownLink className='ant-dropdown-link' onClick={e => e.stopPropagation()}>
                <i className='fas fa-ellipsis-v' />
              </DropdownLink>
            </Dropdown>
          </DropdownTrigger>
        )
      }
    }
  ]

  return (
    <AdminList
      data={algorithmNodeDataTypes}
      columns={columns}
      loading={loading}
      typeName='flowNodeDataTypes'
    />
  )
}

export default DataTypesTab
