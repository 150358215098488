import {
  RadialBarChart,
  RadialBar,
  ResponsiveContainer,
  PolarAngleAxis
} from 'recharts'
import styled from 'styled-components'
import { config } from 'src/libraries/measurements'

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 10;
  height: 100%;
`

const JumboValue = styled.p`
  font-weight: bolder;
  font-size: 2rem;
  color: black;
  text-align: center;
  margin:0;
`
const Subtitle = styled.p`
  margin: 0 auto;
  max-width: 50%;
  font-weight: normal;
  text-align: center;
  color: ${props => props.color || 'gray'};
`

const Relative = styled.div`
  position: relative;
  display: flex;
  flex: 1;
`

const RadialChartApp = (props) => {
  const { value: _value } = props
  const { displayRange: range, comfortableValues: cv, unit, displayName } = config.units.temp
  const value = !isNaN(_value) && _value.toFixed(1)
  const subOpts = value > cv.max
    ? { message: 'It\'s too warm', color: '#fa5a5a' }
    : value < cv.min
      ? { message: 'It\'s too cold', color: '#51b3e8' }
      : { message: 'Correct Temperature', color: '#0aad31' }
  const data = [{ name: displayName, value: value, fill: subOpts.color }]
  const displayJumbo = `${value}${unit}`
  return (
    <Relative>
      <ResponsiveContainer aspect={4 / 3}>
        <RadialBarChart
          data={data}
          innerRadius='75%'
          outerRadius='85%'
          barSize={10}
          startAngle={200}
          endAngle={-20}
        >
          <PolarAngleAxis type='number' domain={range} angleAxisId='temp' />
          <RadialBar
            cornerRadius='100%'
            angleAxisId='temp'
            dataKey='value'
            label={{ fill: 'transparent' }}
            background
            clockWise
          />
        </RadialBarChart>
      </ResponsiveContainer>
      <Overlay>
        <JumboValue>{displayJumbo}</JumboValue>
        <Subtitle color={subOpts.color}>{subOpts.message}</Subtitle>
      </Overlay>
    </Relative>
  )
}

export default RadialChartApp
