import styled, { css } from 'styled-components'

const CardWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin: ${props => props.noMargin ? '0' : '0.5rem 1rem'};
  border-radius: 0.5rem;
  padding: 1rem;
  background-color: white;
  color: ${props => props.theme.primaryColor};
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  font-weight: ${props => props.regular ? 'normal' : 'bold'};
  transition: box-shadow 0.5s ease-out, opacity 0.3s ease-out;
  ${props => props.active && css`
    color: ${props => props.theme.primaryTextColor};
    background-color: ${props => props.theme.primaryColor};
    box-shadow: none;
    opacity: 0.9;
  `}
  ${props => props.hover && css`
    cursor: pointer;
    &:hover {
      opacity: 0.8;
      box-shadow: 1px 3px 5px 0 rgba(0, 0, 0, 0.1);
    }
  `}
`

export default CardWrapper
