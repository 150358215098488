import { Input, message, Modal, Radio, Table, Form, Alert } from 'antd'
import { useEffect, useState } from 'react'
import { useAdminAllSensors, useAuth, useTenant, useTenants } from 'src/hooks'
import styled from 'styled-components'

import ColorSpinner from 'src/components/ColorSpinner/ColorSpinner'
import Button from 'src/components/Button'
import Bugsnag from '@bugsnag/js'
import QrScanner from 'src/components/QrScanner/QrScanner'
import * as c from 'src/constants'

const columns = [
  {
    title: 'Sensor',
    dataIndex: 'name',
    render: (name, rec) => `${name} (${rec?.deviceId})`
  }
]

const REGEX_MAC = /^(([A-Fa-f0-9]{2}[:]){5}[A-Fa-f0-9]{2}[,]?)+$/i

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
`
const TitleContainer = styled.div`
  padding: 1em 2em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: ${props => props.theme.primaryColor || '#7297ff'};
  & h2 {
    color: #fff;
  }
`
const FormContainer = styled.div`
  padding: 2em;
  & .ant-row.ant-form-item {
    justify-content: center;
  }
  & .ant-col-14 {
    max-width: 100%;
  }
`

const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  & .ant-radio-wrapper{
    display: flex;
    align-items: center;
  }
`

const AlertContainer = styled.div`
  margin: 1em 0;
`

const UniFont = styled.span`
  font-weight: normal;
  color: ${props => props.color || 'black'};
  font-size: 20px;
`

const SubTitle = styled.p`
  margin-bottom: 0;
  color: #f0f0f0;
`

const InfoText = styled.i`
  @media (max-width: ${c.SMALL_SCREEN}){
    max-width: 100px;
  }
`

const ButtonContainer = styled.div`
  padding: 0.5em;
`

const TableContainer = styled.div`
  padding: 2em;

  & .ant-row.ant-form-item {
    justify-content: center;
  }

  & .ant-col-14 {
    max-width: 100%;
  }
`

const Search = styled(Input.Search)`
  margin-bottom: 16px;
`

const StyledTable = styled(Table)`
  .ant-pagination-item {
    display: none !important;
  }
`

/**
 * @param {{
 *  isPermitted: ?boolean
 * }} param0
 * @returns {React.ReactNode}
 */
const BatchBindSensor = ({ refresh }) => {
  const [filteredSensors, setFilteredSensors] = useState([])
  const [search, setSearch] = useState('')
  const [showQR, setShowQR] = useState(true)
  const [inputType, setInputType] = useState(1)
  const [cameraError, setCameraError] = useState()
  const [form] = Form.useForm()
  const [selectedSensors, setSelectedSensors] = useState([])
  const [visible, setVisible] = useState(false)
  const { hasRole } = useAuth()
  const { tenantId, isTenantScope } = useTenant()
  const isSuperAdmin = isTenantScope() && hasRole('superAdmin')
  const isTenantAdmin = isTenantScope() && !hasRole('superAdmin') && hasRole(`tenant.${tenantId}.${c.ACCESS_WRITE}`)
  const {
    sensors,
    loading,
    refresh: refreshNonTenentSensors,
    paginationState,
    searchState
  } = useAdminAllSensors({
    fetch: isSuperAdmin,
    fetchAll: isSuperAdmin,
    excludeTenantSensors: isSuperAdmin
  })
  const { bindOrUpdateSensorsTenantRel, bindOrUpdateSensorTenantRel } = useTenants({ fetch: false })

  useEffect(() => {
    const filtered = sensors.filter(sensor => {
      const vals = Object.values(sensor)
        .filter(val => typeof val === 'string' || val === 'number')
        .map(val => val?.toString().toLowerCase())

      return !search || vals.find(val => val.includes(search))
    })

    setFilteredSensors(filtered)
  }, [sensors, search])

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setSelectedSensors([])
    setVisible(false)
  }

  const handleSelectedSensorChange = keys => {
    setSelectedSensors(keys)
  }

  const handleQR = code => {
    const codeSplit = code.split('-')
    form.setFieldsValue({ deviceId: codeSplit[0], name: codeSplit[1] })
    setShowQR(false)
  }

  const updateRadio = e => {
    message.destroy(tenantId)
    setShowQR(true)
    const { target = {} } = e
    setInputType(target.value)
  }

  const handleSearch = e => setSearch(e.target.value.toLowerCase())

  const handleSubmit = async () => {
    try {
      if (isSuperAdmin) {
        await bindOrUpdateSensorsTenantRel(tenantId, selectedSensors, { active: true })
      }

      if (isTenantAdmin) {
        const { deviceId, name } = form.getFieldValue()
        await bindOrUpdateSensorTenantRel(tenantId, deviceId, { sensorName: name, active: true })
      }
    } catch (e) {
      Bugsnag.notify(e)
    } finally {
      refresh?.()
      refreshNonTenentSensors()
      setSelectedSensors([])
      setVisible(false)
    }
  }

  return (
    <>
      <Button type='primary' onClick={showModal}>Bind Sensors</Button>
      <Modal
        closable={false}
        visible={visible}
        onCancel={handleCancel}
        destroyOnClose
        keyboard
        bodyStyle={{ padding: '0' }}
        footer={[
          <FooterContainer key='buttons'>
            <ButtonContainer>
              <Button fill outline key='cancel' onClick={handleCancel}>
                Cancel
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button key='submit' type='primary' onClick={handleSubmit}>
                {`Bind sensor${selectedSensors.length > 1 ? 's' : ''}`}
              </Button>
            </ButtonContainer>
          </FooterContainer>
        ]}
      >
        <div>
          <TitleContainer>
            <h2>Bind sensor(s) to client</h2>
          </TitleContainer>
          <TableContainer>
            {isSuperAdmin && loading && <ColorSpinner />}
            {!loading && isSuperAdmin && sensors.length > 0 && (
              <>
                <Search
                  placeholder='Search'
                  onChange={e => searchState.setValue(e.target.value)}
                  onSearch={() => searchState.cancelTimeout()}
                  onPressEnter={() => searchState.cancelTimeout()}
                  value={searchState.value}
                  allowClear
                />
                <StyledTable
                  rowSelection={{
                    type: 'checkbox',
                    selectedRowKeys: selectedSensors,
                    onChange: handleSelectedSensorChange
                  }}
                  columns={columns}
                  pagination={{
                    position: ['none', 'bottomCenter'],
                    ...paginationState.antdPaginationConfig,
                    onChange: (page) => {
                      paginationState.goToPrevOrNextPage(page)
                    }
                  }}
                  dataSource={filteredSensors.map(sensor => ({ ...sensor, key: sensor.id }))}
                />
              </>
            )}
            {isTenantAdmin && (
              <>
                <Radio.Group
                  onChange={(e) => updateRadio(e)}
                  style={{ width: '100%' }}
                  value={inputType}
                  size='large'
                  defaultValue={1}
                >
                  <RadioContainer>
                    <Radio value={1}>
                      <UniFont>Fill in ID</UniFont>
                    </Radio>
                    <Radio value={2}>
                      <UniFont>Scan QR code</UniFont>
                    </Radio>
                  </RadioContainer>
                </Radio.Group>
                {showQR && inputType && inputType === 2 && (
                  cameraError
                    ? (
                      <AlertContainer>
                        <Alert message='Camera error' description={cameraError.message || 'Unable to find camera'} type='error' showIcon />
                      </AlertContainer>)
                    : <QrScanner setData={handleQR} messageKey={tenantId} showIcon setCameraError={setCameraError} />
                )}
                <Form
                  labelCol={{ span: 14 }}
                  wrapperCol={{ span: 14 }}
                  layout='vertical'
                  size='large'
                  id='addSensor'
                  onFinish={handleSubmit}
                  colon={false}
                  form={form}
                >
                  <Form.Item
                    label='NAME ON THE SENSOR'
                    name='name'
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please add sensor name'
                      },
                      ({ getFieldValue }) => ({
                        validator (rule, value) {
                          if (!value || ((value.length <= 21) && (value.length >= 14))) {
                            return Promise.resolve()
                          }
                          return Promise.reject('The sensor name needs to be between 14-21 characters')
                        }
                      })
                    ]}
                  >
                    <Input
                      placeholder='CG-TRHCV-00.0000'
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item
                    label='ID'
                    name='deviceId'
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please add sensor ID'
                      },
                      ({ getFieldValue }) => ({
                        validator (rule, value) {
                          if (!value || REGEX_MAC.test(value)) {
                            return Promise.resolve()
                          }
                          return Promise.reject('The sensor ID is not valid')
                        }
                      })
                    ]}
                  >
                    <Input
                      placeholder='a1:b2:c3:d4:e5:f6'
                      allowClear
                      onChange={(e) => {
                        if (e.target.value === '') {
                          setShowQR(true)
                        }
                      }}
                    />
                  </Form.Item>
                </Form>
              </>
            )}
          </TableContainer>
        </div>
      </Modal>
    </>
  )
}

export default BatchBindSensor
