import { useMemo, useRef, useState } from 'react'
import ReactFlow, { Background, Controls, MiniMap } from 'react-flow-renderer'
import { PlusOutlined, MenuFoldOutlined } from '@ant-design/icons'
import styled, { css } from 'styled-components'

import AvailableNodesDrawer from 'src/components/FlowEditor/AvailableNodesDrawer'
import NodeSettingsDrawer from 'src/components/FlowEditor/SettingsDrawer'
import nodeTypeFactory from 'src/components/FlowEditor/nodeTypeFactory'
import Spin from 'src/components/Spin/Spin'
import { useAlgorithmFlow, useValidNodes } from 'src/hooks/flow'
import ButtonComponent from 'src/components/Button/Button'
import { useAlgorithmNodeTypes } from 'src/hooks/algorithmNodeTypes'
import { Badge, Tooltip } from 'antd'
import CustomEdge from './Edge'

const DrawerContainer = styled.div`
  position: relative;

  .ant-drawer {
    position: relative;
  }

  & > div {
    height: 100%;
  }
`

const FlowWrapper = styled.div`
  display: flex;
  width: 100%;

  .react-flow__node-default,
  .react-flow__node-input,
  .react-flow__node-output {
    padding: 0;
    border-radius: 0.5rem;
  }
`

const FlowBtnWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  ${({ position = 'left' }) => position}: 10px;
  z-index: 5;
`

const AddCloseIcon = styled(PlusOutlined)`
  transition: ease-in-out 0.2s;
  transform: rotate(${props => props.open ? '45' : '0'}deg);
`

const SettingsOpenCloseIcon = styled(MenuFoldOutlined)`
  transition: ease-in-out 0.2s;
  transform: rotateY(${props => props.open ? '180' : '0'}deg);
`

const StyledBadge = styled(Badge)`
  position: absolute;
  top: 0;
  ${props => props.right ? 'right' : 'left'}: 0;
  transform: translate(${props => props.right ? '20%' : 'calc(-20%)'}, ${props => props.right ? '-20%' : '-20%'});

  @keyframes antStatusProcessing {
    0% {
      transform: scale(0.8);
      opacity: 0.5;
    }

    100% {
      transform: scale(2.1);
      opacity: 0;
    }
  }

  --badge-bg-color: ${props => props.success ? '#52c41a' : '#f50'};

  background-color: transparent;

  &.ant-badge-status-processing::after {
    border: 3px solid var(--badge-bg-color);
    border-radius: 10px;
  }

  & > * {
    background-color: var(--badge-bg-color);
    color: #fff;

    ${props => props.success && css`
      border-radius: 50%;
      padding: 3px;
      box-sizing: border-box;
    `}

    svg {
      height: 14px;
      width: 14px;
    }
  }
`

const customEdgeTypes = {
  CustomEdge
}

/**
 * @typedef FlowEditorProps
 * @type {object}
 * @property {number} flowId
 *
 * @param {FlowEditorProps} props
 * @returns {React.ReactNode}
 */

const FlowEditor = ({ flowId }) => {
  const {
    nodeSettingsForm,
    flowSettingsForm,
    nodes,
    settings,
    loading,
    rfInstance,
    handleAddNode,
    handleConnect,
    handleEdgeUpdate,
    setRfInstance,
    handleDeleteNode,
    handleFlowSettingValueUpdate,
    handleNodeSettingValueUpdate,
    settingFormErrorCount,
    hasInputNode,
    flow
  } = useAlgorithmFlow(flowId)
  const { algorithmNodeTypes = [], loading: nodeTypesLoading } = useAlgorithmNodeTypes()
  const { currentValidNodes, loading: validNodesLoading, getNodes, removeNodes } = useValidNodes()

  const [nodesDrawerVisible, setNodesDrawerVisible] = useState(false)

  const [settingsDrawerVisible, setSettingsDrawerVisible] = useState(flowId === 'add')
  const [drawerNode, setDrawerNode] = useState(null)
  const [activeSettingsKey, setActiveSettingsKey] = useState('flow')
  const [tooltipVisible, setTooltipVisible] = useState(false)

  const reactFlowRef = useRef()
  const flowWrapperRef = useRef()
  const settingsDrawerRef = useRef()
  const availableNodesDrawerRef = useRef()

  const showTooltip = () => setTooltipVisible(hasInputNode ? false : true)
  const hideTooltip = () => setTooltipVisible(false)

  const customNodeTypes = useMemo(
    () => algorithmNodeTypes.reduce((acc, { name, ...config }) => ({
      ...acc,
      [name]: nodeTypeFactory(name, config)
    }), {}),
    [algorithmNodeTypes]
  )

  const handleNodeClick = (_, element) => {
    setSettingsDrawerVisible(true)
    setDrawerNode(element.id)
    setActiveSettingsKey('node')
  }

  const handleDragOver = (event) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }

  /**
   * @param {React.DragEvent<HTMLDivElement>} event
   */
  const handleDrop = (event) => {
    event.preventDefault()

    const reactFlowBounds = reactFlowRef.current.getBoundingClientRect()
    const node = JSON.parse(event.dataTransfer.getData('application/reactflow'))
    const position = rfInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top
    })

    handleAddNode({ ...node, position })
  }

  const handleSettingsTabClick = key => {
    setActiveSettingsKey(key)
  }

  const handleAvailableNodesDrawerToggle = e => {
    e.preventDefault()

    if (!currentValidNodes?.length && !validNodesLoading) {
      getNodes()
    }

    setNodesDrawerVisible(!nodesDrawerVisible)
  }

  const handleSettingsDrawerToggle = e => {
    e.preventDefault()

    setSettingsDrawerVisible(!settingsDrawerVisible)
  }

  const defaultDrawerProps = {
    mask: false,
    closable: false
  }

  const defaultBtnProps = {
    circle: true,
    size: 'large'
  }

  if (loading || nodeTypesLoading) return <Spin />

  return (
    <>
      <AvailableNodesDrawer
        drawerProps={{
          ...defaultDrawerProps,
          visible: nodesDrawerVisible,
          placement: 'left',
          onClose: () => setNodesDrawerVisible(false),
          getContainer: () => availableNodesDrawerRef.current
        }}
        add={handleAddNode}
        currentValidNodes={currentValidNodes}
        loading={validNodesLoading}
      />
      <FlowWrapper ref={flowWrapperRef}>
        <DrawerContainer ref={availableNodesDrawerRef} />
        <ReactFlow
          ref={reactFlowRef}
          elements={nodes || []}
          onElementClick={handleNodeClick}
          onLoad={setRfInstance}
          onConnect={handleConnect}
          onEdgeUpdate={handleEdgeUpdate}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          nodeTypes={customNodeTypes}
          edgeTypes={customEdgeTypes}
        >
          <FlowBtnWrapper
            onMouseEnter={showTooltip}
            onMouseUp={showTooltip}
            onMouseLeave={hideTooltip}
            onMouseDown={hideTooltip}
          >
          <Tooltip
            placement="rightTop"
            title='Start node is missing!'
            visible={tooltipVisible}
          >
            <StyledBadge
              right
              count={!hasInputNode ? 1 : 0}
              className={!hasInputNode > 0 ? 'ant-badge-status-processing' : ''}
            />
            <ButtonComponent
              {...defaultBtnProps}
              onClick={handleAvailableNodesDrawerToggle}
              icon={
                <AddCloseIcon
                  open={nodesDrawerVisible}
                />
              }
            />
            </Tooltip>
          </FlowBtnWrapper>
          <Background />
          <MiniMap style={{ top: '10px', bottom: 'auto' }} />
          <Controls style={{ top: '10px', bottom: 'auto' }} />
          <FlowBtnWrapper position='right'>
            <StyledBadge
              count={settingFormErrorCount}
              className={settingFormErrorCount ? 'ant-badge-status-processing' : 0}
            />
            <ButtonComponent
              {...defaultBtnProps}
              onClick={handleSettingsDrawerToggle}
              icon={
                <SettingsOpenCloseIcon
                  open={settingsDrawerVisible}
                />
              }
            />
          </FlowBtnWrapper>
        </ReactFlow>
        <DrawerContainer ref={settingsDrawerRef} />
      </FlowWrapper>
      <NodeSettingsDrawer
        drawerProps={{
          ...defaultDrawerProps,
          placement: 'right',
          visible: settingsDrawerVisible,
          getContainer: () => settingsDrawerRef.current
        }}
        visibleNodeId={drawerNode}
        nodes={nodes}
        settings={settings}
        loading={loading}
        handleDelete={handleDeleteNode}
        nodeSettingsForm={nodeSettingsForm}
        flowSettingsForm={flowSettingsForm}
        flowId={flowId}
        activeKey={activeSettingsKey}
        handleTabClick={handleSettingsTabClick}
        handleFlowSettingValueUpdate={handleFlowSettingValueUpdate}
        handleNodeSettingValueUpdate={handleNodeSettingValueUpdate}
        flow={flow}
      />
    </>
  )
}

export default FlowEditor
