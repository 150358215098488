import { useEffect } from 'react'
import styled from 'styled-components'
import Button from 'src/components/Button'
import Spin from 'src/components/Spin'
import { useSensorStats } from 'src/hooks'
import { Empty } from 'antd'

const TableWrap = styled.div`
  padding: 1em;
`
const DataWrap = styled.div`
  display: flex;
  width: 100%;
`

const DataHeader = styled.h3`
  font-size: 25px;
  font-weight: bold;
  font-style: italic;
  line-height: 1.2;
`

const DataText = styled.div`
  font-size: 20px;
  text-transform: capitalize;
  &.data{
    font-weight: bold;
  }
`

const InfoWrap = styled.div`
  min-width: 225px;
`

const Center = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em 0 ;
`

const AdminSensorStats = (props) => {
  const { sensor } = props
  const { sensorType = {}, deviceId } = sensor
  const { sensorStats, loading, refresh } = useSensorStats(deviceId)
  console.log('sensorData', sensorStats)

  useEffect(() => {
    if (deviceId) {
      refresh()
    }
  }, [deviceId])
  return (
    <div>
      <TableWrap>
        <DataHeader>Properties</DataHeader>
        <DataWrap>
          <InfoWrap><DataText>sensor ID</DataText></InfoWrap>
          <DataText className='data'>{sensor?.id ?? '-'}</DataText>
        </DataWrap>
        <DataWrap>
          <InfoWrap><DataText>type</DataText></InfoWrap>
          <DataText className='data'>{sensorType?.type ?? '-'}</DataText>
        </DataWrap>
        <DataWrap>
          <InfoWrap><DataText>name</DataText></InfoWrap>
          <DataText className='data'>{sensor?.name ?? '-'}</DataText>
        </DataWrap>
        <DataWrap>
          <InfoWrap><DataText>owner</DataText></InfoWrap>
          <DataText className='data'>{sensor?.owner ?? '-'}</DataText>
        </DataWrap>
        <DataWrap>
          <InfoWrap><DataText>location</DataText></InfoWrap>
          <DataText className='data'>{sensor?.location || '-'}</DataText>
        </DataWrap>
        <DataWrap>
          <InfoWrap><DataText>device ID</DataText></InfoWrap>
          <DataText className='data'>{sensor?.deviceId ?? '-'}</DataText>
        </DataWrap>
        <DataWrap>
          <InfoWrap><DataText>batch ID</DataText></InfoWrap>
          <DataText className='data'>{sensor?.batchId ?? '-'}</DataText>
        </DataWrap>
        <DataWrap>
          <InfoWrap><DataText>notifications</DataText></InfoWrap>
          <DataText className='data'>{sensor?.notifications ?? '0'}</DataText>
        </DataWrap>
        <Center>
          <Button outline style={{ width: '100%' }}>More information</Button>
        </Center>
      </TableWrap>
      <TableWrap>
        <DataHeader>Recent information</DataHeader>
        {loading
          ? <Spin />
          : sensorStats && sensorStats.results
            ? sensorStats.results.map((item, i) => {
              const multi = Object.keys(item).map((key, j) => {
                const value = item[key]
                const displayValue = Array.isArray(value)
                  ? value.pop() // show latest array value
                  : value
                return (
                  <DataWrap key={`${j}-${key}`}>
                    <InfoWrap><DataText>{key}</DataText></InfoWrap>
                    <DataText className='data'>{JSON.stringify(displayValue)}</DataText>
                  </DataWrap>
                )
              })
              return multi
            })
            : <Empty />}
      </TableWrap>
    </div>
  )
}

export default AdminSensorStats
