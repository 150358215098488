import { useState } from 'react'
import {
  Card,
  Checkbox,
  Radio
} from 'antd'
import RadarChartCheck from 'src/components/RadarChart/RadarChart'
import ComfortChart from 'src/components/ComfortChart/ComfortChart'
import LineChart from 'src/components/LineChart/LineChart'
import BarChartSelect from 'src/components/BarChartSelect/BarChartSelect'

import {
  memoMapDisplay
} from 'src/chartUtils'
import F from 'src/f'

import { useTheme, useCurrentState } from 'src/hooks'

import styled, { css } from 'styled-components'
import ComfortChartStatic from 'src/components/ComfortChartStatic/ComfortChartStatic'
const XS = '380px'

const Bordered = styled(F)`
  border-left: 1px solid lightgray;
  padding: 0.5rem;
  margin-left: 1rem;
  border-top: ${props => props.topBorder
    ? '1px solid lightgray'
    : 'none'};
`

const ChartContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${XS}) {
    flex-direction: column;
  }
`
const ChartTitle = styled.p`
  color: ${props => props.color};
  font-size: 1.25rem;
  font-weight: bold;
  padding-left: 1rem;
  margin-bottom: 0.5rem;
  @media (min-width: ${XS}) {
    padding-left: 0;
  }
`

const ChartSubtitle = styled.p`
  font-size: 0.75rem;
  font-weight: bold;
  color: ${props => props.color || 'gray'};
  margin-bottom: 0.25rem;
`

const CardContainer = styled.div`
  padding: 0 1em 1em 1em;
  display: flex;
  flex-direction: ${props => props.column ? 'column' : 'row'};
  width: 50%;
  font-weight: bold;
  & > * {
    border-radius: 8px;
  }
  .ant-card {
    box-shadow: 10px 10px 10px rgba(0,0,0,0.1);
  }
  .ant-card-body {
    height: 100%;
    padding-top: 1rem;
  }
  .ant-card-bordered {
    border: none;
  }
  .recharts-cartesian-axis-tick-line,
  .recharts-polar-grid-concentric > *:not(:last-child) {
    display: none;
  }
  ${props => props.left && css`
    padding-right: 0;
    & > * {
      border-radius: 8px 0 0 8px;
    }
  `}
  ${props => props.right && css`
    padding-left: 0;
    & > * {
      border-radius: 0 8px 8px 0;
    }
  `}
  @media (max-width: ${XS}) {
    flex:1;
    width: 100%;
    box-sizing: content-box;
    padding: 1rem 0;
    background-color: white;
    margin-bottom: 1rem;
    .ant-card {
      box-shadow: none;
    }
    .ant-card-body {
      padding: 0;
    }
    .ant-card-bordered {
      border: none;
    }
    & > * {
      border-radius: 0;
    }
  }
`

const TopCard = styled(CardContainer)`
  width: 100%;
`

const Charts = (props) => {
  const { sensor, periodConfig } = props
  const { units, config } = useCurrentState()
  const {
    radarLatestData: latestData,
    data,
    minData,
    maxData,
    latestDataObj,
    keyIndex,
    avgObj,
    splitData
  } = sensor
  const { colors, antd } = useTheme()
  const { keyColors, chartColors } = colors
  const allKeys = keyIndex.reduce((acc, key) => ({ ...acc, [key]: false }), {})
  const [displayKeys, setDisplayKeys] = useState({
    ...allKeys,
    temp: true,
    hum: true,
    tvoc: true,
    co2: true
  })
  const [displayBarKeys, setDisplayBarKeys] = useState({
    ...allKeys,
    temp: true,
    tvoc: true,
    co2: true
  })
  const mapDisplay = memoMapDisplay(keyIndex, units)

  const handleBarChange = (key) =>
    () =>
      setDisplayBarKeys({
        ...displayBarKeys,
        [key]: !displayBarKeys[key]
      })
  const [displayRadio, setDisplayRadio] = useState('all')
  const handleRadio = (e) => setDisplayRadio(e.target.value)
  const hasData = latestData && latestData.length > 0
  const handleRadarTickClick = (key, i) =>
    () => {
      setDisplayKeys({
        ...displayKeys,
        [key]: !displayKeys[key] // toggle value on / off
      })
    }
  const SC_OFFSET = config.display.chartOffset
  const { temp, hum } = units
  const scatterDomain = {
    x: {
      min: parseInt(Math.min(minData.temp, latestDataObj.temp, temp.acceptableValues.min, temp.comfortableValues.min)) - SC_OFFSET,
      max: parseInt(Math.max(maxData.temp, latestDataObj.temp, temp.acceptableValues.max, temp.comfortableValues.max)) + SC_OFFSET
    },
    y: {
      min: parseInt(Math.min(minData.hum, latestDataObj.hum, hum.acceptableValues.min, hum.comfortableValues.min)) - SC_OFFSET,
      max: parseInt(Math.max(maxData.hum, latestDataObj.hum, hum.acceptableValues.max, hum.comfortableValues.max)) + SC_OFFSET
    }
  }
  const barData = displayRadio === 'average'
    ? [avgObj]
    : splitData
  const aspectRatio = 4.0 / 3.0
  const aspectRatioTop = 8.0 / 3.0
  const CTitle = ({ children, ...props }) => <ChartTitle color={antd['primary-color']} {...props}>{children}</ChartTitle>
  return (
    <ChartContainer>
      <TopCard>
        <Card
          style={{ width: '100%' }}
        >
          <ChartTitle>&nbsp;</ChartTitle>
          <LineChart
            periodPresets={periodConfig}
            syncId='timeSeries'
            data={data}
            width='100%'
            mapDisplay={mapDisplay}
            displayKeys={displayKeys}
            aspectRatio={aspectRatioTop}
          />
        </Card>
      </TopCard>
      <CardContainer>
        <Card
          style={{ width: '100%' }}
        >
          <CTitle>Comfortability</CTitle>
          <ComfortChartStatic
            temp={latestDataObj.temp}
            hum={latestDataObj.hum}
            tooltip='current'
            ellipseStyle={{
              bg: chartColors.current,
              opacity: 0.5,
              style: {
                fontSize: '0.85em'
              }
            }}
            comfortStyle={{
              comfort: {
                fill: chartColors.comfortable,
                fillOpacity: 0.5
              },
              acceptable: {
                fill: chartColors.acceptable,
                fillOpacity: 0.5
              }
            }}
          />
        </Card>
      </CardContainer>

      <CardContainer row>
        <Card
          style={{ width: '100%' }}
        >
          <F row>
            <F column n={3}>
              <CTitle>Raw Data</CTitle>
              {hasData && (
                <RadarChartCheck
                  onCheckClick={handleRadarTickClick}
                  checkedKeys={displayKeys}
                  data={latestData}
                  latestDataObj={latestDataObj}
                  outerRadius='70%'
                  width='100%'
                  aspectRatio={aspectRatio}
                />
              )}
            </F>
            <F column>
              <Bordered column n={2}>
                <ChartSubtitle>Values</ChartSubtitle>
                {keyIndex.map((key) =>
                  <F key={key}>
                    <Checkbox onChange={handleBarChange(key)} checked={displayBarKeys[key]} />
                    <p style={{ margin: 0, color: keyColors[key] }}>&nbsp;{units[key]?.displayName ?? key}</p>
                  </F>
                )}
              </Bordered>
              <Bordered topBorder column>
                <ChartSubtitle>Display</ChartSubtitle>
                <Radio.Group
                  style={{ display: 'flex', flexDirection: 'column' }}
                  onChange={handleRadio}
                  value={displayRadio}
                >
                  <Radio value='all'>All Values</Radio>
                  <Radio value='problem'>Problems</Radio>
                  <Radio value='average'>Average</Radio>
                </Radio.Group>
              </Bordered>
            </F>
          </F>
        </Card>
      </CardContainer>
    </ChartContainer>
  )
}

export default Charts
