import { message } from 'antd'
import { useEffect, useState } from 'react'

import { apiInstance as api } from 'src/api'
import { useIsMounted, useTenant } from 'src/hooks'

export const useAlgorithmNodeType = (algorithmNodeTypeId) => {
  const mounted = useIsMounted()
  const { tenantId, isValidScopeFetch } = useTenant()
  const [algorithmNodeType, setAlgorithmNodeType] = useState({})
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())

  const refresh = () => setLastUpdate(new Date())

  const updateAlgorithmNodeType = (algoNodeId = algorithmNodeTypeId, newData) => {
    const hide = message.loading('Updating node type...')

    api.updateAlgorithm(algoNodeId, newData)
      .then(() => {
        hide()
        message.success('Successfully updated node type.')

        if (mounted()) {
          setAlgorithmNodeType({
            id: algoNodeId,
            ...newData
          })
        }
      })
      .catch(() => {
        if (mounted()) setError()
      })
      .finally(() => hide())
  }

  const addAlgorithmNodeType = node => {
    const hide = message.loading('Adding algorithm node...')
    api.addAlgorithmNodeType(node)
      .then(() => {
        hide()
        message.success('Successfully added algorithm node')
        if (mounted()) setAlgorithmNodeType(node)
      })
      .catch(() => {
        if (mounted()) setError()
      })
      .finally(() => hide())
  }

  useEffect(() => {
    if (!algorithmNodeTypeId) return

    setLoading(true)

    api.getAlgorithmNode(algorithmNodeTypeId)
      .then(({ data, config }) => {
        if (mounted() && isValidScopeFetch(config)) {
          console.log('data', data)
          setAlgorithmNodeType(data)
        }
      })
      .catch((e) => {
        if (mounted()) {
          setAlgorithmNodeType({})
          setError(e)
        }
      })
      .finally(() => {
        if (mounted()) setLoading(false)
      })
  }, [lastUpdate, tenantId])

  return {
    algorithmNodeType,
    loading,
    error,
    refresh,
    updateAlgorithmNodeType,
    addAlgorithmNodeType
  }
}

export const useAlgorithmNodeTypes = () => {
  const mounted = useIsMounted()
  const [algorithmNodeTypes, setAlgorithmNodeTypes] = useState([])
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [lastUpdate, setLastUpdate] = useState(new Date())

  const refresh = () => setLastUpdate(new Date())

  useEffect(() => {
    setLoading(true)
    api.getAlgorithmNodeTypes()
      .then(({ data }) => {
        if (mounted()) {
          setAlgorithmNodeTypes(data)
        }
      })
      .catch(e => {
        if (mounted()) {
          setAlgorithmNodeTypes([])
          setError(e)
        }
      })
      .finally(() => {
        if (mounted()) setLoading(false)
      })
  }, [lastUpdate])

  return {
    algorithmNodeTypes,
    loading,
    error,
    refresh
  }
}
