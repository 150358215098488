import { message } from 'antd'
import { useCallback } from 'react'

import { apiInstance as api } from 'src/api'
import { useTenant } from 'src/hooks/tenant'

/**
 * Hooks for relations between sensor and a user
 * @returns {{
 *  adminRemoveSensorFromUser: (payload: Object) => Promise,
 *  adminAddSensorToUser: (userId: Number, sensor: Number) => Promise
 * }}
 */
export const useSensorUser = () => {
  useTenant()

  const adminAddSensorToUser = useCallback((payload) => {
    return new Promise((resolve, reject) => {
      return api.adminAddSensorToUser(payload)
        .then((res) => {
          message.success(res.message || 'Added sensor to user')
          resolve(res.data)
        })
        .catch((err) => {
          message.error(err.response.data.error || 'Unable to add sensor to user')
          reject(err)
        })
    })
  }, [])

  const addSensorForUser = useCallback((sensor, showMessage = true) => {
    return new Promise((resolve, reject) => {
      return api.addSensorForUser(sensor)
        .then((res) => {
          showMessage && message.success(res.message || 'Sensor was added')
          resolve(res.data)
        })
        .catch((err) => {
          showMessage && message.error(err.response.data.error || 'Unable to add sensor')
          reject(err)
        })
    })
  }, [])

  const adminRemoveSensorFromUser = useCallback((userId, sensorId) => {
    return new Promise((resolve, reject) => {
      return api.adminRemoveSensorFromUser(userId, sensorId)
        .then((res) => {
          message.success(res.message || 'Removed sensor from user')
          resolve(res.data)
        })
        .catch((err) => {
          message.error(err.response.data.error || 'Unable to remove sensor from user')
          reject(err)
        })
    })
  }, [])

  return {
    adminRemoveSensorFromUser,
    adminAddSensorToUser,
    addSensorForUser
  }
}
