
import { useState } from 'react'
import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  ReferenceLine,
  ReferenceArea
} from 'recharts'
import ResponsiveContainer from 'src/components/ResponsiveContainer/ResponsiveContainer'
import {
  xTickStyle,
  getFormatters,
  valueFormatter
} from 'src/chartUtils'
import { useTheme } from 'src/hooks'
import styled from 'styled-components'

const moment = require('moment')

const OFFSET = 2

const Top = styled.div`
    position: absolute;
    right: 100px;
    top: 10;
    display: flex;
    justify-content: space-between;
    user-select: none;
    pointer-events: none;
`

const ZoomButton = styled.a`
      pointer-events: all;
`

const LineChartCustom = (props) => {
  const {
    mapDisplay,
    displayKeys,
    data: measurements,
    width,
    aspectRatio,
    periodPresets
  } = props
  const { colors } = useTheme()
  const { keyColors } = colors
  const {
    labelFormatter,
    tickFormatter
  } = getFormatters(periodPresets)

  console.log(periodPresets)

  const [data, setData] = useState(measurements)
  const [refAreaLeft, setrefAreaLeft] = useState('')
  const [refAreaRight, setrefAreaRight] = useState('')
  const [topRight, setTopRight] = useState('dataMax+2')
  const [bottomLeft, setBottomLeft] = useState('dataMin-2')
  const [topLeft, setTopLeft] = useState('dataMax+2')
  const [bottomRight, setBottomRight] = useState('dataMin-2')
  const [showAnimate, setShowAnimate] = useState(true)
  const [zoomed, setZoomed] = useState(false)

  const TEMP = 'temp'
  const HUM = 'hum'
  const TVOC = 'tvoc'
  const CO2 = 'co2'

  const getAxisYDomain = (ref, offset, from, to) => {
    const fromDate = moment(from)
    const stopDate = moment(to)
    let refData = []
    if (from && to) {
      refData = measurements.filter(d => {
        var time = new Date(d.time).getTime()
        return (fromDate <= time && time <= stopDate)
      })
    } else {
      refData = measurements
    }
    if (measurements.length > refData.length) { setZoomed(true) }

    let [bottomRight, topRight] = [refData[0][TEMP], refData[0][TEMP]]
    refData.forEach(d => {
      if (d[TEMP] > topRight) topRight = d[TEMP]
      if (d[HUM] > topRight) topRight = d[HUM]
      if (d[TEMP] < bottomRight) bottomRight = d[TEMP]
      if (d[HUM] < bottomRight) bottomRight = d[HUM]
    })

    let [bottomLeft, topLeft] = [refData[0][CO2], refData[0][CO2]]
    refData.forEach(d => {
      if (d[TVOC] > topLeft) topLeft = d[TVOC]
      if (d[CO2] > topLeft) topLeft = d[CO2]
      if (d[TVOC] < bottomLeft) bottomLeft = d[TVOC]
      if (d[CO2] < bottomLeft) bottomLeft = d[CO2]
    })

    return {
      refData,
      bottomLeft: (bottomLeft - offset),
      topLeft: (topLeft + offset),
      bottomRight: (bottomRight - offset),
      topRight: (topRight + offset)
    }
  }

  const zoomOut = () => {
    setrefAreaLeft('')
    setrefAreaRight('')
    setData(measurements)
    setZoomed(false)
  }

  const zoom = () => {
    if (refAreaLeft === refAreaRight || refAreaRight === '') {
      setrefAreaLeft('')
      setrefAreaRight('')
      return
    }

    let temprefAreaLeft = refAreaLeft
    let temprefAreaRight = refAreaRight
    // xAxis domain
    if (moment(refAreaLeft) > moment(refAreaRight)) {
      temprefAreaLeft = refAreaRight
      temprefAreaRight = refAreaLeft
    }

    // yAxis domain
    const { refData, bottomLeft, topLeft, bottomRight, topRight } = getAxisYDomain('temp', OFFSET, temprefAreaLeft, temprefAreaRight)
    setShowAnimate(false)
    setrefAreaLeft('')
    setrefAreaRight('')
    setData(refData)
    setBottomLeft(bottomLeft)
    setTopLeft(topLeft)
    setBottomRight(bottomRight)
    setTopRight(topRight)
    setBottomLeft(bottomLeft)
    setTopLeft(topLeft)
  }

  return (
    <>
      <Top>
        {zoomed &&
          <div>
            <ZoomButton onClick={() => zoomOut()}>Zoom Out</ZoomButton>
          </div>}
      </Top>
      <ResponsiveContainer
        aspect={aspectRatio}
        width={width}
      >
        <LineChart
          syncId='timeSeries'
          data={data}
          onMouseDown={(e) => (e && e.activeLabel && setrefAreaLeft(e.activeLabel))}
          onMouseMove={(e) => (e && e.activeLabel && refAreaLeft && refAreaLeft.length > 0 && setrefAreaRight(e.activeLabel))}
          onMouseUp={(e) => e && zoom(e)}
          isAnimationActive={showAnimate}
        >
          <XAxis
            dataKey='time'
            tickFormatter={tickFormatter}
            tick={xTickStyle}
          />
          <YAxis
            yAxisId='threeDigits'
            domain={[bottomLeft, topLeft]}
            tickFormatter={(value) => value?.toFixed(2)}
            style={{ userSelect: 'none' }}
          />
          <YAxis
            yAxisId='twoDigits'
            orientation='right'
            tickFormatter={(value) => value?.toFixed(2)}
            domain={[bottomRight, topRight]}
          />
          <Tooltip labelFormatter={labelFormatter} formatter={valueFormatter} />
          <Legend />
          {mapDisplay(displayKeys, (key, { unit, yAxisId }) =>
            <Line
              type='monotone'
              yAxisId={yAxisId}
              key={key}
              name={unit.displayName}
              dot={false}
              dataKey={key}
              unit={unit.unit}
              stroke={keyColors[key]}
              strokeWidth={2.5}
            />
          )}
          {
            mapDisplay(displayKeys, (key, { unit, yAxisId }) =>
              <ReferenceLine
                key={`ref-${key}`}
                yAxisId={yAxisId}
                y={unit.acceptableValues.max}
                stroke={keyColors[key]}
                strokeDasharray='10 10'
              />
            )
          }
          {
            (refAreaLeft && refAreaRight) &&
              <ReferenceArea yAxisId='threeDigits' x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />
          }
        </LineChart>
      </ResponsiveContainer>
    </>
  )
}

export default LineChartCustom
