import styled, { ThemeContext } from 'styled-components'
import { useState, useEffect, useContext } from 'react'
import { useInterval } from 'react-use'
import { Collapse, Card, Badge, Empty, Button as AntButton, Tooltip } from 'antd'
import { CaretLeftOutlined, WarningOutlined } from '@ant-design/icons'
import * as c from 'src/constants'
import { formatDistance } from 'date-fns'
import AddSensorModal from 'src/components/AddSensorModal'
import SwitchModal from 'src/components/SwitchModal'
import DeleteSensor from 'src/components/DeleteSensor'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const { Panel } = Collapse

const ViewWrapper = styled.div`
  flex: 1;
`

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${c.SMALL_SCREEN}){
    flex-direction: column;
  }
`

const TitleFont = styled.span`
font-weight: bold;
color: ${props => props.theme.primaryColor || '#7297ff'};
font-size: 24px;
margin-right: 0.5rem;
line-height: 1.21;
`

const TitleWrap = styled.div`
margin-bottom: 1em;

`

const UnitFont = styled.span`
font-weight: normal;
color: ${props => props.color || 'black'};
font-size: 18px;
margin-right: 0.5rem;
`

const MachineWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(350px, 1fr));
  grid-gap: 1em;
  @media (max-width: ${c.MEDIUM_SCREEN}){
    grid-template-columns: repeat(1, minmax(350px, 1fr));
  }
  @media (max-width: ${c.SMALL_SCREEN}){
    grid-template-columns: repeat(1, minmax(300px, 1fr));
  }
`

const TopRight = styled.div`
  position: absolute;
  top: 0.5em;
  right: 1.5em;
`

const LeftSide = styled.div`
  position: absolute;
  top: 0em;
  left: 0em;
  height: 100%;
  width: 10px;
  border-radius: 12px 0 0 12px;
  background: ${props => props.color || 'white'};
`

const StyledCard = styled(Card)`
    border-radius: 12px;
    position: relative;
    border-color: ${props => props.color || 'white'};
    & .ant-card-body{
      padding: 10px 24px;
    }
`

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${c.SMALL_SCREEN}){
    flex-direction: column;
  }
`

const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  & img{
    max-width: 100%;
    max-height: 100%;
    padding: 1em;
  }
`

const IndicatorWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const Circle = styled.figure`
  margin: 0;
  width: 12px;
  height: 12px;
  margin-right: 1rem;
  border-radius: 50%;
  background-color: ${props => props.color || 'gray'};
`

const DataContainer = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;

`

const UniFont = styled.span`
  font-weight: normal;
  color: ${props => props.color || 'black'};
  font-size: 24px;
`

const FooterContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: center;
  position: relative;
  @media (max-width: ${c.SMALL_SCREEN}){
    padding-bottom: 4em;
  }
`

const LeftBottom = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
`

const MachineDataView = (props) => {
  const { space, loading, shouldUpdate } = props
  const [allSpaces, setAllSpaces] = useState([])
  const [sensors, setSensors] = useState([])
  const [sensorStatuses, setSensorStatuses] = useState()
  const theme = useContext(ThemeContext)

  const getStatusesFromSensors = (sensors) => {
    return sensors.map((sensor) => {
      const {
        latestData: {
          latest: latestStr
        } = {}
      } = sensor
      const latestTimestamp = latestStr != null
        ? new Date(latestStr)
        : null
      const diff = (new Date() - latestTimestamp) / 1000
      const secondsSince = latestStr != null
        ? parseInt(diff, 10)
        : -100
      const lastActiveString = latestStr != null
        ? `active ${formatDistance(latestTimestamp, new Date(), { addSuffix: true })}`
        : 'unknown status'
      return {
        lastActiveString,
        secondsSince,
        latestTimestamp
      }
    })
  }
  const handleSensorStatusUpdate = () => {
    if (sensors) {
      const statuses = getStatusesFromSensors(sensors)
      setSensorStatuses(statuses)
    }
  }
  useEffect(handleSensorStatusUpdate, [sensors])
  useInterval(handleSensorStatusUpdate, 15000)
  useEffect(() => {
    if (space && loading === false) {
      const { children = [], parents = [], sensors } = space
      const _allSpaces = [...children, ...parents, space]
      setAllSpaces(_allSpaces)
      setSensors(sensors)
    }
  }, [space, loading])
  const machineErrors = 'tbd'
  const totalMachines = 'tbd'
  const errorCount = 'tbd'
  return (
    <ViewWrapper>
      {(sensors && sensors.length > 0)
        ? (
          <Collapse
            defaultActiveKey={['1']}
            ghost
            expandIconPosition='right'
            expandIcon={({ isActive }) => <CaretLeftOutlined rotate={isActive ? -90 : 0} />}
          >
            {sensors.map((sensor, i) => {
              const {
                plugins = [],
                issues = [],
                latestData: {
                  latest: latestStr
                } = {}
              } = sensor
              const displayIssues = issues.length
              const statusColor = issues.length > 0
                ? '#d15656'
                : theme.primaryColor
              const status = sensorStatuses[i] || {}
              const { secondsSince, lastActiveString } = status
              const INACTIVE_SECS = 15 * 60
              const SECS_GREEN = 2 * 60
              const SECS_ORANGE = 15 * 60
              const indicatorColor =
                (secondsSince < 0 && 'gray') || // unknown
                (secondsSince < SECS_GREEN && 'green') ||
                (secondsSince < SECS_ORANGE && 'orange') ||
                'red'
              const displayName = sensor.displayName != null && sensor.location
                ? `${sensor.displayName} @ ${sensor.location}`
                : sensor.displayName != null
                  ? sensor.displayName
                  : sensor.location
                    ? `${sensor.name} @ ${sensor.location}`
                    : sensor.name
              return (
                <Panel
                  style={{
                    padding: '1em',
                    margin: '1em',
                    background: 'white',
                    borderRadius: '12px',
                    boxShadow: '0 0 25px 0 rgba(0, 0, 0, 0.17)'
                  }}
                  header={
                    <HeaderWrap>
                      <IndicatorWrap>
                        <Tooltip title={lastActiveString}>
                          <Circle color={indicatorColor} />
                        </Tooltip>
                        <UnitFont>{displayName}</UnitFont>
                      </IndicatorWrap>
                      <UnitFont color='#d15656'>{
                        issues.length > 0
                          ? <span><strong>{displayIssues}</strong> Machine Errors</span>
                          : <span>&nbsp;</span>
                      }
                      </UnitFont>
                      <UnitFont><strong>{plugins.length}</strong> Machines Connected</UnitFont>
                    </HeaderWrap>
                  }
                  key={sensor.id}
                >
                  {(plugins.length > 0)
                    ? (
                      <MachineWrap>
                        {plugins.map((plugin, i) => {
                          const { SensorPlugin = {} } = plugin
                          const { userSettings: jsonStr = '{}' } = SensorPlugin
                          const settings = JSON.parse(jsonStr)
                          const { user: userSettings } = settings
                          return (
                            <SwitchModal plugin={plugin} settings={settings} key={i} shouldUpdate={shouldUpdate} sensor={sensor}>
                              <StyledCard
                                color={statusColor}
                                hoverable
                              >
                                <TopRight>
                                  <Badge count={displayIssues}>
                                    {/* TODO: add action on click */}
                                  </Badge>
                                </TopRight>
                                <LeftSide color={statusColor} />
                                <CardContent>
                                  <TitleWrap>
                                    <TitleFont>{plugin.name}</TitleFont>
                                  </TitleWrap>
                                  <Flex>
                                    <ImageContainer>
                                      <FontAwesomeIcon color='#7f9cf0' style={{ fontSize: '4em', margin: '0 0.5em' }} icon={['fas', plugin.coverImage]} />
                                    </ImageContainer>
                                    <DataContainer>
                                      {userSettings && Object.entries(userSettings)
                                        .map(([key, obj], index) => {
                                          const value = obj.value || '-'
                                          const name = obj.name || '-'
                                          const displayValue = Array.isArray(value)
                                            ? 'Multiple items..'
                                            : value
                                          return (
                                            <Flex key={key}>
                                              <UnitFont><strong>{name}</strong></UnitFont>
                                              <UnitFont>{displayValue}</UnitFont>
                                            </Flex>
                                          )
                                        })}
                                    </DataContainer>
                                  </Flex>
                                </CardContent>
                              </StyledCard>
                            </SwitchModal>
                          )
                        })}
                      </MachineWrap>
                    )
                    : (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={<UniFont>There are no devices connected to this sensor yet</UniFont>}
                      />

                    )}
                  <FooterContainer>
                    <LeftBottom>
                      <DeleteSensor shouldUpdate={shouldUpdate} sensor={sensor} />
                    </LeftBottom>
                    <SwitchModal shouldUpdate={shouldUpdate} sensor={sensor} />
                  </FooterContainer>
                </Panel>
              )
            })}

          </Collapse>
        )
        : (
          <div>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<UniFont>There are no sensors connected to this room yet</UniFont>}
            >
              <AddSensorModal
                shouldUpdate={shouldUpdate}
                spaceId={space.id}
                spaces={allSpaces}
              />
            </Empty>
          </div>)}
    </ViewWrapper>
  )
}

export default MachineDataView
