import { useCallback, useEffect, useRef, useState } from 'react'

import { apiInstance as api } from 'src/api'
import { useTenant } from 'src/hooks'
import { useIsMounted } from 'src/hooks/mounted'

export const useIssues = (opts = {}) => {
  const mounted = useIsMounted()
  const {
    code,
    user = {},
    issues: _issues = []
  } = opts
  const { tenantId, isValidScopeFetch } = useTenant()
  const [issues, setIssues] = useState(_issues)
  const [nonReadIssues, setNonReadIssues] = useState([])
  const [issuesReadAt, setIssuesReadAt] = useState()
  const [issuesByType, setIssuesByType] = useState()
  const [loading, setLoading] = useState()
  const [issueCount, setIssueCount] = useState()
  const [error, setError] = useState()
  const markIssuesAsRead = useCallback(() => {
    api.setIssuesAsRead()
    setIssuesReadAt(new Date())
    setIssueCount(0)
  }, [])
  useEffect(() => {
    if (_issues.length > 0) return
    setLoading(true)
    api.getAllIssues()
      .then(({ data, config }) => {
        if (mounted() && isValidScopeFetch(config)) {
          if (code) {
            const filteredByCode = data.filter((issue) => issue.code === code)
            return setIssues(filteredByCode)
          } else {
            setIssues(data)
          }
        }
      })
      .catch(() => {
        if (mounted()) {
          setIssues([])
          setError()
        }
      })
      .finally(() => {
        if (mounted()) setLoading(false)
      })
  }, [tenantId])

  useEffect(() => {
    if (!issues) return
    const dateIssuesReadAt = new Date(user.issuesReadAt)
    const issuesWithMeta = issues.map((issue) => ({
      ...issue,
      isRead: new Date(issue.time) < dateIssuesReadAt
    }))
    const nonReadIssues = issuesReadAt != null
      ? issuesWithMeta.filter((issue) => !issue.isRead)
      : issuesWithMeta
    const issueCount = nonReadIssues.length
    const byType = issues.reduce((acc, cur) => {
      return {
        ...acc,
        [cur.category]: acc[cur.category]
          ? [...acc[cur.category], cur]
          : [cur]
      }
    }, {})
    setIssuesReadAt(dateIssuesReadAt)
    setNonReadIssues(nonReadIssues)
    setIssuesByType(byType)
    setIssueCount(issueCount)
  }, [issues])

  return {
    issues,
    nonReadIssues,
    issueCount,
    issuesReadAt,
    markIssuesAsRead,
    loading,
    issuesByType,
    error
  }
}
