import { createContext, useEffect, useRef, useState } from 'react'
import { createAuthClient } from '@redwoodjs/auth/dist/authClients'

import { apiInstance as api } from 'src/api'
import { ACCESS_READ } from 'src/constants'
import CachedUser from 'src/libraries/user'

export const AuthContext = createContext({})

/**
 * Copied and customized from @redwoodjs/auth/src/AuthProvider.tsx @v0.16.0
 *
 * @link https://github.com/redwoodjs/redwood/blob/v0.16.0/packages/auth/src/AuthProvider.tsx
 */
export const AuthProvider = ({ children, client, type, skipFetchCurrentUser }) => {
  const [state, setState] = useState({
    loading: true,
    isAuthenticated: false,
    userMetadata: null,
    currentUser: null,
    hasError: false
  })
  const { current: rwClient } = useRef(createAuthClient(client, type))

  const getCurrentUser = async () => {
    if (skipFetchCurrentUser) return undefined

    const data = await api.isSignedInWithObject()

    if (data?.user) {
      return data.user
    } else {
      throw new Error(`Could not fetch current user: ${data?.status} (${data?.statusCode})`)
    }
  }

  /**
   * @param {string|string[]} roles The format is `scope.?scopeId.?scopeLevel` like `['superAdmin']` or `['tenant.${tenantId}.${minPermissionForTenant}']`
   * @returns {boolean}
   */
  const hasRole = (roles) => {
    if (state.currentUser?.roles?.includes('superAdmin')) return true
    if (typeof roles === 'undefined' && !state?.currentUser?.roles) return false
    const splittedRoles = (Array.isArray(roles) ? roles : [roles]).map(role => role.split('.'))

    return (state.currentUser?.roles || []).some(userRole => {
      const [userScope, userScopeId = -1, userScopeLevel = -1] = userRole.split('.')

      if (!userScope) return false

      return splittedRoles.some(([scope = '', scopeId = -1, scopeLevel = -1]) => {
        if (!!~scopeId && !~scopeLevel) scopeLevel = ACCESS_READ

        return (
          userScope === scope &&
          +userScopeId === +scopeId &&
          +userScopeLevel >= +scopeLevel
        )
      })
    })
  }

  const reauthenticate = async () => {
    const notAuthenticatedState = {
      isAuthenticated: false,
      currentUser: null,
      userMetadata: null,
      loading: false,
      hasError: false
    }

    try {
      const userMetadata = await rwClient.getUserMetadata()
      if (!userMetadata) {
        setState(notAuthenticatedState)
      } else {
        const currentUser = await getCurrentUser()

        CachedUser.set(currentUser)

        setState({
          userMetadata,
          currentUser,
          isAuthenticated: true,
          loading: false
        })
      }
    } catch (e) {
      setState({
        ...notAuthenticatedState,
        hasError: true,
        error: e
      })
    }
  }

  const logIn = async (options) => {
    setState({ ...state, loading: true })
    await rwClient.login(options)
    return reauthenticate()
  }

  const logOut = async (options) => {
    setState({ ...state, loading: true })
    await rwClient.logout(options)
    setState({
      userMetadata: null,
      currentUser: null,
      isAuthenticated: false,
      hasError: false,
      error: undefined,
      loading: false
    })
  }

  useEffect(() => {
    (async () => {
      await rwClient.restoreAuthState?.()
      reauthenticate()
    })()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        ...state,
        logIn,
        logOut,
        getToken: rwClient.getToken,
        getCurrentUser,
        hasRole,
        reauthenticate,
        client,
        type
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
