import { Form, Input, Select, Switch } from 'antd'
import { ApiInputComponentFactory } from 'src/components/ApiInputComponents'
import { NODE_SETTING_COMPONENTS } from 'src/constants'

const { Item } = Form

const ConfigurableSettingFields = ({ form, field }) => {
  return (
    <>
      <Item
        {...field}
        label='Name'
        name={[field.name, 'name']}
        fieldKey={[field.fieldKey, 'name']}
        key={[field.key, 'name']}
      >
        <Input placeholder='Name' />
      </Item>
      <Item
        {...field}
        label='Label'
        name={[field.name, 'label']}
        fieldKey={[field.fieldKey, 'label']}
      >
        <Input placeholder='Label' />
      </Item>
      <Item
        label='Description'
        name='description'
        hasFeedback
      >
        <Input.TextArea
          placeholder='Description'
          allowClear
        />
      </Item>
      <Item
        {...field}
        label='Allow null'
        name={[field.name, 'allowNull']}
        fieldKey={[field.fieldKey, 'allowNull']}
        valuePropName='checked'
      >
        <Switch />
      </Item>
      <Item
        {...field}
        label='Ui component'
        name={[field.name, 'uiComponent']}
        fieldKey={[field.fieldKey, 'uiComponent']}
      >
        <Select
          placeholder='Ui component'
          options={NODE_SETTING_COMPONENTS}
        />
      </Item>
      <ApiInputComponentFactory
        {...field}
        component={form.getFieldValue(['configurableSettings', field.name, 'uiComponent'])}
        renderFn='renderPropFields'
      />
    </>
  )
}

export default ConfigurableSettingFields
