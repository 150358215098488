import { navigate, routes } from '@redwoodjs/router'
import React from 'react'
import { Tag, Dropdown, Menu, Radio, Spin, Table } from 'antd'

import AppContent from 'src/components/AppContent/AppContent'
import AppHeader from 'src/components/AppHeader/AppHeader'
import AppTitle from 'src/components/AppTitle'
import AppLayout from 'src/layouts/AppLayout/AppLayout'
import { BackButton } from 'src/components/Button/Button'
import styled from 'styled-components'
import AdminList from 'src/components/AdminList/AdminList'
import DeleteCheckModal from 'src/components/DeleteCheckModal'
import { useAdminSensorConfigurableSetting, useAdminSensorConfigurableSettingPreset } from 'src/hooks/sensorSetting'
import AddSensorSettingPreset from 'src/components/AdminSensorSettingModals/AddSensorSettingPreset'

const Title = styled(AppTitle)`
  display: inline-flex;
  align-items: center;
`

const CmdTag = styled(Tag)`
  display: inline-block;
  margin: 0 1em;
  font-size: 0.5em;
  padding: 0.4em 0.5em;
  line-height: 0.5em;
  text-transform: lowercase;
`

const Content = styled(AppContent)`
  padding: 1rem;
  justify-content: center;
`

const DropdownLink = styled.a`
  font-size: 1.5rem;
  color: ${props => props?.colors?.['card.menu.color'] || '#C4C4C4'};
  min-width: 25px;
  display: flex;
  justify-content: flex-end;
`

const MenuItem = styled(Menu.Item)`
  text-align: center;

  button {
    width: 100%;
  }
`

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  width: 100%;

  .ant-radio-inner {
    &::after {
      display: block;
    }
  }
`

/**
 * @typedef AdminSensorConfigSettingGroupPageProps
 * @type {object}
 *
 * @param {AdminSensorConfigSettingGroupPageProps} props
 * @returns {React.ReactNode}
 */

const AdminSensorConfigSettingGroupPage = ({ id }) => {
  const { loading, configurableSetting, refresh, updateConfigurableSetting } = useAdminSensorConfigurableSetting({ id })
  const { deletePreset } = useAdminSensorConfigurableSettingPreset()

  const handleDelete = (record) => {
    deletePreset(record.id)
      .then(() => refresh())
  }

  const handleDefaultPresetUpdate = async e => {
    await updateConfigurableSetting({
      value: e.target.value
    })
  }

  const columns = [
    {
      title: 'Default preset',
      key: 'defaultPreset',
      align: 'left',
      render: (_, { id }) => <Radio value={id} />
    },
    {
      title: 'Label',
      key: 'label',
      dataIndex: 'label',
      align: 'left',
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      render: (text, record) => {
        const menu = (
          <Menu>
            <MenuItem>
              <AddSensorSettingPreset
                shouldUpdate={refresh}
                record={record}
                configurableSetting={configurableSetting}
                edit
              />
            </MenuItem>
            <MenuItem>
              <DeleteCheckModal
                titleText={`Are you sure you want to delete this preset (${record?.label})?`}
                deleteFn={() => handleDelete(record)}
                deleteBtnText='Yes, delete'
                cancelBtnText='No, cancel'
              />
            </MenuItem>
          </Menu>
        )

        return (
          <div onClick={(e) => e.stopPropagation()}>
            <Dropdown
              overlay={menu}
              placement='bottomRight'
              trigger={['click']}
            >
              <DropdownLink className='ant-dropdown-link' onClick={e => e.stopPropagation()}>
                <i className='fas fa-ellipsis-v' />
              </DropdownLink>
            </Dropdown>
          </div>
        )
      },
    }
  ]

  if (loading) return <Spin />

  return (
    <AppLayout>
      <AppHeader noMargin>
        <BackButton
          onClick={
            (e) => navigate(
              routes.adminContent({
                type: 'sensorTypes'
              }))
          }
        />
        <Title noMargin>
          {configurableSetting?.label} Presets
        </Title>
      </AppHeader>
      <Content noPadding>
        <StyledRadioGroup
          onChange={handleDefaultPresetUpdate}
          defaultValue={configurableSetting?.value ?? configurableSetting?.SensorSettingPresets?.[0]?.id}
        >
          <AdminList
            typeName='sensorConfigurableSettingPreset'
            data={configurableSetting?.SensorSettingPresets ?? []}
            columns={columns}
            loading={loading}
            shouldUpdate={refresh}
            modalProps={{
              SensorSettingGroupId: id,
              configurableSetting
            }}
          />
        </StyledRadioGroup>
      </Content>
    </AppLayout>
  )
}

export default AdminSensorConfigSettingGroupPage
