import { Link, routes } from '@redwoodjs/router'
import styled from 'styled-components'

const Layout = styled.main`
  width: 100%;
  min-height: 100vh;
  display: flex;
  & > * {
    overflow-y:auto;
  }
`

const DashboardLayout = ({ children }) => {
  return (
    <>
      <Layout>{children}</Layout>
    </>
  )
}

export default DashboardLayout
