import {
  Modal,
  Button as AntButton,
  Form,
  Input,
  Collapse
} from 'antd'
import { DeleteFilled } from '@ant-design/icons'
import { v4 as uuidV4 } from 'uuid'

import { useState } from 'react'
import Button from 'src/components/Button'
import CodeEditor from 'src/components/CodeEditor/CodeEditor'
import { useAlgorithmNodeType } from 'src/hooks/algorithmNodeTypes'
import styled from 'styled-components'
import * as c from '../../constants'
import HandleFields from 'src/components/AdminAddAlgorithmNodeType/HandleFields'
import { getParsedJsonValue } from 'src/utils'

const ButtonContainer = styled.div`
  padding: 0.5em;
`
const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
`
const TitleContainer = styled.div`
  padding: 1em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #7297ff;
  & h2 {
    color: #fff;
  }
`
const FormContainer = styled.div`
  padding: 2em;
  & .ant-row.ant-form-item {
    flex: 1;
    justify-content: center;
    margin-bottom: 1em;
  }
  & .ant-col-14 {
    max-width: 100%;
  }
  & .ant-col-4 {
    padding-left: 8px;
    max-width: 100%;
    flex: 1;
  }
  & .ant-picker {
    width: 100%;
  }

  & .ant-divider{
    margin: 1em;
  }

  & .ant-form-large .ant-form-item-label > label {
    height: 25px;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  & .ant-form-item-control{
    padding: 0 0.5em;
  }
  @media (max-width: ${c.SMALL_SCREEN}){
    flex-direction: column;
  }
`

const Panel = styled(Collapse.Panel)`
  box-shadow: 3px 3px 10px 0 rgb(0 0 0 / 10%);
  margin-bottom: 1em;

  &.ant-collapse-item,
  &.ant-collapse-item:last-child {
    border-radius: 0.5rem;
  }
`

const AddBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1em;
`

const AdminAddAlgorithmNodeType = ({
  edit = false,
  record = {},
  shouldUpdate
}) => {
  const { addAlgorithmNodeType } = useAlgorithmNodeType(record.id)
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = e => {
    form.resetFields()
    setVisible(false)
  }

  const handleSubmit = async item => {
    const payload = {
      ...item,
      props: {
        ...item?.props ?? {},
        style: getParsedJsonValue(item?.props?.style)
      }
    }

    if (edit) {
      const editPayload = { id: record.id, ...payload }
      /**
       * @todo Add update fn
       */
    } else {
      await addAlgorithmNodeType(payload)
    }

    form.resetFields()
    setVisible(false)
    shouldUpdate?.()
  }

  const validateMessages = {
    required: "'${label}' is required!",
    types: {
      email: '${label} is not validate email!',
      number: '${label} is not a validate number!'
    }
  }

  const renderDeleteBtn = clickHandler => (
    <DeleteFilled
      style={{
        color: '#d9363e'
      }}
      onClick={e => {
        e.stopPropagation()

        clickHandler()
      }}
    />
  )

  return (
    <>
      {edit
        ? (
          <AntButton type='link' onClick={showModal}>
            Edit
          </AntButton>)
        : (
          <Button type='primary' onClick={showModal}>
            Add node type
          </Button>)}
      <Modal
        closable={false}
        visible={visible}
        destroyOnClose
        onCancel={handleCancel}
        bodyStyle={{ padding: '0' }}
        footer={[
          <FooterContainer key='buttons'>
            <ButtonContainer>
              <Button fill outline key='cancel' onClick={handleCancel}>
              Cancel
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button key='submit' type='primary' htmlType='submit' form='addNodeType'>
                {edit ? 'Update nodeType' : 'Add node type'}
              </Button>
            </ButtonContainer>
          </FooterContainer>
        ]}
      >
        <div>
          <TitleContainer>
            <h2>{edit ? 'Edit node type' : 'Add node type'}</h2>
          </TitleContainer>
          <FormContainer>
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              layout='vertical'
              size='large'
              id='addNodeType'
              onFinish={handleSubmit}
              colon={false}
              form={form}
              validateMessages={validateMessages}
              initialValues={{
                ...record
              }}
            >
              {() => (
                <>
                  <Form.Item
                    label='Node type name'
                    name='name'
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please add a node type name'
                      }
                    ]}
                  >
                    <Input
                      placeholder='Node type name'
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item
                    label='Description'
                    name='description'
                    hasFeedback
                  >
                    <Input.TextArea
                      placeholder='Description'
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item
                    label='CSS (JSON)'
                    name={['props', 'style']}
                  >
                    <CodeEditor
                      defaultLanguage='json'
                      height='250px'
                    />
                  </Form.Item>
                  <Form.List name='handles'>
                    {(fields, { add, remove }) => (
                      <>
                        <Collapse defaultActiveKey={[fields?.[fields.length - 1]?.key]} key={fields.length} ghost>
                          {fields.map((field, i) => {
                            return (
                              <Panel
                                header={`Handle id: ${i + 1}`}
                                key={field.key}
                                forceRender
                                extra={renderDeleteBtn(() => {
                                  remove(i)
                                })}
                              >
                                <HandleFields field={field} currentIndex={i} />
                              </Panel>
                            )
                          })}
                        </Collapse>
                        <AddBtnContainer>
                          <Button
                            onClick={e => {
                              e.preventDefault()

                              add({ id: uuidV4() })
                            }}
                          >
                            Add handle
                          </Button>
                        </AddBtnContainer>
                      </>
                    )}
                  </Form.List>
                </>
              )}
            </Form>
          </FormContainer>
        </div>
      </Modal>
    </>
  )
}

export default AdminAddAlgorithmNodeType
