import {
  filterObj,
  reduceToOne,
  mapEachObjKeys,
  normalize,
  mapObjValues,
  sortObj
} from 'src/utils'

import * as c from '../constants'

export const chartConfig = {
  [c.CHART_HOUR]: {
    xTickFormatLabel: 'HH:mm'
  },
  [c.CHART_DAY]: {
    xTickFormatLabel: 'HH:mm'
  },
  [c.CHART_WEEK]: {
    xTickFormatLabel: 'dd MMM'
  },
  [c.CHART_MONTH]: {
    xTickFormatLabel: 'dd MMM'
  },
  [c.CHART_YEAR]: {
    xTickFormatLabel: 'dd MMM'
  }
}

const _config = {
  display: {
    sensorBlacklist: [
      'temp_out',
      'rh_out',
      'pluginName',
      'pluginId',
      'pluginUid',
      'time'
    ],
    chartOffset: 3
  },
  units: {
    default: {
      displayRange: [0, 100],
      unit: 'eenheid',
      orderWeight: -1,
      comfortableValues: {
        max: 0,
        min: 0
      },
      acceptableValues: {
        max: 0,
        min: 0
      }
    },
    temp: {
      displayRange: [17, 24],
      unit: '℃',
      orderWeight: 10,
      displayName: 'Temperature',
      comfortableValues: {
        max: 21,
        min: 17
      },
      acceptableValues: {
        max: 25,
        min: 17
      }
    },
    co2: {
      displayName: 'CO2',
      orderWeight: 7,
      displayRange: [150, 900],
      unit: 'ppm',
      acceptableValues: {
        max: 750,
        min: 0
      },
      comfortableValues: {
        max: 500,
        min: 0
      }
    },
    tvoc: {
      displayName: 'TVOC',
      orderWeight: 5,
      displayRange: [0, 4000],
      unit: 'ppb',
      acceptableValues: {
        max: 800,
        min: 0
      },
      comfortableValues: {
        max: 500,
        min: 0
      }
    },
    hum: {
      displayName: 'Humidity',
      unit: '%',
      orderWeight: 9,
      acceptableValues: {
        max: 60,
        min: 25
      },
      comfortableValues: {
        max: 50,
        min: 25
      }
    },
    pm0_3: {
      displayName: 'PM0.3',
      unit: 'µg/m³',
      orderWeight: 5
    },
    pm0_5: {
      displayName: 'PM0.5',
      unit: 'µg/m³',
      orderWeight: 4
    },
    pm1: {
      displayName: 'PM1',
      unit: 'µg/m³',
      orderWeight: 3
    },
    pm2_5: {
      displayName: 'PM2.5',
      unit: 'µg/m³',
      orderWeight: 2

    },
    pm10: {
      displayName: 'PM10',
      orderWeight: 1,
      unit: 'µg/m³'
    }
  }
}

export const config = {
  ..._config,
  units: Object.entries(_config.units)
    .map(([key, unit]) => ({
      key,
      ..._config.units.default,
      ...unit
    }))
    .reduce((acc, cur) => ({
      ...acc,
      [cur.key]: cur
    }), {})
}

export const sortByWeight = (keys, values) => {
  const [a, b] = keys
  const unitA = config.units[a]
  const unitB = config.units[b]
  if (unitA && unitB) {
    return unitB.orderWeight - unitA.orderWeight
  } else {
    return -1
  }
}

export const calculateMeta = (measurements) => {
  const data = measurements
  const { units } = config
  if (data && data.length > 0) {
    const lastRaw = data[data.length - 1]
    const filteredLast = filterObj(lastRaw, (value, key, i) => {
      const { sensorBlacklist } = config.display
      return sensorBlacklist.indexOf(key) === -1
    })
    const last = sortObj(filteredLast, sortByWeight)
    const keys = Object.keys(last)
    const latest = last
    const maxObj = reduceToOne(data, (key, acc, cur) =>
      acc[key]
        ? Math.max(cur[key], acc[key])
        : cur[key])
    const minObj = reduceToOne(data,
      (key, acc, cur) =>
        acc[key]
          ? Math.min(cur[key], acc[key])
          : cur[key])
    const avgObj = mapObjValues(
      reduceToOne(data, (key, acc, cur) => (acc[key] || 0) + cur[key]),
      (val, key) => key !== 'time'
        ? val / data.length
        : lastRaw.time
    ) // 💪 macho programming mvh victor
    const normData = mapEachObjKeys(data,
      (key, acc, cur) => {
        const unit = units[key]
        const [min, max] = (unit && unit.displayRange) || []
        return normalize(cur[key], max, min)
      }
    )
    const splitData = data.map(
      (obj) => mapObjValues(
        obj,
        (value, key) => {
          const unit = units[key]
          if (unit) {
            const { acceptableValues: av } = unit
            return {
              value: value > av.max
                ? av.max
                : value,
              overflow: value > av.max
                ? value - av.max
                : 0
            }
          } else {
            return value
          }
        }
      ))
    return {
      keys,
      splitData,
      normData,
      avgObj,
      minData: minObj,
      maxData: maxObj,
      latest
    }
  }
  return {}
}

export const restructure = (measurements) => {
  if (!measurements || !measurements.length) return {}
  const { normData } = calculateMeta(measurements)
  const { units } = config
  const rawLast = normData[normData.length - 1]
  if (rawLast) {
    const last = sortObj(rawLast, sortByWeight)
    const formatted = Object.keys(last)
      .filter((key) => !isNaN(last[key])) // remove non numeric values
      .map((key) => {
        const unit = units[key]
        const { displayRange, acceptableValues, comfortableValues } = unit
        const [min, max] = displayRange
        const normAcc = normalize(acceptableValues.max, max, min)
        const normComfort = normalize(comfortableValues.max, max, min)
        const current = last[key]
        return {
          unit: key,
          current: current,
          acceptable: unit && normAcc,
          comfortable: unit && normComfort
        }
      })
    return {
      radar: formatted
    }
  }
  return {}
}
