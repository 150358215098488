import Bugsnag from '@bugsnag/browser'
import { message, Form } from 'antd'
import { createContext, useEffect, useRef, useState } from 'react'
import { addEdge, getIncomers, getOutgoers, isEdge, isNode, ReactFlowProvider, removeElements, updateEdge } from 'react-flow-renderer'
import { v4 as uuidv4 } from 'uuid'

import { apiInstance as api } from 'src/api'
import { useIsMounted, useTenant } from 'src/hooks'
import { navigate } from '@redwoodjs/router'
import { deleteNodeError, hasNodeTargetHandles, setNodeError } from 'src/libraries/flow'

export const FlowContext = createContext()

export const FlowProvider = ({ children }) => {
  const { current: hideMsgMap } = useRef(new Map())

  const { isValidScopeFetch, getRoute } = useTenant()
  const [nodeSettingsForm] = Form.useForm()
  const [flowSettingsForm] = Form.useForm()
  const [nodeVariantsForm] = Form.useForm()
  const isMounted = useIsMounted()
  const currentNodes = useState([])
  const rfInstance = useState()
  const hasNodesWithErrors = useState(false)

  const nodes = useState([])
  const [flow, setFlow] = useState({})
  const settings = useState()
  const [nodesLoading, setNodesLoading] = useState(false)

  const [logs, setLogs] = useState([])
  const [logsLoading, setLogsLoading] = useState(false)

  const [flowSettingFormErrorCount, setFlowSettingFormErrorCount] = useState(0)
  const [nodeSettingFormErrorCount, setNodeSettingFormErrorCount] = useState(0)
  const [hasInputNode, setHasInputNode] = useState(false)
  const [nodeSettingFormTypeTimeoutState, setNodeSettingFormTypeTimeoutState] = useState()

  const [nodeTypes, setNodeTypes] = useState([])
  const [nodeTypesLoading, setNodeTypesLoading] = useState(false)

  const [rfInstanceVal] = rfInstance
  const [nodesVal, setNodes] = nodes
  const [_, setSettings] = settings
  const [__, setHasNodesWithErrors] = hasNodesWithErrors

  const fetchNodes = async flowId => {
    if (flowId == null || flowId === 'add') return

    setNodesLoading(true)

    try {
      const { data = {}, config = {} } = await api.getFlow(flowId)

      if (isMounted() && isValidScopeFetch(config)) {
        setFlow(data)

        const { nodes, nodeSettings = {}, ...flowSettings } = data

        setNodes(nodes.map(node => isEdge(node) ? { ...node, type: 'CustomEdge' } : node))
        setSettings(nodeSettings)
        flowSettingsForm.setFieldsValue(flowSettings)
        nodeSettingsForm.setFieldsValue(nodeSettings)
      }
    } catch (e) {
      Bugsnag.notify(e)
    } finally {
      if (isMounted()) setNodesLoading(false)
    }
  }

  const fetchLogs = async flowId => {
    if (flowId == null || flowId === 'add') return

    // @todo Acitvate App logs
    /*setLogsLoading(true)

    try {
      const { data = {}, config = {} } = await api.getFlowLogs(flowId)

      if (isMounted() && isValidScopeFetch(config)) {
        setLogs(data)
      }
    } catch (e) {
      Bugsnag.notify(e)
    } finally {
      if (isMounted()) setLogsLoading(false)
    } */
  }

  const fetchNodeTypes = async flowId => {
    setNodesLoading(true)

    try {
      const { data } = await api.getAlgorithmNodeTypes(flowId)

      if (isMounted()) setNodeTypes(data)
    } catch (e) {
      Bugsnag.notify(e)
    } finally {
      if (isMounted()) setNodesLoading(false)
    }
  }

  const handleNodeUpdateByIndex = (i, updateVals = {}) => {
    if (i < 0 || i >= nodesVal.length || typeof updateVals !== 'object') return

    nodesVal[i] = {
      ...nodesVal[i],
      ...updateVals,
      data: {
        ...nodesVal[index]?.data ?? {},
        ...updateVals?.data ?? {}
      }
    }

    setNodes([...nodesVal])
  }

  const handleNodeUpdateByKey = (key, updateVals = {}, keyName = 'id') => {
    if (typeof updateVals !== 'object' || !keyName) return

    const index = nodesVal.findIndex(node => node?.[keyName] === key)

    if (index == null) return

    nodesVal[index] = {
      ...nodesVal[index],
      ...updateVals,
      data: {
        ...nodesVal[index]?.data ?? {},
        ...updateVals?.data ?? {}
      }
    }

    setNodes([...nodesVal])
  }

  const handleConnect = (params) => {
    const edge = { ...params, id: uuidv4() }
    setNodes((els) => addEdge({ ...edge, type: 'CustomEdge' }, els))
  }

  const handleEdgeUpdate = (oldEdge, newConnection) => {
    setNodes((els) => updateEdge(oldEdge, newConnection, els))
  }

  const handleAddNode = (node = {}) => {
    const newNode = {
      position: {
        x: 100,
        y: 100,
        ...node?.position ?? {}
      },
      AlgorithmNode: node,
      type: node.type,
      data: node.data,
      id: uuidv4()
    }

    setNodes([...nodesVal, newNode])
  }

  const handleDeleteNode = (nodesToRemove) => {
    if (nodes == null) return

    const nodesToRemoveArr = Array.isArray(nodesToRemove)
      ? nodesToRemove
      : [nodesToRemove]

    const vals = nodeSettingsForm.getFieldsValue(true)

    nodesToRemoveArr.forEach(({ id }) => {
      delete vals?.[id]
    })

    nodeSettingsForm.setFieldsValue(vals)

    const newNodes = removeElements(nodesToRemoveArr, nodesVal)

    setNodes([...newNodes])
  }

  /**
   *
   * @param {object} node
   * @param {'source' | 'target'} type
   * @param {?[]} connectedNodes
   */
  const getAllTypeConnectedNodes = (node, type, connectedNodes = [], first = true) => {
    const nodeGetFn = type === 'source'
      ? getIncomers
      : getOutgoers

    if (node?.AlgorithmNode?.passThroughType) {
      const nodes = nodeGetFn(node, rfInstanceVal?.getElements?.() ?? [])

      connectedNodes = [
        ...connectedNodes,
        ...nodes.reduce((acc, neighbor) => [
          ...acc,
          ...getAllTypeConnectedNodes(neighbor, type, connectedNodes, false)
        ]
        , [])
      ]
    } else {
      connectedNodes = [
        ...connectedNodes,
        ...!first ? [node] : []
      ]
    }

    console.log({pass: node?.AlgorithmNode?.passThroughType, node, connectedNodes})

    return connectedNodes
  }

  const isValidSingleConnection = ({ targetNode, sourceNode }) => {
    const hideMsgKey = `${sourceNode?.id}-${targetNode?.id}`
    const dynamicOutputTypeKey = sourceNode?.AlgorithmNode?.dynamicOutputTypeConfig?.configSettingsKey
    const dynamicOutputType = sourceNode?.AlgorithmNode?.OutputDataType?.fields
      ?.find(field => field.name === nodeSettingsForm.getFieldValue([sourceNode.id, dynamicOutputTypeKey, 'value']))

    const outputType = dynamicOutputTypeKey
      ? dynamicOutputType?.type
      : sourceNode?.AlgorithmNode?.outputDataTypeName
    const inputType = targetNode?.AlgorithmNode?.InputDataType?.name
    const connectionValid = inputType == null || inputType === outputType


    if (!connectionValid && !hideMsgMap.has(hideMsgKey)) {
      hideMsgMap.set(
        hideMsgKey,
        message.error(
          `Nodes ${sourceNode?.AlgorithmNode?.name} and ${targetNode?.AlgorithmNode?.name} can not be connected!`,
          2,
          () => hideMsgMap.delete(hideMsgKey)
        )
      )
    }

    return connectionValid
  }

  const isValidConnection = ({ target, source }) => {
    const flowNodes = rfInstanceVal?.getElements?.()
    const sourceNode = flowNodes?.find(({ id }) => id === source)
    const targetNode = flowNodes?.find(({ id }) => id === target)

    if (sourceNode?.AlgorithmNode?.passThroughType || targetNode ?.AlgorithmNode?.passThroughType) {
      let hasInvalidNode = false
      let sourceNodes

      if (sourceNode?.AlgorithmNode?.passThroughType) {
        sourceNodes = getAllTypeConnectedNodes(sourceNode, 'source')

        if (sourceNodes.filter(sourceNode => !isValidSingleConnection({ sourceNode, targetNode  })).length > 0) {
          hasInvalidNode = true
        }
      }

      return !hasInvalidNode
    }

    return isValidSingleConnection({ sourceNode, targetNode  })
  }

  const hasSettingsWithErrors = (nodes, errorFields = []) => nodes.map((node = {}) => {
    if (!isNode(node)) return node

    const nodeErrIndex = node?.data?.errorMsg
      ?.findIndex(msg => msg?.title === 'Invalid settings') ?? -1

    const fieldErrors = errorFields.reduce((acc, { name: [nodeId], errors = [] }) => {
      if (node.id !== nodeId) return acc

      return [
        ...acc,
        ...errors
      ]
    }, [])


    if (fieldErrors.length > 0) {
      const error = {
        title: 'Invalid settings',
        exp: 'The current node has the following setting errors:',
        errors: fieldErrors,
        errorCount: fieldErrors?.length
      }

      setNodeError(node, error, nodeErrIndex)
    } else {
      deleteNodeError(node, nodeErrIndex)
    }

    return node
  })

  const hasUnmetNodeDataDependencies = (elements, dependecies = new Set(), returnArr = []) => {
    if (!Array.isArray(elements)) return

    const title = 'Missing dependencies'
    const exp = 'Please add nodes which set the following data type(s) before this node'

    let unConnectedNodes

    if (returnArr.length === 0) {
      returnArr = elements
      unConnectedNodes = elements
        .filter(ele => isNode(ele) && hasNodeTargetHandles(ele) && getIncomers(ele, elements).length === 0)
      elements = elements.filter(ele => isNode(ele) && ele?.type === 'input')
    }

    if (Array.isArray(unConnectedNodes)) {
      for (const { AlgorithmNode, ...currentNode } of unConnectedNodes) {
        const targetIndex = returnArr.findIndex(node => node.id === currentNode.id)
        const dependencyErrIndex = returnArr?.[targetIndex]?.data?.errorMsg
          ?.findIndex(msg => msg?.title === title) ?? -1
        const missingDependencies = AlgorithmNode?.DataTypeDependencies
          .filter(dep => !dependecies.has(dep.id))
        const missingDependencyNames = missingDependencies
          .map(dep => dep?.name ?? `id: ${dep.id}`)

        if (missingDependencies?.length > 0) {
          const error = {
            title,
            exp,
            errors: missingDependencyNames,
            errorCount: missingDependencyNames?.length
          }

          setNodeError(returnArr[targetIndex], error, dependencyErrIndex)
        } else {
          deleteNodeError(returnArr[targetIndex], dependencyErrIndex)
        }
      }
    }

    for (const { AlgorithmNode, ...currentNode } of elements) {
      const outgoers = getOutgoers(currentNode, returnArr)
      const targetIndex = returnArr.findIndex(node => node.id === currentNode.id)
      const dependencyErrIndex = returnArr?.[targetIndex]?.data?.errorMsg
        ?.findIndex(msg => msg?.title === 'Missing dependencies') ?? -1
      const missingDependencies = AlgorithmNode?.DataTypeDependencies
        .filter(dep => !dependecies.has(dep.id))
      const missingDependencyNames = missingDependencies
        .map(dep => dep?.name ?? `id: ${dep.id}`)

      if (missingDependencies?.length > 0) {
        const error = {
          title,
          exp,
          errors: missingDependencyNames,
          errorCount: missingDependencyNames?.length
        }

        setNodeError(returnArr[targetIndex], error, dependencyErrIndex)
      } else {
        deleteNodeError(returnArr[targetIndex], dependencyErrIndex)
      }

      hasUnmetNodeDataDependencies(
        outgoers,
        new Set([
          ...dependecies,
          ...AlgorithmNode?.saveNodeInDataType && AlgorithmNode?.outputAlgorithmNodeDataTypeId != null
            ? [AlgorithmNode?.outputAlgorithmNodeDataTypeId]
            : []
        ]),
        returnArr
      )
    }

    return returnArr
  }

  const hasInvalidNodeConnection = elements => {
    if (!Array.isArray(elements)) return []

    let hasInvalidNode = false

    elements = elements.map((target = {}) => {
      if (!isNode(target) || !hasNodeTargetHandles(target)) return target

      const connectionErrIndex = target?.data?.errorMsg
        ?.findIndex(msg => msg?.title === 'Invalid connection') ?? -1
      const noConnectionErrIndex = target?.data?.errorMsg
        ?.findIndex(msg => msg?.title === 'No input connections') ?? -1
      const sourceNodes = getIncomers(target, elements)

      if (sourceNodes.length === 0) {
        const error = {
          title: 'No input connections',
          exp: 'Node needs at least one input connection',
          errorCount: 1
        }

        hasInvalidNode = true
        setNodeError(target, error, noConnectionErrIndex)
      } else {
        deleteNodeError(target, noConnectionErrIndex)
      }

      if (sourceNodes.length > 0 && (target?.AlgorithmNode?.InputDataType != null || target?.AlgorithmNode?.passThroughType)) {
        const hasInValidConnection = !!sourceNodes.filter(source => !isValidConnection({ target: target.id, source: source.id })).length > 0

        if (hasInValidConnection) {
          const error = {
            title: 'Invalid connection',
            exp: `Needs an input connection to a node with an output type of ${target?.AlgorithmNode?.InputDataType?.name}`,
            errorCount: 1
          }

          hasInvalidNode = true
          setNodeError(target, error, connectionErrIndex)
        } else {
          deleteNodeError(target, connectionErrIndex)
        }
      }

      return target
    })

    setHasNodesWithErrors(hasInvalidNode)

    return elements
  }

  const handleFlowSettingValueUpdate = () => {
    setFlowSettingFormErrorCount(
      flowSettingsForm
        ?.getFieldsError().reduce((acc, { errors }) => acc+ errors?.length ?? 0, 0)
    )
  }

  /**
   *
   * @param {?[]} nodesToCheck
   * @param {?boolean} validateFirst
   * @param {?any} wait
   * @param {?[]} errs
   */
  const handleNodeSettingValueUpdate = async (nodesToCheck, validateFirst = true, wait = 500, errs) => {
    if (validateFirst && errs == null) {
      if (nodeSettingFormTypeTimeoutState != null) clearTimeout(nodeSettingFormTypeTimeoutState)

      try {
        if (typeof wait === 'number' && wait > 0) {
          await new Promise((resolve) => {
            setNodeSettingFormTypeTimeoutState(
              setTimeout(() => {
                nodeSettingsForm
                  .validateFields()
                  .finally(resolve)
              }, wait)
            )
          })
        } else {
          await nodeSettingsForm.validateFields()
        }
      } catch {}
    }

    nodesToCheck = nodesToCheck ?? nodesVal
    const errorFields = errs ?? nodeSettingsForm?.getFieldsError()

    setNodes([...hasSettingsWithErrors(nodesToCheck, errorFields)])
    setNodeSettingFormErrorCount(
      errorFields.reduce((acc, { errors }) => acc + errors?.length ?? 0, 0)
    )
  }

  useEffect(() => {
    setHasInputNode(nodesVal.find(node => node.type === 'input'))

    const nodesWithDepErrors = hasUnmetNodeDataDependencies(nodesVal)
    const nodesWithInputConnectionErrors = hasInvalidNodeConnection(nodesWithDepErrors)

    handleNodeSettingValueUpdate(nodesWithInputConnectionErrors, true, false)
  }, [nodesVal.length])

  useEffect(() => {
    flowSettingsForm
      .validateFields()
      .finally(() => {
        handleFlowSettingValueUpdate()
      })
  }, [flowSettingsForm?.getFieldsError()?.length])

  const isValidForm = async () => {
    try {
      await nodeSettingsForm.validateFields()
      await flowSettingsForm.validateFields()

      return true
    } catch (error) {
      handleFlowSettingValueUpdate()
      handleNodeSettingValueUpdate(null, false)

      return false
    }
  }

  const handleSave = async flowId => {
    if (!(await isValidForm())) return

    const hide = message.loading('Saving flow...')

    const nodeSettings = nodeSettingsForm.getFieldsValue(true)
    const flowSettings = flowSettingsForm.getFieldsValue(true)
    const nodeVariantSettings = nodeVariantsForm.getFieldsValue(true)
    const flow = rfInstanceVal?.getElements()
      .map(node => {
        if (Object.hasOwnProperty.call(nodeVariantSettings ?? {}, node.id)) {
          return {
            ...node,
            ...typeof nodeVariantSettings[node.id] === 'object'
              ? nodeVariantSettings[node.id]
              : {}
          }
        }

        return node
      })

    try {
      const { data: savedFlow } = await api.saveFlow(flowId, { flow, nodeSettings, flowSettings })

      navigate(getRoute('flowPage', { id: savedFlow?.flow?.id }))

      hide()
      message.success('Flow successfully saved')
    } catch (e) {
      console.log(e)
      Bugsnag.notify(e)

      hide()
      message.error('Flow saving failed')
    }
  }

  const values = {
    flow,
    currentNodes,
    rfInstance,
    nodes,
    settings,
    nodesLoading,
    nodeTypesLoading,
    nodeTypes,
    nodeSettingsForm,
    flowSettingsForm,
    nodeVariantsForm,
    hasNodesWithErrors,
    flowSettingFormErrorCount,
    nodeSettingFormErrorCount,
    settingFormErrorCount: flowSettingFormErrorCount + nodeSettingFormErrorCount,
    hasInputNode,
    logs,
    logsLoading,
    setNodesLoading,
    fetchNodes,
    fetchNodeTypes,
    handleSave,
    handleAddNode,
    handleConnect,
    handleDeleteNode,
    isValidConnection,
    handleNodeSettingValueUpdate,
    handleFlowSettingValueUpdate,
    handleEdgeUpdate,
    isValidForm,
    fetchLogs,
    handleNodeUpdateByIndex,
    handleNodeUpdateByKey
  }

  return (
    <ReactFlowProvider>
      <FlowContext.Provider value={values}>
        {children}
      </FlowContext.Provider>
    </ReactFlowProvider>
  )
}
