
import { format, fromUnixTime } from 'date-fns'
import React from 'react'
import {
  LineChart,
  XAxis,
  YAxis,
  Line,
  Tooltip
} from 'recharts'
import ResponsiveContainer from 'src/components/ResponsiveContainer/ResponsiveContainer'
import styled from 'styled-components'

import ColorSpinner from 'src/components/ColorSpinner'
import { Empty } from 'antd'

const ResponsiveContainerFlex = styled(ResponsiveContainer)`
  display: flex;
  align-items: flex-start;
`

/**
 * @typedef ClientDashboardChartProps
 * @type {object}
 * @property {object[]} data
 * @property {string} lineKey
 * @property {string} XDataKey
 * @property {?number} aspect
 * @property {?string | number} width
 * @property {?() => string} getTimeFormatString
 * @property {?(value: number) => string} tooltipFormatter
 * @property {?string} chartName
 *
 * @param {ClientDashboardChartProps} props
 * @returns {React.ReactNode}
 */
const ClientDashboardChart = ({
  loading,
  lineKey,
  XDataKey,
  data = [],
  aspect = (840 / 315),
  width = '100%',
  getTimeFormatString,
  tooltipFormatter,
  chartName,
  yAxisProps = {}
}) => {
  if (loading) return (<ColorSpinner />)
  if (data?.length < 1) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description='It looks like, there is no data for this chart'
      />
    )
  }

  const ticks = data
    .sort((a, b) => a.createdAtUnix - b.createdAtUnix)
    .reduce((acc, curr, i) => [
      ...acc,
      ...i % 3 === 0 || i === data.length - 1 || data.length <= 7 ? [curr[XDataKey]] : []
    ],
    [])

  return (
    <>
      <ResponsiveContainerFlex
        aspect={aspect}
        width={width}
      >
        <LineChart
          data={data.sort((a, b) => a.createdAtUnix - b.createdAtUnix)}
        >
          <XAxis
            dataKey={XDataKey}
            tickFormatter={tick => {
              const date = fromUnixTime(tick)
              return format(date, getTimeFormatString(date) ?? 'dd-MM')
            }}
            type='number'
            scale='time'
            angle={26}
            tickLine={false}
            tickMargin={10}
            tick={{
              fontWeight: 300,
              color: '#000000',
              fontFamily: 'Montserrat',
              fontSize: '16px'
            }}
            axisLine={{
              strokeWidth: '2px',
              stroke: '#CBCBCB'
            }}
            ticks={ticks}
            domain={['dataMin', 'dataMax']}
            allowDecimals={false}
          />
          <YAxis
            tickLine={false}
            tick={{
              fontWeight: 300,
              color: '#000000',
              fontFamily: 'Montserrat',
              fontSize: '16px'
            }}
            axisLine={{
              strokeWidth: '2px',
              stroke: '#CBCBCB'
            }}
            {...yAxisProps}
          />
          <Tooltip
            formatter={(value, name) => tooltipFormatter(value) ?? ([`${value} ${chartName ?? name}`])}
            labelFormatter={() => ''}
          />
          <Line
            type='monotone'
            dataKey={lineKey}
            stroke='#7297FF'
            dot={{
              fill: '#7297FF',
              width: 10,
              height: 10,
              strokeWidth: 0
            }}
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainerFlex>
    </>
  )
}

export default ClientDashboardChart
