import { Form, Input, Modal, Switch, Button as AntButton, Radio, Upload, Progress, message, Collapse } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  InboxOutlined,
  DeleteOutlined,
  CheckCircleOutlined
} from '@ant-design/icons'

import Button from 'src/components/Button'
import { useAsset, useAuth, useIsMounted, useTenant, useTenants } from 'src/hooks'
import { EMAIL_TYPES } from 'src/constants'

const ButtonContainer = styled.div`
  padding: 0.5em;
`
const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
`
const TitleContainer = styled.div`
  padding: 1em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #7297ff;
  & h2 {
    color: #fff;
  }
`
const FormContainer = styled.div`
  padding: 2em;
  & .ant-row.ant-form-item {
    flex: 1;
    justify-content: center;
    margin-bottom: 1em;
  }
  & .ant-col-14 {
    max-width: 100%;
  }
  & .ant-col-4 {
    padding-left: 8px;
    max-width: 100%;
    flex: 1;
  }
  & .ant-picker {
    width: 100%;
  }

  & .ant-divider{
    margin: 1em;
  }

  & .ant-form-large .ant-form-item-label > label {
    height: 25px;
  }
`

const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > * {
    margin-bottom: 1rem;
  }
`

const PreviewImage = styled.img`
  /* flex: 1; */
  width: 128px;
  height: 128px;
  border-radius: 1rem;
  box-shadow: 0px 10px 25px rgba(0,0,0,0.2);
  object-fit: scale-down;
  ${props => props.isEmpty && css`
    background-color: gray;
    outline: none;
    border: none;
  `}
`

const StyledDragger = styled(Upload.Dragger)`
  width: 100%;
`

const FileListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1em;
`

const FileNameWrap = styled.div`
  display: flex;
  & span {
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 0 0.5em;
  }
`

const RadioContainer = styled(Radio.Group)`
  display: flex;
  justify-content: center;
`

const Panel = styled(Collapse.Panel)`
  box-shadow: 3px 3px 10px 0 rgb(0 0 0 / 10%);
  margin-bottom: 1em;

  &.ant-collapse-item,
  &.ant-collapse-item:last-child {
    border-radius: 0.5rem;
  }
`

/**
 * @typedef AddClientModalProps
 * @type {object}
 * @property {?boolean} edit
 * @property {?object} record
 * @property {?() => void} shouldUpdate
 * @property {?string} title
 *
 * @param {AddClientModalProps} props
 * @returns {React.ReactNode}
 */

const AddClientModal = ({ edit, record = {}, shouldUpdate, title }) => {
  const { addTenant, updateTenant } = useTenants({ fetch: false })
  const { isTenantScope } = useTenant()
  const { hasRole } = useAuth()
  // To check if the component is mounted so it won't update the state if it isn't
  const mounted = useIsMounted()
  const defaultValues = {
    name: null,
    active: true,
    logoUrl: null,
    logoUrlType: 'upload',
    ...edit ? record : {}
  }
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()
  const { getPreview, beforeUpload, customRequest, deleteAsset, uploadStatus, progressUpload, fileList, urlToImage } = useAsset({
    fileList: edit && record?.logoUrl ? [{ name: record?.name, ImageUrl: record?.logoUrl }] : []
  })

  useEffect(() => {
    const values = form.getFieldValue()
    form.setFieldsValue({
      ...values,
      logoUrl: urlToImage
    })
  }, [urlToImage])

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  /**
   * @param {React.FormEvent<HTMLFormElement>} e
   */
  const handleCancel = e => {
    if (form.getFieldValue('logoUrlType') === 'upload') {
      deleteAsset()
    }

    form.resetFields()
    setVisible(false)
  }

  const handleSubmit = async () => {
    const values = form.getFieldsValue(true)
    const logoUrl = urlToImage || values?.logoUrl || record?.logoUrl || null
    const payload = {
      ...edit ? record : {},
      ...values,
      logoUrl
    }

    setLoading(true)

    try {
      if (edit) {
        await updateTenant(payload)
      } else {
        await addTenant(payload)
      }
      setVisible(false)
      shouldUpdate()
    } catch (e) {
      console.log(e)
    } finally {
      if (mounted()) setLoading(false)
      form.resetFields()
    }
  }

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: "'${label}' is required!",
    types: {
      email: '${label} is not validate email!',
      number: '${label} is not a validate number!'
    }
  }
  /* eslint-enable no-template-curly-in-string */

  const logoType = form.getFieldValue('logoUrlType')
  const isUpload = logoType === 'upload' || logoType == null

  return (
    <>
      {edit
        ? (
          <AntButton type='link' onClick={() => setVisible(true)}>
            Edit
          </AntButton>)
        : (
          <Button type='primary' onClick={() => setVisible(true)}>
            Add client
          </Button>)}
      <Modal
        closable={false}
        visible={visible}
        destroyOnClose
        onCancel={handleCancel}
        bodyStyle={{ padding: '0' }}
        footer={[
          <FooterContainer key='buttons'>
            <ButtonContainer>
              <Button
                fill
                outline
                key='cancel'
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              <Button
                key='submit'
                type='primary'
                htmlType='submit'
                form='addTenant'
              >
                {edit ? 'Update client' : 'Add client'}
              </Button>
            </ButtonContainer>
          </FooterContainer>
        ]}
      >
        <div>
          <TitleContainer>
            <h2>{title ?? (edit ? 'Edit client' : 'Add client')}</h2>
          </TitleContainer>
          <FormContainer>
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              layout='vertical'
              size='large'
              id='addTenant'
              onFinish={handleSubmit}
              colon={false}
              form={form}
              validateMessages={validateMessages}
              initialValues={defaultValues}
            >
              {() => (
                <>
                  <Form.Item>
                    <UploadWrapper>
                      <PreviewImage
                        src={(form.getFieldValue('logoUrlType') === 'url' ? form.getFieldValue('logoUrl') : getPreview()) || '/images/tenant-placeholder.png'}
                      />
                    </UploadWrapper>
                  </Form.Item>
                  <Form.Item name='logoUrlType'>
                    <RadioContainer
                      buttonStyle='solid'
                    >
                      <Radio.Button value='upload'>Upload logo</Radio.Button>
                      <Radio.Button value='url'>Type logo url</Radio.Button>
                    </RadioContainer>
                  </Form.Item>
                  <Form.Item
                    hidden={!isUpload}
                    name='coverImageContainer'
                    valuePropName='fileList'
                    getValueFromEvent={normFile}
                    noStyle
                  >
                    <StyledDragger
                      name='file'
                      customRequest={customRequest}
                      beforeUpload={beforeUpload}
                      showUploadList={false}
                    >
                      <p className='ant-upload-drag-icon'>
                        <InboxOutlined />
                      </p>
                      <p className='ant-upload-text'>
                              Click or drag file to this area to upload
                      </p>
                    </StyledDragger>
                  </Form.Item>
                  {progressUpload && (
                    <Progress
                      status={uploadStatus ? 'active' : 'exception'}
                      percent={progressUpload}
                    />
                  )}
                  {isUpload && fileList.map(({ name }, i) => (
                    <FileListContainer key={i}>
                      <FileNameWrap>
                        <CheckCircleOutlined />
                        <a>{name}</a>
                      </FileNameWrap>
                      <DeleteOutlined onClick={deleteAsset} />
                    </FileListContainer>
                  ))}
                  <Form.Item name='logoUrl' hidden={isUpload}><Input /></Form.Item>
                  <Form.Item
                    label='Client name'
                    name='name'
                    required
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label='Client email address'
                    name='emailAddress'
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Email address is required'
                      },
                      {
                        type: 'email',
                        message: 'Email address needs to be a valid email address'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label='Client address'
                    name='address'
                    required
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label='Client zip-code'
                    name='zipCode'
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label='Client country-code'
                    name='countryCode'
                    rules={[
                      {
                        type: 'string',
                        len: 2,
                        message: 'Needs to be a valid two character country code'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label='Client phone number'
                    name='phoneNumber'
                  >
                    <Input />
                  </Form.Item>
                  {(!isTenantScope() && hasRole('superAdmin')) &&(
                    <Form.Item
                      label='Allow sub clients'
                      name='allowSubTenants'
                      valuePropName='checked'
                    >
                      <Switch />
                    </Form.Item>
                  )}
                  <Form.Item
                    label='Active'
                    name='active'
                    valuePropName='checked'
                    required
                  >
                    <Switch />
                  </Form.Item>
                  <Form.Item
                    label='Users can create sub-tenants'
                    name='usersCanCreateSubTenants'
                    valuePropName='checked'
                    required
                  >
                    <Switch />
                  </Form.Item>
                  <Form.Item
                    label='Invite only'
                    name='inviteOnly'
                    valuePropName='checked'
                    required
                  >
                    <Switch />
                  </Form.Item>
                  <Form.Item
                    label='Customize email templates'
                    name='customizeEmailTemplates'
                    valuePropName='checked'
                    required
                  >
                    <Switch />
                  </Form.Item>
                  {form.getFieldValue('customizeEmailTemplates') && (
                    <>
                      <Form.Item>
                        Template config
                      </Form.Item>
                      <Form.Item
                        label='Button color'
                        name={['emailConfig', 'template', 'buttonColor']}
                      >
                        <Input type='color' />
                      </Form.Item>
                      <Form.Item
                        label='Text color'
                        name={['emailConfig', 'template', 'textColor']}
                      >
                        <Input type='color' />
                      </Form.Item>
                      <Collapse ghost>
                        {EMAIL_TYPES.map(({ typeName, title }) => (
                          <Panel
                            header={title}
                            key={typeName}
                            forceRender
                          >
                            <Form.Item
                              label='Subject'
                              name={['emailConfig', 'emailTypeConfig', typeName, 'subject']}
                              preserve={false}
                            >
                              <Input allowClear />
                            </Form.Item>
                            <Form.Item
                              label='Title'
                              name={['emailConfig', 'emailTypeConfig', typeName, 'title']}
                              preserve={false}
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              label='Content before cta'
                              name={['emailConfig', 'emailTypeConfig', typeName, 'contentBeforeCta']}
                              preserve={false}
                            >
                              <Input.TextArea allowClear />
                            </Form.Item>
                            <Form.Item
                              label='Content after cta'
                              name={['emailConfig', 'emailTypeConfig', typeName, 'contentAfterCta']}
                              preserve={false}
                            >
                              <Input.TextArea allowClear />
                            </Form.Item>
                            <Form.Item
                              label='Cta label'
                              name={['emailConfig', 'emailTypeConfig', typeName, 'ctaLabel']}
                              preserve={false}
                            >
                              <Input allowClear />
                            </Form.Item>
                            <Form.Item
                              label='Cta link'
                              name={['emailConfig', 'emailTypeConfig', typeName, 'ctaLink']}
                              rules={[
                                {
                                  type: 'url',
                                  message: 'Has to be a valid url (including http(s)://)'
                                }
                              ]}
                              preserve={false}
                            >
                              <Input allowClear />
                            </Form.Item>
                            <Form.Item
                              label='Footer title'
                              name={['emailConfig', 'emailTypeConfig', typeName, 'footerTitle']}
                              preserve={false}
                            >
                              <Input allowClear />
                            </Form.Item>
                          </Panel>
                        ))}
                      </Collapse>
                    </>
                  )}
                </>
              )}
            </Form>
          </FormContainer>
        </div>
      </Modal>
    </>
  )
}

export default AddClientModal
