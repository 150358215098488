import DynamicNodeType from 'src/components/FlowEditor/DynamicNodeType'
import { CUSTOM_NODE_TYPE_COMPONENT_BODYS } from 'src/constants'

const nodeTypeFactory = (type, typeProps = {}) =>
  props => {
    const Body = CUSTOM_NODE_TYPE_COMPONENT_BODYS?.[type]

    if (!Body) return <DynamicNodeType {...typeProps} type={type} {...props} />

    return (
      <DynamicNodeType {...typeProps} {...props}>
        <Body {...typeProps} {...props} />
      </DynamicNodeType>
    )
  }

export default nodeTypeFactory
