import { useState } from 'react'
import { Menu, Dropdown, Badge, Card } from 'antd'
import { InfoCircleOutlined, InfoOutlined, CloseCircleOutlined } from '@ant-design/icons'
import GifPlayer from 'react-gif-player'
import DoneOneGif from 'src/assets/doneOne.gif'

import styled from 'styled-components'

const { Meta } = Card

const Container = styled.div`
  height: 100%;
  width: 100%;
  & > :hover {
    transition: all 0.3s;
    background-color: #f5f5f5;
  }
`

const DropDownContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 1em;
  display: flex;
  max-width: 150px;
  & a {
    display: flex;
    justify-content: center;
  align-items: center;
  }
`

const DropDownMenuContainer = styled.div`
 box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
`

const NotificationBadge = (props) => {
  const { data } = props
  const [visible, setVisible] = useState(false)
  const [notifications, setNotifications] = useState(data)
  const count = notifications.length || 0

  const handleVisibleChange = (flag) => {
    setVisible(flag)
  }

  const dismissNotification = (i) => {
    setNotifications(notifications.splice((i + 1), 1))
  }

  const menu = (
    <DropDownMenuContainer>
      {notifications && notifications.length > 0
        ? (
          <>
            <Menu>
              {notifications.map((item, i) =>
                <div style={{ margin: '1em' }} key={'error' + i}>
                  <Menu.Item>
                    <Card
                      size='small'
                      title={item.title}
                      style={{ width: '275px', margin: '0 auto' }}
                      actions={[
                        <InfoOutlined key='details' />,
                        <CloseCircleOutlined
                          key='dismiss'
                          onClick={(e) => {
                            dismissNotification(i)
                            handleVisibleChange(true)
                            e.stopPropagation()
                          }}
                        />
                      ]}
                    >
                      <p>{item.info}</p>
                    </Card>
                  </Menu.Item>
                </div>
              )}
            </Menu>
          </>
        )
        : (
          <Card
            size='small'
            cover={
              <div
                style={{
                  minHeight: '200px',
                  width: '275px',
                  margin: '0 auto',
                  pointerEvents: 'none'
                }}
              >
                <GifPlayer
                  autoplay
                  gif={DoneOneGif}

                />
              </div>
            }
          >
            <Meta
              style={{
                justifyContent: 'center',
                display: 'flex'
              }}
              title=' All done for the day!'
            />
          </Card>
        )}
    </DropDownMenuContainer>
  )

  return (
    <Container>
      <Dropdown
        className='desktopContainer'
        overlay={menu}
        onVisibleChange={handleVisibleChange}
        visible={visible}
      >
        <DropDownContainer>
          <a className='ant-dropdown-link' onClick={e => e.preventDefault()}>
            <Badge count={count} offset={[3, -3]}>
              <InfoCircleOutlined style={{ fontSize: '18px' }} />
            </Badge>
          </a>
        </DropDownContainer>
      </Dropdown>
    </Container>
  )
}

export default NotificationBadge
