import { useState, useEffect } from 'react'
import { routes, navigate } from '@redwoodjs/router'
import AppLayout from 'src/layouts/AppLayout'
import AppHeader from 'src/components/AppHeader'
import AppContent from 'src/components/AppContent'
import Button, { BackButton } from 'src/components/Button'
import AdminList from 'src/components/AdminList'
import AppTitle from 'src/components/AppTitle'

import { formatRelative } from 'date-fns'

import {
  useLogs, useTenant
} from 'src/hooks'
import * as c from 'src/constants'

import styled from 'styled-components'

const WrapText = styled.span`
overflow-wrap: anywhere;
`

const columns = [
  {
    title: 'Source',
    dataIndex: 'source',
    render: (source) => <WrapText>{source}</WrapText>
  },
  {
    title: 'ID',
    dataIndex: 'spaceAlgoId'
  },
  {
    title: 'Space',
    dataIndex: 'spaceId'
  },
  {
    title: 'Algorithm',
    dataIndex: 'algoId'
  },
  {
    title: 'Message',
    dataIndex: 'message'
  },
  {
    title: 'Created',
    dataIndex: 'time',
    render: time => formatRelative(new Date(time), new Date())
  }
]

const LogPage = (props) => {
  const { getRoute } = useTenant()
  const { logs, loading } = useLogs()

  return (
    <AppLayout>
      <AppHeader noMargin>
        <BackButton onClick={() => navigate(getRoute('admin'))} />
        <AppTitle noMargin>Logs</AppTitle>
      </AppHeader>
      <AppContent>
        <AdminList
          typeName='Logs'
          data={logs}
          addAction={false}
          columns={columns}
          loading={loading}
        />
      </AppContent>
    </AppLayout>
  )
}

export default LogPage
