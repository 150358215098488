import {
  Modal,
  Form,
  Select,
  Alert,
  Radio,
  Switch
} from 'antd'
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import React, { useState } from 'react'
import Button from 'src/components/Button'
import LoadingScreen from 'src/components/LoadingScreen'
import { useTenants } from 'src/hooks'

import styled from 'styled-components'
import * as c from 'src/constants'

const { Option } = Select

const ButtonContainer = styled.div`
  padding: 0.5em;
`
const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
`
const TitleContainer = styled.div`
  padding: 1em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #7297ff;
  & h2 {
    color: #fff;
  }
`
const FormContainer = styled.div`
  padding: 2em;
  & .ant-row.ant-form-item {
    flex: 1;
    justify-content: center;
    margin-bottom: 1em;
  }
  & .ant-col-14 {
    max-width: 100%;
  }
  & .ant-col-4 {
    padding-left: 8px;
    max-width: 100%;
    flex: 1;
  }
  & .ant-picker {
    width: 100%;
  }

  & .ant-divider{
    margin: 1em;
  }

  & .ant-form-large .ant-form-item-label > label {
    height: 25px;
  }
`

/**
 * @typedef UserTenantAccessModalProps
 * @type {({
 *  sensorTenants: []
 *  sensorId: number
 *  edit: ?boolean
 *  refresh: () => void
 *  children: React.ReactNode
 * })}
 *
 * @param {UserTenantAccessModalProps} props
 * @returns {React.ReactNode}
 */

const SensorTenantAccessModal = ({
  sensorTenants = [],
  sensorId,
  edit = false,
  refresh,
  children
}) => {
  const {
    tenants: { data: tenants = [] } = {},
    loading,
    bindOrUpdateSensorTenantRel
  } = useTenants({ params: { noPagination: 1 } })
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = e => {
    form.resetFields()
    setVisible(false)
  }

  const handleSubmit = obj => {
    const { tenantId: { value: tenantId }, active } = obj
    const payload = { active: !!active }
    bindOrUpdateSensorTenantRel(tenantId, sensorId, payload)
      .then((res) => {
        form.resetFields()
        setVisible(false)
        refresh()
      }).catch(() => {})
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <div onClick={showModal}>
        {children ?? (
          <Button type='primary'>
            Add client
          </Button>
        )}
      </div>
      {visible &&
        loading
        ? <LoadingScreen />
        : (
          <Modal
            closable={false}
            visible={visible}
            destroyOnClose
            onCancel={handleCancel}
            bodyStyle={{ padding: '0' }}
            footer={[
              <FooterContainer key='buttons'>
                <ButtonContainer>
                  <Button fill outline key='cancel' onClick={handleCancel}>
                    Cancel
                  </Button>
                </ButtonContainer>
                <ButtonContainer>
                  <Button key='submit' type='primary' htmlType='submit' form='addTenant' disabled={!tenants}>
                    {edit ? 'Update client' : 'Add client'}
                  </Button>
                </ButtonContainer>
              </FooterContainer>
            ]}
          >
            <div>
              <TitleContainer>
                <h2>{edit ? 'Edit client' : 'Add client'}</h2>
              </TitleContainer>
              <FormContainer>
                <Form
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 14 }}
                  layout='vertical'
                  size='large'
                  id='addTenant'
                  onFinish={handleSubmit}
                  colon={false}
                  form={form}
                  initialValues={{
                    active: 'checked'
                  }}
                >
                  <Form.Item
                    name='active'
                    label='active'
                    valuePropName='checked'
                  >
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                    />
                  </Form.Item>
                  <Form.Item
                    label='Select client'
                    name='tenantId'
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: `Please select a client to add to sensor ${sensorId}`
                      }]}
                  >
                    {tenants
                      ? (
                        <Select
                          labelInValue
                          placeholder='Select client'
                          notFoundContent={null}
                          style={{ width: '100%' }}
                          showSearch
                          optionFilterProp='children'
                          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                          {tenants.map(tenant => {
                            const found = sensorTenants.some(userTenant => userTenant.id === tenant.id)
                            return <Option disabled={found} key={tenant.id} value={tenant.id}>{tenant.name}</Option>
                          })}
                        </Select>
                      )
                      : (
                        <Alert
                          message='Warning'
                          description='No clients found'
                          type='error'
                          showIcon
                        />)}
                  </Form.Item>
                </Form>
              </FormContainer>
            </div>
          </Modal>
        )}
    </div>
  )
}

export default SensorTenantAccessModal
