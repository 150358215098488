import { useState, useEffect } from 'react'
import { Menu, Spin, Alert, Input, Empty } from 'antd'
import { Drawer } from 'antd-mobile'
import styled from 'styled-components'
import { useTheme } from 'src/hooks'
import F from 'src/f'

const { SubMenu } = Menu

const MenuContainer = styled.aside`
  min-height: 100vh;
  background: ${props => props.mode === 'dark'
    ? props.colors['menu.sidebar.background']
    : props.colors['menu.sidebar.background']};
  color: ${props => props.colors['menu.sidebar.color']};
  @media (min-width: 769px) {
    & .mobile {
        display: none;
      }
  }

  @media (max-width: 768px) {
    & .dashboard {
        display: none;
      }
  }
`

const LogoContainer = styled.div`
  min-height: 56px;
  padding: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SearchContainer = styled.div`
  padding: 1em 1.5em;
`

const Logo = styled.img`
  max-width: 100%;
  max-height: 40px;

  @media (max-width: 768px) {
    max-height: 35px;
  }
`

const DashboardContainer = styled.div`
  position: sticky;
  top: 0;
`

const MobileContainer = styled.div`
  & .dashboard-mobile-draw.am-drawer.am-drawer-left {
    margin: -1px;
  }
  & .am-drawer.am-drawer-left .am-drawer-draghandle {
    width: 0;
  }

  & .dashboard-mobile-draw.am-drawer {
    z-index: -1;
  }

  & .dashboard-mobile-draw.am-drawer.am-drawer-open {
    z-index: 999;
  }
`

const SideMenu = ({
  selectedKeys,
  onSelect,
  collapsedState,
  assets,
  sensorsLoading,
  groupsLoading,
  groupedSensors,
  groups,
  setSearch,
  search,
  onToggle,
  openKeys
}) => {
  const loading = groupsLoading || sensorsLoading

  return (
    <Menu
      mode='inline'
      onSelect={onSelect}
      openKeys={openKeys}
      selectedKeys={selectedKeys}
      inlineCollapsed={collapsedState}
      style={{ minHeight: '100vh' }}
    >
      <LogoContainer>
        {collapsedState
          ? <Logo src={assets.logoSmall} />
          : <Logo src={assets.logoWide} />}
      </LogoContainer>
      <SearchContainer>
        <Input
          placeholder='Search'
          value={search}
          key='searchInput'
          onChange={e => setSearch(e.target.value)}
        />
      </SearchContainer>
      {loading &&
        <F h='5rem' ai='center' jc='center'>
          {groupsLoading && !Object.keys(groupedSensors)?.length && <Spin />}
        </F>}
      {}
      {!collapsedState && (
        <>
          {!groupsLoading && !groups?.length && <Empty description='No sensors found' />}
          {groups?.length && (
            groups.map((location) => (
              <SubMenu
                key={location}
                title={<b>{location}</b>}
                onTitleClick={() => onToggle(location)}
              >
                {groupsLoading && !groupedSensors?.[location]?.length && (
                  <F h='5rem' ai='center' jc='center'>
                    <Spin />
                  </F>
                )}
                {groupedSensors?.[location]?.map((sensor) =>
                  <Menu.Item
                    key={sensor.deviceId}
                  >
                    {sensor.name}
                  </Menu.Item>)}
              </SubMenu>
            ))
          )}
        </>
      )}
    </Menu>
  )
}

const DashboardMenu = (props) => {
  const {
    sensors,
    sensorsLoading,
    assets,
    onSelect,
    collapsedState,
    toggleCollaps,
    groupedSensors,
    sensorsError,
    sensorsDone,
    groups,
    groupsLoading,
    setSearch,
    search,
    fetchSensorGroup
  } = props
  const [themeColor, setThemeColor] = useState('dark')
  const [selectedKeys, setSelectedKeys] = useState()
  const [openKeys, setOpenKeys] = useState([])
  const { colors } = useTheme()

  const handleSelect = ({ key }) => {
    if (!key) return

    setSelectedKeys([key])
    onSelect(key)
  }

  const handleSubMenuToggle = location => {
    const locationIndex = openKeys.indexOf(location)

    if (locationIndex === -1) {
      if (!groupedSensors.hasOwnProperty(location)) {
        fetchSensorGroup(location)
      }

      setOpenKeys([...openKeys, location])
    } else {
      openKeys.splice(locationIndex, 1)

      setOpenKeys([...openKeys])
    }
  }

  useEffect(() => {
    const firstGroupKey = groups[0]
    const key = groupedSensors?.[firstGroupKey]?.[0]?.deviceId
    if (!firstGroupKey) return

    if (!openKeys.length) setOpenKeys([firstGroupKey])
    handleSelect({ key })
  }, [groups, groupedSensors])

  const menuProps = {
    assets,
    sensors,
    onSelect: handleSelect,
    selectedKeys,
    onToggle: handleSubMenuToggle,
    openKeys,
    collapsedState,
    themeColor,
    groupedSensors,
    sensorsLoading,
    sensorsError,
    sensorsDone,
    groups,
    groupsLoading,
    setSearch,
    search
  }

  return (
    <MenuContainer mode={themeColor} colors={colors}>
      <DashboardContainer
        className='dashboard'
      >
        <SideMenu
          {...menuProps}
        />
      </DashboardContainer>
      <MobileContainer className='mobile'>
        <Drawer
          className='dashboard-mobile-draw'
          style={{
            minHeight: '100vh'
          }}
          enableDragHandle
          contentStyle={{
            textAlign: 'center',
            paddingTop: 60
          }}
          sidebar={(
            <SideMenu
              {...menuProps}
            />
          )}
          open={!collapsedState}
          onOpenChange={toggleCollaps}
        />
      </MobileContainer>
    </MenuContainer>

  )
}

export default DashboardMenu
