import { navigate, routes } from '@redwoodjs/router'
import React from 'react'
import { Tag, Dropdown, Menu } from 'antd'

import AppContent from 'src/components/AppContent/AppContent'
import AppHeader from 'src/components/AppHeader/AppHeader'
import AppTitle from 'src/components/AppTitle'
import AppLayout from 'src/layouts/AppLayout/AppLayout'
import { BackButton } from 'src/components/Button/Button'
import styled from 'styled-components'
import AdminList from 'src/components/AdminList/AdminList'
import AdminAddParamUiModal from 'src/components/AdminAddParamUiModal'
import DeleteCheckModal from 'src/components/DeleteCheckModal'
import { useAdminSensorConfigurableSetting, useAdminSensorSetting } from 'src/hooks/sensorSetting'
import AddSensorSettingGroup from 'src/components/AdminSensorSettingModals/AddSensorSettingGroup'
import AddSensorConfigurableSetting from 'src/components/AdminSensorSettingModals/AddSensorConfigurableSetting'

const Title = styled(AppTitle)`
  display: inline-flex;
  align-items: center;
`

const CmdTag = styled(Tag)`
  display: inline-block;
  margin: 0 1em;
  font-size: 0.5em;
  padding: 0.4em 0.5em;
  line-height: 0.5em;
  text-transform: lowercase;
`

const Content = styled(AppContent)`
  padding: 1rem;
  justify-content: center;
`

const DropdownLink = styled.a`
  font-size: 1.5rem;
  color: ${props => props?.colors?.['card.menu.color'] || '#C4C4C4'};
  min-width: 25px;
  display: flex;
  justify-content: center;
`

const MenuItem = styled(Menu.Item)`
  text-align: center;

  button {
    width: 100%;
  }
`

/**
 * @typedef AdminSensorConfigSettingGroupPageProps
 * @type {object}
 *
 * @param {AdminSensorConfigSettingGroupPageProps} props
 * @returns {React.ReactNode}
 */

const AdminSensorConfigSettingGroupPage = ({ id }) => {
  const { loading, sensorSetting, refresh } = useAdminSensorSetting({ id })
  const { deleteConfigurableSetting } = useAdminSensorConfigurableSetting({ fetch: false })

  const handleClick = (record) => {
    const { id, type } = record
     if (type === 'preset') {
       navigate(routes.adminSensorConfigSetting({ id }))
      }
  }

  const handleDelete = (record) => {
    deleteConfigurableSetting(record.id)
      .then(() => refresh())
  }

  const columns = [
    {
      title: 'Label',
      dataIndex: 'label'
    },
    {
      title: 'name',
      dataIndex: 'name'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (text) => text === 'preset'
        ? <a>Go to presets</a>
        : text
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => {
        const menu = (
          <Menu>
            <MenuItem>
              <AddSensorConfigurableSetting
                shouldUpdate={refresh}
                tableData={sensorSetting?.SensorConfigurableSettings ?? []}
                record={record}
                edit
              />
            </MenuItem>
            <MenuItem>
              <DeleteCheckModal
                titleText={`Are you sure you want to delete this setting ${record?.label ? ` ${record?.label} (${record?.label})` : record?.name}?`}
                deleteFn={() => handleDelete(record)}
                deleteBtnText='Yes, delete'
                cancelBtnText='No, cancel'
              />
            </MenuItem>
          </Menu>
        )

        return (
          <div onClick={(e) => e.stopPropagation()}>
            <Dropdown
              overlay={menu}
              placement='bottomRight'
              trigger={['click']}
            >
              <DropdownLink className='ant-dropdown-link' onClick={e => e.stopPropagation()}>
                <i className='fas fa-ellipsis-v' />
              </DropdownLink>
            </Dropdown>
          </div>
        )
      }
    }
  ]

  return (
    <AppLayout>
      <AppHeader
        noMargin
        extra={(
          <AddSensorSettingGroup
            edit
            shouldUpdate={refresh}
            record={sensorSetting}
          />
        )}
      >
        <BackButton
          onClick={
            (e) => navigate(
              routes.adminContent({
                type: 'sensorTypes'
              }))
          }
        />
        <Title noMargin>
          {sensorSetting?.title}
        </Title>
      </AppHeader>
      <Content noPadding>
        <AdminList
          handleClick={handleClick}
          typeName='sensorConfigurableSetting'
          data={sensorSetting?.SensorConfigurableSettings ?? []}
          columns={columns}
          loading={loading}
          shouldUpdate={refresh}
          modalProps={{
            SensorSettingGroupId: id
          }}
        />
      </Content>
    </AppLayout>
  )
}

export default AdminSensorConfigSettingGroupPage
